import {
  READ_ORGANIZATION,
  VIEW_ACCESS_TOKENS,
  VIEW_AGENTS,
  VIEW_GROUPS,
  VIEW_OBSERVABILITY,
  VIEW_PROFILES,
  VIEW_REPOSITORIES,
  VIEW_ROLES,
  VIEW_SECRET_STORES,
  VIEW_USERS,
} from "constants/rbac";
import { USERS_PAGE_URL } from "legacy/constants/routes";
import { ROLES_AND_PERMISSIONS_URL } from "pages/routes";
import { Flag } from "store/slices/featureFlags";
import { useFeatureFlag } from "../useFeatureFlag";
import { useAuthorizationCheck } from "./useAuthorizationCheck";

const ORG_PERMISSIONS = [
  {
    permission: READ_ORGANIZATION,
    pathname: "/general",
  },
  {
    permission: VIEW_USERS,
    pathname: "/users",
  },
  {
    permission: VIEW_GROUPS,
    pathname: "/groups",
  },
  {
    permission: VIEW_ROLES,
    pathname: ROLES_AND_PERMISSIONS_URL(),
  },
  {
    permission: VIEW_PROFILES,
    pathname: "/profiles",
  },
  {
    permission: VIEW_REPOSITORIES,
    pathname: "/repositories",
  },
  {
    permission: VIEW_OBSERVABILITY,
    pathname: "/observability",
  },
  {
    permission: VIEW_SECRET_STORES,
    pathname: "/secrets-management",
  },
  {
    permission: VIEW_AGENTS,
    pathname: "/opas",
  },
  {
    permission: VIEW_ACCESS_TOKENS,
    pathname: "/access-tokens",
  },
];

export const useOrganizationSettingsLink = (): {
  canViewOrgSettings: boolean;
  pathname: string;
} => {
  const rbacV2Enabled = useFeatureFlag(Flag.ENABLE_RBAC_V2);
  const results = useAuthorizationCheck(
    ORG_PERMISSIONS.map((p) => p.permission),
  );

  if (!rbacV2Enabled) {
    return {
      canViewOrgSettings: true,
      pathname: USERS_PAGE_URL,
    };
  }

  const firstValidPermission = ORG_PERMISSIONS.find((p, i) => results[i]);

  if (!firstValidPermission) {
    return {
      canViewOrgSettings: false,
      pathname: "",
    };
  }

  return {
    canViewOrgSettings: true,
    pathname: firstValidPermission?.pathname ?? "",
  };
};
