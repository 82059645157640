import { createReducer } from "@reduxjs/toolkit";
import {
  DataTreeGroup,
  DataTreeUser,
  Profile,
  RouteDef,
  getDefaultRoute,
} from "@superblocksteam/shared";
import {
  createPageSuccess,
  createRoute,
  updateCurrentRoute,
} from "legacy/actions/pageActions";
import {
  ApplicationGlobalPayload,
  ReduxAction,
  ReduxActionTypes,
} from "legacy/constants/ReduxActionConstants";
import { User } from "legacy/constants/userConstants";
import { generateReactKey } from "legacy/utils/generators";
import { fetchApplicationSuccess } from "store/slices/application/applicationActions";
import { APP_MODE, ModeMap } from "../types";

export type UrlDataState = {
  queryParams: Record<string, string>;
  protocol: string;
  host: string;
  hostname: string;
  port: string;
  pathname: string;
  hash: string;
  fullPath: string;
};

type AppGlobalState = ApplicationGlobalPayload;

export type Profiles = {
  available: Profile[];
  selected?: Profile;
  default: Profile;
};

export type AppDataState = {
  mode?: APP_MODE;
  user: DataTreeUser;
  URL: UrlDataState;
  store: Record<string, unknown>;
  groups: DataTreeGroup[];
  global: AppGlobalState;
  // TODO: these types should be string only
  deployedAt?: string | Date | null;
  createdAt?: string | Date;
  selectedProfileId?: string;
  profiles?: Profiles;
  routes: { [id: string]: RouteDef };
  currentRoute?: {
    routeDef: RouteDef;
    params: Record<string, string>;
  };
  app?: {
    id: string;
    name: string;
  };
};

const initialState: AppDataState = {
  user: {
    name: "",
    email: "",
    id: "",
    groups: [],
    username: "",
    metadata: {},
  },
  groups: [],
  URL: {
    queryParams: {},
    protocol: "",
    host: "",
    hostname: "",
    port: "",
    pathname: "",
    hash: "",
    fullPath: "",
  },
  store: {},
  profiles: undefined,
  selectedProfileId: undefined,
  global: {
    user: {
      name: "",
      username: "",
      email: "",
      id: "",
      groups: [],
      metadata: {},
    },
    groups: [],
    versions: {
      current: {
        tag: "",
        description: "",
        deployedAt: "",
        deployedBy: {
          email: "",
        },
      },
    },
    app: {
      id: "",
      name: "",
    },
  },
  routes: {},
  currentRoute: undefined,
};

const appReducer = createReducer(initialState, (builder) =>
  builder
    .addCase(
      ReduxActionTypes.SET_APP_MODE,
      (
        state: AppDataState,
        action: ReduxAction<APP_MODE, typeof ReduxActionTypes.SET_APP_MODE>,
      ) => {
        return {
          ...state,
          mode: action.payload,
        };
      },
    )
    .addCase(
      ReduxActionTypes.FETCH_USER_DETAILS_SUCCESS,
      (
        state: AppDataState,
        action: ReduxAction<
          User,
          typeof ReduxActionTypes.FETCH_USER_DETAILS_SUCCESS
        >,
      ) => {
        const user: DataTreeUser = {
          id: action.payload.id,
          email: action.payload.email,
          groups: action.payload.groups.map(
            (group) => new DataTreeGroup(group),
          ),
          name: action.payload.name,
          username: action.payload.username,
          metadata: action.payload.metadata,
        };
        return {
          ...state,
          global: {
            ...state.global,
            user,
          },
          user,
        };
      },
    )
    .addCase(fetchApplicationSuccess, (state: AppDataState, action) => {
      const mode = state.mode && ModeMap[state.mode];
      const initialPageId = action.payload.pageSummaryList?.[0]?.id as string;
      const defaultRoute = getDefaultRoute(initialPageId);

      return {
        ...state,
        ...action.payload.global,
        createdAt: action.payload.created as any,
        deployedAt: action.payload.deployedAt as any,
        ...(mode
          ? {
              // init with default
              selectedProfileId:
                action.payload.settings?.profiles?.[mode]?.defaultProfileId,
            }
          : {}),
        app: {
          id: action.payload.id,
          name: action.payload.name,
        },
        routes: action.payload.configuration?.routes || {
          [defaultRoute.id]: defaultRoute,
        },
      } satisfies AppDataState;
    })
    .addCase(
      ReduxActionTypes.SET_URL_DATA,
      (
        state: AppDataState,
        action: ReduxAction<UrlDataState, typeof ReduxActionTypes.SET_URL_DATA>,
      ) => {
        return {
          ...state,
          URL: action.payload,
        };
      },
    )
    .addCase(
      ReduxActionTypes.CURRENT_APPLICATION_NAME_UPDATE,
      (
        state: AppDataState,
        action: ReduxAction<
          string,
          typeof ReduxActionTypes.CURRENT_APPLICATION_NAME_UPDATE
        >,
      ) => {
        if (!state.app) {
          return state;
        }
        return {
          ...state,
          app: {
            ...state.app,
            name: action.payload,
          },
        };
      },
    )
    .addCase(
      ReduxActionTypes.CURRENT_APPLICATION_IS_PUBLIC_UPDATE,
      (
        state: AppDataState,
        action: ReduxAction<
          boolean,
          typeof ReduxActionTypes.CURRENT_APPLICATION_IS_PUBLIC_UPDATE
        >,
      ) => {
        if (!state.app) {
          return state;
        }
        return {
          ...state,
          app: {
            ...state.app,
            isPublic: action.payload,
          },
        };
      },
    )
    .addCase(
      ReduxActionTypes.UPDATE_APP_STORE,
      (
        state: AppDataState,
        action: ReduxAction<
          Record<string, unknown>,
          typeof ReduxActionTypes.UPDATE_APP_STORE
        >,
      ) => {
        return {
          ...state,
          store: action.payload,
        };
      },
    )
    .addCase(
      ReduxActionTypes.SET_PROFILE,
      (
        state: AppDataState,
        action: ReduxAction<
          { profileId: string },
          typeof ReduxActionTypes.SET_PROFILE
        >,
      ) => {
        return {
          ...state,
          selectedProfileId: action.payload.profileId,
        };
      },
    )
    .addCase(
      ReduxActionTypes.UPDATE_PROFILES,
      (
        state: AppDataState,
        action: ReduxAction<
          {
            available: Profile[];
            selected?: Profile;
            default: Profile;
          },
          typeof ReduxActionTypes.UPDATE_PROFILES
        >,
      ) => {
        return {
          ...state,
          profiles: { ...action.payload },
        };
      },
    )
    .addCase(createRoute, (state, action) => {
      const routeId = action.payload.routeDef.id ?? generateReactKey();
      const route = {
        ...action.payload.routeDef,
        id: routeId,
      } satisfies RouteDef;
      return {
        ...state,
        routes: {
          ...state.routes,
          [routeId]: route,
        },
      };
    })
    .addCase(
      ReduxActionTypes.DELETE_ROUTE,
      (
        state: AppDataState,
        action: ReduxAction<
          {
            id: string;
          },
          typeof ReduxActionTypes.DELETE_ROUTE
        >,
      ) => {
        delete state.routes[action.payload.id];
        return state;
      },
    )
    .addCase(
      ReduxActionTypes.UPDATE_ROUTE_PROPERTIES,
      (
        state: AppDataState,
        action: ReduxAction<
          Record<string, RouteDef>,
          typeof ReduxActionTypes.UPDATE_ROUTE_PROPERTIES
        >,
      ) => {
        return {
          ...state,
          routes: {
            ...state.routes,
            ...action.payload,
          },
        };
      },
    )
    .addCase(
      ReduxActionTypes.DELETE_ROUTES,
      (
        state: AppDataState,
        action: ReduxAction<string[], typeof ReduxActionTypes.DELETE_ROUTES>,
      ) => {
        return {
          ...state,
          routes: Object.fromEntries(
            Object.entries(state.routes).filter(
              ([key]) => !action.payload.includes(key),
            ),
          ),
        };
      },
    )
    .addCase(updateCurrentRoute, (state: AppDataState, action) => {
      if (!action.payload) {
        delete state.currentRoute;
        return;
      }
      return {
        ...state,
        currentRoute: action.payload,
      };
    })
    .addCase(createPageSuccess, (state: AppDataState, action) => {
      if (!action.payload.configuration?.routes) return state;
      return {
        ...state,
        routes: action.payload.configuration.routes,
      };
    }),
);

export default appReducer;
