import { RoleDto, RoleTypeEnum } from "@superblocksteam/shared";
import { Button, Dropdown, Tooltip } from "antd";
import Fuse from "fuse.js";
import { debounce, groupBy } from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { ReactComponent as MoreIcon } from "assets/icons/common/dotdotdot.svg";
import { ReactComponent as PlusIcon } from "assets/icons/common/plus.svg";
import { PrimaryButton, SecondaryButton } from "components/ui/Button";
import { CollapseSectionButton } from "components/ui/CollapseSectionButton";
import RecommendedTable from "components/ui/RecommendedTable";
import { SearchContainer, SearchInput } from "components/ui/SearchSection";
import { MANAGE_ROLES } from "constants/rbac";
import { ROLE_TYPES } from "constants/rbac";
import { usePrevious } from "hooks/ui";
import { useAuthorizationCheck } from "hooks/ui/rbac/useAuthorizationCheck";
import { useEditPermissions } from "hooks/ui/rbac/useEditPermissions";
import { getCurrentOrgId } from "legacy/selectors/organizationSelectors";
import { colors } from "styles/colors";
import { styleAsClass } from "styles/styleAsClass";
import { useListRolesQuery } from "../../store/slices/reduxApi/rbac";
import { CreateRoleModal } from "./CreateRoleModal";
import {
  BottomBarClass,
  filterAndTransformHighights,
  formatRoleTableData,
  getRoleTableColumns,
  getRoleTypeName,
  NestedRoleTableItem,
} from "./Shared";

const TableHeader = styleAsClass`
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  color: ${colors.GREY_900};
  display: flex;
  align-items: center;
  gap: 12px;
  height: 32px;
  margin-bottom: 12px;
  margin-top: 24px;
`;

const ResourceRoleTable = ({
  rowData,
  roleType,
  isLoading,
  roles,
  searchTerm,
  isExpanded,
  onExpandChange,
  handleEdit,
  handleAllowAll,
  handleClearAll,
}: {
  rowData: Array<NestedRoleTableItem>;
  roleType: RoleTypeEnum;
  isLoading: boolean;
  roles: Array<RoleDto>;
  searchTerm: string;
  isExpanded: boolean;
  onExpandChange: (roleType: RoleTypeEnum, isExpanded: boolean) => void;
  handleEdit: (change: {
    updates: Record<string, boolean>;
    roleId: string;
    roleType: RoleTypeEnum;
  }) => void;
  handleAllowAll: (props: { roleId: string; roleType: RoleTypeEnum }) => void;
  handleClearAll: (props: { roleId: string; roleType: RoleTypeEnum }) => void;
}) => {
  const [canAddRoles] = useAuthorizationCheck([MANAGE_ROLES]);
  const columns = useMemo(
    () =>
      getRoleTableColumns({
        data: roles,
        roleType,
        useFixedColumnWidth: true,
        onCheckboxToggled: handleEdit,
        onAllowAll: handleAllowAll,
        onClearAll: handleClearAll,
      }),
    [roles, handleEdit, roleType, handleAllowAll, handleClearAll],
  );
  const [filteredRowData, setFilteredRowData] =
    useState<Array<NestedRoleTableItem>>(rowData);

  const fuse = useMemo(
    () =>
      new Fuse(rowData, {
        shouldSort: false,
        threshold: 0.1,
        distance: 100,
        ignoreLocation: true,
        minMatchCharLength: 1,
        findAllMatches: true,
        keys: ["name"],
        includeMatches: true,
      }),
    [rowData],
  );
  useEffect(() => {
    if (!searchTerm) {
      setFilteredRowData(rowData);
      return;
    }

    const results = fuse.search(searchTerm);
    const withHighlights: Array<NestedRoleTableItem> = results.map((result) => {
      return {
        ...result.item,
        highlights: filterAndTransformHighights(
          searchTerm,
          result?.matches?.[0]?.indices,
        ),
      } as NestedRoleTableItem;
    });
    setFilteredRowData(withHighlights);
  }, [searchTerm, fuse, rowData]);

  const prevSearchTerm = usePrevious(searchTerm);
  useEffect(() => {
    if (
      searchTerm &&
      prevSearchTerm !== searchTerm &&
      filteredRowData.length > 0 &&
      !isExpanded
    ) {
      onExpandChange(roleType, true);
    }
  }, [
    filteredRowData,
    onExpandChange,
    roleType,
    searchTerm,
    prevSearchTerm,
    isExpanded,
  ]);

  const [isCreateRoleModalOpen, setIsCreateRoleModalOpen] = useState(false);
  if (searchTerm && filteredRowData.length === 0) {
    return null;
  }
  return (
    <div style={{ position: "relative" }}>
      {isCreateRoleModalOpen && (
        <CreateRoleModal
          onClose={() => setIsCreateRoleModalOpen(false)}
          roleType={roleType}
        />
      )}
      <div className={TableHeader}>
        <span>{getRoleTypeName(roleType)}</span>
        <CollapseSectionButton
          onClick={() => onExpandChange(roleType, !isExpanded)}
          isCollapsed={!isExpanded}
        />
      </div>
      {isExpanded && (
        <>
          <RecommendedTable
            columns={columns}
            data={filteredRowData ?? []}
            loading={isLoading}
            uniqueKey="name"
            canExpandRows={false}
            useFixedColumnWidths={true}
          />
          <Tooltip
            title={
              canAddRoles
                ? "Create custom role"
                : "You do not have permission to create roles"
            }
          >
            <SecondaryButton
              onClick={() => setIsCreateRoleModalOpen(true)}
              disabled={!canAddRoles}
              style={{
                position: "absolute",
                padding: "4px",
                height: "24px",
                top: "52px",
                right: "8px",
              }}
            >
              <PlusIcon height={16} width={16} />
            </SecondaryButton>
          </Tooltip>
        </>
      )}
    </div>
  );
};

const ALL_ROLES_EXPANDED = ROLE_TYPES.reduce(
  (acc, key) => {
    acc[key] = true;
    return acc;
  },
  {} as Record<RoleTypeEnum, boolean>,
);

const ALL_ROLES_COLLAPSED = ROLE_TYPES.reduce(
  (acc, key) => {
    acc[key] = false;
    return acc;
  },
  {} as Record<RoleTypeEnum, boolean>,
);

export const ResourceRoles = () => {
  const organizationId = useSelector(getCurrentOrgId);
  const { data: roles, isLoading } = useListRolesQuery({ organizationId });
  const rawRolesByType = useMemo(() => {
    if (!roles) return null;
    return groupBy(roles, "type") as Record<RoleTypeEnum, RoleDto[]>;
  }, [roles]);

  const {
    handleEdit,
    handleAllowAll,
    handleClearAll,
    handleReset,
    handleSave,
    permissionEditsByType,
    numEdits,
    isSaving,
  } = useEditPermissions({
    rolesByType: rawRolesByType ?? {},
  });

  const formattedRowDataByType = useMemo(() => {
    const formattedByType = ROLE_TYPES.reduce(
      (acc, key) => {
        acc[key] = rawRolesByType?.[key]
          ? formatRoleTableData(rawRolesByType[key], permissionEditsByType[key])
          : [];
        return acc;
      },
      {} as Record<RoleTypeEnum, NestedRoleTableItem[]>,
    );
    return formattedByType;
  }, [rawRolesByType, permissionEditsByType]);

  const [searchTerm, setSearchTerm] = useState("");
  const onSearchChangeDebounced = useMemo(
    () => debounce(setSearchTerm, 100),
    [],
  );

  const [expandedState, setExpandedState] =
    useState<Record<RoleTypeEnum, boolean>>(ALL_ROLES_EXPANDED);

  const menuItems = useMemo(() => {
    return [
      {
        key: "expand-all",
        label: "Expand all",
        onClick: () => setExpandedState(ALL_ROLES_EXPANDED),
      },
      {
        key: "collapse-all",
        label: "Collapse all",
        onClick: () => setExpandedState(ALL_ROLES_COLLAPSED),
      },
    ];
  }, []);

  const handleRoleExpandChange = (
    roleType: RoleTypeEnum,
    isExpanded: boolean,
  ) => {
    setExpandedState((prev) => ({
      ...prev,
      [roleType]: isExpanded,
    }));
  };

  return (
    <>
      <div style={numEdits > 0 ? { paddingBottom: 64 } : {}}>
        <div className={SearchContainer}>
          <SearchInput
            placeholder="Search permissions by name"
            onChange={(e) => onSearchChangeDebounced(e.target.value)}
          />
          <Dropdown menu={{ items: menuItems }} trigger={["click"]}>
            <Button
              icon={
                <MoreIcon
                  style={{
                    position: "relative",
                    top: "-3px",
                    color: colors.GREY_500,
                    minWidth: 30,
                  }}
                />
              }
            />
          </Dropdown>
        </div>
        {ROLE_TYPES.map((roleType) => (
          <ResourceRoleTable
            rowData={formattedRowDataByType?.[roleType] ?? []}
            roles={rawRolesByType?.[roleType] ?? []}
            roleType={roleType}
            key={roleType}
            isLoading={isLoading}
            searchTerm={searchTerm}
            isExpanded={expandedState[roleType]}
            onExpandChange={handleRoleExpandChange}
            handleEdit={handleEdit}
            handleAllowAll={handleAllowAll}
            handleClearAll={handleClearAll}
          />
        ))}
      </div>
      {numEdits > 0 && (
        <div className={BottomBarClass}>
          {numEdits} unsaved change{numEdits > 1 ? "s" : ""}
          <SecondaryButton onClick={handleReset} disabled={isSaving}>
            Reset
          </SecondaryButton>
          <PrimaryButton onClick={handleSave} disabled={isSaving}>
            Save
          </PrimaryButton>
        </div>
      )}
    </>
  );
};
