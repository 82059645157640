import { debounce } from "lodash";
import React from "react";
import { EditorModes } from "components/app/CodeEditor/EditorConfig";
import BaseControl, { ControlLayout, ControlProps } from "./BaseControl";
import { CodeEditorInput } from "./CodeEditorInput";
import type { InputType } from "legacy/widgets/InputWidget/InputWidgetConstants";

export const CHARACTERS_PER_PX = 18 / 280; // 18 characters in 280px

class InputTextControl extends BaseControl<InputControlProps> {
  render() {
    const {
      expected,
      exampleData,
      docLink,
      propertyValue,
      isValid,
      label,
      placeholderText,
      dataTreePath,
      validationMessage,
      apiErrors,
      defaultValue,
      isTriggerProperty,
      additionalAutoComplete,
      onValueChange,
      onFocus,
      dataTest,
      minLines,
      widgetProperties,
      propertyName,
      getDynamicProperties,
      codeEditorMode,
      isDisabled,
      getPopupWrapperElem,
    } = this.props;

    return (
      <CodeEditorInput
        dataTest={dataTest}
        label={label}
        value={propertyValue ?? defaultValue}
        onChange={onValueChange ?? this.onTextChange}
        isValid={isValid}
        minLines={minLines}
        validationError={validationMessage}
        apiErrors={apiErrors}
        expected={expected}
        exampleData={exampleData}
        docLink={docLink}
        placeholderText={placeholderText}
        dataTreePath={dataTreePath}
        isTriggerProperty={isTriggerProperty}
        additionalAutoComplete={additionalAutoComplete}
        onFocus={onFocus}
        onBlur={this.onBlur}
        widgetProperties={widgetProperties}
        propertyName={propertyName}
        getDynamicProperties={getDynamicProperties}
        codeEditorMode={codeEditorMode ?? EditorModes.TEXT_WITH_BINDING}
        showJsExprModeIndicator={false}
        isDisabled={isDisabled}
        itemScope={this.props.itemScope}
        getPopupWrapperElem={getPopupWrapperElem}
        canExpandEditor={this.props.canExpandEditor}
        itemKind={this.props.itemKind}
      />
    );
  }

  isNumberType(): boolean {
    const { inputType } = this.props;
    switch (inputType) {
      case "CURRENCY":
      case "INTEGER":
      case "NUMBER":
      case "PHONE_NUMBER":
        return true;
      default:
        return false;
    }
  }

  updatePropertyDebounced = debounce(this.updateProperty, 500);
  lastValueIsEmpty: boolean = false; // we want to set the value to a nullish value on blur/dismount if the user cleared the text

  handlePotentiallyEmptyValue() {
    if (this.lastValueIsEmpty && this.props.isRemovable) {
      this.updateProperty(this.props.propertyName, undefined);
      this.lastValueIsEmpty = false;
    }
  }

  componentDidMount() {
    this.changeControlLayoutIfNeeded(this.props.propertyValue);
  }

  componentWillUnmount() {
    this.handlePotentiallyEmptyValue();
    this.updatePropertyDebounced.flush();
  }

  onTextChange = (event: React.ChangeEvent<HTMLTextAreaElement> | string) => {
    let value = event;
    if (typeof event !== "string") {
      value = event.target.value;
    }

    // The updateProperty function creates a synchronous re-render, so we wait for after the user finishes typing
    // to avoid blocking the main thread during typing. Other controls don't have this problem because text is
    // more noticeable than dropdowns, etc
    this.updatePropertyDebounced(this.props.propertyName, value);
    this.changeControlLayoutIfNeeded(value);
    this.lastValueIsEmpty = value === "";
  };

  onBlur = () => {
    this.handlePotentiallyEmptyValue();
    this.updatePropertyDebounced.flush();
    this.props.onBlur?.();

    if (this.lastLayout) {
      this.props.setControlLayout?.(this.lastLayout);
      this.hasBeenVertical = undefined;
    }
  };

  hasBeenVertical?: boolean = undefined;
  lastLayout: ControlLayout | undefined;
  changeControlLayoutIfNeeded = (value: any) => {
    const { getPanelWidth } = this.props;
    const panelWidth = getPanelWidth?.();
    if (!panelWidth) {
      return;
    }
    const layout = InputTextControl.computeControlLayout(value, panelWidth);

    if (layout) {
      this.lastLayout = layout;
      if (layout === ControlLayout.VERTICAL_FLEX) {
        this.hasBeenVertical = true;
        this.props.setControlLayout?.(layout);
      } else if (!this.hasBeenVertical) {
        this.props.setControlLayout?.(layout);
      }
    }
  };

  static computeControlLayout(propertyValue: any, panelWidth: number) {
    if (typeof propertyValue === "string") {
      const layout =
        propertyValue.length > CHARACTERS_PER_PX * panelWidth ||
        propertyValue.includes("\n")
          ? ControlLayout.VERTICAL_FLEX
          : ControlLayout.GRID;

      return layout;
    }
  }

  static getControlLayout({
    propertyValue,
    panelWidth,
  }: {
    propertyValue: any;
    panelWidth: number;
  }) {
    return InputTextControl.computeControlLayout(propertyValue, panelWidth);
  }

  static getControlType() {
    return "INPUT_TEXT";
  }
}

export interface InputControlProps extends ControlProps {
  placeholderText?: string;
  inputType?: InputType;
  minLines?: number;
  validationMessage?: string;
  isDisabled?: boolean;
  defaultValue?: any;
  codeEditorMode?: EditorModes;
  onValueChange?: (
    event: React.ChangeEvent<HTMLTextAreaElement> | string,
  ) => void;
  getDynamicProperties?: (
    controlProps: any,
  ) => undefined | Partial<InputControlProps>;
}

export default InputTextControl;
