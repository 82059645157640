import { Classes } from "@blueprintjs/core";
import { RoleDto, RoleTypeEnum } from "@superblocksteam/shared";
import { Typography } from "antd";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import {
  DropdownOption,
  RecommendedSingleDropdown,
} from "components/ui/RecommendedSingleDropdown";
import { OWNER_ROLE_NAME } from "constants/rbac";
import { VIEW_ROLES } from "constants/rbac";
import { useFeatureFlag } from "hooks/ui";
import { useAuthorizationCheck } from "hooks/ui/rbac/useAuthorizationCheck";
import { SUPPORT_EMAIL_ADDRESS } from "legacy/constants/routes";
import { getCurrentOrgId } from "legacy/selectors/organizationSelectors";
import { Flag } from "store/slices/featureFlags";
import {
  useDeleteOrganizationRoleMutation,
  useGetRoleSettingsQuery,
  useListRolesQuery,
  useUpdateOrganizationRoleMutation,
} from "store/slices/reduxApi/rbac";
import { colors } from "styles/colors";
import { styleAsClass } from "styles/styleAsClass";
import {
  sendErrorUINotification,
  sendSuccessUINotification,
} from "utils/notification";
import ConfirmRoleChangeModal from "./ConfirmRoleChangeModal";

const OrgRoleWrapper = styleAsClass`
  min-width: 120px;
  max-width: 150px;
  .${Classes.INPUT} {
    border-color: transparent !important;
  }
  &:hover {
    .${Classes.INPUT} {
      border-color: ${colors.GREY_100} !important;
    }
  }
`;

export const RoleDropdown = ({
  selectedRole,
  onRoleChanged,
  currentRoleName,
  isClearable,
  isDisabled,
  roleType,
  parentRef,
  defaultRoleName,
  filterRoles,
  isForResourceCreator,
  shouldAutoUpdate,
  tooltip,
}: {
  selectedRole: null | string;
  onRoleChanged: (
    roleId: null | string,
    roleName?: null | string,
  ) => void | Promise<void>;
  currentRoleName?: string; // Role name from user/group response if user has no manage role permission
  isClearable?: boolean;
  isDisabled?: boolean;
  roleType: RoleTypeEnum;
  parentRef?: React.RefObject<HTMLDivElement>;
  defaultRoleName?: string;
  filterRoles?: (role: RoleDto) => boolean;
  isForResourceCreator?: boolean;
  shouldAutoUpdate?: boolean;
  tooltip?: React.ReactNode;
}) => {
  const organizationId = useSelector(getCurrentOrgId);
  const rbacV2Enabled = useFeatureFlag(Flag.ENABLE_RBAC_V2);
  const [canViewRoles] = useAuthorizationCheck([VIEW_ROLES]);

  const { data: fetchedRoles, isError } = useListRolesQuery(
    {
      type: roleType,
      organizationId,
    },
    {
      skip: !rbacV2Enabled || !canViewRoles,
    },
  );

  const { data: roleSettings } = useGetRoleSettingsQuery(undefined, {
    skip: !rbacV2Enabled || !canViewRoles,
  });

  const selectedRoleName = useMemo(
    () => fetchedRoles?.find((role) => role.id === selectedRole)?.name,
    [selectedRole, fetchedRoles],
  );
  const ownerIsSelected = selectedRoleName === OWNER_ROLE_NAME;

  const roleOptions = useMemo(() => {
    if (!isError && fetchedRoles) {
      return fetchedRoles
        .filter((role) => {
          const shouldShow = filterRoles ? filterRoles(role) : true;
          if (!shouldShow) return false;
          return ownerIsSelected || role.name !== OWNER_ROLE_NAME;
        })
        .map((role) => {
          return {
            key: role.name,
            value: role.id,
            displayName: role.name,
            suffixTag: role.organizationId ? "Custom" : undefined,
            subText: role.description,
          };
        });
    } else if (currentRoleName) {
      // if users has no read roles permission, show the role from user/group dto (with dropdown disabled)
      return [
        {
          key: selectedRole ?? currentRoleName,
          value: selectedRole ?? currentRoleName,
          displayName: currentRoleName,
        },
      ];
    }
  }, [
    isError,
    fetchedRoles,
    currentRoleName,
    filterRoles,
    ownerIsSelected,
    selectedRole,
  ]);

  const defaultRole = useMemo(() => {
    const defaultRoleId =
      roleType === RoleTypeEnum.ORGANIZATION
        ? roleSettings?.[RoleTypeEnum.ORGANIZATION]?.new_user
        : roleSettings?.[roleType]?.[isForResourceCreator ? "creator" : "base"];

    return roleOptions
      ? roleOptions.find((role) =>
          defaultRoleId
            ? role.value === defaultRoleId
            : role.displayName === defaultRoleName,
        )?.value
      : undefined;
  }, [
    defaultRoleName,
    isForResourceCreator,
    roleOptions,
    roleSettings,
    roleType,
  ]);

  useEffect(() => {
    if (!isClearable && defaultRole && !selectedRole && shouldAutoUpdate) {
      // auto select default role if no role is selected
      onRoleChanged(defaultRole, defaultRoleName);
    }
  }, [
    defaultRole,
    defaultRoleName,
    isClearable,
    onRoleChanged,
    selectedRole,
    shouldAutoUpdate,
  ]);

  const [isUpdating, setIsUpdating] = useState(false);
  const onUpdateRole = useCallback(
    async (
      selectedItem?: DropdownOption,
      event?: React.SyntheticEvent<HTMLElement>,
    ) => {
      setIsUpdating(true);
      event?.stopPropagation();

      await onRoleChanged(
        selectedItem ? selectedItem.value : null,
        selectedItem ? selectedItem.displayName : null,
      );
      setIsUpdating(false);
    },
    [onRoleChanged],
  );

  return (
    <RecommendedSingleDropdown
      tooltip={
        ownerIsSelected ? (
          <div>
            Owner role changes are restricted.{" "}
            <Typography.Link
              href={`mailto:${SUPPORT_EMAIL_ADDRESS}`}
              target="_blank"
            >
              {" "}
              Contact support
            </Typography.Link>{" "}
            to reassign this role.
          </div>
        ) : (
          tooltip
        )
      }
      options={roleOptions ?? []}
      value={selectedRole ?? undefined}
      onChange={onUpdateRole}
      disabled={isUpdating || isDisabled || ownerIsSelected}
      allowClearing={isClearable}
      placeholder="No role"
      popoverProps={{
        matchTargetWidth: false,
      }}
      minDropdownWidth={{
        width: 340,
        atLeastTargetWidth: true,
      }}
      parentRef={parentRef}
    />
  );
};

// Used on the user + group pages to pick a user or group's organization-level role
export const OrganizationRoleDropdown = ({
  currentRole,
  currentRoleName,
  principalType,
  principalId,
  principalName,
  onRoleChanged,
  isClearable,
  isDisabled,
  currentRoleAssignmentId,
  tooltip,
}: {
  currentRole: string;
  currentRoleName: string;
  principalType: "group" | "user";
  principalId: string;
  principalName: string;
  onRoleChanged: (
    principalId: string,
    updates: Record<string, string | null>,
  ) => void;
  isClearable?: boolean;
  isDisabled?: boolean;
  currentRoleAssignmentId: string;
  tooltip?: React.ReactNode;
}) => {
  const organizationId = useSelector(getCurrentOrgId);
  const [updateRole] = useUpdateOrganizationRoleMutation();
  const [deleteRole] = useDeleteOrganizationRoleMutation();

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [pendingRole, setPendingRole] = useState<string | null>(null);
  const [pendingRoleName, setPendingRoleName] = useState<string | null>(null);

  const onUpdateRole = useCallback(
    async (roleId?: string | null) => {
      try {
        if (!roleId) {
          await deleteRole({
            principalId,
            principalType,
            role: currentRoleAssignmentId,
            organizationId,
          }).unwrap();
          sendSuccessUINotification({
            message: "Role removed",
            duration: 5,
          });
          onRoleChanged(principalId, {
            roleId: null,
            roleAssignmentId: null,
            roleName: null,
          });
          return;
        } else {
          const response = await updateRole({
            principalId,
            principalType,
            role: roleId,
            organizationId: organizationId,
          }).unwrap();

          sendSuccessUINotification({
            message: "Role updated",
            duration: 5,
          });
          onRoleChanged(principalId, {
            roleId: response.data.assignmentId,
            roleAssignmentId: response.data.id,
            roleName: response.data?.role?.name ?? pendingRoleName,
          });
        }
      } catch (e: any) {
        sendErrorUINotification({
          key: "update-role",
          message: e?.error || e?.error?.message || "Failed to update role",
          duration: 5,
        });
      }
    },
    [
      deleteRole,
      principalId,
      principalType,
      currentRoleAssignmentId,
      organizationId,
      onRoleChanged,
      updateRole,
      pendingRoleName,
    ],
  );

  const handleConfirm = useCallback(async () => {
    await onUpdateRole(pendingRole);
    setIsModalVisible(false);
    setPendingRole(null);
    setPendingRoleName(null);
  }, [onUpdateRole, pendingRole]);

  const handleCancel = useCallback(() => {
    setIsModalVisible(false);
    setPendingRole(null);
    setPendingRoleName(null);
  }, []);

  const handleRoleChange = useCallback(
    (roleId?: string | null, roleName?: string | null) => {
      setPendingRole(roleId ?? null);
      setPendingRoleName(roleName ?? null);
      setIsModalVisible(true);
    },
    [],
  );

  return (
    <div className={OrgRoleWrapper}>
      <RoleDropdown
        isDisabled={isDisabled}
        isClearable={isClearable}
        selectedRole={currentRole}
        currentRoleName={currentRoleName}
        onRoleChanged={handleRoleChange}
        roleType={RoleTypeEnum.ORGANIZATION}
        tooltip={tooltip}
      />
      <ConfirmRoleChangeModal
        isVisible={isModalVisible}
        oldRole={currentRoleName}
        newRole={pendingRoleName ?? ""}
        principalName={principalName}
        principalType={principalType}
        onCancel={handleCancel}
        onConfirm={handleConfirm}
        isDeleting={pendingRoleName === null}
      />
    </div>
  );
};
