import { ControlGroup, Classes, InputGroupProps } from "@blueprintjs/core";
import { DateInput3, TimePrecision } from "@blueprintjs/datetime2";
import moment, { isMoment } from "moment-timezone";
import React, { useMemo } from "react";
import styled from "styled-components";
import {
  DatePickerActionButton,
  DatePickerActionsWrapper,
} from "components/ui/Datepicker";
import DynamicSVG from "components/ui/DynamicSVG";
import { FormInputWithErrorWrapperStyle } from "legacy/components/editorComponents/ErrorInlineMessage";
import WidgetErrorsWrapper from "legacy/components/editorComponents/WidgetErrorsWrapper";
import { WidgetLabelPosition } from "legacy/constants/WidgetConstants";
import { ISO_DATE_FORMAT } from "legacy/constants/WidgetValidation";
import { CLASS_NAMES } from "legacy/themes/classnames";
import {
  getLabelWidthCssValue,
  labelStyleRaw,
} from "../Shared/widgetLabelStyles";
import { DEFAULT_ICON_SIZE } from "../shared";
import { createStyleClassName } from "../typographyUtils";
import { DatePickerComponentProps } from "./types";
import {
  NO_TZ_FORMAT,
  convertDateStringTimezone,
  formatIncludesTime,
  formatIncludesTimezone,
  getTimePrecision,
  isValidDate,
  normalizeDateFormat,
  stripTimezoneFromDate,
  stripTimezoneFromString,
} from "./utils";

const StyledControlGroup = styled(ControlGroup)<{ $isValid?: boolean }>`
  height: 100%;
  &&& {
    .${Classes.INPUT} {
      width: 100%;
      height: inherit;
      align-items: center;
    }
    .${Classes.INPUT_GROUP} {
      display: block;
      margin: 0;
      height: 100%;
    }
    .${Classes.CONTROL_GROUP} {
      justify-content: flex-start;
    }
    label {
      align-self: flex-start;
      flex-shrink: 0;
      flex-grow: 0;
      text-align: left;
    }

    &[data-vertical="true"] {
      label {
        ${labelStyleRaw.vertical}
        max-width: 100%;
      }
    }

    &[data-vertical="false"] {
      label {
        ${labelStyleRaw.horizontal}
        flex-basis: var(--label-width);
      }
    }

    .${Classes.INPUT_LEFT_CONTAINER}, .${Classes.INPUT_ACTION} {
      display: flex;
      align-items: center;
      height: 100%;
    }

    .IconWrapper {
      padding: 0 8px;
    }
  }
`;

const Timezone = ({ timezone }: { timezone?: string }) => {
  const abbreviation = useMemo(() => {
    const tz = timezone ?? Intl.DateTimeFormat().resolvedOptions().timeZone;
    return moment().tz(tz).zoneAbbr();
  }, [timezone]);
  return (
    <div
      className={CLASS_NAMES.SYSTEM_TEXT}
      style={{
        margin: "0px 6px 0px 0px",
        padding: "2px 4px",
      }}
    >
      {abbreviation}
    </div>
  );
};

// these are awkward values but they are needed so that fit content height lines up exactly with fixed height for default configured inputs
export const INPUT_ADDITIONAL_MIN_HEIGHT = 17.7;
export const LABEL_EXTRA_HEIGHT_MARGIN = 4;

class DatePickerComponent extends React.Component<
  DatePickerComponentProps,
  DatePickerComponentState
> {
  constructor(props: DatePickerComponentProps) {
    super(props);
    this.state = {
      selectedDateFormatted: props.selectedDate,
      isOpen: undefined,
    };
  }

  componentDidMount(): void {
    if (this.props.forceOpen) {
      this.setState({
        isOpen: this.props.forceOpen,
      });
    }
  }

  componentDidUpdate(prevProps: DatePickerComponentProps) {
    const dateFormat = this.getNormalizedDateFormat();
    const precision = getTimePrecision(dateFormat);
    let newSelectedDate;
    if (
      this.props.selectedDate !== this.state.selectedDateFormatted &&
      !moment(this.props.selectedDate, dateFormat).isSame(
        moment(prevProps.selectedDate, dateFormat),
        precision === TimePrecision.MILLISECOND ? "milliseconds" : "seconds",
      ) &&
      moment(this.props.selectedDate, dateFormat).isValid()
    ) {
      newSelectedDate = this.props.selectedDate;
    }

    // if the date format has changed, ensure that the selected date is still valid
    if (this.props.dateFormat !== prevProps.dateFormat) {
      if (
        newSelectedDate == null &&
        this.props.selectedDate != null &&
        moment(this.props.selectedDate, dateFormat).isValid()
      ) {
        // when the date format changes, we may need to update the selected date
        newSelectedDate = this.props.selectedDate;
      }
      const selectedDate = newSelectedDate ?? this.state.selectedDateFormatted;
      if (
        selectedDate &&
        !moment
          .tz(
            selectedDate as any,
            dateFormat,
            this.props.timezone ?? moment.tz.guess(),
          )
          .isValid()
      ) {
        // try creating the formatted date by using previous format
        const prevFormat = prevProps.dateFormat || ISO_DATE_FORMAT;
        const prevDate = moment.tz(
          selectedDate as any,
          prevFormat,
          this.props.timezone ?? moment.tz.guess(),
        );
        if (prevDate.isValid()) {
          newSelectedDate = prevDate.format(
            this.props.dateFormat
              ? normalizeDateFormat(this.props.dateFormat)
              : undefined,
          );
        }
      }
    }
    if (newSelectedDate) {
      this.setState({
        selectedDateFormatted: newSelectedDate,
      });
    } else if (!this.props.selectedDate && this.state.selectedDateFormatted) {
      this.setState({
        selectedDateFormatted: undefined,
      });
    }
  }

  getValidDate = (date: string, format: string) => {
    const _date = moment(date, format);
    return _date.isValid() ? _date.toDate() : undefined;
  };

  getNormalizedDateFormat = () => {
    return normalizeDateFormat(this.props.dateFormat || ISO_DATE_FORMAT);
  };

  getNormalizedDisplayDateFormat = () => {
    return this.props.displayDateFormat
      ? normalizeDateFormat(this.props.displayDateFormat)
      : undefined;
  };

  shouldWrapTime = () => {
    const dateFormat = this.getNormalizedDateFormat();
    const showTime = formatIncludesTime(dateFormat);
    const timePrecision = getTimePrecision(dateFormat);
    return (
      showTime &&
      this.props.twentyFourHourTime &&
      timePrecision === TimePrecision.MINUTE
    );
  };

  handleInteraction = (openState: boolean) => {
    this.setState({
      isOpen: openState,
    });
  };

  getInitialMonth = (
    currentValue: string | undefined | null,
    minDate: Date | undefined,
    maxDate: Date | undefined,
  ) => {
    if (!minDate && !maxDate) {
      return undefined; // use default if no min/max
    }
    let today =
      currentValue && isValidDate(currentValue)
        ? moment(currentValue)
        : moment();
    if (minDate) {
      today = moment.max(today, moment(minDate));
    }
    if (maxDate) {
      today = moment.min(today, moment(maxDate));
    }
    return today.toDate();
  };

  getCurrentValue = () => {
    if (
      this.state.selectedDateFormatted &&
      typeof this.state.selectedDateFormatted === "string"
    ) {
      // remove the timezone from the selected date
      const dateFormat = this.getNormalizedDateFormat();
      const withoutTz = stripTimezoneFromString(
        this.state.selectedDateFormatted,
        dateFormat,
      );
      // convert the timezone to the display timezone, maintaining the NO_TZ_FORMAT format
      const converted = convertDateStringTimezone(
        withoutTz,
        NO_TZ_FORMAT,
        this.props.timezone,
        this.props.displayTimezone,
      );
      return converted;
    } else if (
      this.state.selectedDateFormatted &&
      isMoment(this.state.selectedDateFormatted)
    ) {
      return moment(this.state.selectedDateFormatted)
        .locale("en")
        .format(NO_TZ_FORMAT);
    }
    return null;
  };

  render() {
    const dateFormat = this.getNormalizedDateFormat();
    const displayFormat = this.getNormalizedDisplayDateFormat() || dateFormat;
    const anchor =
      this.state.selectedDateFormatted &&
      moment(this.state.selectedDateFormatted, dateFormat).isValid()
        ? moment(this.state.selectedDateFormatted, dateFormat)
        : moment();
    const year = anchor.get("year");
    const minDate = this.props.minDate
      ? this.getValidDate(this.props.minDate, dateFormat)
      : anchor
          .clone()
          .set({ month: 0, date: 1, year: year - 10 })
          .toDate();
    const maxDate = this.props.maxDate
      ? this.getValidDate(this.props.maxDate, dateFormat)
      : anchor
          .clone()
          .set({ month: 11, date: 31, year: year + 10 })
          .toDate();
    const showTime = formatIncludesTime(dateFormat);

    const currentValue = this.getCurrentValue();
    const timePrecision = getTimePrecision(dateFormat);
    const initialMonth = this.getInitialMonth(currentValue, minDate, maxDate);

    const styleVars: Record<string, unknown> = {
      "--label-width": getLabelWidthCssValue(this.props.labelWidth),
    };

    const inputClassNames = createStyleClassName({
      textStyleVariant: "input",
      isLoading: this.props.isLoading,
      isDisabled: this.props.isDisabled,
      type: "input",
      isInvalid: !this.props.isValid,
      isActive: this.state.isOpen,
    });

    const isInputFocused = this.state.isOpen;
    const isVertical =
      !this.props.labelPosition ||
      this.props.labelPosition === WidgetLabelPosition.TOP;
    const errorMessages = this.props.errorMessages;

    return (
      <WidgetErrorsWrapper
        widgetId={this.props.widgetId}
        showError={this.props.showError ?? false}
        messages={errorMessages}
        isFocused={this.props.isFocused}
        errorMessagePlacement={this.props.errorMessagePlacement}
        inlineErrorProps={{
          isFitContentHeight: this.props.isFitContentHeight,
        }}
      >
        {(inlineError) => (
          <StyledControlGroup
            fill
            $isValid={this.props.isValid}
            vertical={isVertical}
            data-vertical={isVertical}
            style={styleVars}
            className={this.props.isLoading ? Classes.SKELETON : ""}
          >
            {this.props.label && (
              <label
                className={`${
                  this.props.labelClassName ?? CLASS_NAMES.INPUT_LABEL
                } ${this.props.isDisabled ? CLASS_NAMES.DISABLED_MODIFIER : ""} ${
                  CLASS_NAMES.ELLIPSIS_TEXT
                }`}
                style={this.props.labelStyleOverride}
              >
                {this.props.isRequired &&
                  this.props.label.indexOf("*") === -1 && (
                    <span className={CLASS_NAMES.ERROR_MODIFIER}>* </span>
                  )}
                {this.props.label}
              </label>
            )}
            <div style={FormInputWithErrorWrapperStyle}>
              <DateInput3
                formatDate={this.formatDisplayDate}
                placeholder={"Select Date"}
                disabled={this.props.isDisabled}
                className={`${CLASS_NAMES.DATEPICKER} ${
                  this.state.isOpen ? CLASS_NAMES.POPOVER_WRAPPER : ""
                } ${this.shouldWrapTime() ? "sb-datepicker-wrapped-time" : ""}`}
                // hide the native "today" and "clear" buttons because the onChange event output is hard to use
                showActionsBar={false}
                dayPickerProps={{
                  formatters: {
                    formatMonthCaption: (date) =>
                      date.toLocaleString("default", { month: "short" }),
                  },
                  onSelect: this.onDayChanged,
                  footer: this.getPopoverFooter(),
                }}
                timePickerProps={
                  !showTime
                    ? undefined
                    : {
                        precision: timePrecision,
                        useAmPm: !this.props.twentyFourHourTime,
                        onChange: this.onTimeChanged,
                      }
                }
                value={currentValue}
                initialMonth={initialMonth}
                minDate={minDate}
                maxDate={maxDate}
                closeOnSelection={false}
                inputProps={
                  {
                    className: `${CLASS_NAMES.INPUT} ${
                      this.props.inputClassName ?? ""
                    } ${inputClassNames}`,
                    style: {
                      ...this.props.inputStyleOverride,
                      minHeight: this.props.inputLineHeightPx
                        ? `${
                            this.props.inputLineHeightPx +
                            INPUT_ADDITIONAL_MIN_HEIGHT
                          }px`
                        : undefined,
                    },
                    onClick: this.props.onInputClick,
                    leftElement: this.props.showCalendarIcon ? (
                      <div
                        className={`IconWrapper ${CLASS_NAMES.ICON} ${
                          isInputFocused ? CLASS_NAMES.ACTIVE_MODIFIER : ""
                        } ${
                          this.props.isDisabled ||
                          this.props.selectedDate === "" ||
                          this.props.selectedDate == null
                            ? CLASS_NAMES.DISABLED_MODIFIER
                            : ""
                        } ${!this.props.isValid ? CLASS_NAMES.ERROR_MODIFIER : ""}`}
                      >
                        <DynamicSVG
                          iconName="calendar_today"
                          size={this.props.iconSize ?? DEFAULT_ICON_SIZE}
                        />
                      </div>
                    ) : undefined,
                    "data-invalid": !this.props.isValid && !this.state.isOpen,
                    "data-active": this.state.isOpen,
                    rightElement:
                      !formatIncludesTimezone(displayFormat) &&
                      this.props.showTimezone ? (
                        <Timezone timezone={this.props.displayTimezone} />
                      ) : undefined,
                    onChange: this.onInputChanged,
                  } as Partial<
                    Omit<InputGroupProps, "disabled" | "type" | "value">
                  > & { "data-invalid": boolean } // needed to set data-invalid prop
                }
                popoverProps={{
                  ...(this.props.forceOpen
                    ? { isOpen: this.state.isOpen }
                    : {}),
                  onInteraction: this.handleInteraction,
                  className: CLASS_NAMES.DATEPICKER,
                  onClose: this.props.onDatePickerClosed,
                  position: "auto",
                  rootBoundary: "viewport",
                }}
              />
              {inlineError}
            </div>
          </StyledControlGroup>
        )}
      </WidgetErrorsWrapper>
    );
  }

  getPopoverFooter = () => {
    const dateFormat = this.getNormalizedDateFormat();
    const showTime = formatIncludesTime(dateFormat);
    const shouldWrapTime = this.shouldWrapTime();

    return (
      <DatePickerActionsWrapper
        data-with-time={showTime}
        data-wrapped={shouldWrapTime}
      >
        <DatePickerActionButton
          text="Today"
          onClick={() => {
            const dateFormat = this.getNormalizedDateFormat();
            // convert from the browser timezone to the value timezone if necessary
            const today = convertDateStringTimezone(
              moment().format(dateFormat),
              dateFormat,
              moment.tz.guess(),
              this.props.timezone ?? moment.tz.guess(),
            );
            this.setState({
              selectedDateFormatted: today,
            });
            this.props.onDateSelected(today);
          }}
          data-full-width={!shouldWrapTime}
        />
        <DatePickerActionButton
          text="Clear"
          onClick={() => {
            this.setState({ selectedDateFormatted: undefined });
            this.props.onDateSelected("");
          }}
          data-full-width={!shouldWrapTime}
        />
      </DatePickerActionsWrapper>
    );
  };

  formatDisplayDate = (date: Date): string => {
    const displayDateFormat =
      this.getNormalizedDisplayDateFormat() || this.getNormalizedDateFormat();
    return stripTimezoneFromDate(
      date,
      displayDateFormat,
      this.props.displayTimezone,
    );
  };

  formatSelectedDate = (date: Date): string => {
    const dateFormat = this.getNormalizedDateFormat();
    const dateStr = stripTimezoneFromDate(
      date,
      dateFormat,
      this.props.displayTimezone,
    );
    const converted = convertDateStringTimezone(
      dateStr,
      dateFormat,
      this.props.displayTimezone,
      this.props.timezone,
    );
    return converted;
  };

  parseSelectedDate = (dateStr: string): Date => {
    const dateFormat = this.getNormalizedDateFormat();
    const convertedDateStr = convertDateStringTimezone(
      dateStr,
      dateFormat,
      this.props.timezone,
      this.props.displayTimezone,
    );
    const converted = stripTimezoneFromString(convertedDateStr, dateFormat);
    return new Date(converted);
  };

  // Handles text changes that affect the whole date string
  onInputChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    const formattedDateStr = event.target.value;
    if (formattedDateStr) {
      const dateFormat = this.getNormalizedDateFormat();
      const displayFromat = this.getNormalizedDisplayDateFormat() || dateFormat;

      if (!isValidDate(formattedDateStr)) {
        this.setState({
          selectedDateFormatted: formattedDateStr,
        });
        this.props.onDateSelected(formattedDateStr);
        return;
      }

      // strip the timezone
      const noTzString = stripTimezoneFromString(
        formattedDateStr,
        displayFromat,
      );
      // convert the timezone
      const converted = convertDateStringTimezone(
        noTzString,
        NO_TZ_FORMAT,
        this.props.displayTimezone,
        this.props.timezone,
      );
      // format into the date format
      const formatted = moment(converted, NO_TZ_FORMAT).format(dateFormat);
      this.setState({
        selectedDateFormatted: formatted,
      });
      this.props.onDateSelected(formatted);
    } else {
      this.setState({
        selectedDateFormatted: undefined,
      });
      this.props.onDateSelected("");
    }
  };

  onDayChanged = (selectedDayOnly: Date | undefined) => {
    if (
      selectedDayOnly &&
      (!this.props.selectedDate ||
        !moment(this.props.selectedDate, this.getNormalizedDateFormat()).isSame(
          selectedDayOnly,
          "days",
        ))
    ) {
      // use moment.js to modify the existing month, day, and year without changing the time
      const newDate = moment(selectedDayOnly);
      const currentFormattedDate =
        this.state.selectedDateFormatted &&
        typeof this.state.selectedDateFormatted === "string"
          ? isValidDate(this.state.selectedDateFormatted)
            ? this.parseSelectedDate(this.state.selectedDateFormatted)
            : undefined
          : this.state.selectedDateFormatted || undefined;

      const currentDate = moment(currentFormattedDate).set({
        year: newDate.year(),
        month: newDate.month(),
        date: newDate.date(),
      });

      const formattedDate = this.formatSelectedDate(currentDate.toDate());
      this.setState({
        selectedDateFormatted: formattedDate,
      });

      this.props.onDateSelected(formattedDate);
    }
  };

  onTimeChanged = (selectedDateTime: Date | null) => {
    const currentDate = moment(
      this.state.selectedDateFormatted &&
        typeof this.state.selectedDateFormatted === "string"
        ? this.parseSelectedDate(this.state.selectedDateFormatted)
        : this.state.selectedDateFormatted || undefined,
    ).set({
      hours: selectedDateTime?.getHours(),
      minutes: selectedDateTime?.getMinutes(),
      seconds: selectedDateTime?.getSeconds(),
      milliseconds: selectedDateTime?.getMilliseconds(),
    });
    const formattedDate = this.formatSelectedDate(currentDate.toDate());
    this.setState({ selectedDateFormatted: formattedDate });
    if (formattedDate) this.props.onDateSelected(formattedDate);
  };
}

interface DatePickerComponentState {
  selectedDateFormatted?: unknown; // should be a string, but can be a moment object
  isOpen?: boolean;
}

export default DatePickerComponent;
