export const SIDEBAR_ITEM_ATTRIBUTE = "data-sidebar-item";

export const getSidebarShortcutKeys = (childCount: number) => {
  return Array.from(
    { length: childCount },
    (_, index) => `alt+shift+${index + 1}`,
  ).join(", ");
};

export const getSidebarElements = () => {
  return Array.from(document.querySelectorAll(`[${SIDEBAR_ITEM_ATTRIBUTE}]`));
};

export const getSidebarElement = (key: string) => {
  return document.querySelector(`[${SIDEBAR_ITEM_ATTRIBUTE}="${key}"]`);
};
