import { Dimension, Typographies } from "@superblocksteam/shared";
import React from "react";
import { WIDGET_PADDING } from "legacy/constants/WidgetConstants";
import { GeneratedTheme } from "legacy/themes";
import { CLASS_NAMES } from "legacy/themes/classnames";
import { styleAsClass } from "styles/styleAsClass";
import { generateInputBorderOnHoverColor } from "../Shared/InputUtils";
import { getLabelWidthCssValue } from "../Shared/widgetLabelStyles";
import { getVariableName } from "../base/StylesUtils";
import { createCssVariablesForBorderWidthAndColorWithHover } from "../base/generateBorderCss";
import { generatePaddingVariableAssignmentStyleObject } from "../base/generatePaddingStyle";
import { useTypographyStyling } from "../typographyHooks";
import { KeyValueComponentProps } from "./types";

const VARIABLE_PREFIX = "kv";
const VARIABLE_NAMES = {
  borderTopWidth: getVariableName("border-top-width", VARIABLE_PREFIX),
  borderRightWidth: getVariableName("border-right-width", VARIABLE_PREFIX),
  borderBottomWidth: getVariableName("border-bottom-width", VARIABLE_PREFIX),
  borderLeftWidth: getVariableName("border-left-width", VARIABLE_PREFIX),
  borderColor: getVariableName("border-color", VARIABLE_PREFIX),
  borderTopRightRadius: getVariableName(
    "border-top-right-radius",
    VARIABLE_PREFIX,
  ),
  borderTopLeftRadius: getVariableName(
    "border-top-left-radius",
    VARIABLE_PREFIX,
  ),
  borderBottomRightRadius: getVariableName(
    "border-bottom-right-radius",
    VARIABLE_PREFIX,
  ),
  borderBottomLeftRadius: getVariableName(
    "border-bottom-left-radius",
    VARIABLE_PREFIX,
  ),
  backgroundColor: getVariableName("background-color", VARIABLE_PREFIX),
  spacing: getVariableName("spacing", VARIABLE_PREFIX),
  paddingLeft: getVariableName("padding-left", VARIABLE_PREFIX),
  paddingRight: getVariableName("padding-right", VARIABLE_PREFIX),
  paddingTop: getVariableName("padding-top", VARIABLE_PREFIX),
  paddingBottom: getVariableName("padding-bottom", VARIABLE_PREFIX),
  labelWidth: getVariableName("label-width", VARIABLE_PREFIX),
  dividerColor: getVariableName("divider-color", VARIABLE_PREFIX),
  dividerHeight: getVariableName("divider-height", VARIABLE_PREFIX),
};

export const DEFAULT_KEY_VALUE_WIDGET_LABEL_STYLE_VARIANT: keyof Typographies =
  "inputLabel";
export const DEFAULT_KEY_VALUE_WIDGET_TEXT_STYLE_VARIANT: keyof Typographies =
  "body2";

const getDimensionValue = (dimension: Dimension) =>
  `${dimension.value}${dimension.mode}`;

export const createCssVariables = ({
  theme,
  styleProps,
  keyWidth,
}: {
  theme: GeneratedTheme;
  styleProps: KeyValueComponentProps["styleProps"];
  keyWidth?: Dimension<"px" | "gridUnit">;
}): React.CSSProperties & { [key: `--${string}`]: string } => {
  const borderColorOnHover = generateInputBorderOnHoverColor(
    styleProps?.border,
    theme.colors.primary500,
    theme.colors,
  );

  const borderCssVariables = createCssVariablesForBorderWidthAndColorWithHover({
    border: styleProps.border,
    fallbackBorderColor: theme.colors.neutral100,
    borderColorOnHover,
    defaultBorderWidth: theme.defaultBorder.borderWidth,
    prefix: VARIABLE_PREFIX,
  });

  const defaultBorderRadius = theme.defaultBorder.borderRadius;

  const paddingVariables = generatePaddingVariableAssignmentStyleObject(
    styleProps.padding ?? theme.keyValue.padding,
    VARIABLE_PREFIX,
  );

  const keyVars: Record<string, unknown> = {
    [VARIABLE_NAMES.labelWidth]: getLabelWidthCssValue(keyWidth),
  };

  return {
    ...borderCssVariables,
    ...paddingVariables,
    ...keyVars,
    [VARIABLE_NAMES.borderTopRightRadius]: getDimensionValue(
      styleProps.borderRadius?.topRight ?? defaultBorderRadius,
    ),
    [VARIABLE_NAMES.borderTopLeftRadius]: getDimensionValue(
      styleProps.borderRadius?.topLeft ?? defaultBorderRadius,
    ),
    [VARIABLE_NAMES.borderBottomRightRadius]: getDimensionValue(
      styleProps.borderRadius?.bottomRight ?? defaultBorderRadius,
    ),
    [VARIABLE_NAMES.borderBottomLeftRadius]: getDimensionValue(
      styleProps.borderRadius?.bottomLeft ?? defaultBorderRadius,
    ),
    [VARIABLE_NAMES.backgroundColor]:
      styleProps.backgroundColor ?? theme.colors.neutral,
    [VARIABLE_NAMES.spacing]: getDimensionValue(
      styleProps.spacing ?? theme.keyValue.spacing,
    ),
    [VARIABLE_NAMES.dividerHeight]: getDimensionValue(
      styleProps.divider?.size ?? theme.keyValue.divider.size,
    ),
    [VARIABLE_NAMES.dividerColor]:
      styleProps.divider?.color ?? theme.keyValue.divider.color,
  };
};

export const KeyValueComponentClass = styleAsClass`
  display: flex;
  flex-direction: column;
  gap: var(${VARIABLE_NAMES.spacing});

  overflow: auto;

  border-style: solid;

  border-top-width: var(${VARIABLE_NAMES.borderTopWidth});
  border-right-width: var(${VARIABLE_NAMES.borderRightWidth});
  border-bottom-width: var(${VARIABLE_NAMES.borderBottomWidth});
  border-left-width: var(${VARIABLE_NAMES.borderLeftWidth});
  border-color: var(${VARIABLE_NAMES.borderColor});

  border-top-right-radius: var(${VARIABLE_NAMES.borderTopRightRadius});
  border-top-left-radius: var(${VARIABLE_NAMES.borderTopLeftRadius});
  border-bottom-right-radius: var(${VARIABLE_NAMES.borderBottomRightRadius});
  border-bottom-left-radius: var(${VARIABLE_NAMES.borderBottomLeftRadius});

  background-color: var(${VARIABLE_NAMES.backgroundColor});
  
  padding-left: var(${VARIABLE_NAMES.paddingLeft});
  padding-right: var(${VARIABLE_NAMES.paddingRight});
  padding-top: var(${VARIABLE_NAMES.paddingTop});
  padding-bottom: var(${VARIABLE_NAMES.paddingBottom});
`;

export const KeyValueEntryClass = styleAsClass`
  display: flex;
  gap: 10px;
  position: relative;

  &:not(:last-child)::after {
    content: "";
    position: absolute;

    // Center the divider between the two entries
    bottom: calc(var(${VARIABLE_NAMES.spacing}) * -1 / 2 - var(${VARIABLE_NAMES.dividerHeight}) / 2);
    left: calc(var(${VARIABLE_NAMES.paddingLeft}) * -1);
    right: calc(var(${VARIABLE_NAMES.paddingRight}) * -1);

    background-color: var(${VARIABLE_NAMES.dividerColor});
    height: var(${VARIABLE_NAMES.dividerHeight});
  }
  
  .${CLASS_NAMES.KEY_VALUE_ENTRY_KEY} {
    flex: 1 0 auto;
    max-width: 100%;

    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .${CLASS_NAMES.KEY_VALUE_ENTRY_VALUE} {
    flex-grow: 1;

    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    display: flex;
    
    &.right-aligned {
      justify-content: end;
    }
  }

  &:not(.vertical) {
    align-items: center;

    .${CLASS_NAMES.KEY_VALUE_ENTRY_KEY} {
      flex: 0 0 var(${VARIABLE_NAMES.labelWidth});
      max-width: calc(var(${VARIABLE_NAMES.labelWidth}) - ${String(WIDGET_PADDING)}px);
    }
  }

  &.vertical {
    flex-direction: column;
  }

  .${CLASS_NAMES.KEY_VALUE_ENTRY_KEY}, .${CLASS_NAMES.KEY_VALUE_ENTRY_VALUE} {
    margin-bottom: 0;
  }
`;

export const useLabelStyles = (
  keyProps: KeyValueComponentProps["keyProps"],
) => {
  return useTypographyStyling({
    textStyle: keyProps?.textStyle,
    defaultTextStyleVariant: DEFAULT_KEY_VALUE_WIDGET_LABEL_STYLE_VARIANT,
    applyClassNameStylesToStyle: true,
  });
};

export const useInputStyles = (
  valueProps: KeyValueComponentProps["valueProps"],
) => {
  return useTypographyStyling({
    textStyle: valueProps?.textStyle,
    defaultTextStyleVariant: DEFAULT_KEY_VALUE_WIDGET_TEXT_STYLE_VARIANT,
    applyClassNameStylesToStyle: true,
  });
};
