import React, { PropsWithChildren, useState, useRef, useEffect } from "react";
import styled from "styled-components";
import { useCallbackAsRef } from "hooks/ui";
import {
  CanvasDefaults,
  WIDGET_PADDING,
} from "legacy/constants/WidgetConstants";
import createLoggedObserver from "utils/createLoggedObserver";

interface AutoSizeContainerProps {
  isAutoHeight: boolean;
  isAutoWidth: boolean;
  onHeightUpdate: (height: number) => void;
  onWidthUpdate: (width: number) => void;
  widgetHeightInPixels: number;
  widgetWidthInPixels: number;
  maxWidthInPixels?: number;

  dataTest?: string;
  widgetName?: string;
  isSection?: boolean;
}

type DynamicContainerProps = {
  isAutoHeight: boolean;
  isAutoWidth: boolean;
  maxWidth?: number;
};

const DynamicSizeContainer = styled.div<DynamicContainerProps>`
  height: ${({ isAutoHeight }) => (isAutoHeight ? "auto" : "100%")};
  width: ${({ isAutoWidth }) => (isAutoWidth ? "fit-content" : "100%")};
  max-width: ${({ maxWidth }) => (maxWidth ? `${maxWidth}px` : undefined)};
  min-width: ${CanvasDefaults.MIN_FIT_CONTENT_WIDTH}px;
  overflow-x: hidden;
  > * {
    height: ${({ isAutoHeight }) => (isAutoHeight ? "auto" : "100%")};
    width: ${({ isAutoWidth }) => (isAutoWidth ? "fit-content" : "100%")};
    max-width: ${({ maxWidth }) => (maxWidth ? `${maxWidth}px` : undefined)};
  }
`;

export const FullHeightContainer = styled.div`
  height: 100%;
  > * {
    height: 100%;
  }
`;

export default function AutoSizeContainer({
  children,
  isAutoHeight,
  isAutoWidth,
  onHeightUpdate,
  onWidthUpdate,
  widgetHeightInPixels,
  widgetWidthInPixels,
  maxWidthInPixels,
  widgetName,
  dataTest,
  isSection,
}: PropsWithChildren<AutoSizeContainerProps>) {
  const [expectedHeight, setExpectedHeight] = useState(0);
  const [expectedWidth, setExpectedWidth] = useState(0);

  const ref = useRef<HTMLDivElement>(null);

  const isMeasuredHeightRef = useRef(false);
  const isMeasuredWidthRef = useRef(false);

  const handleResizeObserverResize = useCallbackAsRef(
    (entries: ResizeObserverEntry[]) => {
      const height = entries[0].contentRect.height;
      const width = entries[0].contentRect.width;

      // Explicitly check if height is a number
      if (typeof height === "number" && isAutoHeight) {
        // If this is the first measurement and height is 0, do not update the state
        if (!isMeasuredHeightRef.current && height === 0) {
          isMeasuredHeightRef.current = true;
          return;
        }
        isMeasuredHeightRef.current = true;

        // Set the height
        setExpectedHeight(height);
      }

      // Explicitly check if width is a number
      if (typeof width === "number" && isAutoWidth) {
        // If this is the first measurement and width is 0, do not update the state
        if (!isMeasuredWidthRef.current && width === 0) {
          isMeasuredWidthRef.current = true;
          return;
        }
        isMeasuredWidthRef.current = true;

        // Set the width
        setExpectedWidth(width);
      }
    },
  );

  useEffect(() => {
    // If isAutoHeight changes we should set the expectedHeight to the rect height
    if (isAutoHeight && ref.current !== null) {
      const height = ref.current.getBoundingClientRect().height;
      if (height) {
        setExpectedHeight(height);
      }
    }

    // If isAutoWidth changes we should set the expectedWidth to the rect width
    if (isAutoWidth && ref.current !== null) {
      const width = ref.current.getBoundingClientRect().width;
      if (width) {
        setExpectedWidth(width);
      }
    }

    // If neither isAutoHeight nor isAutoWidth, we don't need to observe the resize
    if (!isAutoHeight && !isAutoWidth) {
      return;
    }

    const observer = createLoggedObserver(
      "AutoSizeContainer-" + widgetName,
      (entries) => {
        handleResizeObserverResize(entries);
      },
    );

    if (ref.current !== null) {
      observer.observe(ref.current);
    }
    return () => observer.disconnect();
  }, [isAutoHeight, isAutoWidth, handleResizeObserverResize, widgetName]);

  useEffect(() => {
    const heightWithPadding = expectedHeight + WIDGET_PADDING * 2;
    if (widgetHeightInPixels !== heightWithPadding && ref.current !== null) {
      if (expectedHeight !== 0 && isAutoHeight) {
        onHeightUpdate(expectedHeight);
      }
    }
  }, [expectedHeight, isAutoHeight, onHeightUpdate, widgetHeightInPixels]);

  useEffect(() => {
    const widthPlusPadding = expectedWidth + WIDGET_PADDING * 2;
    if (widgetWidthInPixels !== widthPlusPadding && ref.current !== null) {
      if (expectedWidth !== 0 && isAutoWidth) {
        onWidthUpdate(widthPlusPadding);
      }
    }
  }, [expectedWidth, isAutoWidth, onWidthUpdate, widgetWidthInPixels]);

  if (isAutoHeight || isAutoWidth) {
    return (
      <DynamicSizeContainer
        isAutoHeight={isAutoHeight}
        isAutoWidth={isAutoWidth}
        maxWidth={isAutoWidth ? undefined : maxWidthInPixels}
        data-test={dataTest}
        ref={ref}
      >
        {children}
      </DynamicSizeContainer>
    );
  }
  if (isSection) {
    return children as JSX.Element;
  }
  return (
    <FullHeightContainer data-test={dataTest}>{children}</FullHeightContainer>
  );
}
