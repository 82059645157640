import { PerSideBorder } from "@superblocksteam/shared/src/types/application";
import { Dimension } from "@superblocksteam/shared/src/types/page/Dimension";
import tinycolor from "tinycolor2";
import { getVariableName } from "./StylesUtils";

const generateBoxShadowCss = (color: string): string => {
  return `0px 1px 3px 0px ${tinycolor(color).setAlpha(0.24)}`;
};

const BORDER_COLOR = "border-color";
const BORDER_COLOR_ON_HOVER = "border-color-on-hover";
const DEFAULT_BORDER_COLOR = "default-border-color";
const BOX_SHADOW_ON_HOVER = "box-shadow-on-hover";
const BORDER_TOP_WIDTH = "border-top-width";
const BORDER_RIGHT_WIDTH = "border-right-width";
const BORDER_BOTTOM_WIDTH = "border-bottom-width";
const BORDER_LEFT_WIDTH = "border-left-width";

/**
 * Generates CSS for border color, width and styles with hover states.
 *
 * Ports a simplified version of `generateBorderWidthAndColorStyleObject` for css,
 * but relies on CSS variables.
 *
 * Benefits: allows hover states, allows to be used for InputGroups
 * Drawbacks: it doesn't set each border's color and style individually.
 *
 */
export const generateCssForBorderWidthAndColorWithHover = ({
  selectors,
  prefix = "",
}: {
  selectors: string | string[];
  prefix?: string;
}): string => {
  const selectorsArray = Array.isArray(selectors) ? selectors : [selectors];
  const selectorsString = selectorsArray.join(", ");
  const selectorsWithInteractionsString = selectorsArray
    .map((selector) => {
      return `${selector}:hover, ${selector}:focus, ${selector}:focus-within, ${selector}:active, ${selector}[data-active="true"]`;
    })
    .join(",");

  const defaultCss = `
    border-style: solid;
    border-top-width: var(${getVariableName(BORDER_TOP_WIDTH, prefix)});
    border-right-width: var(${getVariableName(BORDER_RIGHT_WIDTH, prefix)});
    border-bottom-width: var(${getVariableName(BORDER_BOTTOM_WIDTH, prefix)});
    border-left-width: var(${getVariableName(BORDER_LEFT_WIDTH, prefix)});

    &:not([data-invalid='true']) {
      border-color: var(${getVariableName(DEFAULT_BORDER_COLOR, prefix)});
    }
  `;

  const onHoverCss = `
    box-shadow: var(${getVariableName(BOX_SHADOW_ON_HOVER, prefix)});
    &:not([data-invalid="true"]) {
      border-color: var(${getVariableName(BORDER_COLOR_ON_HOVER, prefix)});
    }
  `;

  return `${selectorsString} { ${defaultCss} } ${selectorsWithInteractionsString} { ${onHoverCss} }`;
};

export const createCssVariablesForBorderWidthAndColorWithHover = ({
  border,
  fallbackBorderColor,
  borderColorOnHover,
  defaultBorderWidth: defaultBorderWidthWithDimension,
  prefix = "",
}: {
  border?: PerSideBorder;
  fallbackBorderColor: string;
  defaultBorderWidth: Dimension;
  borderColorOnHover: string;
  prefix?: string;
}): { [key: `--${string}`]: string } => {
  const defaultBorderWidth = `${defaultBorderWidthWithDimension?.value}${defaultBorderWidthWithDimension?.mode}`;

  const borderColor = border?.top?.color ?? fallbackBorderColor;

  return {
    [getVariableName(BORDER_COLOR, prefix)]: borderColor,
    [getVariableName(BORDER_COLOR_ON_HOVER, prefix)]: borderColorOnHover,
    [getVariableName(DEFAULT_BORDER_COLOR, prefix)]: fallbackBorderColor,
    [getVariableName(BOX_SHADOW_ON_HOVER, prefix)]:
      generateBoxShadowCss(borderColorOnHover),
    [getVariableName(BORDER_TOP_WIDTH, prefix)]: border?.top
      ? `${border?.top?.width?.value}${border?.top?.width?.mode}`
      : (defaultBorderWidth ?? "0px"),
    [getVariableName(BORDER_RIGHT_WIDTH, prefix)]: border?.right
      ? `${border?.right?.width?.value}${border?.right?.width?.mode}`
      : (defaultBorderWidth ?? "0px"),
    [getVariableName(BORDER_BOTTOM_WIDTH, prefix)]: border?.bottom
      ? `${border?.bottom?.width?.value}${border?.bottom?.width?.mode}`
      : (defaultBorderWidth ?? "0px"),
    [getVariableName(BORDER_LEFT_WIDTH, prefix)]: border?.left
      ? `${border?.left?.width?.value}${border?.left?.width?.mode}`
      : (defaultBorderWidth ?? "0px"),
  };
};
