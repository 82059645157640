import { debounce } from "lodash";
import logger from "utils/logger";

const observerRegistry: { label: string; lastObserved: string }[] = [];

function createLoggedObserver(
  label: string,
  callback: ResizeObserverCallback,
): ResizeObserver {
  const info: { label: string; lastObserved: string } = {
    label,
    lastObserved: "",
  };

  const createdObserver = new ResizeObserver((entries, observer) => {
    info.lastObserved = entries.map((e) => e.target).join(",");
    callback(entries, observer);
  });
  observerRegistry.push(info);
  return createdObserver;
}

// Debounced logging function
const logResizeObserverError = debounce(() => {
  logger.warn({
    message: "ResizeObserver loop completed with undelivered notifications",
    details: observerRegistry
      .map(({ label, lastObserved }) => `${label}: ${lastObserved}`)
      .join("\n"),
  });
  observerRegistry.length = 0;
}, 5000);

// Global error handler
const oldOnError = window.onerror;
window.onerror = (
  message: string | Event,
  source?: string,
  lineno?: number,
  colno?: number,
  error?: Error,
) => {
  if (
    typeof message === "string" &&
    message.includes(
      "ResizeObserver loop completed with undelivered notifications",
    )
  ) {
    logResizeObserverError();
    return;
  }

  if (oldOnError) {
    oldOnError(message, source, lineno, colno, error);
  }
};

export default createLoggedObserver;
