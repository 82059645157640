import { Dimension } from "@superblocksteam/shared";
import React, { memo, useCallback, useMemo, useRef } from "react";
import WidgetErrorsWrapper from "legacy/components/editorComponents/WidgetErrorsWrapper";
import {
  GridDefaults,
  WidgetLabelPosition,
} from "legacy/constants/WidgetConstants";
import { GeneratedTheme } from "legacy/themes";
import { CLASS_NAMES } from "legacy/themes/classnames";
import { styleAsClass } from "styles/styleAsClass";
import { SwitchInput } from "../Shared/SwitchInput";
import { useStyleClassNames, useTypographyStyling } from "../typographyHooks";
import { getLineHeightInPxFromTextStyle } from "../typographyUtils";
import { DEFAULT_SWITCH_WIDGET_LABEL_TEXT_STYLE_VARIANT } from "./constants";
import type {
  SwitchComponentProps,
  SwitchComponentWithLayoutsManagedProps,
} from "./types";

export const SWITCH_LABEL_GAP = 8;
const SwitchComponentGroup = styleAsClass`
  display: flex;
  flex-direction: column;
  gap: 2px;
  justify-content: space-between;
`;

const SwitchContainerClassName = styleAsClass`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  width: fit-content;

  label {
    cursor: pointer;
  }
  
  &[data-label-width="fitContent"] {
    gap: ${SWITCH_LABEL_GAP.toString()}px;
  }

  &[data-label-width="fillParent"] {
    width: 100%;
    justify-content: space-between;
  }

  overflow-y: hidden;
`;

const SwitchComponent = memo(
  ({
    label,
    labelPosition,
    isToggledOn,
    isDisabled,
    isRequired,
    onSwitchChange,
    isLoading,
    labelProps,
    widgetId,
    errorMessagePlacement,
    errorMessages,
    labelWidth,
    showError,
    isFitContentHeight,
  }: SwitchComponentProps) => {
    const labelRef = useRef<HTMLLabelElement>(null);

    const toggleSwitch = useCallback(() => {
      if (isDisabled) return;

      onSwitchChange(!isToggledOn);
    }, [onSwitchChange, isToggledOn, isDisabled]);

    const asterisk = "* ";

    const labelClass = useStyleClassNames({
      textStyleVariant: labelProps.textStyleVariant,
      isLoading,
      isDisabled,
      type: "label",
    });

    const labelWidthMode =
      labelPosition === WidgetLabelPosition.LEFT
        ? labelWidth?.mode || "fitContent"
        : "fitContent";

    const errorStyles =
      labelPosition === WidgetLabelPosition.LEFT &&
      labelWidthMode === "fillParent"
        ? ({
            textAlign: "right",
          } as React.CSSProperties)
        : labelPosition === WidgetLabelPosition.LEFT &&
            labelWidthMode === "fitContent"
          ? ({
              marginLeft:
                (labelRef.current?.offsetWidth ?? 0) + SWITCH_LABEL_GAP,
            } as React.CSSProperties)
          : undefined;

    const SwitchLabelComp = useMemo(() => {
      return (
        <label
          data-test="switch-label"
          onClick={toggleSwitch}
          className={labelClass}
          style={labelProps.style}
          ref={labelRef}
        >
          {isRequired && (
            <span className={CLASS_NAMES.ERROR_MODIFIER}>{asterisk}</span>
          )}
          {label}
        </label>
      );
    }, [isRequired, label, toggleSwitch, labelClass, labelProps.style]);

    return (
      <WidgetErrorsWrapper
        widgetId={widgetId}
        errorMessagePlacement={errorMessagePlacement}
        messages={errorMessages}
        showError={!!showError}
        inlineErrorProps={{ style: errorStyles, isFitContentHeight }}
        customTarget
      >
        {(inlineError, onTargetMouseOver, onTargetMouseLeave) => (
          <div className={SwitchComponentGroup}>
            <div
              className={SwitchContainerClassName}
              data-label-width={labelWidthMode}
              data-test={isToggledOn ? "switch-checked" : "switch-not-checked"}
              onMouseOver={onTargetMouseOver}
              onMouseLeave={onTargetMouseLeave}
            >
              {labelPosition === WidgetLabelPosition.LEFT && SwitchLabelComp}
              <SwitchInput
                isValid={!showError}
                onClick={toggleSwitch}
                isToggledOn={isToggledOn}
                isDisabled={isDisabled}
              />
              {labelPosition === WidgetLabelPosition.RIGHT && SwitchLabelComp}
            </div>
            {inlineError}
          </div>
        )}
      </WidgetErrorsWrapper>
    );
  },
);

export const SwitchComponentWithLayoutManaged = (
  props: SwitchComponentWithLayoutsManagedProps,
) => {
  const labelProps = useTypographyStyling({
    textStyle: props.labelProps?.textStyle,
    defaultTextStyleVariant: DEFAULT_SWITCH_WIDGET_LABEL_TEXT_STYLE_VARIANT,
  });

  return (
    <SwitchComponent
      isRequired={!!props.isRequired}
      isToggledOn={!!props.isToggledOn}
      label={props.label}
      labelPosition={props.labelPosition ?? WidgetLabelPosition.RIGHT}
      widgetId={props.widgetId}
      key={props.widgetId}
      isDisabled={!!props.isDisabled}
      onSwitchChange={props.onSwitchChange}
      isLoading={props.isLoading}
      labelProps={labelProps}
      validationErrors={props.validationErrors}
      errorMessagePlacement={props.errorMessagePlacement}
      labelWidth={props.labelProps?.width}
      errorMessages={props.errorMessages}
      showError={props.showError}
      isFitContentHeight={props.height?.mode === "fitContent"}
    />
  );
};

export const estimateInitialSwitchWidgetHeightGU = (
  theme?: GeneratedTheme,
): number | undefined => {
  if (!theme?.typographies) {
    return undefined;
  }

  const textHeight = getLineHeightInPxFromTextStyle({
    textStyleVariant: DEFAULT_SWITCH_WIDGET_LABEL_TEXT_STYLE_VARIANT,
    nestedProps: undefined,
    typographies: theme.typographies,
    defaultTextStyleVariant: DEFAULT_SWITCH_WIDGET_LABEL_TEXT_STYLE_VARIANT,
  });

  return Dimension.toGridUnit(
    Dimension.px(textHeight),
    GridDefaults.DEFAULT_GRID_ROW_HEIGHT,
  ).roundUp().value;
};

SwitchComponent.displayName = "Switch";
