import {
  DropdownOption,
  OrganizationUserStatus,
  PrincipalTypeEnum,
  ResourceTypeEnum,
} from "@superblocksteam/shared";
import { Alert, Tooltip } from "antd";
import { isEmpty } from "lodash";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ReactComponent as IntegrationsImg } from "assets/icons/common/integrations_illustration.svg";
import { WarningIcon, InfoIcon, AlertStyles } from "components/ui/Alert";
import { MultiFilter } from "components/ui/Filter";
import {
  PlainLink,
  UnderlinedLinkClass,
  UnderlinedPlainLink,
} from "components/ui/Link";
import RecommendedTable, {
  RecColumn,
  TagStyle,
} from "components/ui/RecommendedTable";
import {
  SearchContainer,
  SearchInput,
  filterBySearch,
} from "components/ui/SearchSection";
import TruncatedTextTooltip from "components/ui/TruncatedTextTooltip";
import { ADMIN_ROLE_NAME, OWNER_ROLE_NAME } from "constants/rbac";
import { useSaga } from "hooks/store";
import { useDebounce } from "hooks/ui/useDebounce";
import { WORKFLOW_CONFIGURE_URL } from "legacy/constants/routes";
import { SCHEDULED_JOB_CONFIGURE_URL } from "legacy/constants/routes";
import { BUILDER_APP_ID_URL } from "legacy/constants/routes";
import {
  convertResourceTypeToRoleType,
  getResourceTypeDisplayName,
} from "pages/Permissions/constants";
import { ResourceToRender } from "pages/Permissions/constants";
import ShareModalV2 from "pages/components/ShareModalV2";
import { getAllDatasourcesSaga } from "store/slices/datasources/sagas/getSupersetDatasources";
import { selectAllPluginDatasources } from "store/slices/datasources/selectors";
import { selectOnlyOrganization } from "store/slices/organizations";
import { useListUserOrGroupAssignmentsQuery } from "store/slices/reduxApi/rbac";
import { colors } from "styles/colors";
import { styleAsClass } from "styles/styleAsClass";
import { capitalize } from "utils/string";

const NameStyle = styleAsClass`
  max-width: 350px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const RoleStyle = styleAsClass`
  &:not(:hover) {
    color: ${colors.GREY_700};
  }
`;

const EmptyStateWrapperStyle = styleAsClass`
  display: flex;
  flex-direction: column;
  gap: 4px;
  align-items: center;
  flex-grow: 1;
  justify-content: center;
  border: 1px solid ${colors.GREY_100};
  border-radius: 4px;
  height: 266px;
`;

const EmptyStateSubTextStyle = styleAsClass`
  color:${colors.GREY_500};
  font-size: 12px;
  line-height: 20px;
  max-width: 465px;
  text-align: center;
`;

type ColType = RecColumn<ResourceToRender>;

type TypeValue = ResourceTypeEnum;

const allTypeOptions: {
  value: TypeValue;
  displayName: string;
  key: TypeValue;
}[] = [
  {
    value: ResourceTypeEnum.APPLICATIONS,
    displayName: "Application",
    key: ResourceTypeEnum.APPLICATIONS,
  },
  {
    value: ResourceTypeEnum.WORKFLOWS,
    displayName: "Workflow",
    key: ResourceTypeEnum.WORKFLOWS,
  },
  {
    value: ResourceTypeEnum.SCHEDULED_JOBS,
    displayName: "Scheduled Job",
    key: ResourceTypeEnum.SCHEDULED_JOBS,
  },
  {
    value: ResourceTypeEnum.INTEGRATIONS,
    displayName: "Integration",
    key: ResourceTypeEnum.INTEGRATIONS,
  },
];

const renderType = ({ value }: { value: ResourceTypeEnum }) => {
  let text = "Unknown";
  let style: React.CSSProperties = {
    color: colors.GREY_500,
    backgroundColor: `${colors.GREY_500}14`,
    borderColor: `${colors.GREY_500}2E`,
  };

  // TODO(rbac): add more types here:
  switch (value) {
    case ResourceTypeEnum.APPLICATIONS:
      text = "Application";
      style = {
        color: colors.ACCENT_BLUE_500,
        backgroundColor: colors.ACCENT_BLUE_500_25,
        borderColor: colors.ACCENT_BLUE_500_18,
      };

      break;
    case ResourceTypeEnum.SCHEDULED_JOBS:
      text = "Scheduled Job";
      style = {
        color: colors.ACCENT_PURPLE,
        backgroundColor: `${colors.ACCENT_PURPLE}14`,
        borderColor: `${colors.ACCENT_PURPLE}2E`,
      };
      break;
    case ResourceTypeEnum.WORKFLOWS:
      text = "Workflow";
      style = {
        color: colors.ACCENT_GREEN,
        backgroundColor: `${colors.ACCENT_GREEN}14`,
        borderColor: `${colors.ACCENT_GREEN}2E`,
      };
      break;
    case ResourceTypeEnum.INTEGRATIONS:
      text = "Integration"; // TODO: use correct color
      style = {
        color: colors.ACCENT_ORANGE,
        backgroundColor: `${colors.ACCENT_ORANGE}14`,
        borderColor: `${colors.ACCENT_ORANGE}2E`,
      };
  }

  return (
    <div className={TagStyle} style={style}>
      {text}
    </div>
  );
};

const ResourceList = ({
  principalId,
  principalType,
  principalName,
  roleName,
  userStatus,
}: {
  principalId: string;
  principalType: PrincipalTypeEnum;
  principalName: string;
  roleName: string;
  userStatus?: OrganizationUserStatus;
}) => {
  const [resourceToModify, setResourceToModify] = useState<ResourceToRender>();
  const organizationId = useSelector(selectOnlyOrganization).id;

  const navigate = useNavigate();

  const allIntegrations = useSelector(selectAllPluginDatasources);
  const [getDatasources] = useSaga(getAllDatasourcesSaga);
  const [loadingIntegrations, setLoadingIntegrations] = useState(false);
  // avoid infinite loop if integration returned is empty
  const loadingIntegrationInitiated = useRef(false);
  useEffect(() => {
    (async () => {
      if (isEmpty(allIntegrations) && !loadingIntegrationInitiated.current) {
        setLoadingIntegrations(true);
        loadingIntegrationInitiated.current = true;
        await getDatasources({ organizationId });
        setLoadingIntegrations(false);
      }
    })();
  }, [organizationId, getDatasources, allIntegrations]);

  const orgId = useSelector(selectOnlyOrganization).id;

  const [isShareModalVisible, setShareModalVisible] = useState(false);

  const onRoleClick = useCallback((resource: ResourceToRender) => {
    setShareModalVisible(true);
    setResourceToModify(resource);
  }, []);

  const [searchTerm, setSearchTerm] = useState("");
  const onSearchChangeDebounced = useDebounce(
    (e) => setSearchTerm(e.target.value),
    200,
  );

  const [selectedTypes, setSelectedTypes] =
    useState<DropdownOption[]>(allTypeOptions);

  const organization = useSelector(selectOnlyOrganization);

  const {
    data: assignments,
    isLoading: assignmentsInitLoading,
    isFetching: assignmentsFetching,
  } = useListUserOrGroupAssignmentsQuery(
    {
      isUser: principalType === PrincipalTypeEnum.USER,
      principalId,
      organizationId: organization.id ?? "",
    },
    {
      refetchOnMountOrArgChange: true,
    },
  );

  const assignmentsLoading = assignmentsFetching || assignmentsInitLoading;

  const resources: ResourceToRender[] = useMemo(() => {
    const resourceMap = new Map<
      string,
      Omit<ResourceToRender, "roles"> & { roles: Set<string> }
    >();

    (assignments ?? [])
      .filter((assignment) => {
        return [
          ResourceTypeEnum.APPLICATIONS,
          ResourceTypeEnum.WORKFLOWS,
          ResourceTypeEnum.SCHEDULED_JOBS,
          ResourceTypeEnum.INTEGRATIONS,
        ].includes(assignment.resourceType);
      })
      .forEach((assignment) => {
        if (!resourceMap.has(assignment.resourceId)) {
          resourceMap.set(assignment.resourceId, {
            id: assignment.resourceId,
            name:
              assignment?.application?.name ??
              assignment?.api?.name ??
              assignment?.integration?.name ??
              "",
            type: assignment.resourceType,
            roles: new Set<string>(
              assignment.role?.name ? [assignment.role.name] : undefined,
            ), // Initialize roles set
          });
        } else {
          // If the resource already exists, add the role to the roles set
          const existingResource = resourceMap.get(assignment.resourceId);
          if (existingResource && assignment.role?.name) {
            existingResource.roles.add(assignment.role.name);
          }
        }
      });

    // Convert roles from Set to Array for rendering
    return Array.from(resourceMap.values())
      .map((resource) => ({
        ...resource,
        roles: Array.from(resource.roles),
      }))
      .sort((a, b) => {
        const typeOrder: Record<string, number> = {
          [ResourceTypeEnum.APPLICATIONS]: 1,
          [ResourceTypeEnum.SCHEDULED_JOBS]: 2,
          [ResourceTypeEnum.WORKFLOWS]: 3,
          [ResourceTypeEnum.INTEGRATIONS]: 4,
        };

        if (typeOrder[a.type] !== typeOrder[b.type]) {
          return typeOrder[a.type] - typeOrder[b.type];
        }
        return a.name.localeCompare(b.name);
      });
  }, [assignments]);

  const filteredResources = useMemo(() => {
    return resources?.filter(
      (resource) =>
        filterBySearch<ResourceToRender>(resource, searchTerm, [
          "name",
          "type",
        ]) && selectedTypes.some((type) => type.value === resource.type),
    );
  }, [resources, searchTerm, selectedTypes]);

  const renderRoles = useCallback(
    ({ value: roles, cell }: { value: string[]; cell: any }) => {
      const resourceTypeToDisplay = getResourceTypeDisplayName(
        cell.row.original.type,
      )?.toLowerCase();

      const linkProps = {
        className: `${UnderlinedLinkClass} ${RoleStyle}`,
        onClick: () => onRoleClick(cell.row.original),
      };

      return (
        <div>
          {roles.length === 1 ? (
            <>
              <b {...linkProps}>{roles[0]}</b>
            </>
          ) : roles.length === 2 ? (
            <>
              <b {...linkProps}>
                {roles[0]}, {roles[1]}
              </b>
            </>
          ) : (
            <>
              <Tooltip title={`${roles.join(", ")}`}>
                <b {...linkProps}>{roles.length} roles</b>{" "}
              </Tooltip>
            </>
          )}{" "}
          on <span>{resourceTypeToDisplay}</span>
        </div>
      );
    },
    [onRoleClick],
  );

  const columns: ColType[] = useMemo(
    () => [
      {
        Header: "ID",
        accessor: "id",
        hidden: true,
      },
      {
        Header: "Type",
        accessor: "type",
        Cell: renderType,
        style: { width: 130 },
      },
      {
        Header: "Name",
        accessor: "name",
        Cell: ({ value, cell }) => {
          let link = "";

          switch (cell.row.original.type) {
            case ResourceTypeEnum.APPLICATIONS:
              link = BUILDER_APP_ID_URL(cell.row.original.id);
              break;
            case ResourceTypeEnum.WORKFLOWS:
              link = WORKFLOW_CONFIGURE_URL(cell.row.original.id);
              break;
            case ResourceTypeEnum.SCHEDULED_JOBS:
              link = SCHEDULED_JOB_CONFIGURE_URL(cell.row.original.id);
              break;
            case ResourceTypeEnum.INTEGRATIONS: {
              const integration = allIntegrations?.[cell.row.original.id];
              if (integration) {
                link = `/integrations/${integration.pluginId}/${cell.row.original.id}`;
              }

              break;
            }
          }
          return (
            <TruncatedTextTooltip
              text={value}
              className={`${NameStyle} ${UnderlinedLinkClass}`}
              onClick={(event) => {
                if (link) {
                  if (event.ctrlKey || event.metaKey) {
                    window.open(link, "_blank");
                  } else {
                    navigate({ pathname: link });
                  }
                }
              }}
            />
          );
        },
      },
      {
        Header: "Roles",
        accessor: "roles",
        Cell: renderRoles,
      },
    ],
    [allIntegrations, navigate, renderRoles],
  );

  const roleType = resourceToModify
    ? convertResourceTypeToRoleType(resourceToModify.type)
    : null;

  const isAdminOrOrOwner =
    roleName === OWNER_ROLE_NAME || roleName === ADMIN_ROLE_NAME;

  const principalLabel =
    principalType === PrincipalTypeEnum.GROUP ? "group" : "user";

  return (
    <>
      {userStatus === OrganizationUserStatus.INACTIVE ? (
        <>
          <Alert
            showIcon={true}
            className={AlertStyles}
            icon={<WarningIcon />}
            message={
              <div>
                {principalName} has no access because their account is{" "}
                <b>Deactivated</b>.
              </div>
            }
            type="error"
            style={{ marginBottom: 24 }}
          />
        </>
      ) : isAdminOrOrOwner ? (
        <>
          <Alert
            showIcon={true}
            icon={<InfoIcon />}
            className={AlertStyles}
            message={
              <div>
                <b>{principalName}</b> has{" "}
                <b>{`admin access to all resources`}</b> because they are the
                organization{" "}
                <UnderlinedPlainLink
                  href={
                    "https://docs.superblocks.com/administration/org-roles#built-in-organization-roles"
                  }
                  target="_blank"
                  rel="noreferrer"
                >
                  <b>{roleName}</b>
                </UnderlinedPlainLink>
              </div>
            }
            type="info"
            style={{ marginBottom: 24 }}
          />
        </>
      ) : null}

      <div className={SearchContainer}>
        <SearchInput
          placeholder="Search"
          onChange={onSearchChangeDebounced}
          data-test="permissions-entities-search"
        />
        <MultiFilter
          selectedItems={selectedTypes}
          options={allTypeOptions}
          onChange={setSelectedTypes}
          label={"Type"}
          enableSelectAll={true}
          defaultSelectAll={true}
          width={180}
        />
      </div>

      {(resources.length === 0 && !assignmentsLoading) ||
      userStatus === OrganizationUserStatus.INACTIVE ||
      isAdminOrOrOwner ? (
        <div className={EmptyStateWrapperStyle}>
          <IntegrationsImg />
          <div className={EmptyStateSubTextStyle}>
            {isAdminOrOrOwner ? (
              <>
                {`No resource directly assigned to this ${principalLabel}. ${capitalize(
                  principalLabel,
                )} may still have
                access through their organization role. `}
                <PlainLink
                  href={"https://docs.superblocks.com/administration/org-roles"}
                  target="_blank"
                  rel="noreferrer"
                >
                  Learn more
                </PlainLink>
              </>
            ) : (
              <>{`No resource directly assigned to this ${principalLabel}`}</>
            )}
          </div>
        </div>
      ) : (
        <RecommendedTable<ResourceToRender>
          data={filteredResources}
          uniqueKey="id"
          columns={columns}
          paginationOptions={
            resources.length > 10 ? { pageSize: 10 } : undefined
          }
          loading={assignmentsLoading || loadingIntegrations}
          hideHeader={true}
        />
      )}

      {resourceToModify && roleType && (
        <ShareModalV2
          isVisible={isShareModalVisible}
          setShareModalVisible={setShareModalVisible}
          organizationId={orgId}
          resourceId={resourceToModify.id}
          resourceType={resourceToModify.type}
          resourceDisplayName={resourceToModify.name}
          inEditMode={false}
          roleType={roleType}
          readOnlyMode={true}
          showShareTab={true}
        />
      )}
    </>
  );
};

export default ResourceList;
