import { ResourceSettingType, RoleTypeEnum } from "@superblocksteam/shared";
import { Input, Tooltip } from "antd";
import React, { useCallback, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as PencilIcon } from "assets/icons/home/edit.svg";
import { MainWrapper } from "components/app";
import Layout from "components/app/Layout";
import { PrimaryButton, SecondaryButton } from "components/ui/Button";
import { FormItem } from "components/ui/Form";
import Link from "components/ui/Link";
import { HeaderWrapper } from "components/ui/Page";
import { RecommendedMultiDropdown } from "components/ui/RecommendedMultiDropdown";
import {
  DropdownOption,
  RecommendedSingleDropdown,
} from "components/ui/RecommendedSingleDropdown";
import { MANAGE_ORGANIZATION, MANAGE_ROLES } from "constants/rbac";
import { useAuthorizationCheck } from "hooks/ui/rbac/useAuthorizationCheck";
import { theme } from "legacy/constants/DefaultTheme";
import Header from "pages/components/Header";
import { PageNav } from "pages/components/PageNav";
import { PageWrapper } from "pages/components/PageWrapper";
import { selectOnlyOrganization } from "store/slices/organizations";
import { setOrganizationState } from "store/slices/organizations/actions";
import {
  useGetRoleSettingsQuery,
  useListRolesQuery,
  useUpdateRoleSettingsMutation,
} from "store/slices/reduxApi/rbac";
import { Heading2 } from "styles/Typography";
import { colors } from "styles/colors";
import { styleAsClass } from "styles/styleAsClass";
import {
  sendErrorUINotification,
  sendSuccessUINotification,
} from "utils/notification";
import { patchOrganizationGeneral } from "./client";

const SectionHeader = styleAsClass`
  margin-top: 24px;
  margin-bottom: 16px;
`;

const SectionWrapper = styleAsClass`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const OrgNameWrapper = styleAsClass`
  input, .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-focused) {
    background-color: ${colors.GREY_25};
    border-color: ${colors.GREY_100};
  }
 

  &[data-can-edit="true"] {
    input:disabled, .ant-input-affix-wrapper-disabled {
      color: ${colors.GREY_700};
      background-color: ${colors.GREY_25};
      cursor: default;
    }
    .ant-input-affix-wrapper-disabled {
       border-color: ${colors.GREY_100};
    }
  }

  &[data-can-edit="false"] {
    input:disabled.ant-input,
    .ant-input-affix-wrapper-disabled {
      color: ${colors.GREY_300};
      border-color: ${colors.GREY_50};
      background-color: ${colors.GREY_50};
    }
  }

  &[data-editing="true"] {
    input, .ant-input-affix-wrapper {
      background-color: ${colors.WHITE};
    }
  }

  &[data-editing="false"][data-can-edit="true"] {
    .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
      border-color: ${colors.GREY_100};
    }
  }
   &[data-editing="false"][data-can-edit="false"] {
    .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
      border-color: ${colors.GREY_50};
    }
  }

  .ant-input-affix-wrapper svg,
  .ant-input-affix-wrapper-disabled svg, {
    color: ${colors.GREY_500};
  }
`;

const BottomBarClass = styleAsClass`
  height: 64px;
  width: calc(100% - ${String(theme.pageNavWidth)}px);
  padding: 16px;
  background: ${colors.WHITE};
  border-top: 1px solid ${colors.GREY_100};
  box-shadow:0px -1px 0px 0px #22272F14, 0px -3px 6px 0px #22272F0A;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;
  position: fixed;
  bottom: 0px;
  right: 0px;
  font-size: 12px;
  line-height: 16px;
`;

const GeneralPage = () => {
  const [canManageRoles, canManageOrg] = useAuthorizationCheck([
    MANAGE_ROLES,
    MANAGE_ORGANIZATION,
  ]);

  const dispatch = useDispatch();
  // todo: remove the type once the backend is updated.
  const organization = useSelector(selectOnlyOrganization);

  const [selectedAllowedDomainOptions, setSelectedAllowedDomainOptions] =
    useState<DropdownOption[]>([]);
  const [isAllowedDomainDirty, setIsAllowedDomainDirty] = useState(false);

  const allowedDomainOptions = useMemo(() => {
    const allOptions = organization.domains?.map((domain) => ({
      key: domain.id,
      displayName: domain.name,
      value: domain.id,
    }));

    setSelectedAllowedDomainOptions(
      organization.domains
        ?.filter((domain) => domain.allowed)
        .map((domain) => ({
          key: domain.id,
          displayName: domain.name,
          value: domain.id,
        })) ?? [],
    );
    return allOptions;
  }, [organization.domains]);

  const onAllowedDomainChange = useCallback((options: DropdownOption[]) => {
    setSelectedAllowedDomainOptions(options);
    setIsAllowedDomainDirty(true);
  }, []);

  const { data: allRoles } = useListRolesQuery({
    organizationId: organization.id,
  });

  const { data: roleSettings, refetch: refetchRoleSettings } =
    useGetRoleSettingsQuery();

  const [updateRoleSettings, { isLoading: isUpdatingRoles }] =
    useUpdateRoleSettingsMutation();

  const orgRoleOptions = useMemo(() => {
    if (!allRoles) return [];
    return allRoles
      .filter((role) => {
        return role.type === RoleTypeEnum.ORGANIZATION;
      })
      .map((role) => ({
        key: role.id,
        displayName: role.name,
        value: role.id,
      }));
  }, [allRoles]);

  const [selectedOrgRole, setSelectedOrgRole] = useState<string | null>(null);
  const [isOrgRoleDirty, setIsOrgRoleDirty] = useState(false);

  const handleRoleChange = useCallback((option: DropdownOption) => {
    setSelectedOrgRole(option?.value ?? null);
    setIsOrgRoleDirty(true);
  }, []);

  const [organizationName, setOrganizationName] = useState(organization.name);
  const [isNameDirty, setIsNameDirty] = useState(false);

  const handleNameChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setOrganizationName(e.target.value);
      setIsNameDirty(true);
    },
    [],
  );

  const [isSaving, setIsSaving] = useState(false);

  const handleSave = useCallback(async () => {
    setIsSaving(true);

    // save name
    if (organizationName && isNameDirty) {
      try {
        await patchOrganizationGeneral(organization.id, {
          name: organizationName,
        });
        dispatch(
          setOrganizationState.create({
            name: organizationName,
          }),
        );
        setIsNameDirty(false);
      } catch (error: any) {
        sendErrorUINotification({
          message: `Failed to update organization name: ${
            error?.message ?? error?.code
          }`,
        });
        setIsSaving(false);
        return;
      }
    }

    // save allowed domains
    if (isAllowedDomainDirty) {
      const allowedDomainIds = selectedAllowedDomainOptions.map(
        (option) => option.value,
      );

      try {
        await patchOrganizationGeneral(organization.id, {
          allowedDomainIds,
        });

        setIsAllowedDomainDirty(false);
        dispatch(
          setOrganizationState.create({
            domains: organization.domains?.map((domain) => ({
              ...domain,
              allowed: allowedDomainIds.includes(domain.id),
            })),
          }),
        );
      } catch (error: any) {
        sendErrorUINotification({
          message: `Failed to save allowed domains: ${
            error?.message ?? error?.code
          }`,
        });
        setIsSaving(false);
        return;
      }
    }

    // save role settings
    if (isOrgRoleDirty) {
      try {
        const defaultRoleSaveResp = await updateRoleSettings({
          [RoleTypeEnum.ORGANIZATION]: {
            [ResourceSettingType.NEW_USER]:
              selectedOrgRole === "no_access" || !selectedOrgRole
                ? null
                : selectedOrgRole,
          },
        });
        if (defaultRoleSaveResp.error) {
          sendErrorUINotification({
            message: "Failed to update role settings",
          });
          setIsSaving(false);
          return;
        } else {
          setIsOrgRoleDirty(false);
          await refetchRoleSettings();
        }
      } catch (error: any) {
        sendErrorUINotification({
          message: `Failed to update role settings: ${
            error?.message ?? error?.code
          }`,
        });
        setIsSaving(false);
        return;
      }
    }

    sendSuccessUINotification({
      message: "Organization settings updated",
    });

    setIsSaving(false);
  }, [
    organizationName,
    isNameDirty,
    isAllowedDomainDirty,
    isOrgRoleDirty,
    organization.id,
    organization.domains,
    dispatch,
    selectedAllowedDomainOptions,
    updateRoleSettings,
    selectedOrgRole,
    refetchRoleSettings,
  ]);

  const handleReset = useCallback(() => {
    // reset name
    setOrganizationName(organization.name);

    // reset allowed domains
    setSelectedAllowedDomainOptions(
      organization.domains
        ?.filter((domain) => domain.allowed)
        .map((domain) => ({
          key: domain.id,
          displayName: domain.name,
          value: domain.id,
        })) ?? [],
    );

    // reset role settings
    setSelectedOrgRole(
      roleSettings?.[RoleTypeEnum.ORGANIZATION]?.new_user ?? null,
    );

    setIsOrgRoleDirty(false);
    setIsNameDirty(false);
    setIsAllowedDomainDirty(false);
  }, [organization.domains, organization.name, roleSettings]);

  const [showPencil, setShowPencil] = useState(false);
  const [pencilHovered, setPencilHovered] = useState(false);
  const onPencilMouseEnter = useCallback(() => {
    setPencilHovered(true);
  }, []);
  const onPencilMouseLeave = useCallback(() => {
    setPencilHovered(false);
  }, []);
  const [isEditing, setIsEditing] = useState(false);
  const handleInputMouseEnter = useCallback(() => {
    if (!isEditing) {
      setShowPencil(true);
    }
  }, [isEditing]);

  const handleInputMouseLeave = useCallback(() => {
    if (!isEditing) {
      setShowPencil(false);
    }
  }, [isEditing]);

  const handlePencilClick = useCallback(() => {
    setIsEditing(true);
  }, []);

  const handleInputFocus = useCallback(
    (e: React.FocusEvent<HTMLInputElement>) => {
      if (!isEditing) {
        e.preventDefault();
        e.stopPropagation();
      }
    },
    [isEditing],
  );

  const handleInputBlur = useCallback(() => {
    setIsEditing(false);
    setShowPencil(false);
  }, []);

  return (
    <PageWrapper pageName={"General"}>
      <Layout Header={<Header />} Sider={<PageNav />}>
        <MainWrapper style={{ maxWidth: 730 }}>
          <div className={HeaderWrapper}>
            <div className="page-header-title"> General</div>
          </div>
          <div className={`${Heading2} ${SectionHeader}`}>Details</div>
          <FormItem
            label="Name"
            required={true}
            error={
              isNameDirty && !organizationName ? "Name is required" : undefined
            }
          >
            <Tooltip
              title={
                !canManageOrg
                  ? "You do not have permission to manage organization name"
                  : ""
              }
            >
              <div
                className={OrgNameWrapper}
                data-editing={isEditing}
                data-can-edit={canManageOrg}
                onMouseEnter={handleInputMouseEnter}
                onMouseLeave={handleInputMouseLeave}
              >
                <Input
                  value={organizationName}
                  onChange={handleNameChange}
                  disabled={!canManageOrg || (!isEditing && !pencilHovered)}
                  onFocus={handleInputFocus}
                  onBlur={handleInputBlur}
                  suffix={
                    <PencilIcon
                      width={16}
                      height={16}
                      onClick={canManageOrg ? handlePencilClick : undefined}
                      style={{
                        opacity:
                          !showPencil || isEditing || !canManageOrg ? 0 : 1,
                        cursor: canManageOrg ? "pointer" : "not-allowed",
                      }}
                      onMouseEnter={onPencilMouseEnter}
                      onMouseLeave={onPencilMouseLeave}
                    />
                  }
                />
              </div>
            </Tooltip>
          </FormItem>
          <div className={`${Heading2} ${SectionHeader}`}>Access</div>
          <div className={SectionWrapper}>
            <FormItem
              label="Allowed email domains"
              subText="Anyone with email addresses at these domains can automatically join your organization"
            >
              <RecommendedMultiDropdown
                options={allowedDomainOptions}
                selectedItems={selectedAllowedDomainOptions}
                onChange={onAllowedDomainChange}
                disabled={!canManageOrg}
                tooltip={
                  !canManageOrg
                    ? "You do not have permission to manage allowed domains"
                    : ""
                }
                placeholder={canManageOrg ? "Select an email domain" : ""}
                noResultsMessage={
                  (allowedDomainOptions ?? []).length === 0 ? (
                    <span>
                      Add members with email addresses on private domains to
                      configure.{" "}
                      <Link
                        to="https://docs.superblocks.com/administration/members/invite-users"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Learn more
                      </Link>
                    </span>
                  ) : (
                    <span>
                      Type the email domain of a member of this organization
                    </span>
                  )
                }
              />
            </FormItem>
            <FormItem
              label="New user default role"
              subText="The organization role users will be assigned when joining your organization if not explicitly set"
            >
              <RecommendedSingleDropdown
                options={orgRoleOptions ?? []}
                value={
                  selectedOrgRole ??
                  roleSettings?.[RoleTypeEnum.ORGANIZATION]?.new_user ??
                  undefined
                }
                onChange={handleRoleChange}
                allowClearing={false}
                placeholder="No role"
                popoverProps={{
                  matchTargetWidth: true,
                }}
                disabled={isUpdatingRoles || !canManageRoles}
                tooltip={
                  !canManageRoles
                    ? "You do not have permission to manage roles"
                    : ""
                }
              />
            </FormItem>
          </div>
          {(isOrgRoleDirty || isNameDirty || isAllowedDomainDirty) && (
            <div className={BottomBarClass}>
              <SecondaryButton onClick={handleReset} disabled={isSaving}>
                Reset
              </SecondaryButton>
              <PrimaryButton onClick={handleSave} disabled={isSaving}>
                Save
              </PrimaryButton>
            </div>
          )}
        </MainWrapper>
      </Layout>
    </PageWrapper>
  );
};

export default GeneralPage;
