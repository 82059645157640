import { Classes, ControlGroup } from "@blueprintjs/core";
import CodeMirror from "codemirror";
import React, { useRef, memo, useEffect, useMemo } from "react";
import styled from "styled-components";
import { EditorStyles } from "components/app/CodeEditor/editorStyles";
import { disableNudgeAction } from "legacy/actions/widgetActions";
import {
  FormInputWithErrorWrapperStyle,
  GAP_BETWEEN_INPUT_AND_INLINE_ERROR_MESSAGE,
  INLINE_ERROR_MESSAGE_HEIGHT,
} from "legacy/components/editorComponents/ErrorInlineMessage";
import WidgetErrorsWrapper from "legacy/components/editorComponents/WidgetErrorsWrapper";
import {
  ErrorMessagePlacement,
  LINTING_ENABLED_CODE_WIDGET_MODES,
  WIDGET_PADDING,
} from "legacy/constants/WidgetConstants";
import { selectGeneratedTheme } from "legacy/selectors/themeSelectors";
import { CLASS_NAMES } from "legacy/themes/classnames";
import { useAppDispatch, useAppSelector } from "store/helpers";
import { getComponentDimensions } from "utils/size";
import {
  getLabelWidthCssValue,
  labelStyleRaw,
} from "../Shared/widgetLabelStyles";
import { useStyleClassNames, useTypographyStyling } from "../typographyHooks";
import { getLineHeightInPxFromTextStyle } from "../typographyUtils";
import { DEFAULT_CODE_WIDGET_LABEL_TEXT_STYLE_VARIANT } from "./constants";
import { CodeComponentWithManagedLayoutProps, CodeEditorProps } from "./types";
import "./jsonLint";

import "codemirror/mode/javascript/javascript";
import "codemirror/mode/ruby/ruby";
import "codemirror/mode/htmlembedded/htmlembedded";
import "codemirror/mode/markdown/markdown";
import "codemirror/mode/python/python";
import "codemirror/addon/hint/show-hint";
import "codemirror/addon/comment/comment";
import "codemirror/addon/edit/matchbrackets";
import "codemirror/addon/display/placeholder";
import "codemirror/addon/edit/closebrackets";
import "codemirror/addon/fold/foldcode";
import "codemirror/addon/fold/foldgutter";
import "codemirror/addon/fold/brace-fold";
import "codemirror/addon/fold/indent-fold";
import "codemirror/addon/lint/lint";
import "components/app/CodeEditor/CodeEditor.css";

CodeMirror.defineMIME("application/hjson", {
  name: "javascript",
  json: true,
});

const StyledWrapper = styled.div<{
  height?: number;
}>`
  ${EditorStyles}
  height: ${(props) => (props.height ? `${props.height}px` : "fit-content")};

  overflow: hidden;

  && {
    .CodeMirror,
    .CodeMirror-scroll,
    .CodeMirror-gutter {
      height: "auto";
    }
  }
`;

const defaultOptions: CodeMirror.EditorConfiguration = {
  viewportMargin: 10,
  tabSize: 2,
  lineWrapping: false,
  lint: true,
  scrollbarStyle: "native",
  extraKeys: {
    "Ctrl-/": (cm) => cm.execCommand("toggleComment"),
    "Cmd-/": (cm) => cm.execCommand("toggleComment"),
    "Ctrl-F": (cm) => cm.execCommand("findPersistent"),
    "Cmd-F": (cm) => cm.execCommand("findPersistent"),
  },
  theme: "neo",
  foldOptions: {},
  foldGutter: true,
  gutters: [
    "CodeMirror-lint-markers",
    "CodeMirror-foldgutter",
    "CodeMirror-linenumbers",
  ],
};

const defaultNewline = (props: CodeEditorProps) => {
  const rn = props.initialValue?.indexOf("\r\n");
  const n = props.initialValue?.indexOf("\n");
  if (rn === -1 && n === -1) return "\n";
  if (rn === -1) return "\n";
  if (n === -1) return "\r\n";
  return rn < n ? "\r\n" : "\n";
};

const updateNewlineCharacter = (
  props: CodeEditorProps,
  editor: CodeMirror.Editor,
) => {
  const newlineCharacter =
    props.newlineCharacter !== undefined
      ? props.newlineCharacter
      : defaultNewline(props);

  if (newlineCharacter !== editor.getOption("lineSeparator")) {
    editor.setOption("lineSeparator", newlineCharacter);
    if (props.initialValue) {
      editor.setValue(props.initialValue);
    }
  }
};

function reinitializeCodeMirror(
  editor: CodeMirror.Editor,
  props: CodeEditorProps,
) {
  if (props.placeholder !== editor.getOption("placeholder")) {
    editor.setOption("placeholder", props.placeholder);
  }
  const readOnly = !props.isEditable;
  if (readOnly !== editor.getOption("readOnly")) {
    editor.setOption("readOnly", readOnly);
    // hiding cursor, instead of using "nocursor" mode, to allow copying and proper scrolling
    editor.setOption("cursorBlinkRate", readOnly ? -1 : 530);
  }
  if (props.mode !== editor.getOption("mode")) {
    editor.setOption("mode", props.mode);
    editor.performLint();
  }
  if (props.lineWrapping !== editor.getOption("lineWrapping")) {
    editor.setOption("lineWrapping", props.lineWrapping);
  }

  if (props.showLineNumbers !== editor.getOption("lineNumbers")) {
    editor.setOption("lineNumbers", props.showLineNumbers);
  }

  updateNewlineCharacter(props, editor);
}

function CodeEditor(props: CodeEditorProps) {
  const textareaRef = useRef<HTMLTextAreaElement | null>(null);
  const cm = useRef<CodeMirror.Editor | null>(null);
  const editor = cm.current;
  const dispatch = useAppDispatch();

  if (editor) {
    reinitializeCodeMirror(editor, props);
  }

  const { stringValue } = props;

  useEffect(() => {
    // Watching for changes in meta properties from an external source,
    // such as calling resetComponent.
    if (
      cm.current &&
      typeof stringValue === "string" &&
      cm.current.getValue() !== stringValue
    ) {
      cm.current.setValue(stringValue);
      cm.current.performLint();
    }
  }, [stringValue]);

  // refresh styles in the case that the editor is inside a modal
  useEffect(() => {
    const timer = setTimeout(() => {
      cm.current?.refresh();
    }, 1200);

    return () => clearTimeout(timer); // Cleanup the timer on unmount
  }, []);

  const handleFocus = () => props.onFocusChange?.(true);
  const handleBlur = () => props.onFocusChange?.(false);

  return (
    <StyledWrapper
      height={props.height}
      className={`${CLASS_NAMES.CODE_EDITOR}
      ${props.showError ? CLASS_NAMES.ERROR_MODIFIER : ""}
      ${props.isEditable ? "" : CLASS_NAMES.DISABLED_MODIFIER}`}
      data-test="ce-text-area-wrapper"
      onFocus={handleFocus}
      onBlur={handleBlur}
    >
      <textarea
        ref={(v) => {
          if (!textareaRef.current && v) {
            textareaRef.current = v;
            cm.current = CodeMirror.fromTextArea(v, {
              ...defaultOptions,
              lint: props.lintingEnabled,
              lineNumbers: props.showLineNumbers,
            });
            reinitializeCodeMirror(cm.current, props);
            cm.current.on("change", (e, changeObj) => {
              // ignore changes that happened programmatically with setValue
              if (changeObj.origin !== "setValue") {
                props.onChange(e.getValue(props.newlineCharacter));
              }
            });
            cm.current.on("focus", (e) => {
              dispatch(disableNudgeAction(true));
              // in the edge case that the styling is off for some reason
              // refresh on focus to fix it
              cm.current?.refresh();
            });
            cm.current.on("blur", (e) => {
              dispatch(disableNudgeAction(false));
            });
            cm.current.setSize(null, "100%");
          }
        }}
      />
    </StyledWrapper>
  );
}

const MemoCodeEditor = memo(CodeEditor);

const StyledOuterWrapper = styled(ControlGroup)`
  &&:not(.${Classes.VERTICAL}) {
    label {
      ${labelStyleRaw.horizontal}
      flex-basis: var(--label-width);
      max-width: calc(var(--label-width) - ${WIDGET_PADDING}px);
    }

    .${CLASS_NAMES.CODE_EDITOR} {
      height: max-content;
    }
  }

  &&.${Classes.VERTICAL} {
    label {
      ${labelStyleRaw.vertical}
      max-width: 100%;
      margin-bottom: 0;
    }
  }

  label {
    flex: 0 0 auto;
    text-align: left;
  }
`;

export const CodeComponentWithManagedLayout: React.FC<
  CodeComponentWithManagedLayoutProps
> = (props) => {
  const { label } = props;
  const generatedTheme = useAppSelector(selectGeneratedTheme);

  const labelProps = useTypographyStyling({
    textStyle: props.labelProps?.textStyle,
    defaultTextStyleVariant: DEFAULT_CODE_WIDGET_LABEL_TEXT_STYLE_VARIANT,
  });

  const componentHeight = getComponentDimensions(props).componentHeight;
  const mainHeight = useMemo(() => {
    const availableHeight =
      props.errorMessagePlacement === ErrorMessagePlacement.INLINE
        ? componentHeight -
          GAP_BETWEEN_INPUT_AND_INLINE_ERROR_MESSAGE -
          INLINE_ERROR_MESSAGE_HEIGHT
        : componentHeight;

    const labelLineHeight =
      props.vertical && props.label
        ? getLineHeightInPxFromTextStyle({
            textStyleVariant: labelProps.textStyleVariant,
            nestedProps: props.labelProps?.textStyle,
            defaultTextStyleVariant:
              DEFAULT_CODE_WIDGET_LABEL_TEXT_STYLE_VARIANT,
            typographies: generatedTheme.typographies,
          })
        : 0;

    return props.height.mode === "fitContent"
      ? undefined
      : availableHeight - labelLineHeight;
  }, [
    componentHeight,
    props.errorMessagePlacement,
    props.vertical,
    props.label,
    labelProps.textStyleVariant,
    props.labelProps?.textStyle,
    generatedTheme.typographies,
    props.height.mode,
  ]);

  // Set as true for code editors created before this property was introduced
  const showLineNumbers = props.showLineNumbers !== false;

  const labelClass = useStyleClassNames({
    textStyleVariant: labelProps.textStyleVariant,
    type: "label",
  });

  const styleVars: Record<string, unknown> = {
    "--label-width": getLabelWidthCssValue(props.labelProps?.width),
  };

  return (
    <WidgetErrorsWrapper
      widgetId={props.widgetId}
      showError={props.showError ?? false}
      messages={props.errorMessages}
      errorMessagePlacement={props.errorMessagePlacement}
      isFocused={props.isFocused}
      inlineErrorProps={{
        isFitContentHeight: props.height?.mode === "fitContent",
      }}
    >
      {(inlineError) => (
        <StyledOuterWrapper
          key={props.mode}
          className={props.isLoading ? Classes.SKELETON : ""}
          vertical={props.vertical}
          style={styleVars}
        >
          {!props.isLoading && label && (
            <label className={labelClass} style={labelProps?.style}>
              {props.isRequired && props.label.indexOf("*") === -1 && (
                <span className={`asterisk ${CLASS_NAMES.ERROR_MODIFIER}`}>
                  *{" "}
                </span>
              )}
              {label}
            </label>
          )}
          <div style={FormInputWithErrorWrapperStyle}>
            <MemoCodeEditor
              height={mainHeight}
              mode={props.mode}
              isEditable={!props.readOnly}
              initialValue={props.initialValue}
              onChange={props.onChange}
              stringValue={props.stringValue}
              lineWrapping={props.lineWrapping}
              showLineNumbers={showLineNumbers}
              newlineCharacter={props.newlineCharacter}
              lintingEnabled={
                LINTING_ENABLED_CODE_WIDGET_MODES.indexOf(props.mode) !== -1
              }
              vertical={props.vertical}
              showError={props.showError}
              errorMessages={props.errorMessages}
              onFocusChange={props.onFocusChange}
            />
            {inlineError}
          </div>
        </StyledOuterWrapper>
      )}
    </WidgetErrorsWrapper>
  );
};
