import { Placement } from "@popperjs/core";
import { isArray } from "lodash";
import React from "react";
import Popper from "components/ui/Popper";
import { Layers } from "legacy/constants/Layers";
import { useErrorMessages } from "./ErrorUtils";

interface Props {
  isOpen: boolean;
  messages: string | string[];
  wrapperClassName?: string;
  attachTo: Element | null | undefined;
  placement?: Placement;
}

const ErrorTooltip = (props: Props) => {
  const messages = useErrorMessages(props.messages);

  if (!props.attachTo || !props.isOpen || !messages?.length) return null;

  return (
    <Popper
      targetNode={props.attachTo}
      isOpen={props.isOpen && !!messages?.length}
      placement={props.placement ?? "bottom"}
      zIndex={Layers.max}
    >
      <div className={`${props.wrapperClassName} error-tooltip-popover`}>
        {isArray(messages) ? (
          messages.length > 0 ? (
            <ul>
              {messages.map((msg) => (
                <li key={msg}>{msg}</li>
              ))}
            </ul>
          ) : undefined
        ) : (
          String(messages)
        )}
      </div>
    </Popper>
  );
};

export default ErrorTooltip;
