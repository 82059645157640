import { OnboardingTask } from "@superblocksteam/shared";
import { createSelector } from "reselect";
import { PermissionToCheck } from "hooks/ui/rbac/useAuthorizationCheck";
import { getUserPermissionsMap } from "legacy/selectors/usersSelectors";
import { tasks } from "pages/components/Checklist/tasks";
import { Flag, selectFlags } from "../featureFlags";
import slice from "./slice";

export const selectUserChecklist = createSelector(
  slice.selector,
  (state) => state.entity.checklistTasks,
);

export const selectVisibleChecklist = createSelector(
  selectUserChecklist,
  selectFlags,
  getUserPermissionsMap,
  (checklist, flags, permissionMap) => {
    const rbacV2Enabled = flags[Flag.ENABLE_RBAC_V2];
    const visibleTasks = tasks
      .filter((task) => !task.hidden)
      .map((task) => {
        const checklistItem: OnboardingTask | undefined = checklist?.find(
          (i: OnboardingTask) => i.id === task.id,
        );
        return {
          ...task,
          completed: checklistItem?.completed,
          viewed: checklistItem?.viewed,
        };
      })
      .sort(
        (x, y) =>
          (x.completed?.getTime?.() ?? Number.MAX_VALUE) -
          (y.completed?.getTime?.() ?? Number.MAX_VALUE),
      );
    return !rbacV2Enabled
      ? visibleTasks
      : visibleTasks.filter(
          (task) =>
            !task.permissionsRequired ||
            task.permissionsRequired?.every((permission: PermissionToCheck) => {
              const { action, resourceType } = permission;
              return permissionMap?.[resourceType]?.[action] ?? false;
            }),
        );
  },
);

export const selectLastCompletedTask = createSelector(
  selectVisibleChecklist,
  (checklist) => {
    return checklist
      .slice()
      .reverse()
      .find((task) => task.completed);
  },
);

export const selectRecommendedDatasources = createSelector(
  slice.selector,
  (state) => state.entity.recommendedDatasources,
);
