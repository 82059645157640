import { RoleSettingsDto } from "@superblocksteam/shared";
import { callServer } from "store/utils/client";

export const patchOrganizationGeneral = (
  organizationId: string,
  updates: {
    name?: string;
    allowedDomainIds?: string[];
    roleSettings?: RoleSettingsDto;
  },
) => {
  return callServer(
    {
      method: "PATCH",
      url: `/v1/organizations/${organizationId}/general`,
      body: updates,
    },
    {
      notifyOnError: false,
      onError: (error) => {
        throw error;
      },
    },
  );
};
