import {
  Dimension,
  DimensionModes,
} from "@superblocksteam/shared/src/types/page";
import { css } from "styled-components";
import { WIDGET_PADDING } from "legacy/constants/WidgetConstants";

export const DEFAULT_LABEL_WIDTH_IN_PERCENT = 30;
const DEFAULT_LABEL_WIDTH_IN_PX = 80;

// raw strings for use with stylus
// NOTE: var(--label-margin) is theme based and is set in themeStyles
export const labelStyleRaw = {
  vertical: `
    margin: var(--label-margin) var(--label-margin) var(--label-margin) 0;
  `,
  horizontal: `
    margin: 8px ${WIDGET_PADDING * 2}px 4px 0;
  `,
};

// styled-components objects
export const labelStyle = {
  vertical: css`
    ${labelStyleRaw.vertical}
  `,
  horizontal: css`
    ${labelStyleRaw.horizontal}
  `,
};

export const getLabelWidthCssValue = (
  dimension?: Dimension<DimensionModes>,
) => {
  if (dimension?.mode === "fitContent") {
    return "unset";
  }

  // Dimension could have mode: 'gridUnit', or mode: 'px', but be missing the value.
  // This could happen when transitioning from modes without values a mode with values

  let dimensionWithDefault: Dimension<DimensionModes> | undefined = dimension;
  if (!dimensionWithDefault) {
    dimensionWithDefault = Dimension.percent(DEFAULT_LABEL_WIDTH_IN_PERCENT);
  } else if (
    dimensionWithDefault.mode === "px" &&
    dimensionWithDefault.value === undefined
  ) {
    dimensionWithDefault = Dimension.px(DEFAULT_LABEL_WIDTH_IN_PX);
  } else if (
    (dimensionWithDefault.mode === "%" &&
      dimensionWithDefault.value === undefined) ||
    dimensionWithDefault.mode === "gridUnit"
  ) {
    dimensionWithDefault = Dimension.percent(DEFAULT_LABEL_WIDTH_IN_PERCENT);
  }

  return `${dimensionWithDefault.value}${dimensionWithDefault.mode}`;
};
