import { Auth0Provider } from "@auth0/auth0-react";
import { appendParams, extractParamsWithPrefix } from "@superblocksteam/shared";
import React from "react";
import { useNavigate } from "react-router";
import envs from "env";
import { HOME_URL } from "legacy/constants/routes";
import loginState from "utils/loginState";

const Auth0ProviderWithHistory = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const navigate = useNavigate();

  const onRedirectCallback = (appState: any) => {
    loginState.newlyLoggedIn = true;
    let url =
      appState?.returnUrl ??
      (appState?.referrer
        ? `${HOME_URL}?referrer=${appState.referrer}`
        : HOME_URL);
    const utmParams = extractParamsWithPrefix(appState, "utm_");
    const sbTrackingParams = extractParamsWithPrefix(appState, "sbt_");
    url = appendParams(url, utmParams);
    url = appendParams(url, sbTrackingParams);
    if (url.startsWith("/code-mode")) {
      window.location.href = url;
    } else {
      navigate(url);
    }
  };

  return (
    <Auth0Provider
      domain={envs.get("SUPERBLOCKS_UI_AUTH_ISSUER_URL")}
      clientId={envs.get("SUPERBLOCKS_UI_AUTH_CLIENT_ID")}
      redirectUri={window.location.origin}
      audience={envs.get("SUPERBLOCKS_UI_AUTH_AUDIENCE")}
      scope="openid profile email"
      // TODO consider using session storage for XSS protection
      cacheLocation={(window as any).Cypress ? "localstorage" : "memory"}
      onRedirectCallback={onRedirectCallback}
    >
      {children}
    </Auth0Provider>
  );
};

export default Auth0ProviderWithHistory;
