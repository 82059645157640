import {
  Classes,
  Popover,
  PopoverInteractionKind,
  PopoverProps,
} from "@blueprintjs/core";
import { Dimension, Padding } from "@superblocksteam/shared/src/types";
import { clamp } from "lodash";
import React, { memo, useCallback, useMemo } from "react";
import { ReactComponent as ChevronDownIcon } from "assets/icons/common/chevron-down.svg";
import DynamicSVG from "components/ui/DynamicSVG";
import { WIDGET_PADDING } from "legacy/constants/WidgetConstants";
import { getAppMode } from "legacy/selectors/applicationSelectors";
import { selectGeneratedThemeTypographies } from "legacy/selectors/themeSelectors";
import { TextStyleWithVariant } from "legacy/themes";
import { BUTTON_PADDING } from "legacy/themes/constants";
import { useAppSelector } from "store/helpers";
import { styleAsClass } from "styles/styleAsClass";
import ButtonComponent from "../ButtonWidget/ButtonComponent";
import { DEFAULT_BUTTON_WIDGET_TEXT_STYLE_VARIANT } from "../ButtonWidget/constants";
import { ButtonComponentProps } from "../ButtonWidget/types";
import { isFitContent } from "../base/sizing/dynamicLayoutUtils";
import { useTypographyStyling } from "../typographyHooks";
import {
  getFontSizeInPxFromTextStyle,
  getIconSizeBasedOnFontSize,
} from "../typographyUtils";
import { MenuList, MenuListProps } from "./MenuList";
import type { RunWidgetEventHandlers } from "../BaseWidget";

// Applies to the popover element
const PopoverElementWrapper = styleAsClass`
 &&&& .${Classes.POPOVER_CONTENT} {
    // By default this class has overflow: hidden;
    // which prevents 2nd-level sub-menus from showing
    overflow: visible;

    padding: 0;
    border-radius: 4px;

    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.16) !important; // Overrides Blueprint's default shadow for sub-menus
  }
`;

// Applies to popover target (the menu button)
const PopoverTargetWrapper = styleAsClass`
  &&&&.${Classes.POPOVER_TARGET} > * {
    height: 100%; // allow button to be expanded
  }

  .chevron-right {
    transform: rotate(-90deg);
  }
`;

export type InteractionKind = PopoverInteractionKind;

export interface MenuComponentProps {
  widgetId?: string;
  items: MenuListProps["items"];
  isOpen?: boolean;
  interactionKind: InteractionKind;

  onClose?: (event?: React.SyntheticEvent<HTMLElement, Event>) => void;
  onBeforeMenuOpen?: () => void;

  runEventHandlers: (payload: RunWidgetEventHandlers) => void;

  // Button props
  buttonProps: Omit<
    ButtonComponentProps,
    "onClick" | "isLoading" | "icon" | "textProps" | "width" | "height"
  > & {
    icon?: string;
    width: Dimension;
    componentWidth: number;
    textStyle?: TextStyleWithVariant;
    height: Dimension;
    showCaret?: boolean;
  };

  menuProps: Pick<PopoverProps, "placement"> & {
    textStyle?: TextStyleWithVariant;
    width: Dimension;
    orientation: "vertical" | "horizontal";
  };
}

const POPOVER_MODIFIERS: PopoverProps["modifiers"] = {
  offset: {
    enabled: true,
    options: {
      offset: [0, 6],
    },
  },
};

const IconWrapper = memo(
  ({ size, children }: { size: number; children: React.ReactNode }) => {
    return (
      <div
        style={{
          minWidth: 0,
          width: size,
          overflow: "hidden",
          display: "flex",
        }}
      >
        {children}
      </div>
    );
  },
);

IconWrapper.displayName = "IconWrapper";

export default function MenuComponentWrapper(props: MenuComponentProps) {
  const appMode = useAppSelector(getAppMode);
  const themeTypographies = useAppSelector(selectGeneratedThemeTypographies);

  const buttonTextProps = useTypographyStyling({
    textStyle: props.buttonProps.textStyle,
    defaultTextStyleVariant: "buttonLabel",
  });

  const menuTextProps = useTypographyStyling({
    textStyle: props.menuProps.textStyle,
    defaultTextStyleVariant: "body2",
  });

  /**
   * For design reasons, when the menu opens below / on top of the button,
   * we want the menu width to be at least as wide as the button.
   */

  const minWidthForFitContent =
    props.menuProps.orientation === "vertical" &&
    props.menuProps.width.mode === "fitContent"
      ? props.buttonProps.componentWidth - 2 * WIDGET_PADDING
      : undefined;

  const menu =
    props.items.length > 0 ? (
      <MenuList
        items={props.items}
        textProps={menuTextProps}
        runEventHandlers={props.runEventHandlers}
        width={props.menuProps.width}
        minWidth={minWidthForFitContent}
        onClose={props.onClose}
      />
    ) : (
      <div />
    );

  const isButtonAutoWidth = isFitContent(props.buttonProps.width.mode);
  const isButtonAutoHeight = isFitContent(props.buttonProps.height.mode);

  let buttonMaxWidth = props.buttonProps.maxWidth;
  if (props.buttonProps.maxWidth) {
    buttonMaxWidth = props.buttonProps.maxWidth - 2 * WIDGET_PADDING;
  }

  let buttonMinWidth = props.buttonProps.minWidth;
  if (props.buttonProps.minWidth) {
    buttonMinWidth = props.buttonProps.minWidth - 2 * WIDGET_PADDING;
  }

  const iconSize = useMemo(() => {
    return buttonTextProps.textStyleVariant
      ? getIconSizeBasedOnFontSize(
          getFontSizeInPxFromTextStyle({
            nestedProps: props.buttonProps.textStyle,
            typographies: themeTypographies,
            textStyleVariant: props.buttonProps.textStyle?.variant,
            defaultTextStyleVariant: DEFAULT_BUTTON_WIDGET_TEXT_STYLE_VARIANT,
          }),
        )
      : clamp(
          !isButtonAutoWidth
            ? props.buttonProps.componentWidth -
                Padding.x(BUTTON_PADDING).value -
                WIDGET_PADDING * 2
            : 18,
          8,
          18,
        );
  }, [
    buttonTextProps.textStyleVariant,
    props.buttonProps.textStyle,
    themeTypographies,
    isButtonAutoWidth,
    props.buttonProps.componentWidth,
  ]);

  const icon = useMemo(() => {
    return props.buttonProps.icon ? (
      <DynamicSVG size={iconSize} iconName={props.buttonProps.icon} />
    ) : (
      <></>
    );
  }, [iconSize, props.buttonProps.icon]);

  const { interactionKind, isOpen, onBeforeMenuOpen, onClose } = props;
  const handleButtonClick = useCallback(() => {
    if (interactionKind === "click") {
      onBeforeMenuOpen?.();
    }
  }, [interactionKind, onBeforeMenuOpen]);

  const handleButtonHover = useCallback(() => {
    if (interactionKind === "hover") {
      onBeforeMenuOpen?.();
    }
  }, [interactionKind, onBeforeMenuOpen]);

  const handleInteraction = useCallback(
    (
      nextIsOpenState: boolean,
      e?: React.SyntheticEvent<HTMLElement, Event>,
    ) => {
      if (!nextIsOpenState && isOpen) {
        onClose?.(e);
      }
    },
    [onClose, isOpen],
  );

  const chevronIcon = props.buttonProps.showCaret ? (
    props.menuProps.orientation === "vertical" ? (
      <IconWrapper size={iconSize}>
        <ChevronDownIcon width={iconSize} height={iconSize} />
      </IconWrapper>
    ) : (
      <IconWrapper size={iconSize}>
        <ChevronDownIcon
          className="chevron-right"
          width={iconSize}
          height={iconSize}
        />
      </IconWrapper>
    )
  ) : (
    <></>
  );

  return (
    <Popover
      content={menu}
      placement={props.menuProps.placement}
      fill={true}
      popoverClassName={PopoverElementWrapper}
      className={PopoverTargetWrapper}
      isOpen={props.isOpen}
      onClose={props.onClose}
      matchTargetWidth={props.menuProps.width.mode === "fillParent"}
      modifiers={POPOVER_MODIFIERS}
      onInteraction={handleInteraction}
      interactionKind={interactionKind}
      transitionDuration={-1} // disable animation
    >
      <ButtonComponent
        onClick={handleButtonClick}
        onHover={handleButtonHover}
        text={props.buttonProps.text}
        disabled={props.buttonProps.disabled}
        isLoading={false}
        buttonStyle={props.buttonProps.buttonStyle}
        width={isButtonAutoWidth ? "auto" : "100%"}
        height={isButtonAutoHeight ? "auto" : "100%"}
        textColor={buttonTextProps?.style?.color}
        backgroundColor={props.buttonProps.backgroundColor}
        maxWidth={buttonMaxWidth}
        minWidth={buttonMinWidth}
        textAlignment={props.buttonProps.textAlignment}
        border={props.buttonProps.border}
        borderRadius={props.buttonProps.borderRadius}
        padding={props.buttonProps.padding ?? BUTTON_PADDING}
        icon={icon}
        iconPosition={props.buttonProps.iconPosition ?? "LEFT"}
        systemIcon={chevronIcon}
        textProps={buttonTextProps}
        data-test="menu-button"
        appMode={appMode}
        widgetId={props.widgetId}
        ddogActionName="menu-widget-button"
        version="v2"
      />
    </Popover>
  );
}
