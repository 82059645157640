import { isPlain } from "@reduxjs/toolkit";
import { Middleware } from "redux";
import { shouldLogPerformance } from "utils/performance";
import { isPayloadAction } from "./utils/action";

export const measureTiming: Middleware = () => {
  let currentDepth = 0;
  return (next) => (action) => {
    if (!isPayloadAction(action)) return next(action);
    if (performance.mark === undefined) return next(action);

    currentDepth++;
    const depth = currentDepth;
    performance.mark(`${action.type}_start-${depth}`);
    const result = next(action);
    performance.mark(`${action.type}_end-${depth}`);
    currentDepth--;

    const measureName = `♻️ ${action.type}${depth > 0 ? `(${depth})` : ""}`;
    performance.measure(
      measureName,
      `${action.type}_start-${depth}`,
      `${action.type}_end-${depth}`,
    );
    const time = performance.getEntriesByName(measureName)[0].duration;
    if (time > 5 && shouldLogPerformance()) {
      console.log("[Profiler] ", measureName, time);
    }

    performance.clearMarks(`${action.type}_start-${depth}`);
    performance.clearMarks(`${action.type}_end-${depth}`);
    performance.clearMeasures(measureName);

    return result;
  };
};

export const serializableCheck = {
  // The default serializability check is too strict for our use case
  isSerializable: (v: any) => {
    if (isPlain(v)) return true;
    if (typeof v === "object" && v instanceof Date) {
      return true;
    }
    if (
      typeof v === "object" &&
      // do not add more items to this list, only remove them.
      // also, keep in mind that these are the constructor names in dev mode only
      ["DataTreeGroup", "AgentWithHealth"].includes(v.constructor.name)
    ) {
      return true;
    }
    return false;
  },
};
