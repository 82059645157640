import {
  PropsPanelCategory,
  type PropertyPaneCategoryConfig,
} from "legacy/constants/PropertyControlConstants";
import { WidgetLabelPosition } from "legacy/constants/WidgetConstants";
import { PanelCategory } from "legacy/pages/Editor/PropertyPane/propertyPaneCategoryUtils";
import { ANIMATE_LOADING_PROPERTY_CONTROL_HELP_TEXT } from "pages/Editors/AppBuilder/Sidebar/PropertyControlCommons";
import {
  customValidationProperties,
  errorMessagePlacementProperty,
  labelWidthProperty,
} from "../FormInputs/formInputsProperties";
import {
  isDisabledProperty,
  labelPositionProperty,
  sizeSection,
  visibleProperties,
} from "../basePropertySections";
import { getPopoverConfig } from "../eventHandlerPanel";
import { typographyProperties } from "../styleProperties";
import { DEFAULT_RTE_WIDGET_LABEL_TEXT_STYLE_VARIANT } from "./constants";
import { RichTextEditorWidgetProps } from "./types";

const config: PropertyPaneCategoryConfig<RichTextEditorWidgetProps>[] = [
  PanelCategory(PropsPanelCategory.Content, [
    {
      helpText: "Sets a label text for the text editor",
      propertyName: "label",
      label: "Label",
      controlType: "INPUT_TEXT",
      placeholderText: "Enter label text",
      isBindProperty: true,
      isTriggerProperty: false,
      visibility: "SHOW_NAME",
      isRemovable: true,
      defaultValue: "Label",
    },
    {
      propertyName: "defaultValue",
      helpText:
        "Sets the default value of the component, parsed by mode. If the default text changes it will overwrite the user input.",
      label: "Default value",
      controlType: "INPUT_TEXT",
      placeholderText: "Enter HTML",
      isBindProperty: true,
      isTriggerProperty: false,
      visibility: "SHOW_NAME",
      isRemovable: true,
      defaultValue: "",
    },
  ]),
  PanelCategory(PropsPanelCategory.Appearance, [
    labelPositionProperty({
      hidden: (props: RichTextEditorWidgetProps) => !props.label,
      parentDottedPath: "labelProps",
    }),
    ...typographyProperties({
      defaultVariant: DEFAULT_RTE_WIDGET_LABEL_TEXT_STYLE_VARIANT,
      textStyleParentDottedPath: "labelProps",
      propertyNameForHumans: "Label",
      hiddenIfPropertyNameIsNullOrFalse: "label",
    }),
    {
      propertyName: "enableMenuBar",
      label: "File menu",
      helpText:
        "Controls whether the menu bar is shown, which exposed more advanced editing options.",
      controlType: "SWITCH",
      isJSConvertible: true,
      isBindProperty: true,
      isTriggerProperty: false,
      defaultValue: false,
    },
    {
      propertyName: "animateLoading",
      label: "Loading animation",
      helpText: ANIMATE_LOADING_PROPERTY_CONTROL_HELP_TEXT,
      controlType: "SWITCH",
      isJSConvertible: true,
      isBindProperty: true,
      isTriggerProperty: false,
    },
  ]),
  PanelCategory(PropsPanelCategory.Layout, [
    sizeSection(),
    labelWidthProperty({
      hidden: (props: RichTextEditorWidgetProps) =>
        !props.labelProps?.position ||
        !props.label ||
        props.labelProps?.position !== WidgetLabelPosition.LEFT,
    }),
    ...visibleProperties({ useJsExpr: false }),
  ]),
  PanelCategory(PropsPanelCategory.Interaction, [
    {
      propertyName: "convertMarkdown",
      label: "Convert markdown",
      helpText:
        "If enabled, markdown in the default value will automatically be converted to rich text.",
      controlType: "SWITCH",
      isJSConvertible: true,
      isBindProperty: true,
      isTriggerProperty: false,
    },
    isDisabledProperty({ useJsExpr: false }),
    {
      propertyName: "isRequired",
      label: "Required",
      helpText: "Makes input to the component mandatory",
      controlType: "SWITCH",
      isJSConvertible: true,
      isBindProperty: true,
      isTriggerProperty: false,
    },
    errorMessagePlacementProperty(),
    ...customValidationProperties(),
  ]),
  PanelCategory(PropsPanelCategory.EventHandlers, [
    getPopoverConfig(
      "onTextChanged",
      "Triggers an action when the text is changed",
    ),
  ]),
];

export default config;
