import {
  BranchDto,
  RepositoryEntityType,
  RepositoryWithEntitiesDto,
  VcsProvider,
} from "@superblocksteam/shared";
import { Button, Checkbox, Input, Select, Typography } from "antd";
import { Option } from "antd/lib/mentions";
import Table, { ColumnsType } from "antd/lib/table";
import Title from "antd/lib/typography/Title";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { ReactComponent as EmptyTableGraphic } from "assets/icons/common/empty-table-graphic.svg";
import { ReactComponent as ExternalLinkIcon } from "assets/icons/common/external-link.svg";
import { ReactComponent as RefreshIcon } from "assets/icons/common/refresh-2.svg";
import { Spinner } from "components/ui/Spinner";
import { useSaga } from "hooks/store";
import { useDebounce } from "hooks/ui";
import { useFeatureFlag } from "hooks/ui/useFeatureFlag";
import {
  BUILDER_APP_ID_URL,
  REPOSITORIES_URL,
  SCHEDULED_JOB_CONFIGURE_URL,
  WORKFLOW_CONFIGURE_URL,
} from "legacy/constants/routes";
import { BreadCrumb } from "pages/components/navigation/BreadCrumb";
import { Flag } from "store/slices/featureFlags";
import { HttpMethod, callServer } from "store/utils/client";
import { colors } from "styles/colors";
import { styleAsClass } from "styles/styleAsClass";
import {
  NotificationPosition,
  sendErrorUINotification,
  sendSuccessUINotification,
} from "utils/notification";
import { truncate } from "utils/string";
import { getUrlWithBranch } from "utils/urlWithBranch";
import { Layout, MainWrapper } from "../../components/app";
import {
  getRepositoriesSaga,
  refetchRepositorySaga,
  selectRepositories,
  selectRepositoriesLoading,
} from "../../store/slices/repositories";
import Header from "../components/Header";
import { PageNav } from "../components/PageNav";
import { PageWrapper, REPOSITORIES_TITLE } from "../components/PageWrapper";
import DeleteRepositoryModal from "./DeleteRepositoryModal";
import TypeTag from "./TypeTag";
import { getProviderBaseUrl, getProviderIcon } from "./utils";

const { Text } = Typography;

const StyledTable = styleAsClass`
  overflow-x: auto;
  border-top: 1px solid;
  border-color: ${colors.GREY_100};

  .ant-table-cell {
    padding: 11px 12px;
    background: none;
    font-size: 12px;
    font-weight: 500;
    color: ${colors.GREY_700};
  }

  .ant-avatar-string {
    font-size: 14px;
  }

  .ant-table-pagination-right {
    margin-right: 20px;
  }

  th {
    white-space: nowrap;
  }

  td {
    white-space: nowrap;
  }

  .builder-check-box {
    max-width: 50px;
    word-wrap: break-word;
  }
`;

const StyledTableCell = styleAsClass`
  width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

const Container = styleAsClass`
  display: flex;
  flex-direction: column;

  > .ant-typography {
    font-size: 12px;
    font-weight: 500;
    color: ${colors.GREY_700};
  }

  &&&
    .ant-select-disabled.ant-select:not(.ant-select-customize-input)
    .ant-select-selector,
  &&& .ant-input[disabled] {
    background: ${colors.GREY_50};
    border: 0;
  }

  &&& .ant-select-selection-item {
    color: ${colors.GREY_300};
  }
`;

const TableWrapper = styleAsClass`
  border: 1px solid ${colors.GREY_100};
  border-radius: 4px;
`;

const TableHeader = styleAsClass`
  display: flex;
  justify-content: space-between;
  padding: 12px;

  .left-section {
    display: flex;
    flex-direction: column;
    .ant-typography:first-child {
      color: ${colors.GREY_900};
      font-size: 15px;
      font-weight: 600;
    }
    .ant-typography:last-child {
      color: ${colors.GREY_700};
      font-size: 12px;
      font-weight: 400;
    }
  }

  .right-section {
    display: flex;
    gap: 12px;
    button.ant-btn {
      padding: 6px 7px;
    }
  }
`;

const DirInput = styleAsClass`
  width: 100%;
  font-size: 12px;
`;

const SearchInput = styleAsClass`
  width: 250px;
  height: 32px;
  border-color: ${colors.GREY_100};
`;

const RemoveRepositoryButton = styleAsClass`
  margin-top: 32px;
  border-color: ${colors.GREY_100};
`;

const RefetchButton = styleAsClass`
  @keyframes spinAnimation {
    from {
      transform: rotate(0deg);
    }

    to {
      transform: rotate(360deg);
    }
  }
  &[data-rotateIcon="true"] svg {
    animation: spinAnimation 1s linear infinite;
  }

  &[data-rotateIcon="false"] svg {
    animation: "none" 1s linear infinite;
  }
`;

const HeaderWrapper = styleAsClass`
  display: flex;
  justify-content: space-between;
  align-items: start;

  .ant-typography {
    margin: 0;
  }

  margin-bottom: 1rem;
`;

const TitleWrapper = styleAsClass`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  gap: 0.5em;
  color: ${colors.GREY_900};

  .ant-typography {
    font-size: 20px;
  }

  .header-repo-name {
    display: flex;
    align-items: center;
    gap: 8px;
  }
`;

const EmptyTable = styleAsClass`
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 32px;
  svg {
    margin-bottom: 8px;
  }
  span {
    width: 260px;
    color: ${colors.GREY_500};
    font-size: 12px;
    text-align: center;
  }
`;

const MainText = styleAsClass`
  font-size: 12px;
  color: ${colors.GREY_700};
`;

// Enable when connection status is implemented
// const ConnectionStatus = styled.div`
//   display: flex;
//   align-items: center;
//   gap: 8px;
//   color: ${({ theme }) => theme.colors.GREY_700};

//   .indicator-dot {
//     width: 12px;
//     height: 12px;
//     background: ${({ theme }) => theme.colors.LIGHT_GREEN}33;
//     border-radius: 12px;
//     position: relative;

//     &::after {
//       content: "";
//       width: 6px;
//       height: 6px;
//       border-radius: 6px;
//       background: ${({ theme }) => theme.colors.LIGHT_GREEN};
//       top: 3px;
//       left: 3px;
//       position: absolute;
//     }
//   }
// `;

const LinkToRepo = styleAsClass`
  width: 16px;
  height: 16px;
  color: ${colors.GREY_500};
`;

export default function Repositories() {
  const navigate = useNavigate();
  const location = useLocation();
  const enableMultiRepos = useFeatureFlag(Flag.ENABLE_MULTI_REPOS);

  const { repositoryId } = useParams() as { repositoryId: string };
  const [showConfirmDeleteModal, setConfirmDeleteModalVisible] =
    useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const onSearchChangeDebounced = useDebounce(
    (e) => setSearchTerm(e.target.value),
    200,
  );
  const repositories = useSelector(selectRepositories);
  const repositoriesLoading = useSelector(selectRepositoriesLoading);
  const currentRepository = repositories[repositoryId];

  const filteredAppflobs = useMemo(() => {
    const sortOrderByType: Record<RepositoryEntityType, number> = {
      [RepositoryEntityType.APPLICATION]: 0,
      [RepositoryEntityType.WORKFLOW]: 1,
      [RepositoryEntityType.SCHEDULED_JOB]: 2,
    };
    return currentRepository?.entities
      .filter((repo) =>
        repo.name.toLowerCase().includes(searchTerm.toLowerCase()),
      )
      .sort((a, b) => {
        if (a.type === b.type) {
          return a.name.localeCompare(b.name);
        } else {
          return sortOrderByType[a.type] - sortOrderByType[b.type];
        }
      });
  }, [currentRepository, searchTerm]);

  const [refetchRepository] = useSaga(refetchRepositorySaga);
  const [getRepositories] = useSaga(getRepositoriesSaga);

  const handleRefetchRepoConfig = () => {
    refetchRepository({ repositoryId: currentRepository?.id });
  };

  const repoFullName = `${currentRepository?.owner}/${currentRepository?.name}`;

  const columns: ColumnsType<RepositoryWithEntitiesDto["entities"][0]> =
    useMemo(() => {
      return [
        {
          width: 80,
          title: <Typography.Text strong>Type</Typography.Text>,
          dataIndex: "type",
          key: "type",
          fixed: "left",
          align: "left",
          render: (type: RepositoryEntityType) => {
            return <TypeTag type={type} />;
          },
        },
        {
          title: <Typography.Text strong>Name</Typography.Text>,
          dataIndex: "name",
          key: "name",
          render: (value: string) => (
            <div className={StyledTableCell}>
              {value ? truncate(value, 100) : ""}
            </div>
          ),
        },
      ];
    }, []);

  const [branches, setBranches] = useState<BranchDto[]>([]);

  const defaultBranch = useMemo(
    () => branches.find((branch) => branch.isDefault),
    [branches],
  );

  useEffect(() => {
    const getBranches = async () => {
      try {
        const branches = await callServer<BranchDto[]>({
          method: HttpMethod.Get,
          url: `/v1/repositories/${currentRepository.id}/branches`,
        });
        setBranches(branches);
      } catch (err: any) {
        console.error("Failed to load branches");
        sendErrorUINotification({
          message: `Failed to load branches (${err?.message ?? err?.code})`,
        });
      }
    };
    currentRepository?.id && getBranches();
  }, [currentRepository?.id]);

  const [updateBranchPending, setUpdateBranchPending] = useState(false);

  const updateProtectDefaultBranch = useCallback(
    async (isProtected: boolean) => {
      if (currentRepository?.id) {
        setUpdateBranchPending(true);
        const updatedBranch = await callServer<BranchDto>({
          method: HttpMethod.Put,
          url: getUrlWithBranch(
            `/v1/repositories/${currentRepository.id}`,
            defaultBranch?.name,
          ),
          body: {
            protected: isProtected,
          },
        });

        setBranches(
          branches.map((branch) =>
            branch.name === updatedBranch.name ? updatedBranch : branch,
          ),
        );
        sendSuccessUINotification({
          message: `Editing is ${isProtected ? "disabled" : "allowed"} on ${
            defaultBranch?.name
          } branch now.`,
          placement: NotificationPosition.top,
        });
        setUpdateBranchPending(false);
      }
    },
    [branches, currentRepository?.id, defaultBranch?.name],
  );

  const onClickProtectedCheckBoxText = useCallback(() => {
    updateProtectDefaultBranch(!defaultBranch?.protected);
  }, [defaultBranch?.protected, updateProtectDefaultBranch]);

  useEffect(() => {
    getRepositories({});
  }, [getRepositories]);

  const ProviderIcon = getProviderIcon(
    currentRepository?.provider ?? VcsProvider.GITHUB,
  );

  return (
    <PageWrapper pageName={repoFullName}>
      <Layout Header={<Header />} Sider={<PageNav />}>
        {currentRepository ? (
          <MainWrapper>
            {enableMultiRepos && (
              <BreadCrumb
                paths={[
                  {
                    title: REPOSITORIES_TITLE,
                    link: REPOSITORIES_URL,
                  },
                  { title: repoFullName },
                ]}
              />
            )}

            <div className={HeaderWrapper}>
              <div className={TitleWrapper}>
                <div className="header-repo-name">
                  <ProviderIcon width={20} height={20} />
                  <Title level={2}>{repoFullName}</Title>
                  <a
                    href={`${getProviderBaseUrl(
                      currentRepository?.provider,
                    )}/${repoFullName}`}
                    className={LinkToRepo}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <ExternalLinkIcon />
                  </a>
                </div>
              </div>
              {/* Temporarily hide connection status till it's implemented on BE */}
              {/* <ConnectionStatus>
              <div className="indicator-dot" />
              Connected
            </ConnectionStatus> */}
            </div>

            <div className={Container} style={{ marginTop: "36px" }}>
              <Text>Branch</Text>
              <Select
                style={{ width: "100%" }}
                defaultValue={currentRepository?.defaultBranch}
                disabled
              >
                <Option value={currentRepository?.defaultBranch}>
                  {currentRepository?.defaultBranch}
                </Option>
              </Select>
            </div>

            <Spinner spinning={updateBranchPending}>
              <div
                className={Container}
                style={{
                  marginTop: 12,
                  flexDirection: "row",
                  alignItems: "center",
                  cursor: "pointer",
                }}
                onClick={onClickProtectedCheckBoxText}
              >
                <Checkbox checked={defaultBranch?.protected} />
                <div className={MainText}>
                  &nbsp; Prevent editing on the <strong>main</strong> branch
                </div>
              </div>
            </Spinner>

            <div
              className={Container}
              style={{ marginTop: "8px", marginBottom: "32px" }}
            >
              <Text>Directory</Text>
              <Input
                className={DirInput}
                value={currentRepository?.configFileDirectory ?? ""}
                disabled
              />
            </div>

            <div className={TableWrapper}>
              <div className={TableHeader}>
                <div className="left-section">
                  <Text strong>
                    Applications, Workflows, and Scheduled Jobs
                  </Text>
                  <Text>
                    Commits made to synced tools will be automatically pushed to
                    this repository.
                  </Text>
                </div>
                <div className="right-section">
                  <Input
                    className={SearchInput}
                    placeholder="Search"
                    onChange={onSearchChangeDebounced}
                  />
                  <Button
                    className={RefetchButton}
                    data-rotateIcon={repositoriesLoading}
                    type="primary"
                    onClick={handleRefetchRepoConfig}
                  >
                    <RefreshIcon />
                  </Button>
                </div>
              </div>

              {!filteredAppflobs || filteredAppflobs?.length === 0 ? (
                <Spinner spinning={Boolean(repositoriesLoading)}>
                  <table className={EmptyTable}>
                    <EmptyTableGraphic />
                    <span>
                      {searchTerm.length > 0 ? (
                        <>
                          No tool named <strong>{searchTerm}</strong> is synced
                          to this repository. &nbsp;Learn{" "}
                        </>
                      ) : (
                        <>
                          No tools have been added to this repository to sync.
                          &nbsp;Learn{" "}
                        </>
                      )}
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://docs.superblocks.com/development-lifecycle/deploy/git-sync"
                      >
                        how to add new tools{" "}
                      </a>
                      or{" "}
                      <Typography.Link onClick={handleRefetchRepoConfig}>
                        refetch
                      </Typography.Link>{" "}
                      to start syncing tools recently added.
                    </span>
                  </table>
                </Spinner>
              ) : (
                <Table<RepositoryWithEntitiesDto["entities"][0]>
                  className={StyledTable}
                  dataSource={filteredAppflobs}
                  columns={columns}
                  pagination={{
                    simple: true,
                    position: ["bottomCenter"],
                    defaultPageSize: 10,
                  }}
                  onRow={(record) => ({
                    style: { cursor: "pointer" },
                    onClick: () => {
                      navigate({
                        pathname:
                          record.type === RepositoryEntityType.APPLICATION
                            ? BUILDER_APP_ID_URL(record.id)
                            : RepositoryEntityType.WORKFLOW
                              ? WORKFLOW_CONFIGURE_URL(record.id)
                              : SCHEDULED_JOB_CONFIGURE_URL(record.id),
                        search: location.search,
                      });
                    },
                  })}
                />
              )}
            </div>

            <Button
              className={RemoveRepositoryButton}
              danger
              onClick={() => setConfirmDeleteModalVisible(true)}
            >
              Remove repository
            </Button>
          </MainWrapper>
        ) : (
          <MainWrapper>
            {enableMultiRepos && (
              <BreadCrumb
                paths={[
                  {
                    title: REPOSITORIES_TITLE,
                    link: REPOSITORIES_URL,
                  },
                  { title: "" },
                ]}
              />
            )}
          </MainWrapper>
        )}
      </Layout>

      <DeleteRepositoryModal
        isVisible={showConfirmDeleteModal}
        setIsVisible={setConfirmDeleteModalVisible}
        repositoryId={currentRepository?.id}
      />
    </PageWrapper>
  );
}
