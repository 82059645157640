import { set, get } from "lodash";
import { call, put, select } from "redux-saga/effects";
import { WidgetType } from "legacy/constants/WidgetConstants";
import { getItemPropertyPaneConfig } from "legacy/pages/Editor/PropertyPane/ItemPropertyPaneConfig";
import { getWidget } from "legacy/selectors/sagaSelectors";
import { SB_CUSTOM_TEXT_STYLE } from "legacy/themes/typographyConstants";
import { mergeUpdatesWithBindingsOrTriggers } from "legacy/utils/DynamicBindingUtils";
import { AllFlags, Flag, selectFlags } from "store/slices/featureFlags";
import { fastClone } from "utils/clone";
import { selectAiState } from "../selectors";
import { updateAiChanges, setAiChanges } from "../slice";
import { applyWidgetHooksToAiEdits } from "./utils";

export function* updateAiChangesSaga(
  action: ReturnType<typeof updateAiChanges>,
) {
  const {
    changedKeys,
    dataTreeChanges,
    widgetRename,
    discardedEdits,
    selectedWidgetId,
    dependentChangesByWidgetId,
  }: ReturnType<typeof selectAiState> = yield select(selectAiState);
  if ("rename" in action.payload) {
    yield put(
      setAiChanges({
        changedKeys: changedKeys || [],
        dataTreeChanges: dataTreeChanges || {},
        rename: action.payload.rename,
        discardedEdits: discardedEdits || [],
        dependentChangesByWidgetId,
      }),
    );
  } else {
    const { updates, properties } = action.payload;
    const id = properties.widgetId as string;
    if (id !== selectedWidgetId) {
      // update additional changes
      yield put(
        setAiChanges({
          changedKeys: changedKeys || [],
          dataTreeChanges: dataTreeChanges || {},
          discardedEdits: discardedEdits || [],
          dependentChangesByWidgetId: {
            ...dependentChangesByWidgetId,
            [id]: updates,
          },
        }),
      );
      return;
    }
    const featureFlags: Partial<AllFlags> = yield select(selectFlags);
    const changesWithBindings = mergeUpdatesWithBindingsOrTriggers(
      properties,
      getItemPropertyPaneConfig(properties.type as WidgetType),
      updates,
      featureFlags[Flag.ENABLE_DEEP_BINDINGS_PATHS],
    );

    const newDataTreeChanges = dataTreeChanges
      ? fastClone(dataTreeChanges)
      : {};
    const newChangedKeys = changedKeys ? fastClone(changedKeys) : [];
    Object.entries(changesWithBindings).forEach(([key, value]) => {
      set(newDataTreeChanges, key, value);
      if (!newChangedKeys.includes(key)) {
        newChangedKeys.push(key);
      }
    });
    const existingWidget: ReturnType<typeof getWidget> = yield select((state) =>
      getWidget(state, selectedWidgetId ?? ""),
    );
    yield call(applyWidgetHooksToAiEdits, {
      changes: newDataTreeChanges,
      existingWidget,
    });

    // Filter out undefined text style properties from newChangedKeys
    // to ensure we don't show inline text style properties that are not in aiEdits
    // this is for when a user manually changes to a variant from a custom variant that
    // was previously set by the AI
    let hasCustomTextStyleVariantUpdate = false;
    for (const [key, value] of Object.entries(updates)) {
      if (key.includes("textStyle.variant") && value === SB_CUSTOM_TEXT_STYLE) {
        hasCustomTextStyleVariantUpdate = true;
        break;
      }
    }

    const filteredNewChangedKeys = newChangedKeys.filter(
      (dottedPropertyPath: string) => {
        const isANonVariantTextStyleProperty =
          dottedPropertyPath.includes("textStyle.") &&
          !dottedPropertyPath.includes("textStyle.variant");

        if (
          isANonVariantTextStyleProperty &&
          updates &&
          (get(updates, dottedPropertyPath) === undefined ||
            hasCustomTextStyleVariantUpdate)
        ) {
          return false;
        }
        return true;
      },
    );

    yield put(
      setAiChanges({
        changedKeys: filteredNewChangedKeys,
        dataTreeChanges: newDataTreeChanges,
        rename: widgetRename,
        discardedEdits: discardedEdits ?? [],
        dependentChangesByWidgetId,
      }),
    );
  }
}
