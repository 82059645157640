// This file contains the definition of the protocol used for communication between the SB server and clients

import { DirectoryEntry } from '../dbfs/types';
import { ApiToSign, ApiToVerify, AppToSign, AppToVerify, ApplicationSignatureTreeSigned, Signature } from '../signing/constants';
import {
  ApplicationConfiguration,
  ApplicationPageClonePayload,
  CreateApplicationPageResponseBody,
  CreateApplicationPageSocketPayload,
  GetMultiPageApplicationResponseBody,
  GetApiResponseBody,
  IApplicationV2Dto,
  PageSummary,
  PostApiBranchCreateResponseBody,
  PostApplicationBranchCreateResponseBody,
  PostApplicationCreateRequestBody,
  RemoteCommitDto,
  ResponseMeta,
  ExportedMultiPageApplicationDto,
  ExportViewMode,
  ExportedApiV3Dto,
  ModifyEntitiesPayload,
  ModifyEntitiesResponse
} from '../types';
import { MethodSchema } from './types';

export const serverWsPath = 'v1/rpc-ws';

type ResponseDto<T> = {
  responseMeta: ResponseMeta;
  data: T;
};

type ServerMethodSchema<Params, Response> = MethodSchema<Params, ResponseDto<Response>>;

// the version numbers (e.g. v1, v2, v3) follow the version numbers of the REST API
export interface ServerMethods {
  v1: {
    echo: ServerMethodSchema<{ message: string }, { message: string }>;
    public: {
      application: {
        component: {
          register: ServerMethodSchema<
            {
              applicationId: string;
              branchName: string;
              cliVersion: string;
              componentEvent: string;
              components: Record<string, unknown>;
            },
            { success: boolean }
          >;
          update: ServerMethodSchema<
            {
              applicationId: string;
              branchName?: string;
              srcFiles: string[];
              buildFiles: string[];
              registeredComponents: Record<string, never>;
              cliVersion: string | undefined;
              componentBaseUrl: string;
              signingRequired: boolean;
            },
            { success: boolean }
          >;
        };
        pushCommit: ServerMethodSchema<
          {
            applicationId: string;
            branchName: string;
            commitId: string;
            commitMessage: string;
            application: Record<string, unknown>;
            page: Record<string, unknown>;
            apis: Record<string, unknown>[];
            gitState: Record<string, unknown>;
          },
          RemoteCommitDto
        >;
      };
      api: {
        pushCommit: ServerMethodSchema<
          {
            apiId: string;
            branchName: string;
            commitId: string;
            commitMessage: string;
            apiPb: Record<string, unknown>;
            gitState: Record<string, unknown>;
            skipCommit?: boolean;
          },
          RemoteCommitDto | { updated: Date }
        >;
        get: ServerMethodSchema<{ apiId: string; branchName?: string; viewMode: ExportViewMode; commitId?: string }, ExportedApiV3Dto>;
      };
    };
    dbfs: {
      fileContents: {
        get: ServerMethodSchema<{ hash: string }, { contents: string }>;
        getByPath: ServerMethodSchema<{ directoryHash: string; path: string }, { contents: string }>;
        put: ServerMethodSchema<{ contents: string }, { hash: string }>;
      };
      directoryContents: {
        get: ServerMethodSchema<{ hash: string }, { contents: DirectoryEntry[] }>;
        getByPath: ServerMethodSchema<{ directoryHash: string; path: string }, { contents: DirectoryEntry[] }>;
        put: ServerMethodSchema<{ contents: DirectoryEntry[] }, { hash: string }>;
      };
    };
  };
  v2: {
    application: {
      create: ServerMethodSchema<PostApplicationCreateRequestBody, IApplicationV2Dto>;
      clone: ServerMethodSchema<{ applicationId: string }, IApplicationV2Dto>;
      createBranch: ServerMethodSchema<
        { applicationId: string; branchName: string; baseBranchName: string },
        PostApplicationBranchCreateResponseBody
      >;
      page: {
        clone: ServerMethodSchema<
          ApplicationPageClonePayload,
          {
            page: PageSummary;
            updated: Date;
            signature: ApplicationSignatureTreeSigned | null;
            configuration: ApplicationConfiguration;
          }
        >;
        create: ServerMethodSchema<CreateApplicationPageSocketPayload, CreateApplicationPageResponseBody>;
      };
      modifyEntities: ServerMethodSchema<ModifyEntitiesPayload, ModifyEntitiesResponse>;
    };
    public: {
      application: {
        pushCommit: ServerMethodSchema<
          {
            applicationId: string;
            branchName: string;
            commitId: string;
            commitMessage: string;
            application: Record<string, unknown>;
            pages: Record<string, unknown>[];
            apis: Record<string, unknown>[];
            gitState: Record<string, unknown>;
            skipCommit?: boolean;
          },
          RemoteCommitDto | { updated: Date }
        >;
        get: ServerMethodSchema<
          { applicationId: string; viewMode: ExportViewMode; branchName?: string; commitId?: string },
          ExportedMultiPageApplicationDto
        >;
      };
    };
  };
  v3: {
    application: {
      get: ServerMethodSchema<{ applicationId: string; branchName?: string }, GetMultiPageApplicationResponseBody>;
      liveEditDirectoryContents: {
        get: ServerMethodSchema<{ applicationId: string; branchName?: string }, { hash: string }>;
        set: ServerMethodSchema<{ applicationId: string; branchName?: string; hash: string }, { hash: string }>;
      };
    };
    api: {
      createBranch: ServerMethodSchema<{ apiId: string; branchName: string; baseBranchName: string }, PostApiBranchCreateResponseBody>;
      get: ServerMethodSchema<{ apiId: string; branchName?: string }, GetApiResponseBody>;
    };
  };
}

export interface ClientMethods {
  v1: {
    signing: {
      signApplication: MethodSchema<{ branchName: string; toSign: AppToSign }, { signature: Signature }>;
      signApis: MethodSchema<{ branchName: string; toSign: ApiToSign[] }, { signatures: Signature[] }>;
      verifyApplication: MethodSchema<{ branchName: string; toVerify: AppToVerify }, { ok: boolean }>;
      verifyApi: MethodSchema<{ branchName: string; toVerify: ApiToVerify[] }, { ok: boolean }>;
    };
  };
}
