import React, {
  useMemo,
  useCallback,
  useRef,
  SetStateAction,
  Dispatch,
  FunctionComponent,
} from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { createContext, Context } from "use-context-selector";
import { FormRef } from "components/app/SBDynamicForm";
import { useGetEditorPath } from "hooks/store/useGetEditorPath";
import { ReduxActionTypes } from "legacy/constants/ReduxActionConstants";
import {
  EditorRouteBottomPanelParams,
  WORKFLOW_EDITOR_URL,
  SCHEDULED_JOB_EDITOR_URL,
  EditorRoute,
} from "legacy/constants/routes";
import { useAppDispatch, useAppSelector } from "store/helpers";
import { ApiTriggerType } from "store/slices/apis/types";
import {
  BackendTypes,
  selectV2ApiLoadingById,
  getTriggerTypeFromApi,
} from "store/slices/apisV2";
import { BlockPositionLocator } from "store/slices/apisV2/control-flow/locators";
import {
  findBlockLocation,
  getBlockListFromLocator,
} from "store/slices/apisV2/control-flow/locators";
import {
  ControlFlowFrontendDSL,
  GenericBlock,
} from "store/slices/apisV2/control-flow/types";
import { addNewPromise } from "store/utils/resolveIdSingleton";
import {
  useControlFlowExplorer,
  ControlFlowExplorerState,
  ActionDispatchers,
} from "./control-flow-explorer-state";
import { ResultsPanelState, useResultsPanelState } from "./results-panel-state";
import { useResultsPaneState } from "./useResultsPaneState";

type BlockComponentType = FunctionComponent<{
  block: GenericBlock;
  children?: React.ReactNode;
}>;
type BlockListType = FunctionComponent<{
  handleBlockDrop: (params: { droppedNames: string[]; idx: number }) => void;
  blockNames: Array<string>;
  baseLocation: BlockPositionLocator;
  children?: React.ReactNode;
}>;

export type BlockRegistryType = {
  StepBlock: BlockComponentType;
  ParallelBlock: BlockComponentType;
  ConditionalBlock: BlockComponentType;
  LoopBlock: BlockComponentType;
  TryCatchBlock: BlockComponentType;
  GenericBlockCard: BlockComponentType;
  BlockList: BlockListType;
  ReturnBlock: BlockComponentType;
  WaitBlock: BlockComponentType;
  BreakBlock: BlockComponentType;
  VariablesBlock: BlockComponentType;
  ThrowBlock: BlockComponentType;
  SendBlock: BlockComponentType;
  StreamBlock: BlockComponentType;
};

export type ControlFlowContextType = {
  blockRegistry: BlockRegistryType;
  navigateToBlockForApi: (blockName: string) => void;
  explorerState: ControlFlowExplorerState;
  explorerActions: ActionDispatchers;
  apiId: BackendTypes.Api["metadata"]["id"];
  undo: () => void;
  redo: () => void;
  forceFocusApiEditor: () => void;
  getBlockSelectionAfterDelete: (blockName: string) => string | null;
  numBlocks: number;
  isApiRunning: boolean;
  actionFormRef: React.RefObject<FormRef>;
  resultsPane: ReturnType<typeof useResultsPaneState>;
  isWorkflowMode: boolean;
  resultsPanelState: ResultsPanelState;
  setResultsPanelState: Dispatch<SetStateAction<ResultsPanelState>>;
};

export const ControlFlowContext: Context<ControlFlowContextType> =
  createContext(
    new Proxy<ControlFlowContextType>({} as any, {
      get() {
        throw new Error("control flow context has not been defined");
      },
    }),
  );

export const emptyControlFlow: ControlFlowFrontendDSL = {
  rootBlocks: [],
  blocks: {},
};

export const ControlFlowContextProvider = (props: {
  blockRegistry: BlockRegistryType;
  controlFlow: ControlFlowFrontendDSL;
  api: BackendTypes.Api;
  isWorkflowMode: boolean;
  children?: React.ReactNode;
}) => {
  const { blockRegistry, api, controlFlow, isWorkflowMode } = props;
  const params = useParams<EditorRouteBottomPanelParams>();
  const location = useLocation();
  const navigate = useNavigate();
  const triggerType = getTriggerTypeFromApi(api);
  const getEditorPath = useGetEditorPath();

  const navigateToBlockForApi = useCallback(
    (blockName: string) => {
      let pathname: string | undefined;
      if (triggerType === ApiTriggerType.UI && params.applicationId) {
        pathname = getEditorPath(EditorRoute.EditApiAction, {
          applicationId: params.applicationId,
          apiId: api.metadata.id,
          actionId: blockName,
        });
      } else if (triggerType === ApiTriggerType.WORKFLOW) {
        pathname = WORKFLOW_EDITOR_URL(params.apiId, blockName);
      } else if (triggerType === ApiTriggerType.SCHEDULE) {
        pathname = SCHEDULED_JOB_EDITOR_URL(params.apiId, blockName);
      }
      if (pathname) {
        navigate({
          pathname,
          search: location.search,
        });
      } else {
        console.error("Unable to navigate to block");
      }
    },
    [
      getEditorPath,
      api.metadata.id,
      navigate,
      location.search,
      params.apiId,
      params.applicationId,
      triggerType,
    ],
  );

  const [explorerState, explorerActions] = useControlFlowExplorer({
    dsl: controlFlow,
    apiId: api.metadata.id,
  });

  const [resultsPanelState, setResultsPanelState] = useResultsPanelState();

  const dispatch = useAppDispatch();

  const forceFocusApiEditor = useCallback(() => {
    const apiEditor: HTMLElement | null = document.querySelector(
      "[data-superblocks=api-editor-container]",
    );
    apiEditor?.focus();
  }, []);

  const replay = useCallback(
    (replayType: "UNDO" | "REDO") => {
      const onCompletion = (blockName: string) => {
        navigateToBlockForApi(blockName);
        forceFocusApiEditor();
      };
      const callbackId = addNewPromise(onCompletion);
      dispatch({
        type: ReduxActionTypes.API_UNDO_REDO_OPERATION,
        payload: {
          apiId: api.metadata.id,
          operation: replayType,
          callbackId,
        },
      });
    },
    [dispatch, api.metadata.id, navigateToBlockForApi, forceFocusApiEditor],
  );

  const undo = useCallback(() => {
    replay("UNDO");
  }, [replay]);

  const redo = useCallback(() => {
    replay("REDO");
  }, [replay]);

  const getBlockSelectionAfterDelete = useCallback(
    (deletedBlockName: string) => {
      const blockLocation = findBlockLocation(controlFlow, deletedBlockName);
      if (!blockLocation) {
        throw new Error(
          `Cannot find location of block ${deletedBlockName} from DSL ${JSON.stringify(
            controlFlow,
          )}`,
        );
      }

      const siblingBlockList = getBlockListFromLocator(
        controlFlow,
        blockLocation,
      );

      // can we select the next block upstream of this one?
      if (blockLocation.idx > 0) {
        return siblingBlockList[blockLocation.idx - 1];
      }
      // if not, can we select the next block downstream?
      else if (blockLocation.idx === 0 && siblingBlockList.length > 1) {
        return siblingBlockList[1];
      }
      // If not, can we select a parent?
      else if (blockLocation.parentId) {
        return controlFlow.blocks[blockLocation.parentId].name;
      }
      // Otherwise, it was the last root block, so we can't select anything

      return null;
    },
    [controlFlow],
  );

  const numBlocks = Object.keys(controlFlow.blocks).length;

  const isApiRunning = useAppSelector((state) =>
    selectV2ApiLoadingById(state, api.metadata.id),
  );
  const actionFormRef = useRef<FormRef>(null);
  const resultsPane = useResultsPaneState(api.metadata.id);

  const contextValue = useMemo(() => {
    return {
      blockRegistry,
      resultsPane,
      explorerState,
      explorerActions,
      apiId: api.metadata.id,
      numBlocks,
      isApiRunning,
      actionFormRef,
      navigateToBlockForApi,
      undo,
      redo,
      forceFocusApiEditor,
      getBlockSelectionAfterDelete,
      isWorkflowMode,
      resultsPanelState,
      setResultsPanelState,
    } satisfies ControlFlowContextType;
  }, [
    blockRegistry,
    resultsPane,
    explorerState,
    explorerActions,
    api.metadata.id,
    numBlocks,
    isApiRunning,
    navigateToBlockForApi,
    undo,
    redo,
    forceFocusApiEditor,
    getBlockSelectionAfterDelete,
    isWorkflowMode,
    resultsPanelState,
    setResultsPanelState,
  ]);

  return (
    <ControlFlowContext.Provider value={contextValue}>
      {props.children}
    </ControlFlowContext.Provider>
  );
};
