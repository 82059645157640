import { ApplicationScope } from "@superblocksteam/shared";
import React from "react";
import {
  getDerivedPropertiesWithErrors,
  getErrorMessagesList,
} from "legacy/components/editorComponents/ErrorUtils";
import { EventType } from "legacy/constants/ActionConstants";
import { WidgetType } from "legacy/constants/WidgetConstants";
import { VALIDATION_TYPES } from "legacy/constants/WidgetValidation";
import {
  WidgetPropertyValidationType,
  BASE_WIDGET_VALIDATION,
} from "legacy/constants/WidgetValidation";

import { createRunEventHandlersPayloadOptional } from "legacy/utils/actions";
import { getComponentDimensions } from "utils/size";
import BaseWidget from "../BaseWidget";
import withMeta from "../withMeta";
import { InputComponentWithLayoutManaged } from "./InputComponentWithLayoutManaged";
import inputPropertyPaneConfig from "./InputPropertyPaneConfig";
import {
  INPUT_TYPES_WITH_MINMAX,
  InputTypes,
  currencyDecimalPlaces,
} from "./InputWidgetConstants";
import derivedProperties from "./parseDerivedProperties";
import type { DerivedPropertiesMap } from "../Factory";
import type { InputComponentProps, InputWidgetProps } from "./types";
import type { PropertyPaneConfig } from "legacy/constants/PropertyControlConstants";

class InputWidget extends BaseWidget<
  InputWidgetProps,
  { isFocused: boolean; text: string }
> {
  constructor(props: InputWidgetProps) {
    super(props);
    this.state = {
      text: props.text,
      isFocused: false,
    };
  }

  static getPropertyPaneConfig(): PropertyPaneConfig[] {
    return inputPropertyPaneConfig;
  }

  static getPropertyValidationMap(): WidgetPropertyValidationType {
    return {
      ...BASE_WIDGET_VALIDATION,
      inputType: VALIDATION_TYPES.TEXT,
      defaultText: VALIDATION_TYPES.TEXT,
      isDisabled: VALIDATION_TYPES.BOOLEAN,
      text: VALIDATION_TYPES.TEXT,
      placeholderText: VALIDATION_TYPES.TEXT,
      label: VALIDATION_TYPES.TEXT,
      focusIndex: VALIDATION_TYPES.NUMBER,
      isAutoFocusEnabled: VALIDATION_TYPES.BOOLEAN,
      isRequired: VALIDATION_TYPES.BOOLEAN,
      isValid: VALIDATION_TYPES.BOOLEAN,
      resetOnSubmit: VALIDATION_TYPES.BOOLEAN,
      minLength: VALIDATION_TYPES.NUMBER_ALLOW_UNDEFINED,
      maxLength: VALIDATION_TYPES.NUMBER_ALLOW_UNDEFINED,
      errorMessagePlacement: VALIDATION_TYPES.TEXT,
      customValidationRule: VALIDATION_TYPES.TEXT,
      customErrorMessage: VALIDATION_TYPES.TEXT,
      icon: VALIDATION_TYPES.ICONS,
      "inputProps.border": VALIDATION_TYPES.OBJECT_OR_UNDEFINED,
      "inputProps.borderRadius": VALIDATION_TYPES.OBJECT_OR_UNDEFINED,
    };
  }

  static getDerivedPropertiesMap(): DerivedPropertiesMap {
    return getDerivedPropertiesWithErrors({
      validationErrors: `{{(()=>{${derivedProperties.getValidationErrors}})()}}`,
      isValid: `{{!Object.values(this.validationErrors).some(e => !!e)}}`,
      value: `{{(()=>{${derivedProperties.getValue}})()}}`,
      isoCurrencyCode: `{{this.currency}}`,
    });
  }

  static getDefaultPropertiesMap(): Record<string, string> {
    return {
      text: "defaultText",
    };
  }

  static getMetaPropertiesMap(): Record<string, any> {
    return {
      text: undefined,
      isTouched: false,
    };
  }

  componentDidUpdate(prevProps: InputWidgetProps): void {
    if (this.props.inputType === InputTypes.CURRENCY && this.props.currency) {
      const decimalPlaces = currencyDecimalPlaces[this.props.currency] ?? 2;
      if (
        prevProps.currency !== this.props.currency ||
        (prevProps.minimumFractionDigits === "" &&
          prevProps.maximumFractionDigits === "")
      )
        this.updateWidgetProperties({
          minimumFractionDigits: decimalPlaces,
          maximumFractionDigits: decimalPlaces,
        });
    }
    if (
      this.props.inputType === InputTypes.EMAIL &&
      prevProps.inputType !== this.props.inputType
    ) {
      this.updateWidgetProperties({ icon: "mail" });
    }
    if (
      prevProps.inputType === InputTypes.EMAIL &&
      this.props.inputType !== InputTypes.EMAIL
    ) {
      this.updateWidgetProperties({ icon: undefined });
    }
  }

  onValueChange = (value: string) => {
    this.props.updateWidgetMetaProperty(
      "text",
      value,
      createRunEventHandlersPayloadOptional({
        steps: this.props.onTextChanged,
        currentScope: ApplicationScope.PAGE,
        type: EventType.ON_TEXT_CHANGE,
        entityName: this.props.widgetName,
      }),
    );
    if (!this.props.isTouched) {
      this.props.updateWidgetMetaProperty("isTouched", true);
    }
  };

  handleFocusChange = (focusState: boolean) => {
    const { onFocusOut, onFocus } = this.props;
    if (focusState && onFocus) {
      super.runEventHandlers({
        steps: onFocus,
        type: EventType.ON_FOCUS,
      });
    }
    if (!focusState) {
      if (!this.props.isTouched) {
        this.props.updateWidgetMetaProperty("isTouched", true);
      }
      if (onFocusOut) {
        super.runEventHandlers({
          steps: onFocusOut,
          type: EventType.ON_BLUR,
        });
      }
    }
    this.disableNudge(focusState);
    this.setState({ isFocused: focusState });
  };

  handleKeyDown = (
    e:
      | React.KeyboardEvent<HTMLTextAreaElement>
      | React.KeyboardEvent<HTMLInputElement>,
  ) => {
    const { isValid, onSubmit } = this.props;
    const isEnterKey = e.key === "Enter" || e.keyCode === 13;
    if (isEnterKey && onSubmit && isValid) {
      super.runEventHandlers({
        steps: onSubmit,
        type: EventType.ON_SUBMIT,
      });
    }
  };

  getPageView() {
    const { maxLength, minLength, ...baseProps } = this.props;
    const value = this.props.text || "";

    const conditionalProps: Partial<InputComponentProps> = {};
    if (INPUT_TYPES_WITH_MINMAX.includes(this.props.inputType)) {
      if (maxLength) conditionalProps.maxLength = maxLength;
      if (minLength) conditionalProps.minLength = minLength;
    }
    const validationErrors = { ...this.props.validationErrors };
    if (value && this.props.isRequired) {
      // remove the isrequired error if it is on errorMessages
      validationErrors.required = "";
    }
    conditionalProps.errorMessages = getErrorMessagesList(
      validationErrors,
      this.props.showError,
    );
    const isInvalid =
      conditionalProps.errorMessages.length > 0 && this.props.isTouched;
    const hasLabel = Boolean(this.props.label?.trim?.().length);
    const { componentHeight, componentWidth } = getComponentDimensions(
      this.props,
    );
    return (
      <InputComponentWithLayoutManaged
        {...baseProps}
        value={value}
        isInvalid={isInvalid}
        iconPosition={this.props.iconPosition ?? "LEFT"}
        onValueChange={this.onValueChange}
        widgetType="INPUT_WIDGET"
        vertical={this.props.isVertical}
        label={hasLabel ? this.props.label : ""}
        defaultValue={this.props.defaultText}
        placeholder={this.props.placeholderText}
        componentHeight={componentHeight}
        componentWidth={componentWidth}
        showStepper={this.props.stepper}
        stepSize={this.props.stepSize || 1}
        onFocusChange={this.handleFocusChange}
        disableNewLineOnPressEnterKey={!!this.props.onSubmit}
        onKeyDown={this.handleKeyDown}
        currency={this.props.currency || "USD"}
        currencyCodeDisplay={this.props.currencyCodeDisplay || "symbol"}
        preventFormattingWhileTyping
        isFocused={this.state.isFocused}
        {...conditionalProps}
      />
    );
  }

  getWidgetType(): WidgetType {
    return "INPUT_WIDGET";
  }
}

export default InputWidget;
export const ConnectedInputWidget = withMeta(InputWidget);
