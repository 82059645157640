import { Dimension } from "@superblocksteam/shared";
import {
  GAP_BETWEEN_INPUT_AND_INLINE_ERROR_MESSAGE,
  INLINE_ERROR_MESSAGE_HEIGHT,
} from "legacy/components/editorComponents/ErrorInlineMessage";
import {
  PropertyPaneControlConfig,
  PropsPanelCategory,
} from "legacy/constants/PropertyControlConstants";
import { ErrorMessagePlacement } from "legacy/constants/WidgetConstants";
import { getFlattenedCanvasWidgets } from "legacy/selectors/editorSelectors";
import { getParentPath } from "utils/dottedPaths";
import { getComponentDimensions } from "utils/size";
import { WidgetProps, WidgetPropsRuntime } from "../BaseWidget";
import { WidgetLayoutProps } from "../shared";

export const errorMessagePlacementProperty = (
  overrides: Partial<PropertyPaneControlConfig> = {},
): PropertyPaneControlConfig => ({
  propertyName: "errorMessagePlacement",
  label: "Error display",
  isBindProperty: false,
  isTriggerProperty: false,
  defaultValue: ErrorMessagePlacement.TOOLTIP,
  controlType: "RADIO_BUTTON_GROUP",
  options: [
    {
      label: "Tooltip",
      value: ErrorMessagePlacement.TOOLTIP,
      showTooltip: true,
      tooltip: "Error will be displayed in a tooltip when hovering input",
    },
    {
      label: "Inline",
      value: ErrorMessagePlacement.INLINE,
      showTooltip: true,
      tooltip: "Error will be displayed below the input",
    },
  ],
  getAdditionalDataForPropFunc: {
    widgets: getFlattenedCanvasWidgets,
  },
  updateHook: ({
    props,
    propertyValue,
    additionalDataForPropFunc,
  }: {
    props: WidgetProps;
    propertyPath: string;
    propertyValue: string;
    additionalDataForPropFunc?: {
      widgets?: Record<string, WidgetLayoutProps | WidgetPropsRuntime>;
    };
  }) => {
    if (props.height?.mode === "px" || props.height?.mode === "gridUnit") {
      const errorHeight =
        INLINE_ERROR_MESSAGE_HEIGHT +
        GAP_BETWEEN_INPUT_AND_INLINE_ERROR_MESSAGE;

      const widget = additionalDataForPropFunc?.widgets?.[props.widgetId];
      if (widget) {
        const componentHeight = getComponentDimensions(widget).componentHeight;
        return [
          {
            propertyPath: "height",
            propertyValue: Dimension.px(
              componentHeight +
                (propertyValue === "inline" ? errorHeight : -errorHeight),
            ),
          },
        ];
      }
    }

    return [];
  },
  ...overrides,
});

export const labelWidthProperty = (
  overrides: Partial<PropertyPaneControlConfig> = {},
): PropertyPaneControlConfig => ({
  propertyCategory: PropsPanelCategory.Layout,
  propertyName: "labelProps.width",
  label: "Label width",
  controlType: "SIMPLE_SIZE_CONTROL",
  defaultValue: Dimension.gridUnit(1),
  helpText: "Control the width of the label subcomponent in the input",
  options: [
    {
      label: "Fluid",
      value: "gridUnit",
      subText: "Label takes up 30% of component width",
    },
    {
      label: "Pixels",
      labelWhenSelected: "px",
      value: "px",
      subText: "Width in pixels that remains constant",
    },
  ],
  isBindProperty: false,
  isTriggerProperty: false,
  getAdditionalDataForPropFunc: {
    widgets: getFlattenedCanvasWidgets,
  },
  updateHook: ({
    props,
    propertyPath,
    propertyValue,
    additionalDataForPropFunc,
  }) => {
    if (
      propertyValue === "px" &&
      (!props.labelProps?.width?.mode ||
        props.labelProps?.width?.mode === "gridUnit") &&
      additionalDataForPropFunc?.widgets[props.widgetId]
    ) {
      const parentPath = getParentPath(propertyPath) || "";
      const componentWidth = getComponentDimensions(
        additionalDataForPropFunc.widgets[props.widgetId],
      ).componentWidth;

      if (!componentWidth) return [];
      return [
        {
          propertyPath: `${parentPath}.value`,
          propertyValue: componentWidth * 0.3,
        },
      ];
    }

    return [];
  },
  ...overrides,
});

export const customValidationProperties = ({
  validationOverrides,
  customErrorOverrides,
}: {
  validationOverrides?: Partial<PropertyPaneControlConfig>;
  customErrorOverrides?: Partial<PropertyPaneControlConfig>;
} = {}) => [
  {
    propertyName: "customValidationRule",
    label: "Validation",
    helpText: "Sets a custom validation rule",
    controlType: "INPUT_TEXT" as const,
    placeholderText: "{{/./.test(Input1.value)}}",
    isBindProperty: true,
    isTriggerProperty: false,
    visibility: "SHOW_NAME" as const,
    isRemovable: true,
    defaultValue: "",
    ...validationOverrides,
  },
  {
    propertyName: "customErrorMessage",
    label: "Error",
    helpText: "Sets a custom message to display when validation doesn't pass",
    controlType: "INPUT_TEXT" as const,
    placeholderText: "Enter error message",
    isBindProperty: true,
    isTriggerProperty: false,
    visibility: "SHOW_NAME" as const,
    isRemovable: true,
    defaultValue: "",
    hidden: (props: { customValidationRule?: string }) =>
      props.customValidationRule === undefined,
    ...customErrorOverrides,
  },
];
