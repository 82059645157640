import { Dimension, PerSideBorder } from "@superblocksteam/shared";
import * as React from "react";
import styled from "styled-components";
import {
  GridDefaults,
  ImageAlign,
  WIDGET_PADDING,
  WidgetWidthModes,
  WidgetHeightModes,
} from "legacy/constants/WidgetConstants";
import { CLASS_NAMES } from "legacy/themes/classnames";
import createLoggedObserver from "utils/createLoggedObserver";
import { ComponentProps } from "../../components/designSystems/default/BaseComponent";
import { generateBorderStyleObject } from "../base/generateBorderStyle";

const StyledImage = styled.img`
  opacity: 0;
  pointer-events: none;
`;

const StyledBackgroundImage = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  background-repeat: no-repeat;
  overflow: hidden;
`;

const Wrapper = styled.div`
  height: 100%;
  width: 100%;
`;

class ImageComponent extends React.Component<
  ImageComponentProps,
  {
    imageError: boolean;
    imageHeight?: number;
  }
> {
  private imageRef: React.RefObject<HTMLImageElement>;
  private resizeObserver: ResizeObserver | null;

  constructor(props: ImageComponentProps) {
    super(props);
    this.state = {
      imageError: false,
      imageHeight: undefined,
    };
    this.imageRef = React.createRef();
    this.resizeObserver = null;
  }

  componentDidMount() {
    this.observeContainer();
  }
  componentDidUpdate(prevProps: ImageComponentProps) {
    if (prevProps.imageUrl !== this.props.imageUrl) {
      this.setState({
        imageError: false,
        imageHeight: undefined,
      });
    } else if (prevProps.height.mode !== this.props.height.mode) {
      this.setState({
        imageHeight: undefined,
      });
    }
  }

  componentWillUnmount() {
    this.disconnectObserver();
  }

  observeContainer() {
    if (this.imageRef.current) {
      this.resizeObserver = createLoggedObserver(
        "ImageComponent",
        this.handleResize,
      );
      this.resizeObserver.observe(this.imageRef.current);
    }
  }

  disconnectObserver() {
    if (this.resizeObserver) {
      this.resizeObserver.disconnect();
      this.resizeObserver = null;
    }
  }

  handleResize: ResizeObserverCallback = (entries) => {
    const shouldRound = this.props.roundHeightToGrid;
    for (const entry of entries) {
      const { height } = entry.contentRect;
      const rowHeight = GridDefaults.DEFAULT_GRID_ROW_HEIGHT;

      const finalHeight = shouldRound
        ? Math.ceil((height + WIDGET_PADDING * 2) / rowHeight) * rowHeight -
          WIDGET_PADDING * 2
        : height;
      if (finalHeight > 0 && this.props.height.mode === "fitContent") {
        this.setState({
          imageHeight: Math.max(finalHeight, rowHeight),
        });
      }
    }
  };

  render() {
    const classes = [CLASS_NAMES.ROUNDED_CONTAINER];
    if (this.props.isLoading) {
      classes.push("bp5-skeleton");
    }

    const imageUrl = !this.state.imageError
      ? this.props.imageUrl
      : this.props.defaultImageUrl;

    const imageStyle: React.CSSProperties = {
      borderRadius: this.props.borderRadius,
      objectFit: this.props.fillContainer ? "cover" : "contain",
      objectPosition: this.props.align ?? "center",
      height: this.props.height.mode === "fitContent" ? "fit-content" : "100%",
      width: this.props.width.mode === "fitContent" ? "fit-content" : "100%",
    };

    const borderStyle = this.props.border
      ? generateBorderStyleObject({
          border: this.props.border,
        })
      : {};

    const backgroundStyle: React.CSSProperties = {
      backgroundColor: this.props.backgroundColor,
      backgroundImage: `url(${imageUrl})`,
      borderRadius: this.props.borderRadius,
      backgroundSize: this.props.fillContainer ? "cover" : "contain",
      backgroundPosition: this.props.align ?? "center",
      height:
        this.props.height.mode === "fitContent"
          ? this.state.imageHeight
          : "100%",
      width: this.props.width.mode === "fitContent" ? "fit-content" : "100%",
      cursor: this.props.onClick ? "pointer" : "inherit",
      ...borderStyle,
    };

    return (
      <Wrapper>
        <StyledBackgroundImage
          className={classes.join(" ")}
          data-test="styledImage"
          style={backgroundStyle}
          onClick={this.props.onClick}
        >
          <StyledImage
            ref={this.imageRef}
            style={imageStyle}
            alt={this.props.widgetName}
            src={imageUrl}
            onError={this.onImageError}
            onLoad={this.onImageLoad}
          />
        </StyledBackgroundImage>
      </Wrapper>
    );
  }

  onImageError = (event: React.SyntheticEvent<HTMLImageElement, Event>) => {
    if (event.currentTarget.src === this.props.defaultImageUrl) {
      return;
    }
    this.setState({
      imageError: true,
    });
  };

  onImageLoad = (event: React.SyntheticEvent<HTMLImageElement, Event>) => {
    if (event.currentTarget.src === this.props.defaultImageUrl) {
      return;
    }
    this.setState({
      imageError: false,
    });
  };
}

interface ImageComponentProps extends ComponentProps {
  imageUrl: string;
  defaultImageUrl: string;
  isLoading: boolean;
  showHoverPointer?: boolean;
  align?: ImageAlign;
  borderRadius: string;
  fillContainer: boolean;
  height: Dimension<WidgetHeightModes>;
  width: Dimension<WidgetWidthModes>;
  roundHeightToGrid: boolean;
  border?: PerSideBorder;
  disableDrag: (disabled: boolean) => void;
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
}

export default ImageComponent;
