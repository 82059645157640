import Icon from "@ant-design/icons";
import { Spinner } from "@blueprintjs/core";
import {
  ActionTypeEnum,
  BillingPlan,
  DEMO_ENTITIES_FOLDER_NAME,
  ENVIRONMENT_PRODUCTION,
  FolderDto,
  IHomepageApplicationV2Dto,
  ScheduleState,
} from "@superblocksteam/shared";
import {
  Collapse,
  Form,
  Input,
  Modal,
  Radio,
  Row,
  Tooltip,
  Typography,
} from "antd";
import { isArray, isEmpty } from "lodash";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { Route, Routes, useLocation, useNavigate } from "react-router";
import styled from "styled-components";
import { ReactComponent as PlusCircleIcon } from "assets/icons/common/plus-circle.svg";
import { ReactComponent as AppIcon } from "assets/icons/home/app.svg";
import { ReactComponent as ChevronDownCircleIcon } from "assets/icons/home/chevron-down-circle.svg";
import { ReactComponent as FolderPlusIcon } from "assets/icons/home/folder-plus.svg";
import { ReactComponent as JobIcon } from "assets/icons/home/job.svg";
import { ReactComponent as WorkflowIcon } from "assets/icons/home/workflow.svg";
import { Layout, MainWrapper } from "components/app";
import { Button } from "components/ui/Button";
import HiddenCollapse from "components/ui/HiddenCollapse";
import { ConfirmModalClass } from "components/ui/Modal";
import { HeaderWrapper } from "components/ui/Page";
import { FullWidthSpace } from "components/ui/Space";
import {
  CREATE_APPLICATIONS,
  CREATE_JOBS,
  CREATE_WORKFLOWS,
  MANAGE_FOLDERS,
  MANAGE_ORGANIZATION,
} from "constants/rbac";
import envs from "env";
import { useSaga } from "hooks/store";
import { getEditorBasePath } from "hooks/store/useGetEditorPath";
import { useFeatureFlag } from "hooks/ui";
import { useAuthorizationCheck } from "hooks/ui/rbac/useAuthorizationCheck";
import useTimeout from "hooks/ui/useTimeout";
import { openPricingModal } from "legacy/actions/modalActions";
import { Toaster } from "legacy/components/ads/Toast";
import { Variant } from "legacy/components/ads/common";
import {
  EventType,
  ExecuteActionCallStack,
} from "legacy/constants/ActionConstants";
import {
  getApplicationDeployedURL,
  SCHEDULED_JOB_CONFIGURE_URL,
  WORKFLOW_CONFIGURE_URL,
  HomeModalRoutes as ModalRoutes,
  EditorRoute,
} from "legacy/constants/routes";
import { getDemoApplication } from "legacy/selectors/applicationSelectors";
import { getCurrentBranch } from "legacy/selectors/editorSelectors";
import { getCurrentUser } from "legacy/selectors/usersSelectors";
import { STORAGE_KEYS } from "legacy/utils/StorageUtils";
import sessionStorage, { SessionStorageKey } from "legacy/utils/sessionStorage";
import LandingPage from "pages/Onboarding/LandingPage";
import VideoModal from "pages/Onboarding/VideoModal";
import { extractCurrentBranchFromSearchStringOrStorage } from "pages/Repositories/utils";
import Header from "pages/components/Header";
import { PageNav } from "pages/components/PageNav";
import { HOME_TITLE, PageWrapper } from "pages/components/PageWrapper";
import PromotionModal from "pages/components/PromotionModal";
import InviteOrgUserModal from "pages/components/invitation/InviteOrgUserModal";
import { useAppDispatch } from "store/helpers";
import { ApiTriggerType, cloneApiSaga, deleteApiSaga } from "store/slices/apis";
import {
  getAllScheduledJobs,
  getAllWorkflows,
  getAllWorkflowsAndScheduledJobs,
} from "store/slices/apis/client";
import {
  executeV2ApiSaga,
  fetchV2ApiSaga,
  persistV2ApiSettingsSaga,
} from "store/slices/apisV2";
import { updateScheduleStateSaga } from "store/slices/apisV2/sagas/updateScheduleState";
import { type ApiDtoWithPb } from "store/slices/apisV2/slice";
import { Flag } from "store/slices/featureFlags";
import { deleteFolder, getFolders } from "store/slices/folders/client";
import {
  getApplicationList,
  getIsFetchingApplications,
} from "store/slices/homepage/selectors";
import {
  deleteApplicationInit,
  duplicateApplicationInit,
  getAllApplicationsInit,
} from "store/slices/homepage/slice";
import { selectOrganizations } from "store/slices/organizations";
import { fastClone } from "utils/clone";
import { EntityType } from "utils/entity";
import logger from "utils/logger";
import { shortenUsername } from "utils/user";
import { sendErrorUINotification } from "../../utils/notification";
import CreateEntityModal from "./CreateEntityModal";
import CreateFolderModal from "./CreateFolderModal";
import EntityCard, {
  DropdownAction,
  Entity,
  EmptyCard,
  CARD_HEIGHT,
  CARD_SPACING,
  CARD_MIN_WIDTH,
} from "./EntityCard";
import { FolderHeader } from "./FolderHeader";
import MoveEntityModal, { EMPTY_FOLDER_ID } from "./MoveEntityModal";
import RenameEntityModal from "./RenameEntityModal";
import RenameFolderModal from "./RenameFolderModal";
import ResponsiveGrid from "./ResponsiveGrid";

const MODAL_AUTO_LOAD_TIMEOUT = 2000;

const { Panel } = Collapse;

const { confirm } = Modal;

const WideInput = styled(Input)`
  flex: 1;
  margin-right: 8px;
`;

const StyledRadioGroup = styled(Radio.Group)`
  span {
    height: 100%;
    display: flex;
    align-items: center;
  }
  svg {
    width: 20px;
    height: 20px;
    color: #27bbff;
  }
`;

const StyledSpace = styled(FullWidthSpace)`
  div:nth-child(2) {
    flex: 1;
  }
`;

// We only have scheduled jobs running in production at this time.
const SCHEDULED_JOB_ENVIRONMENT = ENVIRONMENT_PRODUCTION;

const CreateModalButton = styled(Button)`
  display: flex;
  align-items: center;
  padding: 0 8px;

  svg {
    margin-right: 6px;
  }
`;

const CreateFolderButton = styled(Button)``;

function showConfirm(name: string, onOk: () => void) {
  confirm({
    title: `Are you sure you want to delete "${name}"`,
    icon: <></>,
    okText: "Delete",
    okType: "danger",
    className: `${ConfirmModalClass}`,
    onOk: onOk,
  });
}

function appToEntity(app: IHomepageApplicationV2Dto): Entity {
  const baseEditUrl = getEditorBasePath(EditorRoute.EditApplication, {
    applicationId: app.id,
  });
  const editUrl = app.devEnvEnabled ? `/code-mode${baseEditUrl}` : baseEditUrl;
  return Object.assign({}, app, {
    editUrl,
    deployUrl: getApplicationDeployedURL(app.id),
    type: EntityType.APPLICATION,
    permissions: app.permissions,
    canDelete: app.canDelete,
    devEnvEnabled: app.devEnvEnabled,
  });
}

function apiToEntity(api: ApiDtoWithPb): Entity {
  return {
    id: api.id,
    name: api.apiPb?.metadata?.name || api.actions?.name || api.name,
    // ApiDtos can only tell if they're deployed if they're schedules. Default
    // to false here for workflows.
    isDeployed: !!api.isDeployed,
    isEditable: api.isEditable ?? true,
    updated: api.updated,
    scheduleState: api.scheduleState,
    editUrl:
      api?.triggerType === ApiTriggerType.WORKFLOW ||
      api?.actions?.triggerType === ApiTriggerType.WORKFLOW
        ? WORKFLOW_CONFIGURE_URL(api.id)
        : SCHEDULED_JOB_CONFIGURE_URL(api.id),
    type:
      api?.triggerType === ApiTriggerType.WORKFLOW ||
      api?.actions?.triggerType === ApiTriggerType.WORKFLOW
        ? EntityType.WORKFLOW
        : EntityType.SCHEDULED_JOB,
    folderId: api.folderId,
    canDelete: api.canDelete,
    permissions: api.permissions,
  };
}

// EMPTY_FOLDER is how we render an empty folder on the UI.
const EMPTY_FOLDER: FolderDto = {
  id: EMPTY_FOLDER_ID,
  name: "",
  organizationId: "",
};

const Home = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [canManageFolders, canCreateApps, canCreateJobs, canCreateWorkflows] =
    useAuthorizationCheck([
      MANAGE_FOLDERS,
      CREATE_APPLICATIONS,
      CREATE_JOBS,
      CREATE_WORKFLOWS,
    ]);
  const canCreateEntity = canCreateApps || canCreateJobs || canCreateWorkflows;

  useEffect(() => {
    dispatch(getAllApplicationsInit());
  }, [dispatch]);

  const openModal = useCallback(
    (modal: ModalRoutes) => {
      navigate(modal);
    },
    [navigate],
  );
  const closeModal = useCallback(() => {
    navigate("..");
  }, [navigate]);

  const [movingEntity, setMovingEntity] = useState<Entity>();
  const moveEntity = useCallback(
    (entity: any) => {
      setMovingEntity(entity);
      if (entity) {
        openModal(ModalRoutes.MOVE);
      } else {
        closeModal();
      }
    },
    [closeModal, openModal],
  );

  const applications = useSelector(getApplicationList);
  const user = useSelector(getCurrentUser);
  const userName = useMemo(() => {
    return user?.name;
  }, [user]);

  // get current organization ID
  const organizations = useSelector(selectOrganizations);
  const organization = Object.values(organizations)[0];

  const [activeFolders, setActiveFolders] = useState<string[]>([]);
  const [folders, setFolders] = useState<FolderDto[]>([]);
  const refreshFolders = useCallback(async () => {
    const fetchedFolders = await getFolders();
    setFolders(
      fetchedFolders.sort((a, b) => {
        if (a.name === DEMO_ENTITIES_FOLDER_NAME) {
          return 1;
        } else if (b.name === DEMO_ENTITIES_FOLDER_NAME) {
          return -1;
        } else {
          return (a.name ?? "").localeCompare(b.name ?? "");
        }
      }),
    );
    setActiveFolders(fetchedFolders.map((folder) => folder.id));
  }, []);

  useEffect(() => {
    (async () => {
      await refreshFolders();
    })();
  }, [refreshFolders]);

  const [isWorkflowsLoading, setIsWorkflowsLoading] = useState(false);
  const [isJobsLoading, setIsJobsLoading] = useState(false);
  const isFetchingApplications = useSelector(getIsFetchingApplications);

  const isLoading = useMemo(() => {
    return isFetchingApplications || isWorkflowsLoading || isJobsLoading;
  }, [isFetchingApplications, isWorkflowsLoading, isJobsLoading]);

  const [workflows, setWorkflows] = useState<ApiDtoWithPb[]>([]);

  const refreshWorkflows = useCallback(async () => {
    setIsWorkflowsLoading(true);
    let workflows = await getAllWorkflows();
    workflows = workflows.map((workflow) => {
      workflow.name = workflow.apiPb?.metadata?.name || workflow.actions?.name;
      return workflow;
    });
    setWorkflows(workflows);
    setIsWorkflowsLoading(false);
  }, []);

  const [jobs, setJobs] = useState<ApiDtoWithPb[]>([]);
  const refreshJobs = useCallback(async () => {
    setIsJobsLoading(true);
    let scheduledJobs = await getAllScheduledJobs();
    scheduledJobs = scheduledJobs.map((job) => {
      job.name = job.apiPb?.metadata?.name || job.actions?.name;
      return job;
    });
    setJobs(scheduledJobs);
    setIsJobsLoading(false);
  }, []);

  const refreshWorkflowsAndJobs = useCallback(async () => {
    setIsWorkflowsLoading(true);
    setIsJobsLoading(true);
    const apis = await getAllWorkflowsAndScheduledJobs();
    setWorkflows(
      apis.filter((api) => api.triggerType === ApiTriggerType.WORKFLOW),
    );
    setJobs(apis.filter((api) => api.triggerType === ApiTriggerType.SCHEDULE));
    setIsWorkflowsLoading(false);
    setIsJobsLoading(false);
  }, []);

  useEffect(() => {
    refreshWorkflowsAndJobs();
  }, [refreshWorkflowsAndJobs]);

  const [awjFilter, setAWJFilter] = useState<null | "app" | "workflow" | "job">(
    null,
  );
  const entities: Entity[] = useMemo(
    () => [
      ...(awjFilter === "app" || !awjFilter
        ? applications.map(appToEntity)
        : []),
      ...(awjFilter === "workflow" || !awjFilter
        ? workflows.map(apiToEntity)
        : []),
      ...(awjFilter === "job" || !awjFilter ? jobs.map(apiToEntity) : []),
    ],
    [awjFilter, applications, workflows, jobs],
  );

  const onEntityFilterChange = useCallback(
    (value: any) =>
      awjFilter === value // double select should clear
        ? setAWJFilter(null)
        : setAWJFilter(value),
    [awjFilter, setAWJFilter],
  );

  const [persistV2ApiSettings] = useSaga(persistV2ApiSettingsSaga);
  const [doUpdateScheduleState] = useSaga(updateScheduleStateSaga);

  const updateScheduleState = useCallback(
    async (apiId: string, newState: ScheduleState) => {
      const jobsToUpdate = jobs.filter((job) => job.id === apiId);
      if (jobsToUpdate.length !== 1) {
        logger.error(`Expected to find 1 job to update, found ${jobsToUpdate}`);
        return;
      }
      const newApi = jobsToUpdate[0];
      newApi.scheduleState = newState;
      doUpdateScheduleState({
        scheduledJobId: apiId,
        scheduleState: newState,
      });
      setJobs(
        jobs.map((job) => {
          if (job.id === apiId) {
            job.scheduleState = newState;
          }
          return job;
        }),
      );
    },
    [jobs, setJobs, doUpdateScheduleState],
  );

  const makeApiFolderUpdater = useCallback(
    (entityList: ApiDtoWithPb[], setter: (apis: ApiDtoWithPb[]) => void) => {
      return async (apiId: string, update: (api: ApiDtoWithPb) => void) => {
        const apisToUpdate = entityList.filter((api) => api.id === apiId);
        if (apisToUpdate.length !== 1) {
          logger.error(
            `Expected to find 1 API to update, found ${apisToUpdate}`,
          );
          return;
        }
        const newApi = fastClone(apisToUpdate[0]);
        update(newApi);
        await persistV2ApiSettings({
          id: newApi.id,
          folderId: newApi.folderId,
          updated: newApi.updated,
        });

        setter(
          entityList.map((api) => {
            if (api.id === apiId) {
              const newApi = fastClone(api);
              update(newApi);
              return newApi;
            }
            return api;
          }),
        );
      };
    },
    [persistV2ApiSettings],
  );

  const updateWorkflowFolder = makeApiFolderUpdater(workflows, setWorkflows);
  const updateJobFolder = makeApiFolderUpdater(jobs, setJobs);

  const visibleEntities = useMemo(
    () => entities.filter((entity) => entity.isDeployed || entity.isEditable),
    [entities],
  );

  const [entityFilter, setEntityFilter] = useState(
    sessionStorage.getItem(SessionStorageKey.HOME_SEARCH) ?? "",
  );
  const filteredEntities = useMemo(
    () =>
      visibleEntities
        .filter((entity) => {
          const filterString = entityFilter.toLowerCase().trim();
          const foundName = entity.name
            ?.trim()
            ?.toLowerCase()
            ?.includes(filterString);
          if (foundName || entity.folderId === undefined) {
            return foundName;
          }

          return folders
            .find((folder) => folder.id === entity.folderId)
            ?.name?.trim()
            ?.toLowerCase()
            ?.includes(filterString);
        })
        .sort((a, b) => {
          return (
            new Date(b.updated ?? 0).valueOf() -
            new Date(a.updated ?? 0).valueOf()
          );
        }),
    [entityFilter, folders, visibleEntities],
  );

  const entityGroups = useMemo(() => {
    return filteredEntities.reduce(
      function (groups, entity) {
        let key = entity.folderId ?? EMPTY_FOLDER_ID;
        if (!folders.map((f) => f.id).includes(key)) {
          key = EMPTY_FOLDER_ID;
        }
        groups[key] = groups[key] || [];
        groups[key].push(entity);
        return groups;
      },
      {} as Record<string, Entity[]>,
    );
  }, [filteredEntities, folders]);

  const [renamingApplicationId, setRenamingApplicationId] = useState<string>();
  const [renamingApplicationName, setRenamingApplicationName] =
    useState<string>();

  const [renamingFolder, setRenamingFolder] = useState<FolderDto>();
  const [renameForm] = Form.useForm();
  const [createForm] = Form.useForm();

  const [cloneApi] = useSaga(cloneApiSaga);
  const handleCloneApi = useCallback(
    async (apiId: string, isWorkflow: boolean) => {
      const clonedApi = await cloneApi({ id: apiId });
      // Navigate to cloned api.
      navigate({
        pathname: isWorkflow
          ? WORKFLOW_CONFIGURE_URL(clonedApi.id)
          : SCHEDULED_JOB_CONFIGURE_URL(clonedApi.id),
      });
    },
    [cloneApi, navigate],
  );

  const branch = useSelector(getCurrentBranch);
  const [deleteApi] = useSaga(deleteApiSaga);
  const handleDeleteApi = useCallback(
    async (apiId: string) => {
      await deleteApi({ id: apiId, branch: branch?.name });
      setWorkflows(workflows.filter((workflow) => workflow.id !== apiId));
      setJobs(jobs.filter((job) => job.id !== apiId));
    },
    [deleteApi, branch, workflows, jobs],
  );

  const [executeV2Api] = useSaga(executeV2ApiSaga);
  const [fetchV2Api] = useSaga(fetchV2ApiSaga);

  const handleRunJob = useCallback(
    async (entity: Entity) => {
      const callStack: ExecuteActionCallStack = [
        {
          type: EventType.ON_RUN_CLICK,
          propertyPath: `<${entity.name ?? "API"}>.<run now>`,
        },
      ];
      const branch = extractCurrentBranchFromSearchStringOrStorage(
        location.search,
        entity.id,
        navigate,
      );
      await fetchV2Api({
        apiId: entity.id,
        environment: SCHEDULED_JOB_ENVIRONMENT,
        branch,
      });
      await executeV2Api({
        apiId: entity.id,
        environment: SCHEDULED_JOB_ENVIRONMENT,
        viewMode: true,
        eventType: EventType.ON_RUN_CLICK,
        params: [],
        notifyOnSystemError: true,
        manualRun: true,
        callStack,
        includeOutputs: false,
      });
    },
    [fetchV2Api, executeV2Api, location.search, navigate],
  );

  const handleDuplicateApplication = useCallback(
    (applicationId: any) => {
      dispatch(duplicateApplicationInit({ applicationId }));
    },
    [dispatch],
  );

  const handleDeleteApplication = useCallback(
    (applicationId: any) => {
      dispatch(
        deleteApplicationInit({
          applicationId,
        }),
      );
    },
    [dispatch],
  );

  const [canCreateApp, canCreateWorkflow, canCreateJob] = useAuthorizationCheck(
    [CREATE_APPLICATIONS, CREATE_WORKFLOWS, CREATE_JOBS],
  );

  const getDropdownOptionsForEntity = useCallback(
    (
      entityType: string,
      options: { isDeployed: boolean; allowAppCloning?: boolean },
    ): DropdownAction[] => {
      const { isDeployed, allowAppCloning } = options;
      const actions: DropdownAction[] = [];
      switch (entityType) {
        case EntityType.APPLICATION:
          actions.push(
            {
              label: "Rename",
              action: (entity: Entity) => {
                setRenamingApplicationId(entity.id);
                setRenamingApplicationName(entity.name);
                renameForm.setFieldsValue({ name: entity.name });
                openModal(ModalRoutes.RENAME);
              },
              hidden: (entity: Entity) => {
                return entity.permissions
                  ? !entity.permissions.includes(ActionTypeEnum.UPDATE)
                  : !entity.isEditable;
              },
            },
            {
              label: "Move",
              action: (entity: Entity) => {
                moveEntity(entity);
              },
              hidden: (entity: Entity) => {
                return entity.permissions
                  ? !entity.permissions.includes(ActionTypeEnum.UPDATE)
                  : !entity.isEditable;
              },
            },
            ...(allowAppCloning && canCreateApp
              ? [
                  {
                    label: "Clone",
                    action: (entity: Entity) => {
                      handleDuplicateApplication(entity.id);
                    },
                    hidden: (entity: Entity) => {
                      return entity.permissions
                        ? !entity.permissions.includes(ActionTypeEnum.UPDATE)
                        : !entity.isEditable;
                    },
                  },
                ]
              : []),
            {
              label: "Delete",
              isDanger: true,
              action: (entity: Entity) => {
                showConfirm(entity.name ?? "", () =>
                  handleDeleteApplication(entity.id),
                );
              },
              hidden: (entity: Entity) => {
                return entity.permissions
                  ? !entity.permissions.includes(ActionTypeEnum.DELETE)
                  : !entity.canDelete;
              },
            },
          );
          break;
        case EntityType.WORKFLOW:
          actions.push(
            {
              label: "Move",
              action: (entity: Entity) => {
                moveEntity(entity);
              },
              hidden: (entity: Entity) => {
                return entity.permissions
                  ? !entity.permissions.includes(ActionTypeEnum.UPDATE)
                  : !entity.isEditable;
              },
            },
            ...(canCreateWorkflow
              ? [
                  {
                    label: "Clone",
                    action: (entity: Entity) => {
                      handleCloneApi(
                        entity.id,
                        entity.type === EntityType.WORKFLOW,
                      );
                    },
                    hidden: (entity: Entity) => {
                      return entity.permissions
                        ? !entity.permissions.includes(ActionTypeEnum.UPDATE)
                        : !entity.isEditable;
                    },
                  },
                ]
              : []),
            {
              label: "Delete",
              isDanger: true,
              action: (entity: Entity) => {
                if (!entity.canDelete) {
                  sendErrorUINotification({
                    message: `Only admins and owners can delete a workflow`,
                  });
                } else {
                  showConfirm(entity.name ?? "", () => {
                    handleDeleteApi(entity.id);
                  });
                }
              },
              hidden: (entity: Entity) => {
                return entity.permissions
                  ? !entity.permissions.includes(ActionTypeEnum.DELETE)
                  : !entity.canDelete;
              },
            },
          );
          break;
        case EntityType.SCHEDULED_JOB:
          actions.push({
            label: "Run Now",
            action: (entity: Entity) => {
              handleRunJob(entity);
            },
            hidden: (entity: Entity) => {
              const canManageSchedule = entity.permissions
                ? entity.permissions.includes(ActionTypeEnum.RUN)
                : true;
              return !isDeployed || !canManageSchedule;
            },
          });
          actions.push(
            {
              label: "Move",
              action: (entity: Entity) => {
                moveEntity(entity);
              },
              hidden: (entity: Entity) => {
                return entity.permissions
                  ? !entity.permissions.includes(ActionTypeEnum.UPDATE)
                  : !entity.isEditable;
              },
            },
            ...(canCreateJob
              ? [
                  {
                    label: "Clone",
                    action: (entity: Entity) => {
                      handleCloneApi(
                        entity.id,
                        entity.type === EntityType.WORKFLOW,
                      );
                    },
                    hidden: (entity: Entity) => {
                      return entity.permissions
                        ? !entity.permissions.includes(ActionTypeEnum.UPDATE)
                        : !entity.isEditable;
                    },
                  },
                ]
              : []),
            {
              label: "Delete",
              isDanger: true,
              action: (entity: Entity) => {
                if (!entity.canDelete) {
                  sendErrorUINotification({
                    message: `Only admins and owners can delete a workflow`,
                  });
                } else {
                  showConfirm(entity.name ?? "", () => {
                    handleDeleteApi(entity.id);
                  });
                }
              },
              hidden: (entity: Entity) => {
                return entity.permissions
                  ? !entity.permissions.includes(ActionTypeEnum.DELETE)
                  : !entity.canDelete;
              },
            },
          );
          break;
      }
      return actions;
    },
    [
      canCreateApp,
      canCreateWorkflow,
      canCreateJob,
      renameForm,
      openModal,
      moveEntity,
      handleDuplicateApplication,
      handleDeleteApplication,
      handleCloneApi,
      handleDeleteApi,
      handleRunJob,
    ],
  );

  const allowAppCloning = useFeatureFlag(Flag.ENABLE_APP_CLONE);
  const dropdownOptionsByType = useMemo(() => {
    return {
      [EntityType.APPLICATION]: {
        deployed: getDropdownOptionsForEntity(EntityType.APPLICATION, {
          isDeployed: true,
          allowAppCloning,
        }),
        undeployed: getDropdownOptionsForEntity(EntityType.APPLICATION, {
          isDeployed: false,
          allowAppCloning,
        }),
      },
      [EntityType.WORKFLOW]: {
        deployed: getDropdownOptionsForEntity(EntityType.WORKFLOW, {
          isDeployed: true,
        }),
        undeployed: getDropdownOptionsForEntity(EntityType.WORKFLOW, {
          isDeployed: false,
        }),
      },
      [EntityType.SCHEDULED_JOB]: {
        deployed: getDropdownOptionsForEntity(EntityType.SCHEDULED_JOB, {
          isDeployed: true,
        }),
        undeployed: getDropdownOptionsForEntity(EntityType.SCHEDULED_JOB, {
          isDeployed: false,
        }),
      },
    };
  }, [getDropdownOptionsForEntity, allowAppCloning]);

  const ItemRenderer = useCallback(
    ({ item }: { item: Entity }) => {
      return (
        <EntityCard
          entity={item}
          dropdownActions={
            dropdownOptionsByType[item.type][
              item.isDeployed ? "deployed" : "undeployed"
            ]
          }
          updatePauseResume={updateScheduleState}
        />
      );
    },
    [dropdownOptionsByType, updateScheduleState],
  );

  const folderSections = useMemo(() => {
    const allFolders = [EMPTY_FOLDER, ...folders];

    const folderGroup = allFolders.map((folder) => {
      let showFolder = !isEmpty(folder.name);
      if (!isEmpty(entityFilter) && entityGroups[folder.id] === undefined) {
        showFolder = false;
      }

      const entitiesInGroup = entityGroups[folder.id] ?? [];
      const hasEntities = entitiesInGroup.length > 0;

      // if there are no entities in the folder and the user can't manage folders, don't show the folder
      if (!hasEntities && !canManageFolders && !isEmpty(folder.name)) {
        showFolder = false;
      }

      const folderHeader = (
        <FolderHeader
          folder={folder}
          entityCount={entitiesInGroup.length}
          handleRename={(folder) => {
            setRenamingFolder(folder);
            openModal(ModalRoutes.RENAME_FOLDER);
          }}
          handleDelete={(folder) => {
            showConfirm(folder.name ?? "unknown", () => {
              deleteFolder(folder.id)
                .then(() => {
                  Toaster.show({
                    text: "Folder deleted",
                    variant: Variant.success,
                  });
                  refreshFolders();
                })
                .catch(() => {
                  Toaster.show({
                    text: "Folder deletion failed",
                    variant: Variant.danger,
                  });
                });
            });
          }}
        />
      );

      const emptyCard = (
        <EmptyCard>
          <Typography.Text type="secondary">
            No Applications, Workflows, or Scheduled Jobs
          </Typography.Text>
        </EmptyCard>
      );

      const entityCardGroup = hasEntities ? (
        <ResponsiveGrid<Entity>
          rowHeight={CARD_HEIGHT}
          rowGap={CARD_SPACING}
          columnGap={CARD_SPACING}
          minItemWidth={CARD_MIN_WIDTH}
          items={entitiesInGroup}
          ItemRenderer={ItemRenderer}
        />
      ) : (
        showFolder && emptyCard
      );

      if (showFolder) {
        return (
          <Panel key={folder.id} header={folderHeader}>
            {entityCardGroup}
          </Panel>
        );
      } else {
        return <div key="empty-folder">{entityCardGroup}</div>;
      }
    });

    return (
      <HiddenCollapse
        onChange={(keys) => setActiveFolders(isArray(keys) ? keys : [])}
        activeKey={activeFolders}
        ghost
        expandIcon={({ isActive }) => (
          <Icon component={ChevronDownCircleIcon} rotate={isActive ? 0 : -90} />
        )}
      >
        {folderGroup}
      </HiddenCollapse>
    );
  }, [
    folders,
    activeFolders,
    entityFilter,
    entityGroups,
    ItemRenderer,
    openModal,
    refreshFolders,
    canManageFolders,
  ]);

  const fileterdFolders = useMemo(() => {
    return folders.filter(
      (folder) => folder.name !== DEMO_ENTITIES_FOLDER_NAME,
    );
  }, [folders]);

  const modals = useMemo(() => {
    return (
      <>
        <PromotionModal />
        <Routes>
          <Route
            path={ModalRoutes.DEMO_VIDEO}
            element={
              <VideoModal
                url={envs.get("SUPERBLOCKS_UI_DEMO_VIDEO_URL")}
                isOpen={true}
                onClose={closeModal}
              />
            }
          />
          <Route
            path={ModalRoutes.CREATE}
            element={
              <CreateEntityModal
                organization={organization}
                createForm={createForm}
                isVisible={true}
                folders={folders}
                onCancel={closeModal}
                refreshEntities={() => {
                  refreshJobs();
                  refreshWorkflows();
                }}
              />
            }
          />
          <Route
            path={ModalRoutes.CREATE_FOLDER}
            element={
              <CreateFolderModal
                isOpen={true}
                setIsOpen={closeModal}
                onComplete={(newFolder: FolderDto) => {
                  refreshFolders();
                }}
              />
            }
          />
          <Route
            path={ModalRoutes.MOVE}
            element={
              <MoveEntityModal
                movingEntity={movingEntity}
                setMovingEntity={moveEntity}
                updateWorkflow={updateWorkflowFolder}
                updateJob={updateJobFolder}
                refreshFolders={refreshFolders}
                folders={fileterdFolders}
              />
            }
          />
          <Route
            path={ModalRoutes.RENAME}
            element={
              <RenameEntityModal
                renamingApplicationId={renamingApplicationId}
                renamingApplicationName={renamingApplicationName}
                closeModal={closeModal}
              />
            }
          />
          <Route
            path={`/${ModalRoutes.RENAME_FOLDER}`}
            element={
              <RenameFolderModal
                renamingFolder={renamingFolder}
                onClose={() => {
                  setRenamingFolder(undefined);
                  closeModal();
                }}
                refreshFolders={refreshFolders}
              />
            }
          />
          <Route
            path={ModalRoutes.INVITE_USER}
            element={
              <InviteOrgUserModal isVisible={true} onClose={closeModal} />
            }
          />
        </Routes>
      </>
    );
  }, [
    closeModal,
    organization,
    createForm,
    folders,
    fileterdFolders,
    movingEntity,
    moveEntity,
    updateWorkflowFolder,
    updateJobFolder,
    refreshFolders,
    renamingApplicationId,
    renamingApplicationName,
    renamingFolder,
    refreshJobs,
    refreshWorkflows,
  ]);

  const demoApp = useSelector(getDemoApplication);
  const demoEntity = demoApp ? appToEntity(demoApp) : undefined;
  const hasOnlyDemoApp =
    visibleEntities?.length === 1 && visibleEntities[0]?.id === demoEntity?.id;

  const welcomeText = useMemo(() => {
    return userName ? `Welcome, ${shortenUsername(userName)}` : HOME_TITLE;
  }, [userName]);

  const openVideoModal = useCallback(
    () => openModal(ModalRoutes.DEMO_VIDEO),
    [openModal],
  );
  const openFolderModal = useCallback(
    () => openModal(ModalRoutes.CREATE_FOLDER),
    [openModal],
  );
  const openCreateModal = useCallback(
    () => openModal(ModalRoutes.CREATE),
    [openModal],
  );

  const setSearchFilter = useCallback((e: any) => {
    sessionStorage.setItem(SessionStorageKey.HOME_SEARCH, e.target.value);
    setEntityFilter(e.target.value);
  }, []);

  const [canUpgradePlan] = useAuthorizationCheck([MANAGE_ORGANIZATION]);

  useTimeout(() => {
    if (
      organization.billing.plan === BillingPlan.TRIAL &&
      organization.billing.isExpired &&
      canUpgradePlan
    ) {
      const lastAutoLoadedTimeInEpoch =
        Number(
          localStorage.getItem(STORAGE_KEYS.PRICING_MODAL_AUTOLOADED_EPOCH_KEY),
        ) || 0;
      const currentTimeInEpoch = new Date().getTime();
      // Popup pricing modal once per day per user
      if (currentTimeInEpoch - lastAutoLoadedTimeInEpoch > 24 * 3600 * 1000) {
        dispatch(openPricingModal(true));
        localStorage.setItem(
          STORAGE_KEYS.PRICING_MODAL_AUTOLOADED_EPOCH_KEY,
          String(currentTimeInEpoch),
        );
      }
    }
  }, MODAL_AUTO_LOAD_TIMEOUT);

  return (
    <PageWrapper pageName={HOME_TITLE}>
      <Layout Header={<Header />} Sider={<PageNav />}>
        {(visibleEntities.length <= 0 || hasOnlyDemoApp) && !isLoading ? (
          <LandingPage
            title={welcomeText}
            dataTest="home-list-ready"
            description="This is where you can manage your applications, workflows, and jobs and create new ones"
            callToActionButtonText="Create new"
            onClickCallToAction={openCreateModal}
            onClickDemoVideo={openVideoModal}
            demoApp={demoEntity}
            demoAppButtonText="View demo app"
          />
        ) : (
          <MainWrapper
            data-test={`home-list-${isLoading ? "loading" : "ready"}`}
          >
            <FullWidthSpace direction="vertical">
              <div className={HeaderWrapper}>
                <div className="page-header-title"> {welcomeText} </div>
              </div>
              <FullWidthSpace direction="vertical" size={40}>
                <Row justify="space-between">
                  <StyledSpace direction="horizontal" size={10}>
                    <StyledRadioGroup value={awjFilter}>
                      <Tooltip title={"Show only applications"}>
                        <Radio.Button
                          value="app"
                          onClick={() => onEntityFilterChange("app")}
                          disabled={isLoading || applications.length === 0}
                        >
                          <AppIcon />
                        </Radio.Button>
                      </Tooltip>
                      <Tooltip title={"Show only workflows"}>
                        <Radio.Button
                          value="workflow"
                          onClick={() => onEntityFilterChange("workflow")}
                          disabled={isLoading || workflows.length === 0}
                        >
                          <WorkflowIcon />
                        </Radio.Button>
                      </Tooltip>
                      <Tooltip title={"Show only scheduled jobs"}>
                        <Radio.Button
                          value="job"
                          onClick={() => onEntityFilterChange("job")}
                          disabled={isLoading || jobs.length === 0}
                        >
                          <JobIcon />
                        </Radio.Button>
                      </Tooltip>
                    </StyledRadioGroup>
                    <WideInput
                      autoFocus
                      defaultValue={entityFilter}
                      placeholder="Search"
                      data-test="home-page-search"
                      onChange={setSearchFilter}
                    />
                    {canManageFolders && (
                      <Tooltip title={"Create a new folder"}>
                        <CreateFolderButton
                          onClick={openFolderModal}
                          data-test="create-folder"
                        >
                          <FolderPlusIcon />
                        </CreateFolderButton>
                      </Tooltip>
                    )}
                    <Tooltip
                      title={
                        canCreateEntity
                          ? undefined
                          : "You do not have permission to create applications, jobs, or workflows"
                      }
                    >
                      <CreateModalButton
                        data-test="create-entity"
                        type="primary"
                        onClick={openCreateModal}
                        disabled={!canCreateEntity}
                      >
                        <PlusCircleIcon />
                        Create New
                      </CreateModalButton>
                    </Tooltip>
                  </StyledSpace>
                </Row>
                <div style={{ display: "block" }}>
                  {isLoading ? <Spinner size={42} /> : folderSections}
                </div>
              </FullWidthSpace>
            </FullWidthSpace>
          </MainWrapper>
        )}
      </Layout>
      {modals}
    </PageWrapper>
  );
};

Home.displayName = HOME_TITLE;

export default Home;
