import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { ReactComponent as CheckedIcon } from "assets/icons/common/checkmark.svg";
import { colors } from "styles/colors";
import { Spinner } from "./Spinner";

const StyledCheckIcon = styled(CheckedIcon)`
  path {
    stroke: ${colors.INFO};
  }
  position: relative;
  top: 2px;
  transition: opacity 0.25s;
`;

const SPINNER_TIME = 350;
const VISIBLE_TIME = 1650;

// This component is optimized for the use case of being rendered inside a virtualized list.
// when `isActive` is true, and `activeTimestamp` is recent, we will show a brief loading spinner before showing a checkmark
export const TemporaryCheckmark = (props: {
  isActive: boolean;
  activeTimestamp?: number | null;
}) => {
  const { isActive, activeTimestamp } = props;

  const [showState, setShowState] = useState<
    "HIDE" | "LOADING" | "SHOW" | "SHOW_END"
  >("HIDE");

  useEffect(() => {
    // in a virtualized list, when the user scrolls away then scrolls back, the component gets remounted
    // so we need to determine what state the loading indicator should be in.
    if (!isActive || !activeTimestamp) {
      setShowState("HIDE");
      return;
    }
    const timeSinceCreation = Date.now() - activeTimestamp;
    if (timeSinceCreation > SPINNER_TIME) {
      setShowState("SHOW");
    } else {
      setShowState("LOADING");
    }
  }, [isActive, activeTimestamp]);

  const isLoading = showState === "LOADING";
  useEffect(() => {
    if (!isLoading) {
      return;
    }
    const timer = setTimeout(() => {
      setShowState("SHOW");
    }, SPINNER_TIME);
    return () => clearTimeout(timer);
  }, [isLoading]);

  const isShow = showState === "SHOW";
  useEffect(() => {
    if (!isShow || !activeTimestamp) {
      return;
    }
    const timeSinceCreation = Date.now() - activeTimestamp;
    const timer = setTimeout(
      () => {
        setShowState("SHOW_END");
      },
      Math.max(VISIBLE_TIME - timeSinceCreation, 0),
    );
    return () => clearTimeout(timer);
  }, [isShow, activeTimestamp]);

  if (showState === "HIDE") {
    return null;
  }

  return (
    <>
      <Spinner size="small" spinning={showState === "LOADING"}>
        <StyledCheckIcon
          height="16px"
          width="16px"
          style={{
            opacity: showState === "SHOW" ? 1 : 0,
          }}
        />
      </Spinner>
    </>
  );
};
