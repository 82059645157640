import { useMemo } from "react";
import logger from "utils/logger";

export const useErrorMessages = (messages: string | string[] | undefined) => {
  return useMemo(() => {
    if (!messages) return [];
    const rawData = messages;
    let msgArray = Array.isArray(rawData)
      ? rawData.filter((msg) => !!msg)
      : rawData;
    if (
      Array.isArray(msgArray) &&
      !msgArray.every((msg) => typeof msg === "string")
    ) {
      // TODO: Once we figure out why this is crashing, fix it. For now this will not crash the component.
      msgArray = [];
      logger.error(
        `Debugging component crash: error tooltip was called with ${JSON.stringify(
          rawData,
        )}`,
      );
    }

    // return simple string if there is only one message
    if (msgArray.length === 1) {
      return msgArray[0];
    }

    return msgArray;
  }, [messages]);
};

export const getErrorMessagesList = (
  errors: Record<string, string> = {},
  showError?: boolean,
) => {
  if (!showError) return [];

  if (errors.isRequired || errors.required) {
    return [errors.isRequired ?? errors.required];
  }

  return Object.values(errors).filter((error) => !!error);
};

// `validationErrors` and `value` may differ from widget to widget.
// In order for these to work, we must already have a `value` and `validationErrors` derived properties
export const getDerivedPropertiesWithErrors = ({
  value,
  validationErrors,
  ...other
}: {
  value?: string;
  validationErrors: string;
} & { [key: string]: string }) => ({
  validationErrors,
  ...(value ? { value } : {}),
  showError: /*javascript*/ `{{
    (() => {
      const hasValue = Array.isArray(this.value) ? this.value.length > 0 : !!this.value;
      if (hasValue) {
        return !this.isValid;
      }
  
      return !this.isValid && this.isTouched;
    })()
  }}`,
  isValid: /*javascript*/ `{{ Object.keys(this.validationErrors ?? {}).length === 0 }}`,
  ...other,
});
