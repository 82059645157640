import { setOrganizationState } from "./actions";
import slice from "./slice";

slice.reducer(setOrganizationState, (state, { payload }) => {
  const organizationId = Object.keys(state.entities)?.[0];
  if (!organizationId) return;
  state.entities[organizationId] = {
    ...state.entities[organizationId],
    ...payload,
  };
});
