import {
  PropsPanelCategory,
  type PropertyPaneCategoryConfig,
} from "legacy/constants/PropertyControlConstants";
import { PanelCategory } from "legacy/pages/Editor/PropertyPane/propertyPaneCategoryUtils";
import { ANIMATE_LOADING_PROPERTY_CONTROL_HELP_TEXT } from "pages/Editors/AppBuilder/Sidebar/PropertyControlCommons";
import {
  customValidationProperties,
  errorMessagePlacementProperty,
} from "../FormInputs/formInputsProperties";
import {
  isDisabledProperty,
  sizeSection,
  visibleProperties,
} from "../basePropertySections";
import { getPopoverConfig } from "../eventHandlerPanel";
import { typographyProperties } from "../styleProperties";
import { DEFAULT_CHECKBOX_WIDGET_TEXT_STYLE_VARIANT } from "./constants";
import { type CheckboxWidgetProps } from "./types";

const config: PropertyPaneCategoryConfig<CheckboxWidgetProps>[] = [
  PanelCategory(PropsPanelCategory.Content, [
    {
      propertyName: "label",
      label: "Label",
      controlType: "INPUT_TEXT",
      helpText: "Displays a label next to the component",
      placeholderText: "Enter label text",
      isBindProperty: true,
      isTriggerProperty: false,
      visibility: "SHOW_NAME",
      isRemovable: true,
      defaultValue: "Label",
      propertyCategory: PropsPanelCategory.Content,
    },
    {
      propertyName: "defaultCheckedState",
      label: "Default checked",
      helpText:
        "Checks / un-checks the checkbox by default. Changes to the default selection update the component state",
      controlType: "SWITCH",
      isJSConvertible: true,
      isBindProperty: true,
      isTriggerProperty: false,
      propertyCategory: PropsPanelCategory.Content,
    },
  ]),
  PanelCategory(PropsPanelCategory.Appearance, [
    ...typographyProperties({
      defaultVariant: DEFAULT_CHECKBOX_WIDGET_TEXT_STYLE_VARIANT,
      textStyleParentDottedPath: "labelProps",
      propertyNameForHumans: "Label",
      hiddenIfPropertyNameIsNullOrFalse: "label",
    }),
    {
      helpText: ANIMATE_LOADING_PROPERTY_CONTROL_HELP_TEXT,
      propertyName: "animateLoading",
      label: "Loading animation",
      controlType: "SWITCH",
      isJSConvertible: true,
      isBindProperty: true,
      isTriggerProperty: false,
      propertyCategory: PropsPanelCategory.Appearance,
    },
  ]),
  PanelCategory(PropsPanelCategory.Layout, [
    sizeSection({
      widthSupportsFitContent: true,
      heightSupportsFitContent: true,
    }),
    ...visibleProperties({ useJsExpr: false }),
  ]),
  PanelCategory(PropsPanelCategory.Interaction, [
    {
      propertyName: "isRequired",
      label: "Required",
      helpText: "Makes checking this component mandatory",
      controlType: "SWITCH",
      isJSConvertible: true,
      isBindProperty: true,
      isTriggerProperty: false,
      propertyCategory: PropsPanelCategory.Interaction,
    },
    isDisabledProperty({ useJsExpr: false }),
    errorMessagePlacementProperty(),
    ...customValidationProperties(),
  ]),
  PanelCategory(PropsPanelCategory.EventHandlers, [
    getPopoverConfig(
      "onCheckChange",
      "Triggers an action when the check state is changed",
    ),
  ]),
];

export default config;
