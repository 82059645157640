import { debounce } from "lodash";
import React from "react";
import {
  getDerivedPropertiesWithErrors,
  getErrorMessagesList,
} from "legacy/components/editorComponents/ErrorUtils";
import { EventType } from "legacy/constants/ActionConstants";
import { type PropertyPaneConfig } from "legacy/constants/PropertyControlConstants";
import {
  WidgetLabelPosition,
  WidgetType,
  WidgetTypes,
} from "legacy/constants/WidgetConstants";
import { VALIDATION_TYPES } from "legacy/constants/WidgetValidation";
import {
  WidgetPropertyValidationType,
  BASE_WIDGET_VALIDATION,
} from "legacy/constants/WidgetValidation";
import BaseWidget from "../BaseWidget";
import withMeta from "../withMeta";
import { CodeComponentWithManagedLayout } from "./CodeEditor";
import CodeWidgetPropertyCategories from "./CodeWidgetPropertyCategories";
import { CodeWidgetProps } from "./types";
import type { DerivedPropertiesMap } from "../Factory";

class CodeWidget extends BaseWidget<CodeWidgetProps, { isFocused: boolean }> {
  constructor(props: CodeWidgetProps) {
    super(props);
    this.state = {
      isFocused: false,
    };
  }

  static getNewPropertyPaneConfig():
    | PropertyPaneConfig<CodeWidgetProps>[]
    | undefined {
    return CodeWidgetPropertyCategories;
  }

  static getPropertyPaneConfig(): PropertyPaneConfig[] {
    throw new Error("Deprecated config should not be called");
  }

  static getDefaultPropertiesMap(): Record<string, string> {
    return {
      stringValue: "initialValue",
    };
  }

  static getDerivedPropertiesMap(): DerivedPropertiesMap {
    // There was a circular dependency between the value and the validation derived properties
    // to avoid it and try to reuse as much code as possible, I'm extracting this parsing logic.
    const infoParser = /*javascript*/ `
      const input = this.stringValue ?? this.initialValue;
      if (this.mode === "application/hjson") {
        parsedValue = HJSON.parse(input);
      } else if (this.mode === "application/json") {
        parsedValue = JSON.parse(input);
      } else {
        parsedValue = input;
      }
    `;

    return getDerivedPropertiesWithErrors({
      _parsedInfo: /*javascript*/ `{{(() => {
        const input = this.stringValue ?? this.initialValue;
        let parsedValue;
        let isValid = true;
        let errors = {};
        try {
          ${infoParser}
        } catch (e) {
          isValid = false;
          errors.formatError = "Format error";
        }
        if (this.isRequired && !input) {
          isValid = false;
          errors.isRequired = "Required";
        }
        if (this.customValidationRule === "false") {
          isValid = false;
          errors.customError = this.customErrorMessage || "Invalid";
        }
        return { parsedValue, isValid, errors };
      })()}}`,
      validationErrors: /*javascript*/ `{{this._parsedInfo.errors}}`,
      value: /*javascript*/ `{{(() => {
        let parsedValue;
        try {
          ${infoParser}
          return parsedValue;
        } catch (e) {
          return this.stringValue ?? this.initialValue;
        }
      })()}}`,
      parsedValue: /*javascript*/ `{{this._parsedInfo.parsedValue}}`,
      isValid: /*javascript*/ `{{this._parsedInfo.isValid}}`,
    });
  }

  static getMetaPropertiesMap(): Record<string, any> {
    return {
      stringValue: undefined,
      isTouched: false,
    };
  }

  static getPropertyValidationMap(): WidgetPropertyValidationType {
    return {
      ...BASE_WIDGET_VALIDATION,
      initialValue: VALIDATION_TYPES.TEXT,
      label: VALIDATION_TYPES.TEXT,
      isRequired: VALIDATION_TYPES.BOOLEAN,
      customValidationRule: VALIDATION_TYPES.TEXT,
      customErrorMessage: VALIDATION_TYPES.TEXT,
    };
  }

  getPageView() {
    return (
      <CodeComponentWithManagedLayout
        {...this.props}
        onChange={this.onChange}
        onFocusChange={this.handleFocusChange}
        vertical={
          !this.props.labelProps?.position ||
          this.props.labelProps?.position === WidgetLabelPosition.TOP
        }
        errorMessages={getErrorMessagesList(
          this.props.validationErrors,
          this.props.showError,
        )}
        isFocused={this.state.isFocused}
      />
    );
  }

  componentWillUnmount() {
    this.onChangeDebounced.flush();
  }

  onChangeDebounced = debounce((value: string) => {
    if (this.props.onChange) {
      this.runEventHandlers?.({
        steps: this.props.onChange,
        type: EventType.ON_TEXT_CHANGE,
        additionalNamedArguments: {},
      });
    }
  }, 300);

  onChange = (value: string) => {
    this.props.updateWidgetMetaProperty("stringValue", value);
    if (!this.props.isTouched) {
      this.props.updateWidgetMetaProperty("isTouched", true);
    }
    this.onChangeDebounced(value);
  };

  handleFocusChange = (focused: boolean) => {
    this.setState({ isFocused: focused });

    if (!focused && !this.props.isTouched) {
      this.props.updateWidgetMetaProperty("isTouched", true);
    }
  };

  getWidgetType(): WidgetType {
    return WidgetTypes.CODE_WIDGET;
  }
}

export default CodeWidget;
export const ConnectedCodeWidget = withMeta(CodeWidget);
