export class WeakCache<Type extends object> {
  private cache = new Map<string, WeakRef<Type>>();
  private finalizationRegistry: FinalizationRegistry<string>;

  constructor() {
    // When an object is GC’ed, this callback is triggered
    // with the key to remove from the Map.
    this.finalizationRegistry = new FinalizationRegistry((key: string) => {
      this.cache.delete(key);
    });
  }

  public get(key: string): Type | undefined {
    const ref = this.cache.get(key);
    return ref?.deref(); // deref() returns the actual object or undefined if it’s been GC’ed
  }

  public set(key: string, t: Type): void {
    // Wrap the dimension in a WeakRef
    const ref = new WeakRef(t);
    this.cache.set(key, ref);
    // Register the object with a finalization callback
    this.finalizationRegistry.register(t, key);
  }

  public clear(): void {
    this.cache.clear();
  }
}
