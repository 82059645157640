import { isString } from "lodash";

export enum BooleanStyleFalse {
  EMPTY = "EMPTY",
  CLOSE = "CLOSE",
  MINUS = "MINUS",
  EMPTY_CHECKBOX = "EMPTY_CHECKBOX",
}

export enum ImageSize {
  Fixed = "FIXED",
  Fit = "FIT",
  Cover = "COVER",
  Grow = "GROW",
}

export function parseImageURLValue(value: any) {
  let imageUrls: string[] | undefined;
  if (isString(value)) {
    // We are splitting the value by comma to support multiple images. This is problematic, because comma is a valid
    // character in URIs. In particular, it is used in data URIs (but it can occur in other URIs as well). We are
    // preserving the old splitting behavior here for backwards compatibility, but not for data URIs. We should
    // consider changing this in the future.
    if (/^data:/.test(value)) {
      imageUrls = [value];
    } else {
      imageUrls = value.split(",");
    }
  } else if (Array.isArray(value) && value.every(isString)) {
    imageUrls = value;
  }
  return imageUrls;
}
