import {
  PropsPanelCategory,
  type PropertyPaneCategoryConfig,
} from "legacy/constants/PropertyControlConstants";
import { WidgetLabelPosition } from "legacy/constants/WidgetConstants";
import { PanelCategory } from "legacy/pages/Editor/PropertyPane/propertyPaneCategoryUtils";
import { ANIMATE_LOADING_PROPERTY_CONTROL_HELP_TEXT } from "pages/Editors/AppBuilder/Sidebar/PropertyControlCommons";
import {
  customValidationProperties,
  errorMessagePlacementProperty,
  labelWidthProperty,
} from "../FormInputs/formInputsProperties";
import {
  labelPositionProperty,
  sizeSection,
  visibleProperties,
} from "../basePropertySections";
import { typographyProperties } from "../styleProperties";
import { DEFAULT_FILE_PICKER_WIDGET_LABEL_STYLE_VARIANT } from "./constants";
import { FilePickerWidgetProps } from "./types";

const config: PropertyPaneCategoryConfig<FilePickerWidgetProps>[] = [
  PanelCategory(PropsPanelCategory.Content, [
    {
      helpText: "Sets a label text for the file picker",
      propertyName: "labelProps.text",
      label: "Label",
      controlType: "INPUT_TEXT",
      placeholderText: "Enter label text",
      isBindProperty: true,
      isTriggerProperty: false,
      visibility: "SHOW_NAME",
      isRemovable: true,
      defaultValue: "Label",
    },
    {
      propertyName: "selectionType",
      label: "Selection type",
      controlType: "DROP_DOWN",
      options: [
        {
          label: "Single select",
          value: 1,
        },
        {
          label: "Multi select",
          value: undefined,
        },
      ],
      isBindProperty: false,
      isTriggerProperty: false,
    },
    {
      propertyName: "allowedFileTypes",
      helpText:
        "File types can either be mime types or suffixes. * is wildcard.",
      label: "Allowed file types",
      controlType: "INPUT_TEXT",
      placeholderText: "['image/*', '.csv', '.zip']",
      inputType: "ARRAY",
      isBindProperty: true,
      isTriggerProperty: false,
    },
  ]),
  PanelCategory(PropsPanelCategory.Appearance, [
    labelPositionProperty({
      parentDottedPath: "labelProps",
      hidden: (props) => !props.labelProps?.text,
    }),
    ...typographyProperties({
      textStyleParentDottedPath: "labelProps",
      propertyNameForHumans: "Label",
      defaultVariant: DEFAULT_FILE_PICKER_WIDGET_LABEL_STYLE_VARIANT,
      hiddenIfPropertyNameIsNullOrFalse: "labelProps.text",
    }),
    {
      propertyName: "animateLoading",
      label: "Loading animation",
      helpText: ANIMATE_LOADING_PROPERTY_CONTROL_HELP_TEXT,
      controlType: "SWITCH",
      isJSConvertible: true,
      isBindProperty: true,
      isTriggerProperty: false,
    },
  ]),
  PanelCategory(PropsPanelCategory.Layout, [
    sizeSection({ heightSupportsFitContent: true }),
    labelWidthProperty({
      hidden: (props: FilePickerWidgetProps) =>
        !props.labelProps?.text ||
        !props.labelProps?.position ||
        props.labelProps?.position === WidgetLabelPosition.TOP,
    }),
    ...visibleProperties({ useJsExpr: false }),
  ]),
  PanelCategory(PropsPanelCategory.Interaction, [
    {
      propertyName: "isRequired",
      label: "Required",
      helpText: "Makes input to the component mandatory",
      controlType: "SWITCH",
      isJSConvertible: true,
      isBindProperty: true,
      isTriggerProperty: false,
    },
    {
      propertyName: "isDisabled",
      label: "Disabled",
      helpText: "Disables user interaction with this component",
      controlType: "SWITCH",
      isJSConvertible: true,
      isBindProperty: true,
      isTriggerProperty: false,
    },
    errorMessagePlacementProperty(),
    ...customValidationProperties(),
  ]),
];

export default config;
