import { Dimension } from "@superblocksteam/shared";
import { GridDefaults } from "legacy/constants/WidgetConstants";
import { GeneratedTheme } from "legacy/themes";
import { formatNumber, formatPercentage } from "legacy/utils/FormatUtils";
import { formatCurrency } from "legacy/utils/FormatUtils";
import { formatDate } from "legacy/utils/FormatUtils";
import { TagsColorAssignment } from "../TableWidget/TableComponent/Constants";
import { sanitizeCellValue } from "../TableWidget/TableComponent/TableUtilities";
import { getLineHeightPx } from "../TextWidget/TextComponent";
import { DEFAULT_BORDER_OBJECT } from "./constants";
import { KeyValueProperty, PropertyType } from "./types";

export const getValueByType = (
  property: KeyValueProperty,
  data: Record<string, unknown>,
): string | boolean => {
  const text = property.isDerived
    ? `${property.computedValue}`
    : `${property.displayedValue ?? data[property.id]}`;
  switch (property.type) {
    case PropertyType.CURRENCY: {
      return formatCurrency(
        text,
        property.typeSpecificProps?.currency,
        property.typeSpecificProps?.notation,
        property.typeSpecificProps?.minimumFractionDigits,
        property.typeSpecificProps?.maximumFractionDigits,
      );
    }
    case PropertyType.NUMBER: {
      return formatNumber(
        text,
        property.typeSpecificProps?.notation,
        property.typeSpecificProps?.minimumFractionDigits,
        property.typeSpecificProps?.maximumFractionDigits,
      );
    }
    case PropertyType.PERCENTAGE: {
      return formatPercentage(
        text,
        property.typeSpecificProps?.minimumFractionDigits,
        property.typeSpecificProps?.maximumFractionDigits,
      );
    }
    case PropertyType.DATE: {
      return formatDate(
        text,
        property.typeSpecificProps?.dateInputFormat,
        property.typeSpecificProps?.dateOutputFormat,
        property.typeSpecificProps?.manageTimezone
          ? property.typeSpecificProps?.timezone
          : undefined,
        property.typeSpecificProps?.manageTimezone
          ? property.typeSpecificProps?.displayTimezone
          : undefined,
      );
    }
    case PropertyType.BOOLEAN: {
      return !!(
        property &&
        (property.isDerived ? property.computedValue : data[property.id])
      );
    }
    default:
      return text;
  }
};

export const getProperties = (
  data: Record<string, unknown>,
  currentProperties: Record<string, KeyValueProperty>,
): Record<string, KeyValueProperty> => {
  const derivedProperties = Object.values(currentProperties).filter(
    (property) => property.isDerived,
  );

  const baseProperties = derivedProperties.reduce(
    (acc, property) => {
      acc[property.id] = property;
      return acc;
    },
    {} as Record<string, KeyValueProperty>,
  );

  return Object.keys(data).reduce((acc, key) => {
    acc[key] = {
      id: key,
      key,
      label: key,
      type: "text",
      isVisible: true,
      ...((currentProperties[key] ?? {}) as Partial<KeyValueProperty>),
      isDerived: false,
    };
    return acc;
  }, baseProperties);
};

export const getTagsColorAssignment = (
  tagValues: string[],
): TagsColorAssignment => {
  return {
    uniqueTagsCount: tagValues.length,
    mapping: tagValues.reduce(
      (acc, tag, index) => {
        acc[sanitizeCellValue(tag)] = index;
        return acc;
      },
      {} as Record<string, number>,
    ),
  };
};

export const estimateInitialKeyValueWidgetHeightGU = ({
  theme,
  rowCount,
}: {
  theme?: GeneratedTheme;
  rowCount: number;
}): number => {
  if (!theme?.typographies) {
    return 14;
  }

  const textHeightPx = getLineHeightPx({}, theme?.typographies);
  const { padding, spacing } = theme.keyValue;

  return Dimension.toGridUnit(
    Dimension.px(
      (padding.top?.value ?? 0) +
        (padding.bottom?.value ?? 0) +
        (DEFAULT_BORDER_OBJECT.top.width?.value ?? 0) * 2 +
        textHeightPx * rowCount +
        (spacing.value ?? 0) * (rowCount - 1),
    ),
    GridDefaults.DEFAULT_GRID_ROW_HEIGHT,
  ).raw().value;
};
