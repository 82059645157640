import { InputRef } from "antd";
import Fuse from "fuse.js";
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { ListChildComponentProps, VariableSizeList } from "react-window";
import styled from "styled-components";
import { useCallbackAsRef } from "hooks/ui";
import {
  SearchableListContainer,
  SearchableListInput,
  useListKeyboardNavigation,
} from "pages/Editors/ApiEditor/ApiListControlFlow/SearchableList/SearchableList";
import { colors } from "styles/colors";
import { styleAsClass } from "styles/styleAsClass";

const SearchableListWrapper = styled(SearchableListContainer)`
  background: ${colors.WHITE};
  box-shadow:
    0px 0px 1px 0px #22272f52,
    0px 12px 32px -8px #22272f29,
    0px 1px 3px 0px #22272f1f;
  width: 220px;
  border-radius: 4px;
`;

const StyledSearchableListInput = styled(SearchableListInput)`
  margin-bottom: 0;

  input::selection {
    color: ${colors.GREY_900};
    background: #cde7ff;
  }
`;

export const SearchListItem = styled.div<{ keyboardFocusActive?: boolean }>`
  cursor: pointer;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  color: ${colors.GREY_700};
  padding: 8px 10px;
  border-radius: 4px;
  margin: 0 4px;

  &[data-focused="true"] {
    background: ${colors.GREY_50};
  }

  ${({ keyboardFocusActive }) =>
    !keyboardFocusActive &&
    `
    &:hover {
      background: ${colors.GREY_50};
    }
  `}

  &[data-hasPaidTag="true"] {
    cursor: not-allowed;
  }

  // Support for items with icons
  display: flex;
  align-items: center;
  gap: 6px;

  &[data-disabled="true"] {
    cursor: not-allowed;
  }

  &[data-intent="primary"] {
    color: ${colors.ACCENT_BLUE_500};

    &:hover {
      background-color: ${colors.ACCENT_BLUE_500_50};
    }

    svg path {
      color: ${colors.ACCENT_BLUE_500};
      stroke: ${colors.ACCENT_BLUE_500};
    }
  }
`;

const RenderBelowListClassName = styleAsClass`
  border-top: 1px solid ${colors.GREY_100};
`;

export type ItemsType = {
  value: string;
  label: string;
  hasDivider?: boolean;
  icon?: React.ReactNode;
  customRender?: (props: CustomRenderProps) => React.ReactNode;
  dataTest?: string;
};

export type CustomRenderProps = {
  focusedIndex: number | undefined;
  index: number;
  className: string;
  onMouseEnter: () => void;
  onClick: () => void;
  dataTest: string;
  keyboardFocusActive?: boolean;
  lastCreatedItem?: [value: string | undefined, uniqueIndex: number];
};

export type SectionProps = {
  name: string;
  header?: {
    render: () => React.ReactNode;
    height?: number; // for virtualized list, height needs to be known beforehand
  };
  footer?: {
    render: () => React.ReactNode;
    height?: number; // for virtualized list, height needs to be known beforehand
  };
  divider?: boolean;
  items: Array<ItemsType>;
};

export type SearchableListProps = {
  sections: Array<SectionProps>;
  onItemSelect: (item: string) => void;
  id?: string;
  wrapperStyles?: React.CSSProperties;
  dataTest?: string;
  renderBelowList?: React.ReactNode;
  wrapperClassName?: string;
  placeholder?: string;
  noResultsMessage?: string | React.ReactNode;
  refocusOnEnter?: boolean;
};

type RenderItemType = (
  item: ItemsType,
  index: number,
  focusedIndex: number | undefined,
) => React.ReactNode;

type ItemRow = {
  item: ItemsType;
  itemIndex: number;
};
type DecorationRow = {
  height: number;
  render: () => React.ReactNode;
};
type VirtualRowInfo = ItemRow | DecorationRow;

const rowIsDecoration = (row: VirtualRowInfo): row is DecorationRow => {
  return typeof (row as DecorationRow).render === "function";
};

const parseDimensionValue = (dimension: string | number | undefined) =>
  typeof dimension === "string"
    ? parseFloat(dimension)
    : typeof dimension === "number"
      ? dimension
      : 0;

const defaultRowHeight = 30;

export const SectionTitle = styled.div`
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: ${colors.GREY_800};
`;

const defaultHeader = (name: string) => {
  return (
    <SectionTitle
      style={{
        padding: "8px 10px",
        margin: "0 4px",
      }}
    >
      {name}
    </SectionTitle>
  );
};

export const SearchableListWithSections = (props: SearchableListProps) => {
  const searchInputRef = useRef<InputRef>(null);
  const virtualContainerRef = useRef<VariableSizeList<any>>(null);
  const [searchText, setSearchText] = useState<string>("");

  const flattenedItems = useMemo(() => {
    return props.sections.reduce((acc, section) => {
      return acc.concat(section.items);
    }, [] as ItemsType[]);
  }, [props.sections]);

  const fuseList = useMemo(() => {
    const fuse = new Fuse(flattenedItems, {
      keys: ["label", "value"],
      threshold: 0.3,
    });

    return fuse;
  }, [flattenedItems]);

  const searchFilteredOptionsList: Array<ItemsType> = useMemo(() => {
    if (searchText) {
      const searchedList = fuseList
        .search(searchText)
        .map((result) => result.item);

      return searchedList;
    }

    return flattenedItems;
  }, [flattenedItems, fuseList, searchText]);

  const hasSearchText = searchText.length > 0;
  const [virtualRows, indexMapping] = useMemo<
    [Array<VirtualRowInfo>, undefined | Record<number, number>]
  >(() => {
    const wrapItem = (item: ItemsType, itemIndex: number): VirtualRowInfo => {
      return {
        item,
        itemIndex,
      };
    };
    if (hasSearchText) {
      return [searchFilteredOptionsList.map(wrapItem), undefined];
    }

    let itemIndexStart = 0;
    const indexMapping: Record<number, number> = {};
    const items = props.sections.reduce((acc, section) => {
      acc.push({
        render: section?.header?.render ?? (() => defaultHeader(section.name)),
        height: section?.header?.height ?? defaultRowHeight,
      });

      section.items.forEach((item, index) => {
        indexMapping[index + itemIndexStart] = acc.length;
        acc.push(wrapItem(item, index + itemIndexStart));
      });
      itemIndexStart += section.items.length;
      if (section.footer) {
        acc.push({
          render: section.footer.render,
          height: section.footer.height ?? defaultRowHeight,
        });
      }
      return acc;
    }, [] as VirtualRowInfo[]);
    return [items, indexMapping];
  }, [hasSearchText, searchFilteredOptionsList, props.sections]);

  const scrollToIndex = useCallback(
    (index: number) => {
      if (virtualContainerRef.current) {
        const mappedIndex = indexMapping ? indexMapping[index] : index;
        // we want to show the header if we're at the top otherwise it looks weird
        virtualContainerRef.current.scrollToItem(
          index === 0 ? 0 : mappedIndex,
          "center",
        );
      }
    },
    [indexMapping],
  );

  // we need to track keyboard focus because otherwise the mouse move events interfere with arrow key movement
  const [keyboardFocusActive, setKeyboardFocusActive] =
    useState<boolean>(false);
  const handleArrowKeyPress = useCallback(() => {
    setKeyboardFocusActive(true);
  }, []);
  const handleMouseMove = useCallbackAsRef(() => {
    if (keyboardFocusActive) {
      setKeyboardFocusActive(false);
    }
  });

  const onItemSelect = props.onItemSelect;
  const [lastCreatedItem, setLastCreatedItem] = useState<
    [value: string | undefined, timeCreated: number]
  >([undefined, 0]);

  const internalOnItemSelect = useCallbackAsRef((item: string) => {
    onItemSelect(item);
    setLastCreatedItem([item, Date.now()]);
    searchInputRef.current?.select();
  });

  const { handleKeyPress, focusedIndex, resetFocusedIndex, setFocusedIndex } =
    useListKeyboardNavigation({
      defaultFocusedIndex: 0,
      filteredItems: searchFilteredOptionsList,
      onEnterKeypress: internalOnItemSelect,
      onFocused: scrollToIndex,
      onArrowKeyPress: handleArrowKeyPress,
      idKey: "value" satisfies keyof ItemsType,
      refocusOnEnter: props.refocusOnEnter,
    });

  const handleSearchChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setSearchText(e.target.value);
      scrollToIndex(0);
      setFocusedIndex(0);
    },
    [setSearchText, scrollToIndex, setFocusedIndex],
  );

  const focusSearchInput = useCallback(() => {
    // add a small delay to focus the input to allow the popper to open
    setTimeout(() => {
      searchInputRef.current?.focus();
    }, 100);
  }, [searchInputRef]);

  useEffect(() => {
    focusSearchInput();
  }, [focusSearchInput, resetFocusedIndex]);

  const handleItemMouseOver = useCallbackAsRef((index: number) => {
    if (!keyboardFocusActive) {
      setFocusedIndex(index);
    }
  });

  const renderItem = useMemo(() => {
    const renderStandardItem: RenderItemType = (
      item: ItemsType,
      index: number,
      focusedIndex: number | undefined,
    ) => {
      return (
        <SearchListItem
          className="searchable-list-item" // for useScrollToFocusedItem support
          onClick={() => internalOnItemSelect(item.value)}
          onMouseEnter={() => handleItemMouseOver(index)}
          data-focused={focusedIndex === index}
          data-test={`dropdown-option-${item.dataTest ?? item.value}`}
          keyboardFocusActive={keyboardFocusActive}
        >
          {item.icon ?? null}
          {item.label}
        </SearchListItem>
      );
    };

    const renderCustomItem: RenderItemType = (
      item: ItemsType,
      index: number,
      focusedIndex: number | undefined,
    ) => {
      return item.customRender
        ? item.customRender({
            focusedIndex,
            index,
            className: "searchable-list-item",
            onMouseEnter: () => handleItemMouseOver(index),
            keyboardFocusActive,
            lastCreatedItem,
            onClick: () => internalOnItemSelect(item.value),
            dataTest: `dropdown-option-${item.value}`,
          })
        : null;
    };

    const renderItem: RenderItemType = (
      item: ItemsType,
      index: number,
      focusedIndex: number | undefined,
    ) => {
      const itemToRender = item.customRender
        ? renderCustomItem
        : renderStandardItem;
      return (
        <React.Fragment key={item.value}>
          {itemToRender(item, index, focusedIndex)}
          {item.hasDivider && searchText.length === 0 && (
            <div
              style={{
                borderTop: `1px solid ${colors.GREY_100}`,
                margin: "4px 0",
              }}
            />
          )}
        </React.Fragment>
      );
    };
    return renderItem;
  }, [
    handleItemMouseOver,
    internalOnItemSelect,
    searchText,
    keyboardFocusActive,
    lastCreatedItem,
  ]);

  const renderVirtualRow = useCallback(
    (props: ListChildComponentProps) => {
      const row = virtualRows[props.index];
      let node = null;
      if (rowIsDecoration(row)) {
        node = row.render();
      } else {
        node = renderItem(row.item, row.itemIndex, focusedIndex);
      }
      const top =
        parseDimensionValue(props.style.top) + (hasSearchText ? 8 : 0);

      return (
        <div
          style={{
            ...props.style,
            top: `${top}px`,
          }}
        >
          {node}
        </div>
      );
    },
    [focusedIndex, renderItem, virtualRows, hasSearchText],
  );

  // need to make sure the ref is stable since it will repeatedly unmount otherwise
  const RenderVirtualRowComponent = useCallbackAsRef(renderVirtualRow);

  const getItemSize = useCallback(
    (index: number) => {
      // add some breathing room at the bottom
      const row = virtualRows[index];
      return rowIsDecoration(row) ? row.height : defaultRowHeight;
    },
    [virtualRows],
  );

  // way to add padding on top of the list recommended by react-window
  // https://github.com/bvaughn/react-window?tab=readme-ov-file#can-i-add-padding-to-the-top-and-bottom-of-a-list
  const innerElementType = forwardRef(function ListElement(
    { style, ...rest }: ListChildComponentProps,
    ref,
  ) {
    const height = parseDimensionValue(style.height) + (hasSearchText ? 16 : 8);
    return (
      <div
        ref={ref as React.Ref<HTMLDivElement>}
        style={{
          ...style,
          height: `${height}px`, // add some breathing room at the top (if there is search text) and bottom
        }}
        {...rest}
      />
    );
  });

  return (
    <SearchableListWrapper
      id={props.id}
      style={props.wrapperStyles}
      data-test={props.dataTest}
      className={props.wrapperClassName}
      onMouseMove={handleMouseMove}
    >
      <StyledSearchableListInput
        ref={searchInputRef}
        value={searchText}
        onSearchChange={handleSearchChange}
        allowClear={false}
        showSearchIcon={true}
        onKeyPress={handleKeyPress}
        placeholder={props.placeholder}
        style={{ marginBottom: 0 }}
      />
      {searchFilteredOptionsList?.length > 0 ? (
        <VariableSizeList
          itemSize={getItemSize}
          itemCount={virtualRows.length}
          height={280}
          width={"100%"}
          ref={virtualContainerRef}
          style={{
            scrollbarColor: `${colors.GREY_200} transparent`,
            scrollbarWidth: "thin",
          }}
          innerElementType={innerElementType}
        >
          {RenderVirtualRowComponent}
        </VariableSizeList>
      ) : (
        <div
          style={{
            color: colors.GREY_500,
            padding: "8px 10px",
            margin: "0 4px",
            fontSize: "12px",
          }}
        >
          {props.noResultsMessage}
        </div>
      )}
      {props.renderBelowList && (
        <div className={RenderBelowListClassName}>{props.renderBelowList}</div>
      )}
    </SearchableListWrapper>
  );
};
