import { Dimension, Padding } from "@superblocksteam/shared";
import { get } from "lodash";
import React, { CSSProperties } from "react";
import { connect } from "react-redux";
import { select } from "redux-saga/effects";
import {
  UpdateWidgetPropertiesPayload,
  updateWidgetProperties,
} from "legacy/actions/controlActions";
import { WidgetAddChild } from "legacy/actions/pageActions";
import {
  PropsPanelCategory,
  type Hidden,
  type PropertyPaneConfig,
} from "legacy/constants/PropertyControlConstants";
import { ReduxAction } from "legacy/constants/ReduxActionConstants";
import {
  WidgetTypes,
  GridDefaults,
  CanvasLayout,
  CanvasDefaults,
  CanvasAlignment,
  ATTACHED_WIDGET_AND_CAN_HAVE_CHILDREN,
  LAYOUT_DEFAULTS,
} from "legacy/constants/WidgetConstants";
import {
  BASE_WIDGET_VALIDATION,
  VALIDATION_TYPES,
  WidgetPropertyValidationType,
} from "legacy/constants/WidgetValidation";
import { APP_MODE } from "legacy/reducers/types";
import {
  updateSectionWidgetCanvasHeights,
  repositionWidgetsFromStackIntoFixedGrid,
} from "legacy/sagas/WidgetOperationsSagasUtils";
import { getAppMode } from "legacy/selectors/applicationSelectors";
import { getFlattenedCanvasWidgets } from "legacy/selectors/editorSelectors";
import {
  getCanvasWidgets,
  isColumnUtil,
} from "legacy/selectors/entitiesSelector";
import { getDynamicLayoutWidgets } from "legacy/selectors/layoutSelectors";
import { selectGeneratedTheme } from "legacy/selectors/themeSelectors";
import { GeneratedTheme } from "legacy/themes";
import {
  DEFAULT_CONTAINER_BORDER_OBJECT,
  NO_BORDER_OBJECT,
} from "legacy/themes/constants";
import { getSortedWidgetOrder } from "legacy/utils/MoveWidgetUtils";
import { getCanvasSnapRows } from "legacy/utils/WidgetPropsUtils";
import { getCanvasClassName } from "legacy/utils/generators";
import {
  iconPositionProperty,
  iconProperty,
} from "legacy/widgets/appearanceProperties";
import { Flags } from "store/slices/featureFlags";
import { getParentColumnSpaceMinusPadding } from "utils/size";
import { WidgetPropsRuntime, PartialWidgetProps } from "./BaseWidget";
import ContainerWidget, { ContainerWidgetProps } from "./ContainerWidget";
import WidgetFactory, {
  CanvasWidgetsReduxState,
  WidgetActionHook,
  WidgetActionResponse,
} from "./Factory";
import StackDropTargetComponent from "./StackLayout/StackDropTargetComponent";
import DropTargetComponent from "./base/DropTargetComponent";
import {
  getApplicableMinHeight,
  getCanvasMinHeightFlattened,
  getBorderThickness,
  getWidgetDefaultPadding,
} from "./base/sizing";
import {
  isVisibleProperty,
  collapseWhenHiddenProperty,
  sizeSection,
  marginProperty,
  paddingProperty,
} from "./basePropertySections";
import { layoutProperties } from "./layoutProperties";
import { WidgetLayoutProps, FlattenedWidgetLayoutMap } from "./shared";
import { backgroundColorProperty, borderProperty } from "./styleProperties";
import type {
  TabContainerWidgetProps,
  TabsWidgetProps,
} from "./TabsWidget/types";
import type { DynamicWidgetsVisibilityState } from "legacy/selectors/visibilitySelectors";
import type { WidgetProps } from "legacy/widgets";
import type { AppState } from "store/types";

function isWidgetWithFixedLayout(
  props: CanvasWidgetProps,
): props is CanvasWidgetProps & { layout: CanvasLayout.FIXED | undefined } {
  return props.layout === CanvasLayout.FIXED || props.layout === undefined;
}

// type check to ensure that this method of checking if the component is a child of a tab stays valid
const tabIdentifierKey: keyof Omit<
  TabContainerWidgetProps,
  keyof WidgetPropsRuntime
> = "tabId";

const shouldHideScroll: Hidden = (props, path, flags) => {
  if (tabIdentifierKey in props) return true;
  return false;
};

const shouldHideIsVisible: Hidden = (props, path, flags) => {
  if (tabIdentifierKey in props) return true;
  return false;
};

const shouldHideIfNotTab: Hidden = (props, path, flags) => {
  return !(tabIdentifierKey in props);
};

const shouldHideIfNotColumn: Hidden = (
  props,
  path,
  flags,
  additionalHiddenData,
) => {
  const isColumn = additionalHiddenData?.canvasWidgets
    ? isColumnUtil(additionalHiddenData.canvasWidgets, props.widgetId)
    : false;
  return !isColumn;
};

const didLayoutSwitch = (
  action: ReduxAction<UpdateWidgetPropertiesPayload>,
  originalWidgetValues: PartialWidgetProps,
) => {
  if (action.payload.updates.layout === undefined) {
    return {
      switchedToGrid: false,
      switchedToStack: false,
    };
  }

  const switchedToGrid =
    action.payload.updates.layout === CanvasLayout.FIXED &&
    originalWidgetValues.layout !== CanvasLayout.FIXED;

  const switchedToVStack =
    action.payload.updates.layout === CanvasLayout.VSTACK ||
    originalWidgetValues.layout !== CanvasLayout.VSTACK;
  const switchedToHStack =
    action.payload.updates.layout === CanvasLayout.HSTACK ||
    originalWidgetValues.layout !== CanvasLayout.HSTACK;

  const switchedToStack = switchedToVStack || switchedToHStack;

  return {
    switchedToGrid,
    switchedToStack,
  };
};

const PROPERTIES_REQUIRING_COLUMN_HEIGHT_UPDATE = new Set<keyof WidgetProps>([
  "spacing",
  "layout",
  "padding",
  "minHeight",
  "maxHeight",
]);

export type CanvasWidgetProps = ContainerWidgetProps & {
  generatedTheme: GeneratedTheme;
};
class CanvasWidget extends ContainerWidget<CanvasWidgetProps> {
  static getPropertyPaneConfig(): PropertyPaneConfig[] {
    return [
      {
        sectionName: "Tab Info",
        sectionCategory: PropsPanelCategory.Appearance,
        hidden: shouldHideIfNotTab,
        children: [
          {
            label: "Label",
            helpText: "Displayed on the parent tab bar",
            controlType: "INPUT_TEXT",
            isBindProperty: false,
            isTriggerProperty: false,
            getTargetWidgetId: (props) => props.parentId,
            propertyName: (props, extras) => {
              const tabWidgetId = extras.originalItemId;
              const tabs = (props as TabsWidgetProps<any>).tabs ?? [];
              const index = tabs.findIndex(
                (tab) => tab.widgetId === tabWidgetId,
              );
              return `tabs[${index}].label`;
            },
            updateHook: ({
              props,
              propertyPath,
              propertyValue,
            }: {
              props: TabsWidgetProps<any>;
              propertyPath: string;
              propertyValue: any;
            }) => {
              const defaultTab = props.defaultTab;
              const oldTabValue = get(props, propertyPath);
              if (defaultTab === oldTabValue) {
                return [
                  {
                    propertyPath: "defaultTab",
                    propertyValue,
                  },
                ];
              }
              return [];
            },
          },
        ],
      },
      {
        sectionName: "General",
        children: [
          ...layoutProperties({ isLayoutOnChild: false }),
          paddingProperty(),
          marginProperty(),
        ],
      },
      {
        sectionName: "Style",
        children: [
          backgroundColorProperty({
            themeValue: "{{ theme.colors.neutral }}",
            hidden: shouldHideIfNotTab,
          }),
          backgroundColorProperty({
            hidden: shouldHideIfNotColumn,
            themeValue: () => {
              return {
                value: "transparent",
                treatAsNull: true,
              };
            },
            getAdditionalHiddenData: {
              canvasWidgets: getCanvasWidgets,
            },
          }),
          borderProperty({
            hidden: shouldHideIfNotColumn,
            themeValue: () => {
              return {
                value: NO_BORDER_OBJECT,
                treatAsNull: true,
              };
            },
            defaultValue: DEFAULT_CONTAINER_BORDER_OBJECT,
            getAdditionalHiddenData: {
              canvasWidgets: getCanvasWidgets,
            },
          }),
        ],
      },
      {
        sectionName: "Icons",
        sectionCategory: PropsPanelCategory.Appearance,
        hidden: shouldHideIfNotTab,
        children: [
          iconProperty({
            propertyName: "tabIcon",
          }),
          iconPositionProperty(
            {
              propertyName: "tabIconPosition",
            },
            "tabIcon",
          ),
        ],
      },
      sizeSection({
        hideMinMaxHeightSection: true,
        hideMinMaxWidthSection: true,
      }),
      {
        sectionName: "Layout",
        children: [
          {
            propertyName: "shouldScrollContents",
            propertyCategory: PropsPanelCategory.Layout,
            label: "Scroll contents",
            controlType: "SWITCH",
            isBindProperty: false,
            isTriggerProperty: false,
            hidden: shouldHideScroll,
          },
          isVisibleProperty({
            hidden: shouldHideIsVisible,
          }),
          collapseWhenHiddenProperty({
            hidden: shouldHideIsVisible,
          }),
        ],
      },
    ];
  }

  static getPropertyValidationMap(): WidgetPropertyValidationType {
    return {
      ...BASE_WIDGET_VALIDATION,
      border: VALIDATION_TYPES.OBJECT_OR_UNDEFINED,
      tabIcon: VALIDATION_TYPES.ICONS,
      tabIconPosition: VALIDATION_TYPES.TEXT,
    };
  }

  getWidgetType = () => {
    return WidgetTypes.CANVAS_WIDGET;
  };

  getCanvasProps(): ContainerWidgetProps {
    return {
      ...this.props,
      border: undefined,
      padding:
        this.props.padding ||
        getWidgetDefaultPadding(this.props.generatedTheme, this.props),
      parentRowSpace: this.props.parentRowSpace ?? 1,
      parentColumnSpace: this.props.parentColumnSpace ?? 1,
      top: this.props.top ?? Dimension.gridUnit(0),
      left: this.props.left ?? Dimension.gridUnit(0),
    };
  }

  renderAsDropTarget() {
    const canvasProps = this.getCanvasProps();

    const parentWidget = this.props;

    if (isWidgetWithFixedLayout(parentWidget)) {
      const columnSpaceForChildren = getParentColumnSpaceMinusPadding({
        parentWidget,
      });

      return (
        <DropTargetComponent
          {...canvasProps}
          gridRowSpace={GridDefaults.DEFAULT_GRID_ROW_HEIGHT}
          gridColumnSpace={columnSpaceForChildren}
          gridColumns={this.props.gridColumns!}
          data-test={`canvas-droppable-${canvasProps.widgetId}`}
          minHeight={
            (getApplicableMinHeight(
              this.props as CanvasWidgetProps,
            ) as Dimension<"gridUnit" | "px">) ||
            Dimension.px(GridDefaults.DEFAULT_GRID_ROW_HEIGHT)
          }
        >
          {this.renderAsContainerComponent(canvasProps)}
        </DropTargetComponent>
      );
    } else {
      return (
        <StackDropTargetComponent {...canvasProps}>
          {this.renderAsContainerComponent(canvasProps)}
        </StackDropTargetComponent>
      );
    }
  }

  renderChildWidget(childWidgetData: WidgetLayoutProps): React.ReactNode {
    if (!childWidgetData) return null;
    return WidgetFactory.createWidget(childWidgetData, this.props.appMode);
  }

  getPageView() {
    const padding =
      this.props.padding ??
      getWidgetDefaultPadding(this.props.generatedTheme, this.props);
    const paddingY = Padding.y(padding).value;

    const borderWidth = getBorderThickness(
      this.props,
      this.props.generatedTheme,
    );

    const gridRows = getCanvasSnapRows(this.props.height, this.props.minHeight);
    const height =
      gridRows * GridDefaults.DEFAULT_GRID_ROW_HEIGHT +
      paddingY -
      borderWidth * 2;

    const style: CSSProperties = {
      width: this.props.layout === CanvasLayout.HSTACK ? "auto" : "100%",
      height:
        this.props.layout === CanvasLayout.HSTACK ? undefined : `${height}px`,
      marginTop: `${-borderWidth}px`,
      marginLeft: `${-borderWidth}px`,
      background: "none",
      position: "relative",
    };
    // This div is the DropTargetComponent alternative for the page view
    // DropTargetComponent and this div are responsible for the canvas height
    return (
      <div className={getCanvasClassName()} style={style}>
        {this.renderAsContainerComponent(this.getCanvasProps())}
      </div>
    );
  }

  getCanvasView() {
    if (this.props.dropDisabled) {
      return this.getPageView();
    } else {
      return this.renderAsDropTarget();
    }
  }

  static computeMinHeightFromProps = (
    props: Omit<ContainerWidgetProps, "children">,
    widgets: CanvasWidgetsReduxState | FlattenedWidgetLayoutMap,
    _theme: GeneratedTheme,
    _appMode: APP_MODE,
    _dynamicVisibilty: DynamicWidgetsVisibilityState,
  ): Dimension<"px"> => {
    return getCanvasMinHeightFlattened(props, widgets);
  };

  static applyActionHook: WidgetActionHook = function* (params: {
    widgetId: string;
    widgets: Readonly<CanvasWidgetsReduxState>;
    action: ReduxAction<UpdateWidgetPropertiesPayload | WidgetAddChild>;
    originalWidgetValues: PartialWidgetProps;
    flags: Flags;
  }): Generator<any, any, any> {
    const { widgetId, widgets, action, originalWidgetValues } = params;
    if (widgets[widgetId].type !== WidgetTypes.CANVAS_WIDGET) {
      return;
    }

    const updates: WidgetActionResponse = [];
    const widget = widgets[widgetId] as unknown as Readonly<CanvasWidgetProps>;
    const parent = widgets[widget.parentId];

    switch (action.type) {
      case updateWidgetProperties.type: {
        if (!updateWidgetProperties.match(action)) break;
        // CHECK FOR QUICK STYLE CHANGES
        // Make Mark fix this, it's kinda hacky
        if (action.payload.widgetId === widgetId) {
          const containerStylesChanged =
            action.payload.updates.containerStyle != null;
          if (
            (parent.type === WidgetTypes.CONTAINER_WIDGET ||
              parent.type === WidgetTypes.FORM_WIDGET) &&
            containerStylesChanged
          ) {
            // Clear overrides such as padding and background color
            const updatedWidget: CanvasWidgetProps = {
              ...widget,
              padding: undefined,
            };
            updates.push({
              widgetId: widgetId,
              widget: updatedWidget,
            });

            // Clear overrides such as border and background color
            // Also set the containerStyle to the parent so they are in sync
            updates.push({
              widgetId: parent.widgetId,
              widget: {
                ...parent,
                backgroundColor: undefined,
                border: undefined,
                borderRadius: undefined,
                containerStyle: action.payload.updates.containerStyle,
              } as ContainerWidgetProps,
            });
          }
        }

        // CHECK FOR LAYOUT CHANGES
        if (action.payload.widgetId === widgetId) {
          const { switchedToGrid, switchedToStack } = didLayoutSwitch(
            action,
            originalWidgetValues,
          );
          // We must reconfigure as the first step to ensure that the widgets
          // have the correct heights before we then resize the section height
          if (switchedToGrid) {
            const dynamicWidgetLayout: ReturnType<
              typeof getDynamicLayoutWidgets
            > = yield select(getDynamicLayoutWidgets);

            const widgetsRuntime: CanvasWidgetsReduxState = yield select(
              getFlattenedCanvasWidgets,
            );

            // FIXME: This should not modify the widgets in place. It should follow the pattern
            // of returning an array of updates. Alternatively, we could make all
            // widget hooks modify the widgets in place given they are passed the widgets proxy

            repositionWidgetsFromStackIntoFixedGrid({
              widgets,
              dynamicWidgetLayout,
              canvasWidgetId: widget.widgetId,
              canvasWidgetParentColumnSpace:
                widgetsRuntime[widget.widgetId].parentColumnSpace ||
                CanvasDefaults.MIN_GRID_UNIT_WIDTH,
              previousLayout: originalWidgetValues.layout,
            });
          } else if (switchedToStack) {
            let updatedWidget: CanvasWidgetProps = {
              ...widget,
            };

            // set default spacing if not set
            if (!widget.spacing) {
              updatedWidget.spacing = CanvasDefaults.SPACING;
            }

            // set default alignment and distribution for the stack
            updatedWidget = {
              ...updatedWidget,
              ...LAYOUT_DEFAULTS[widget.layout as CanvasLayout],
              ...(action.payload.updates.alignment
                ? {
                    alignment: action.payload.updates
                      .alignment as CanvasAlignment,
                  }
                : {}),
            };

            if (
              parent?.width?.mode === "fitContent" &&
              widget.layout === CanvasLayout.VSTACK &&
              updatedWidget.alignment === CanvasAlignment.STRETCH
            ) {
              // TODO this correction should happen in a single controlled place
              updatedWidget = {
                ...updatedWidget,
                alignment: CanvasAlignment.LEFT,
              };
            }

            // only try to sort if are also no explicit updates to the children
            if (
              widget.children != null &&
              action.payload.updates.children == null
            ) {
              updatedWidget = {
                ...updatedWidget,
                children: getSortedWidgetOrder(
                  widget.children,
                  widgets,
                  widget.layout as CanvasLayout,
                  originalWidgetValues.layout as CanvasLayout,
                ) as CanvasWidgetProps["children"],
              };
            }

            updates.push({
              widgetId: widgetId,
              widget: updatedWidget,
            });
          }
        }

        // CHECK FOR HEIGHT CHANGES
        // we need to update for both parent and child
        if (
          action.payload.widgetId === widgetId ||
          action.payload.widgetId === widget.parentId
        ) {
          const hasUpdateRequiringSectionHeightUpdate = Object.keys(
            action.payload.updates,
          ).some((update) =>
            PROPERTIES_REQUIRING_COLUMN_HEIGHT_UPDATE.has(
              update as keyof WidgetProps,
            ),
          );
          if (hasUpdateRequiringSectionHeightUpdate) {
            // Otherwise we made some other property upate that requires resizing the section

            // But first ensure it wasn't a change to min or max height value
            // If we're changing min or max height, we need to be turning on or off, not just updating the value
            // For the value update, the saga where that happens handles calling updateSectionWidgetCanvasHeights
            const incomingMinHeight = action.payload.updates.minHeight;
            const incomingMaxHeight = action.payload.updates.maxHeight;
            if (
              (incomingMinHeight &&
                (incomingMinHeight !== undefined ||
                  originalWidgetValues.minHeight !== undefined)) ||
              (incomingMaxHeight &&
                (incomingMaxHeight !== undefined ||
                  originalWidgetValues.maxHeight !== undefined))
            ) {
              return;
            }

            const appMode = yield select(getAppMode) ?? APP_MODE.PUBLISHED;
            const theme = yield select(selectGeneratedTheme);
            updateSectionWidgetCanvasHeights(widgets, theme, appMode, parent);
          }
        }

        // CHECK FOR ALIGNMENT CHANGES
        const alignmentUpdate = action.payload.updates.alignment;
        if (
          action.payload.widgetId === widgetId &&
          alignmentUpdate &&
          alignmentUpdate === CanvasAlignment.STRETCH
        ) {
          for (const childId of widget.children || []) {
            const child = widgets[childId];

            // TODO: Layouts - If alignment is stretch, we need to update container children otherwise their children won't have the right
            // canvas space to work with. Ideally we find a better solution for this as if the user goes back to a non-stretch alignment
            // these container children will still be full width
            if (
              widget.gridColumns &&
              ATTACHED_WIDGET_AND_CAN_HAVE_CHILDREN.includes(
                child.type as WidgetTypes,
              )
            ) {
              updates.push({
                widgetId: childId,
                widget: {
                  ...child,
                  width: Dimension.gridUnit(widget.gridColumns),
                  gridColumns: widget.gridColumns,
                },
              });
            }
          }
        }

        break;
      }
      default:
        break;
    }
    return updates;
  };
}

const mapStateToProps = (state: AppState) => {
  return {
    generatedTheme: selectGeneratedTheme(state),
  };
};

const connector = connect(mapStateToProps);

export const ConnectedCanvasWidget = connector(CanvasWidget as any);
export default CanvasWidget;
