import { ApplicationScope } from "@superblocksteam/shared";
import {
  restartEvaluation,
  stopEvaluation,
} from "legacy/actions/evaluationActions";
import {
  ReduxAction,
  ReduxActionTypes,
} from "legacy/constants/ReduxActionConstants";
import { emptyDataTree } from "legacy/entities/DataTree/DataTreeHelpers";
import { DataTree } from "legacy/entities/DataTree/dataTreeFactory";
import {
  ReferenceManager,
  ReferenceMetaPayload,
} from "legacy/workers/utils/ReferenceManager";
import { createImmerReducer } from "../createReducer";

export type EvaluatedTreeState = DataTree;

export type SetEvaluatedTreeAction = ReduxAction<
  {
    dataTree: DataTree;
    referenceMeta?: ReferenceMetaPayload;
  },
  typeof ReduxActionTypes.SET_EVALUATED_TREE
>;

const initialState: EvaluatedTreeState = emptyDataTree();

const evaluatedTreeReducer = createImmerReducer(initialState, (builder) =>
  builder
    .addCase(
      ReduxActionTypes.SET_EVALUATED_TREE,
      (state: EvaluatedTreeState, action: SetEvaluatedTreeAction) => {
        const referenceMeta = action.payload.referenceMeta;
        const dataTree = referenceMeta
          ? ReferenceManager.rehydrateReferences(
              action.payload.dataTree,
              referenceMeta,
            )
          : action.payload.dataTree;
        for (const info of dataTree.deletedEntities ?? []) {
          delete state[info.scope][info.name];
        }

        // Warning - this is a performance critical section. Large tree updates
        // can cause significant performance issues, do not modify without profiling
        for (const possibleScope in dataTree) {
          if (possibleScope === "deletedEntities") continue;
          const scope = possibleScope as unknown as ApplicationScope;
          const scopedTree = dataTree[scope];
          if (!scopedTree) continue;
          for (const entityName in scopedTree) {
            state[scope][entityName] = Object.freeze(
              scopedTree[entityName],
            ) as any;
          }
        }
      },
    )
    .addCase(ReduxActionTypes.FETCH_PAGE_INIT, (state: EvaluatedTreeState) => {
      state[ApplicationScope.PAGE] = initialState[ApplicationScope.PAGE];
    })
    .addCase(restartEvaluation, (state: EvaluatedTreeState) => {
      state[ApplicationScope.PAGE] = initialState[ApplicationScope.PAGE];
    })
    .addCase(stopEvaluation, () => initialState),
);

export default evaluatedTreeReducer;
