import { Border, Dimension, PerSideBorder } from "@superblocksteam/shared";
import { PropertyType } from "./types";

export const KEY_VALUE_WIDGET_PROPERTY_TYPES = [
  {
    label: "Plain text",
    value: PropertyType.TEXT,
  },
  {
    label: "Number",
    value: PropertyType.NUMBER,
  },
  {
    label: "Currency",
    value: PropertyType.CURRENCY,
  },
  {
    label: "Percentage",
    value: PropertyType.PERCENTAGE,
  },
  {
    label: "Image",
    value: PropertyType.IMAGE,
  },
  {
    label: "Video",
    value: PropertyType.VIDEO,
  },
  {
    label: "Date",
    value: PropertyType.DATE,
  },
  {
    label: "Button",
    value: PropertyType.BUTTON,
  },
  {
    label: "Link",
    value: PropertyType.LINK,
  },
  {
    label: "Boolean",
    value: PropertyType.BOOLEAN,
  },
  {
    label: "Tags",
    value: PropertyType.TAGS,
  },
  {
    label: "Email",
    value: PropertyType.EMAIL,
  },
];

const DEFAULT_BORDER = {
  width: Dimension.px(1),
  style: "solid",
  color: "{{ theme.colors.neutral100 }}",
} satisfies Border;

export const DEFAULT_BORDER_OBJECT: PerSideBorder = {
  left: DEFAULT_BORDER,
  right: DEFAULT_BORDER,
  top: DEFAULT_BORDER,
  bottom: DEFAULT_BORDER,
};
