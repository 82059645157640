import {
  SHOW_LOADING_STATE_PARAM,
  SHOW_SUPERBLOCKS_HEADER_PARAM,
} from "constants/pageSearchParams";

const isSuperblocksApp = (source: string) => {
  // ee support
  const eePattern = /^https:\/\/pr-\d+\.superblocks\.dev/;

  const superblocks_env_urls = [
    // dev
    "https://dev.superblocks.com",
    // staging
    "https://staging.superblockshq.com",
    "https://staging.superblocks.com",
    // prod
    "https://app.superblockshq.com",
    "https://app.superblocks.com",
    "https://eu.superblocks.com",
  ];

  return (
    superblocks_env_urls.some((app) => source.startsWith(app)) ||
    eePattern.test(source)
  );
};

export function formatEmbeddedAppUrl(source: string) {
  if (isSuperblocksApp(source)) {
    const url = new URL(source);
    const searchParams = url.searchParams;
    const hasShowLoadingState = searchParams.has(SHOW_LOADING_STATE_PARAM);
    if (!hasShowLoadingState) {
      searchParams.set(SHOW_LOADING_STATE_PARAM, "false");
    }
    const hasShowSuperblocksHeader = searchParams.has(
      SHOW_SUPERBLOCKS_HEADER_PARAM,
    );
    if (!hasShowSuperblocksHeader) {
      searchParams.set(SHOW_SUPERBLOCKS_HEADER_PARAM, "false");
    }
    return url.toString();
  }
  return source;
}
