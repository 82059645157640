import unescapeJS from "unescape-js";

/**
 * Transforms a derived property value by:
 * 1. Replacing 'this.' references with the entity name
 * 2. Unescaping special characters
 */
export function transformDerivedPropertyValue(
  derivedProperty: string,
  entityName: string,
): string {
  // TODO regex is too greedy
  const dpv = derivedProperty.replace(/this./g, `${entityName}.`);

  // TODO: we could remove the `unescapeJS` function from here, once we:
  // 1. Refactor derived properties to remove the escaped values (such as \\n)
  // 2. Then we could do `derivedProps[propertyName] = propertyValue;`
  return unescapeJS(dpv);
}

export type DerivedSymbol = {
  $$derived: true;
};

export const DERIVED_SYMBOL = Object.freeze({
  $$derived: true,
});

export function isDerivedSymbol(value: any): value is DerivedSymbol {
  return (
    typeof value === "object" &&
    value !== null &&
    value.$$derived === true &&
    Object.keys(value).length === 1
  );
}
