import { ApplicationScope } from "@superblocksteam/shared";
import { debounce } from "lodash";
import React, { lazy, Suspense } from "react";
import { Converter } from "showdown";
import {
  getDerivedPropertiesWithErrors,
  getErrorMessagesList,
} from "legacy/components/editorComponents/ErrorUtils";
import Skeleton from "legacy/components/utils/Skeleton";
import { EventType } from "legacy/constants/ActionConstants";
import { type PropertyPaneConfig } from "legacy/constants/PropertyControlConstants";
import {
  WidgetLabelPosition,
  WidgetType,
} from "legacy/constants/WidgetConstants";
import { VALIDATION_TYPES } from "legacy/constants/WidgetValidation";
import {
  WidgetPropertyValidationType,
  BASE_WIDGET_VALIDATION,
} from "legacy/constants/WidgetValidation";
import { retryPromise } from "legacy/utils/Utils";
import { createRunEventHandlersPayloadOptional } from "legacy/utils/actions";
import BaseWidget from "../BaseWidget";
import { DerivedPropertiesMap } from "../Factory";
import withMeta from "../withMeta";
import RichTextEditorPropertyCategories from "./RichTextEditorPropertyCategories";
import { RichTextEditorWidgetProps } from "./types";

const RichTextEditorComponentWithManagedLayout = lazy(() =>
  retryPromise(
    () => import(/* webpackChunkName: "rte" */ "./RichTextEditorComponent"),
  ),
);

const converter = new Converter();
converter.setOption("simpleLineBreaks", true);

class RichTextEditorWidget extends BaseWidget<
  RichTextEditorWidgetProps,
  { localValue: string; isFocused: boolean }
> {
  static getNewPropertyPaneConfig():
    | PropertyPaneConfig<RichTextEditorWidgetProps>[]
    | undefined {
    return RichTextEditorPropertyCategories;
  }

  static getPropertyPaneConfig(): PropertyPaneConfig[] {
    throw new Error("Deprecated config should not be called");
  }

  static getDerivedPropertiesMap(): DerivedPropertiesMap {
    return getDerivedPropertiesWithErrors({
      validationErrors: `{{
        (() => {
          const errors = {};

          if (this.isRequired && !this.value && !this.value.length) {
            errors.isRequired = "Required";
          }
          if (
            this.customValidationRule &&
            this.customValidationRule.length &&
            this.customValidationRule === "false"
          ) {
            errors.customValidationRule = this.customErrorMessage || "Invalid";
          }
          return errors;
        })()
      }}`,
    });
  }

  static getPropertyValidationMap(): WidgetPropertyValidationType {
    return {
      ...BASE_WIDGET_VALIDATION,
      value: VALIDATION_TYPES.TEXT,
      defaultValue: VALIDATION_TYPES.TEXT,
      enableMenuBar: VALIDATION_TYPES.BOOLEAN,
      convertMarkdown: VALIDATION_TYPES.BOOLEAN,
      customValidationRule: VALIDATION_TYPES.TEXT,
      customErrorMessage: VALIDATION_TYPES.TEXT,
      animateLoading: VALIDATION_TYPES.BOOLEAN,
      label: VALIDATION_TYPES.TEXT,
      isRequired: VALIDATION_TYPES.BOOLEAN,
    };
  }

  static getMetaPropertiesMap(): Record<string, any> {
    return {
      value: undefined,
      isTouched: false,
    };
  }

  static getDefaultPropertiesMap(): Record<string, string> {
    return {
      value: "defaultValue",
    };
  }

  constructor(props: RichTextEditorWidgetProps) {
    super(props);
    // If there is a meta prop already set, use it.
    this.state = {
      localValue: props.value ?? props.defaultValue ?? "",
      isFocused: false,
    };
  }

  componentDidMount = () => {
    if (this.props.convertMarkdown) {
      this.onValueChange(
        converter.makeHtml(String(this.props.defaultValue ?? "")).toString(),
        false,
      );
    }
  };

  componentDidUpdate = (prevProps: Readonly<RichTextEditorWidgetProps>) => {
    // Reset local state if the value has updated via default value
    if (
      prevProps.defaultValue !== this.props.defaultValue ||
      prevProps.convertMarkdown !== this.props.convertMarkdown
    ) {
      if (this.props.convertMarkdown) {
        this.onValueChange(
          converter.makeHtml(String(this.props.defaultValue ?? "")).toString(),
          false,
        );
      } else {
        this.onValueChange(this.props.defaultValue ?? "", false);
      }
    }
    if (
      prevProps.value !== this.props.value &&
      this.props.value !== this.state.localValue &&
      !this.state.isFocused
    ) {
      this.setState({ localValue: this.props.value ?? "" });
    }
  };

  onValueChange = (content: string, wasUserInput: boolean = true) => {
    this.setState({ localValue: content });
    this.onValueChangeDebounced(content, wasUserInput);
    if (!this.props.isTouched && wasUserInput) {
      this.props.updateWidgetMetaProperty("isTouched", true);
    }
  };

  onValueChangeDebounced = debounce((text: string, wasUserInput: boolean) => {
    this.props.updateWidgetMetaProperty(
      "value",
      text,
      createRunEventHandlersPayloadOptional({
        steps: this.props.onTextChanged,
        currentScope: ApplicationScope.PAGE,
        type: EventType.ON_TEXT_CHANGE,
        entityName: this.props.widgetName,
      }),
    );
  }, 200);

  componentWillUnmount() {
    this.onValueChangeDebounced.flush();
  }

  onFocus = () => {
    this.setState({ isFocused: true });
  };

  onBlur = () => {
    this.setState({ isFocused: false });
    if (!this.props.isTouched) {
      this.props.updateWidgetMetaProperty("isTouched", true);
    }
  };

  getPageView() {
    return (
      <Suspense fallback={<Skeleton />}>
        <RichTextEditorComponentWithManagedLayout
          {...this.props}
          onValueChange={this.onValueChange}
          currentValue={this.state?.localValue || ""}
          onBlur={this.onBlur}
          onFocus={this.onFocus}
          showError={this.props.showError}
          errorMessages={getErrorMessagesList(
            this.props.validationErrors,
            this.props.showError,
          )}
          vertical={
            !this.props.labelProps?.position ||
            this.props.labelProps?.position === WidgetLabelPosition.TOP
          }
          isFocused={this.state.isFocused}
        />
      </Suspense>
    );
  }

  getWidgetType(): WidgetType {
    return "RICH_TEXT_EDITOR_WIDGET";
  }
}

export default RichTextEditorWidget;
export const ConnectedRichTextEditorWidget = withMeta(RichTextEditorWidget);
