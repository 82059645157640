import {
  Organization,
  GetGroupsResponseDto,
  GroupBrief,
  GroupType,
  TASKS,
  OrganizationUserDto,
  RoleTypeEnum,
  AssignmentDto,
} from "@superblocksteam/shared";
import Modal from "antd/lib/modal";
import React, {
  useCallback,
  useEffect,
  useState,
  useMemo,
  useRef,
} from "react";
import { useSelector } from "react-redux";
import { PrimaryButton, SecondaryButton } from "components/ui/Button";
import { FormItem, FormWrapper } from "components/ui/Form";
import {
  FooterWrapperWide,
  ModalInnerWrapper,
  ModalWrapClass,
} from "components/ui/Modal";
import { MANAGE_GROUP_MEMBERS } from "constants/rbac";
import { useFeatureFlag } from "hooks/ui";
import { useAuthorizationCheck } from "hooks/ui/rbac/useAuthorizationCheck";
import { useMarkTaskComplete } from "hooks/ui/useCheckTask";
import { User } from "legacy/constants/userConstants";
import { DescriptionWrapper } from "legacy/pages/Editor/Explorer/SidebarHeader";
import { getCurrentUser } from "legacy/selectors/usersSelectors";
import { RoleDropdown } from "pages/Permissions/RoleDropdown";
import { Invitee, InviteeToSend } from "pages/Permissions/constants";
import { Flag } from "store/slices/featureFlags";
import { selectOnlyOrganization } from "store/slices/organizations";
import { callServer, HttpMethod } from "store/utils/client";
import { HttpError } from "store/utils/types";
import { sendSuccessUINotification } from "utils/notification";
import SearchAndInvite from "../SearchAndInviteV2";
import AddItemsInput from "./AddItemsInput";
import { sendOrgInvitation } from "./utils";

interface Props {
  isVisible: boolean;
  onClose: () => void;
  onUsersAdded?: (
    users: OrganizationUserDto[],
    operation: "concat" | "replace",
  ) => void;
}

const InviteOrgUserModal = ({ isVisible, onClose, onUsersAdded }: Props) => {
  const organization = useSelector(selectOnlyOrganization) as Organization;
  const currentUser = useSelector(getCurrentUser) as User;
  const organizationId = currentUser.currentOrganizationId;

  const [emails, setEmails] = useState<string[]>([]);
  const [groupIds, setGroupIds] = useState<string[]>([]);
  const [emailsError, setEmailsError] = useState<string | undefined>(undefined);
  const [inviteError, setInviteError] = useState<string | undefined>(undefined);
  const [roleError, setRoleError] = useState<string | undefined>(undefined);

  const cleanStates = useCallback(() => {
    setEmails([]);
    setEmailsError(undefined);
    setGroupIds([]);
    setInviteError(undefined);
  }, []);
  const rbacV2Enabled = useFeatureFlag(Flag.ENABLE_RBAC_V2);

  const [canAddToGroup] = useAuthorizationCheck([MANAGE_GROUP_MEMBERS]);

  const [selectedRole, setSelectedRole] = useState<string | null>(null);

  // User groups
  const [groups, setGroups] = useState<GroupBrief[]>([]);
  const groupOptions = useMemo(
    () =>
      groups
        .filter((group) => {
          if (group.type === GroupType.EVERYONE) {
            return false;
          }
          if (rbacV2Enabled) {
            return canAddToGroup;
          }

          return group.requestingUserIsMember || currentUser.isAdmin;
        })
        .map(
          (group) =>
            ({
              name: group.name,
              type: "group",
              id: group.id,
              size: group.size,
            }) as Invitee,
        ),
    [currentUser?.isAdmin, groups, canAddToGroup, rbacV2Enabled],
  );
  const handleGroupsChange = useCallback((invitees: InviteeToSend[]) => {
    setGroupIds(invitees.map((g) => g.id));
  }, []);

  const fetchGroups = useCallback(async () => {
    function getGroups(orgId: string) {
      return callServer<GetGroupsResponseDto>({
        method: HttpMethod.Get,
        url: `v1/organizations/${organizationId}/groups`,
      });
    }

    const getGroupsResponseDto = await getGroups(organizationId);
    setGroups(getGroupsResponseDto.groups);
  }, [organizationId]);

  const init = useCallback(async () => {
    fetchGroups();
  }, [fetchGroups]);

  useEffect(() => {
    if (isVisible) {
      // reload users list on modal open
      init();
    }
  }, [isVisible, init]);

  const [checkTask] = useMarkTaskComplete(TASKS.INVITE_TEAMMATE);

  const [isSending, setIsSending] = useState(false);

  const validate = useCallback(() => {
    let validated = true;
    if (emails.length === 0) {
      setEmailsError("Name is required");
      validated = false;
    }
    if (rbacV2Enabled && !selectedRole) {
      setRoleError("Role is required");
      validated = false;
    }
    return validated;
  }, [emails.length, rbacV2Enabled, selectedRole]);

  const handleOk = useCallback(async () => {
    if (!validate()) {
      return;
    }
    setIsSending(true);
    try {
      let maybeError: HttpError | undefined;
      const onError = (e: HttpError) => {
        maybeError = e;
      };
      const invitationResponse = await sendOrgInvitation({
        userEmails: emails,
        organizationId,
        groupIds,
        onError,
        roleId: selectedRole ?? undefined,
      });
      if (maybeError) {
        setInviteError(maybeError.message);
      } else {
        const { users, error } = invitationResponse;
        if (error) {
          setInviteError(error ?? "Failed to send invites");
          return;
        }
        // without await, event will not be sent
        await checkTask();

        const usersWithAssignments = rbacV2Enabled
          ? users.map((user) => ({
              ...user,
              assignments: [
                {
                  assignmentId: selectedRole,
                },
              ] as unknown as AssignmentDto[],
            }))
          : users;
        onUsersAdded?.(
          usersWithAssignments,
          rbacV2Enabled ? "concat" : "replace",
        );
        onClose();

        sendSuccessUINotification({
          message: "Invites sent",
        });

        cleanStates();
      }
    } catch {
      setInviteError("Failed to send invites");
    }
    setIsSending(false);
  }, [
    validate,
    emails,
    organizationId,
    groupIds,
    checkTask,
    onUsersAdded,
    onClose,
    cleanStates,
    selectedRole,
    rbacV2Enabled,
  ]);

  const handleCancel = useCallback(() => {
    cleanStates();
    onClose();
  }, [cleanStates, onClose]);

  const modalRef = useRef(null);

  return (
    <Modal
      open={isVisible}
      onCancel={handleCancel}
      title={`Invite users to ${organization.name}`}
      onOk={handleOk}
      footer={null}
      destroyOnClose={true}
      wrapClassName={ModalWrapClass}
      width={450}
    >
      <div className={ModalInnerWrapper} ref={modalRef}>
        <div className={FormWrapper}>
          <FormItem
            label="Email addresses"
            error={emailsError}
            required={true}
            dataTest={"invite-org-user-email-input"}
          >
            <AddItemsInput
              items={emails}
              setItems={setEmails}
              placeholder="Invite by email address..."
            />
          </FormItem>
          {rbacV2Enabled && (
            <FormItem
              label="Organization role"
              error={roleError}
              required={true}
            >
              <RoleDropdown
                selectedRole={selectedRole}
                onRoleChanged={setSelectedRole}
                isClearable={false}
                isDisabled={false}
                roleType={RoleTypeEnum.ORGANIZATION}
                parentRef={modalRef}
                shouldAutoUpdate={true}
              />
              <DescriptionWrapper>
                Manage user access with roles.
              </DescriptionWrapper>
            </FormItem>
          )}
          {groupOptions.length > 0 && (
            <FormItem label="Groups" required={false}>
              <SearchAndInvite
                allInvitees={groupOptions}
                onSelectedInviteesChange={handleGroupsChange}
                parentRef={modalRef}
                noGrouping={true}
                placeholder="Enter group names"
                noResultsMessage={"No matching groups"}
                popoverProps={{
                  hasBackdrop: true,
                }}
              />
            </FormItem>
          )}
          <FormItem error={inviteError} hidden={!inviteError} />
        </div>
        <div className={FooterWrapperWide}>
          <SecondaryButton
            data-test="cancel-create-token-button"
            onClick={handleCancel}
            loading={isSending}
          >
            Cancel
          </SecondaryButton>
          <PrimaryButton
            type="primary"
            onClick={handleOk}
            data-test="invite-org-user-submit"
            loading={isSending}
          >
            Invite users
          </PrimaryButton>
        </div>
      </div>
    </Modal>
  );
};

export default InviteOrgUserModal;
