import React, { Suspense } from "react";
import shallowEqual from "shallowequal";
import Skeleton from "legacy/components/utils/Skeleton";
import { EventType } from "legacy/constants/ActionConstants";
import { type PropertyPaneConfig } from "legacy/constants/PropertyControlConstants";
import { WidgetType } from "legacy/constants/WidgetConstants";
import { VALIDATION_TYPES } from "legacy/constants/WidgetValidation";
import {
  WidgetPropertyValidationType,
  BASE_WIDGET_VALIDATION,
} from "legacy/constants/WidgetValidation";

import BaseWidget, { WidgetState } from "../BaseWidget";
import withMeta from "../withMeta";
import { CalloutComponentWithLayoutManaged } from "./CalloutComponent";
import CalloutWidgetPropertyCategories from "./CalloutWidgetPropertyCategories";

import type { CalloutWidgetProps } from "./types";

class CalloutWidget extends BaseWidget<CalloutWidgetProps, WidgetState> {
  static getNewPropertyPaneConfig():
    | PropertyPaneConfig<CalloutWidgetProps>[]
    | undefined {
    return CalloutWidgetPropertyCategories;
  }

  static getPropertyPaneConfig(): PropertyPaneConfig[] {
    throw new Error("Deprecated config should not be called");
  }

  static getPropertyValidationMap(): WidgetPropertyValidationType {
    return {
      ...BASE_WIDGET_VALIDATION,
      title: VALIDATION_TYPES.TEXT,
      description: VALIDATION_TYPES.TEXT,
      ctaText: VALIDATION_TYPES.TEXT,
      backgroundColor: VALIDATION_TYPES.TEXT,
      border: VALIDATION_TYPES.OBJECT_OR_UNDEFINED,
      borderRadius: VALIDATION_TYPES.OBJECT_OR_UNDEFINED,
    };
  }

  static getMetaPropertiesMap(): Record<string, any> {
    return {};
  }

  static getDefaultPropertiesMap(): Record<string, string> {
    return {};
  }

  shouldComponentUpdate(nextProps: CalloutWidgetProps) {
    return !shallowEqual(nextProps, this.props);
  }

  onDismiss = () => {
    const { onDismiss, updateWidgetMetaProperty } = this.props;
    updateWidgetMetaProperty("isVisible", false);
    if (onDismiss)
      super.runEventHandlers({
        steps: onDismiss,
        type: EventType.ON_CALLOUT_DISMISS,
      });
  };

  onCtaClick = () => {
    const { onCtaClick } = this.props;
    if (onCtaClick)
      super.runEventHandlers({
        steps: onCtaClick,
        type: EventType.ON_CALLOUT_CTA_CLICK,
      });
  };

  getPageView() {
    const { isLoading } = this.props;

    if (isLoading) return <Skeleton />;

    return (
      <Suspense fallback={<Skeleton />}>
        <CalloutComponentWithLayoutManaged
          {...this.props}
          onCtaClick={this.onCtaClick}
          onDismiss={this.onDismiss}
        />
      </Suspense>
    );
  }

  getWidgetType(): WidgetType {
    return "CALLOUT_WIDGET";
  }
}

export default CalloutWidget;
export const ConnectedCalloutWidget = withMeta(CalloutWidget);
