import React from "react";
import { getPositionInPage, sendMessage } from "utils/iframe";
import { PasteInsertionIndexes } from "../hooks/ui/usePasteWidget";

class IframeClickHandlerEventBus extends EventTarget {}

export const iframeClickHandlerEventBusInstance =
  new IframeClickHandlerEventBus();

export enum IframeClickHandlerEvent {
  OPEN_COMPONENT_MENU = "OPEN_COMPONENT_MENU",
  OPEN_CONTEXT_MENU = "OPEN_CONTEXT_MENU",
}

export type IframeClickHandlerEventDetails = {
  type: IframeClickHandlerEvent;
  context: {
    clientX: number;
    clientY: number;
    widgetId: string;
    insertionIndexes?: PasteInsertionIndexes;
  };
};

/**
 * Register a click event in the iframe
 * @param event - The click event
 * @param widgetId - The id of the widget where the click happened
 * @param insertionIndexes - The insertion indexes to where the widget should be inserted
 */
export const registerIframeClickEvent = (
  event: React.MouseEvent,
  widgetId: string,
  insertionIndexes?: PasteInsertionIndexes,
) => {
  let iframeClickEventType: IframeClickHandlerEvent | undefined = undefined;

  if (event.type === "contextmenu") {
    // Right click opens context menu
    iframeClickEventType = IframeClickHandlerEvent.OPEN_CONTEXT_MENU;
  } else if (event.altKey) {
    // Alt + click opens add component menu
    iframeClickEventType = IframeClickHandlerEvent.OPEN_COMPONENT_MENU;
  }

  if (!iframeClickEventType) {
    // do not handle any other click events
    return;
  }

  sendMessage({
    type: "register-click",
    payload: {
      context: {
        widgetId,
        clientX: event.clientX,
        clientY: event.clientY,
        insertionIndexes,
      },
      type: iframeClickEventType,
    },
  });
};

/**
 * App handler for iframe click events.
 * @param type {IframeClickHandlerEvent} - The type of the click event.
 * @param context {object} - The context of the click event.
 * @param context.clientX {number} - The x coordinate of the click event.
 * @param context.clientY {number} - The y coordinate of the click event.
 * @param context.widgetId {string} - The id of the widget where the click happened.
 * @param context.insertionIndexes {PasteInsertionIndexes} - The insertion indexes if it applies.
 */
export const handleIframeClickEvent = ({
  type,
  context,
}: IframeClickHandlerEventDetails) => {
  // Adjust the x,y based on iframe location
  const { x, y } = getPositionInPage(context.clientX, context.clientY);
  iframeClickHandlerEventBusInstance.dispatchEvent(
    new CustomEvent<IframeClickHandlerEventDetails>(type, {
      detail: {
        type,
        context: { ...context, clientX: x, clientY: y },
      },
    }),
  );
};
