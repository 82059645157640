import React from "react";
import { PropertyPaneConfig } from "legacy/constants/PropertyControlConstants";
import { WidgetType } from "legacy/constants/WidgetConstants";
import {
  BASE_WIDGET_VALIDATION,
  VALIDATION_TYPES,
  WidgetPropertyValidationType,
} from "legacy/constants/WidgetValidation";

import BaseWidget, { RunWidgetEventHandlers } from "../BaseWidget";
import { DerivedPropertiesMap } from "../Factory";
import withMeta from "../withMeta";
import { KeyValueComponent } from "./KeyValueComponent";
import KeyValueWidgetPropertyCategories from "./KeyValueWidgetPropertyCategories";
import { KeyValueWidgetProps } from "./types";

class KeyValueWidget extends BaseWidget<KeyValueWidgetProps, never> {
  runEventHandlersBound: (payload: RunWidgetEventHandlers) => void;

  constructor(props: KeyValueWidgetProps) {
    super(props);
    this.runEventHandlersBound = this.runEventHandlers.bind(this);
  }

  getPageView() {
    return (
      <KeyValueComponent
        keyValueData={this.props.keyValueData}
        properties={this.props.properties}
        styleProps={this.props.styleProps}
        propertiesOrder={this.props.propertiesOrder}
        keyProps={this.props.keyProps}
        valueProps={this.props.valueProps}
        runEventHandlers={this.runEventHandlersBound}
      />
    );
  }

  static getDerivedPropertiesMap(): DerivedPropertiesMap {
    return {
      data: /*javascript*/ `{{ Object.values(this.properties).reduce((accum, property) => {
        accum[property.key] = property.computedValue || this.keyValueData[property.key];
        return accum;
      }, {}) }}`,
    };
  }

  static getPropertyValidationMap(): WidgetPropertyValidationType {
    return {
      ...BASE_WIDGET_VALIDATION,
      keyValueData: VALIDATION_TYPES.OBJECT_OR_UNDEFINED,
    };
  }

  getWidgetType(): WidgetType {
    return "KEY_VALUE_WIDGET";
  }

  static getNewPropertyPaneConfig():
    | PropertyPaneConfig<KeyValueWidgetProps>[]
    | undefined {
    return KeyValueWidgetPropertyCategories;
  }

  static getPropertyPaneConfig(): PropertyPaneConfig<KeyValueWidgetProps>[] {
    throw new Error("Deprecated config should not be called");
  }
}

export default KeyValueWidget;

export const ConnectedKeyValueWidget = withMeta(KeyValueWidget);
