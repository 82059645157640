import { ColumnProperties } from "legacy/widgets/TableWidget/TableComponent/Constants";

export const getColumnIdForAi = (
  columnId: string,
  columnConfig: ColumnProperties,
) => {
  if (!columnConfig?.isDerived) {
    return columnId;
  }
  // use the label or buton label
  const label = (columnConfig.label || columnConfig.buttonLabel) ?? columnId;
  // replace spaces and non underscore/letter/numbers with underscores
  const cleanedLabel = label
    .replace(/\s+/g, "_")
    .replace(/[^a-zA-Z0-9_]/g, "_");
  return cleanedLabel;
};

export const getColumnIdFromAi = (
  columnId: string,
  columns: Record<string, ColumnProperties>,
) => {
  // wasn't translated, just return the columnId
  if (columns[columnId]) {
    return columnId;
  }
  const matchedId = Object.keys(columns).find((key) => {
    const translatedId = getColumnIdForAi(key, columns[key]);
    return translatedId === columnId;
  });
  return matchedId ?? columnId;
};
