import { WidgetType } from "legacy/constants/WidgetConstants";
import { CalloutType } from "legacy/widgets/CalloutWidget/Constants";
import { TableFilters } from "legacy/widgets/TableWidget/TableFiltersConstants";

const FIELD_VALUES: Record<
  WidgetType | "API_ACTION",
  Record<string, string>
> = {
  API_ACTION: {
    body: "JSON",
    params: "string",
    headers: "string",
    path: "string",
  },
  CANVAS_WIDGET: {},
  PAGE_WIDGET: {},
  SECTION_WIDGET: {},
  ICON_WIDGET: {},
  SKELETON_WIDGET: {},
  CONTAINER_WIDGET: {
    backgroundColor: "string",
    isVisible: "boolean",
  },
  DATE_PICKER_WIDGET: {
    defaultDate: "string",
    isRequired: "boolean",
    isVisible: "boolean",
    isDisabled: "boolean",
    // onDateSelected: "Function Call",
  },
  TABLE_WIDGET: {
    tableData: "Array<Object>",
    isVisible: "boolean",
    exportPDF: "boolean",
    exportExcel: "boolean",
    exportCsv: "boolean",
    defaultSelectedRow: "string",
    defaultFilters: `{"byColumn": Object}`,
    // onRowSelected: "Function Call",
    // onPageChange: "Function Call",
    // onFiltersChanged: "Function Call",
  },
  VIDEO_WIDGET: {
    url: "string",
    autoPlay: "boolean",
    isVisible: "boolean",
  },
  IMAGE_WIDGET: {
    image: "string",
    defaultImage: "string",
    isVisible: "boolean",
    maxZoomLevel: "number",
    fillContainer: "boolean",
    borderRadius: "string",
  },
  RADIO_GROUP_WIDGET: {
    options: "Array<{ label: string, value: string }>",
    defaultOptionValue: "string",
    isRequired: "boolean",
    isVisible: "boolean",
    // onSelectionChange: "Function Call",
  },
  TABS_WIDGET: {
    tabs: "Array<{ label: string, id: string(unique), widgetId: string(unique) }>",
    selectedTab: "string",
    isVisible: "boolean",
  },
  CHART_WIDGET: {
    chartData: "Array<Object>",
    isVisible: "boolean",
    plotlyChartJson: "{ data: Array<Object>, layout: <Object>(optional)}",
    plotlyChartLayout: "Plotly Layout JSON Format",
    "y.minimumFractionDigits": "number between 0-20",
    "y.maximumFractionDigits": "number between 0-20",
  },
  MODAL_WIDGET: {
    canOutsideClickClose: "boolean",
  },
  SLIDEOUT_WIDGET: {
    canOutsideClickClose: "boolean",
    hasBackdrop: "boolean",
  },
  INPUT_WIDGET: {
    inputType: "string",
    placeholderText: "string",
    defaultText: "string",
    regex: "string",
    errorMessage: "string",
    isRequired: "boolean",
    isVisible: "boolean",
    isDisabled: "boolean",
    // onTextChanged: "Function Call",
  },
  DROP_DOWN_WIDGET: {
    label: "string",
    selectionType: "SINGLE_SELECT | MULTI_SELECT",
    options:
      "Array<string|number> | Array<{ label: string, value: string | number }> | Array<{ [key: string]: string | number }>",
    defaultOptionValue: "string | number | Array<string|number>",
    isRequired: "boolean",
    isVisible: "boolean",
    // onOptionChange: "Function Call",
    // onClear: "Function Call",
  },
  FORM_BUTTON_WIDGET: {
    text: "string",
    buttonStyle: "PRIMARY_BUTTON | SECONDARY_BUTTON | DANGER_BUTTON",
    disabledWhenInvalid: "boolean",
    resetFormOnClick: "boolean",
    isVisible: "boolean",
    // onClick: "Function Call",
  },
  MAP_WIDGET: {
    mapCenter: "{ lat: number, long: number }",
    defaultMarkers: "Array<{ lat: number, long: number, title?: string }>",
    enableSearch: "boolean",
    enablePickLocation: "boolean",
    enableCreateMarker: "boolean",
    isVisible: "boolean",
    // onMarkerClick: "Function Call",
    // onCreateMarker: "Function Call",
  },
  BUTTON_WIDGET: {
    text: "string",
    buttonStyle: "PRIMARY_BUTTON | SECONDARY_BUTTON | DANGER_BUTTON",
    isVisible: "boolean",
    isDisabled: "boolean",
    // onClick: "Function Call",
  },
  RICH_TEXT_EDITOR_WIDGET: {
    defaultValue: "string",
    isVisible: "boolean",
    isDisabled: "boolean",
    // onTextChanged: "Function Call",
  },
  FILE_PICKER_WIDGET: {
    selectionType: "number",
    allowedFileTypes: "Array<string>",
    isVisible: "boolean",
  },
  CHECKBOX_WIDGET: {
    label: "string",
    defaultCheckedState: "boolean",
    isRequired: "boolean",
    isDisabled: "boolean",
    isVisible: "boolean",
    // onCheckChange: "Function Call",
  },
  SWITCH_WIDGET: {
    label: "string",
    labelPosition: "LEFT | RIGHT",
    defaultSwitchState: "boolean",
    isToggledOn: "boolean",
    isDisabled: "boolean",
    isVisible: "boolean",
    isRequired: "boolean",
    // onSwitchChange: "Function Call",
  },
  FORM_WIDGET: {
    backgroundColor: "string",
    isVisible: "boolean",
  },
  TEXT_WIDGET: {
    text: "string",
    textAlign: "LEFT | CENTER | RIGHT",
    textStyle: "HEADING | LABEL | BODY",
    shouldScroll: "boolean",
    isVisible: "boolean",
    minimumFractionDigits: "number between 0-20",
    maximumFractionDigits: "number between 0-20",
  },
  GRID_WIDGET: {
    gridData: "Array<Object>",
    isVisible: "boolean",
  },
  CODE_WIDGET: {
    parsedValue: "string | object | array | number | null",
    stringValue: "string | null",
    initialValue: "string",
    isVisible: "boolean",
    isValid: "boolean",
  },
  PDF_VIEWER_WIDGET: {
    fileUrl: "string (URL | Base64)",
  },
  IFRAME_WIDGET: {
    source: "string (URL | Base64)",
    srcDoc: "string (HTML)",
  },
  DIFF_WIDGET: {
    originalLabel: "string",
    newLabel: "string",
    originalText: "string",
    newText: "string",
    isVisible: "boolean",
    isDisabled: "boolean",
    diffs: "array",
  },
  CHAT_WIDGET: {
    isVisible: "boolean",
    isDisabled: "boolean",
    header: "string",
    placeholderText: "string",
    data: "array",
    timestampField: "string | null",
    messageField: "string",
    userField: "string",
    avatarField: "string | null",
    timestampFormat: "string | null",
    enableChatSearch: "boolean",
    enableEnterToSend: "boolean",
    enableImages: "boolean",
    enableEmojis: "boolean",
    messageHistory: "Array<Object>",
  },
  CALLOUT_WIDGET: {
    calloutType: Object.values(CalloutType).join(" | "),
    title: "string (accepts HTML)",
    description: "string (accepts HTML)",
    ctaText: "string",
    isVisible: "boolean",
    isDismissible: "boolean",
  },
  MENU_WIDGET: {
    isVisible: "boolean",
  },
  LINK_WIDGET: {
    text: "string",
    linkStyle: "LINK | PRIMARY_BUTTON | SECONDARY_BUTTON | TERTIARY_BUTTON",
    isVisible: "boolean",
    isDisabled: "boolean",
  },
  CUSTOM_WIDGET: {},
  UNREGISTERED_CUSTOM_WIDGET: {},
  KEY_VALUE_WIDGET: {},
};

export const EXPECTED_DATA_EXAMPLE: Record<
  string,
  Record<string, { type: string; example: string; docLink: string }>
> = {
  DIFF_WIDGET: {
    originalText: {
      type: FIELD_VALUES.DIFF_WIDGET.originalText,
      example: "",
      docLink:
        "https://docs.superblocks.com/components/diff-viewer#use-diff-viewer-to-compare-data",
    },
    newText: {
      type: FIELD_VALUES.DIFF_WIDGET.newText,
      example: "",
      docLink:
        "https://docs.superblocks.com/components/diff-viewer#use-diff-viewer-to-compare-data",
    },
  },
  IFRAME_WIDGET: {
    source: {
      type: FIELD_VALUES.IFRAME_WIDGET.source,
      example: "",
      docLink: "https://docs.superblocks.com/components/iframe",
    },
    srcDoc: {
      type: FIELD_VALUES.IFRAME_WIDGET.srcDoc,
      example: "",
      docLink: "https://docs.superblocks.com/components/iframe",
    },
  },
  PDF_VIEWER_WIDGET: {
    fileUrl: {
      type: FIELD_VALUES.PDF_VIEWER_WIDGET.fileUrl,
      example: "",
      docLink:
        "https://docs.superblocks.com/components/pdf-viewer#how-to-view-an-https-pdf",
    },
  },
  CODE_WIDGET: {
    initialValue: {
      type: FIELD_VALUES.CODE_WIDGET.initialValue,
      example: "",
      docLink:
        "https://docs.superblocks.com/components/code-editor#use-code-editor-to-visualize-data",
    },
  },
  MAP_WIDGET: {
    defaultMarkers: {
      type: FIELD_VALUES.MAP_WIDGET.defaultMarkers,
      example: JSON.stringify([
        {
          lat: -34.397,
          long: 150.644,
          title: "Test A",
        },
      ]),
      docLink: "https://docs.superblocks.com/components/map#add-markers",
    },
  },
  VIDEO_WIDGET: {
    url: {
      type: FIELD_VALUES.VIDEO_WIDGET.url,
      example: "",
      docLink: "https://docs.superblocks.com/components/video",
    },
  },
  INPUT_WIDGET: {
    defaultText: {
      type: FIELD_VALUES.INPUT_WIDGET.defaultText,
      example: "",
      docLink:
        "https://docs.superblocks.com/components/input#set-input-label-and-placeholder-text",
    },
  },
  FILE_PICKER_WIDGET: {
    allowedFileTypes: {
      type: FIELD_VALUES.FILE_PICKER_WIDGET.allowedFileTypes,
      example: "",
      docLink:
        "https://docs.superblocks.com/components/file-picker#use-file-picker-to-import-files",
    },
  },
  IMAGE_WIDGET: {
    image: {
      type: FIELD_VALUES.IMAGE_WIDGET.image,
      example: "",
      docLink: "https://docs.superblocks.com/components/image#display-an-image",
    },
  },
  CHAT_WIDGET: {
    messageHistory: {
      type: FIELD_VALUES.CHAT_WIDGET.messageHistory,
      docLink: "",
      example: JSON.stringify(
        [
          {
            role: "assistant",
            content: "What can I help you with?",
          },
          {
            role: "user",
            content: "Is a koala a mammal?",
          },
        ],
        null,
        2,
      ),
    },
  },
  CHART_WIDGET: {
    chartData: {
      type: FIELD_VALUES.CHART_WIDGET.chartData,
      example: JSON.stringify(
        [
          {
            city: "Toronto",
            population: 2930000,
            country: "Canada",
          },
          {
            city: "New York",
            population: 8419000,
            country: "USA",
          },
        ],
        null,
        2,
      ),
      docLink:
        "https://docs.superblocks.com/components/charts#convert-sql-queries-to-charts",
    },
    plotlyChartJson: {
      type: FIELD_VALUES.CHART_WIDGET.plotlyChartJson,
      example: JSON.stringify(
        {
          data: [
            {
              type: "bar",
              x: [1, 2, 3],
              y: [2, 5, 3],
            },
          ],
          layout: {},
        },
        null,
        2,
      ),
      //TODO: Refactor doc to be in one place, (in PropertyPaneConfig maybe)
      docLink:
        "https://docs.superblocks.com/components/charts?tab=properties#plotly-chart-properties",
    },
    plotlyChartLayout: {
      type: FIELD_VALUES.CHART_WIDGET.plotlyChartLayout,
      example: JSON.stringify(
        {
          margin: {
            l: 50,
            r: 50,
            t: 50,
            b: 50,
            pad: 4,
          },
        },
        null,
        2,
      ),
      docLink:
        "https://docs.superblocks.com/components/charts?tab=properties#plotly-chart-properties",
    },
  },
  TABLE_WIDGET: {
    tableData: {
      type: FIELD_VALUES.TABLE_WIDGET.tableData,
      example: JSON.stringify(
        [
          {
            city: "Toronto",
            population: 2930000,
            country: "Canada",
          },
          {
            city: "New York",
            population: 8419000,
            country: "USA",
          },
        ],
        null,
        2,
      ),
      docLink:
        "https://docs.superblocks.com/components/table#add-data-to-a-table",
    },
    defaultFilters: {
      type: FIELD_VALUES.TABLE_WIDGET.defaultFilters,
      example: JSON.stringify(
        {
          byColumn: {
            name: {
              condition: {
                type: "textStartsWith",
                param: "b",
              },
            },
          },
        } as TableFilters,
        null,
        2,
      ),
      docLink:
        "https://docs.superblocks.com/components/table/additional-use-cases#setting-default-filters",
    },
  },
  GRID_WIDGET: {
    gridData: {
      type: FIELD_VALUES.GRID_WIDGET.gridData,
      example: JSON.stringify(
        [
          {
            name: "Hollywood Mansion",
            stars: 4.9,
            pricePerNight: 956,
            img: "https://superblocks.s3.us-west-2.amazonaws.com/img/demo/listing1.jpg",
          },
          {
            name: "Oceanside Villa",
            stars: 4.8,
            pricePerNight: 1025,
            img: "https://superblocks.s3.us-west-2.amazonaws.com/img/demo/listing2.jpg",
          },
        ],
        null,
        2,
      ),
      docLink: "https://docs.superblocks.com/components/grids",
    },
  },
  RADIO_GROUP_WIDGET: {
    options: {
      type: FIELD_VALUES.RADIO_GROUP_WIDGET.options,
      example: JSON.stringify(
        [
          { label: "New York", value: "NY" },
          { label: "California", value: "CA" },
        ],
        null,
        2,
      ),
      docLink:
        "https://docs.superblocks.com/components/radio-button?tab=properties",
    },
  },
  DROP_DOWN_WIDGET: {
    options: {
      type: FIELD_VALUES.DROP_DOWN_WIDGET.options,
      example: JSON.stringify(
        [
          { label: "New York", value: "NEW YORK" },
          { label: "Toronto", value: "TORONTO" },
        ],
        null,
        2,
      ),
      docLink:
        "https://docs.superblocks.com/components/dropdown#dynamically-set-dropdown-options-from-api-response",
    },
  },
  CALLOUT_WIDGET: {
    calloutType: {
      type: FIELD_VALUES.CALLOUT_WIDGET.calloutType,
      example: "INFO",
      docLink: "",
    },
    title: {
      type: FIELD_VALUES.CALLOUT_WIDGET.title,
      example: "Important content",
      docLink: "",
    },
    descritpion: {
      type: FIELD_VALUES.CALLOUT_WIDGET.descritpion,
      example: "Details about the important content",
      docLink: "",
    },
    ctaText: {
      type: FIELD_VALUES.CALLOUT_WIDGET.ctaText,
      example: "Click to resolve",
      docLink: "",
    },
  },
  CUSTOM_WIDGET: {},
};

export default FIELD_VALUES;
