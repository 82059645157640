import { memoize } from "lodash";

/**
 * Formats byte sizes into human readable strings
 */
export const formatBytes = (bytes: number): string => {
  if (bytes < 1024) return `${bytes} B`;
  if (bytes < 1024 * 1024) return `${(bytes / 1024).toFixed(2)} KB`;
  return `${(bytes / (1024 * 1024)).toFixed(2)} MB`;
};

/**
 * Gets exact byte size of object using UTF-8 encoding
 */
export const getDetailedSize = (obj: any): number => {
  try {
    const encoder = new TextEncoder();
    return encoder.encode(JSON.stringify(obj)).length;
  } catch (e) {
    return 0;
  }
};

/**
 * Checks if performance logging is enabled via URL query parameter
 * @returns boolean indicating if sb_log_performance parameter is present
 */
export const shouldLogPerformance = memoize(() => {
  try {
    return new URLSearchParams(window.location.search).has(
      "sb_log_performance",
    );
  } catch (e) {
    return false;
  }
});

export const LOG_STYLES = {
  WORKER: "color: #E67E00",
  REFLOW: "color: #6B47D1",
  EVALUATION: "color: #1E88E5",
} as const;
