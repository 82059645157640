import { Options, useDebouncedCallback } from "use-debounce";

export type { Options as DebounceSettings };

export function useDebounce<T extends (...args: any[]) => any>(
  fn: T,
  ms: number,
  options?: Options,
) {
  return useDebouncedCallback(fn, ms, options);
}
