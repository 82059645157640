import React from "react";
import { useMemo } from "react";
import { ControlProps } from "legacy/components/propertyControls/BaseControl";
import { GeneratedTheme } from "legacy/themes";
import { ItemKinds } from "./ItemKindConstants";
import { useAreItemPropertiesLoading } from "./ItemKinds";

type WithNestedLoadingStateProps = ControlProps & {
  loadingStates: Record<string, boolean>;
  generatedTheme?: GeneratedTheme;
};

const WithNestedLoadingState = (
  Component: React.ComponentType<WithNestedLoadingStateProps> & {
    getControlType: () => string;
  },
  nestedKeys: string[],
) => {
  const WrappedComponent = (props: WithNestedLoadingStateProps) => {
    const { itemKind, itemId, propertyName } = props;
    const propertyNames = useMemo(
      () => nestedKeys.map((key) => `${propertyName}.${key}`),
      [propertyName],
    );
    const loadingStates = useAreItemPropertiesLoading(
      itemKind ?? ItemKinds.WIDGET,
      itemId ?? "",
      propertyNames,
    );
    const loadingStateObj = useMemo(() => {
      return nestedKeys.reduce(
        (acc, key, i) => {
          acc[key] = loadingStates[i];
          return acc;
        },
        {} as Record<string, boolean>,
      );
    }, [loadingStates]);
    return <Component {...props} loadingStates={loadingStateObj} />;
  };

  WrappedComponent.displayName = `WithNestedLoadingState(${Component.displayName || Component.name || "Component"})`;

  WrappedComponent.getControlType = Component.getControlType;
  return WrappedComponent;
};

export default WithNestedLoadingState;
