import { Padding } from "@superblocksteam/shared";
import Decimal from "decimal.js";
import memoizeOne from "memoize-one";
import { GeneratedTheme } from "legacy/themes";
import { INPUT_PADDING } from "legacy/themes/constants";
import {
  DEFAULT_INPUT_TEXT_STYLE_VARIANT,
  DEFAULT_TYPOGRAPHIES_NEW,
} from "legacy/themes/typographyConstants";
import logger from "utils/logger";
import { generatePaddingVariableAssignmentStyleObject } from "../base/generatePaddingStyle";
import { getIconPadding } from "../shared";
import { getFontSizeInPxFromTextStyle } from "../typographyUtils";
import { DEFAULT_INPUT_BORDER } from "./InputWidgetConstants";
import { InputComponentProps } from "./types";

export const sanitizeNumericInputValue = (
  value: number | string,
  isPercentInput: boolean,
): string => {
  const sanitizedValue =
    typeof value === "string" ? value.trim() : String(value).trim();

  try {
    const isValidDecimal = /^[+-]?\d*\.?\d+(e[+-]?\d+)?$/i.test(sanitizedValue);

    const number = String(
      isPercentInput && sanitizedValue !== "" && isValidDecimal
        ? new Decimal(sanitizedValue).times(100)
        : sanitizedValue,
    );
    return number;
  } catch (error) {
    logger.error(
      `Decimal creation failed for value: ${sanitizedValue}, error: ${error}`,
    );
    return "";
  }
};

// Returns top value for icon to be vertically centered in the first line of the input/textarea
export const getIconTop = ({
  inputHeight,
  inputProps,
  inputStyleOverride,
  multiline,
  iconHeight,
  generatedTheme,
}: Pick<
  InputComponentProps,
  "inputProps" | "inputStyleOverride" | "multiline"
> & {
  iconHeight: number;
  inputHeight: number;
  generatedTheme: GeneratedTheme;
}) => {
  if (!inputProps) {
    return undefined;
  }

  const borderTop = inputProps.border?.top?.width ?? DEFAULT_INPUT_BORDER.width;
  const borderBottom =
    inputProps.border?.bottom?.width ?? DEFAULT_INPUT_BORDER.width;

  const generatedInputTypographiesTheme = generatedTheme.typographies;

  const lineHeight = (inputStyleOverride?.lineHeight ??
    generatedInputTypographiesTheme?.inputText?.lineHeight) as number;
  const fontSize = getFontSizeInPxFromTextStyle({
    nestedProps: inputProps?.textStyle,
    typographies: generatedInputTypographiesTheme ?? DEFAULT_TYPOGRAPHIES_NEW,
    textStyleVariant: inputProps?.textStyle?.variant,
    defaultTextStyleVariant: DEFAULT_INPUT_TEXT_STYLE_VARIANT,
  });

  const paddingTop = inputProps.padding?.top ?? INPUT_PADDING.top;
  const paddingBottom = inputProps.padding?.bottom ?? INPUT_PADDING.bottom;

  // This is the size the input content should have
  // Note: When the input breaks because it has no space to fit the inputContentHeight, the icon will
  // be misaligned because there's no way of knowing the real size. In the future, if we fix the input
  // breaking when the height is smaller than it needs, this should work.
  const inputContentHeight =
    inputHeight -
    (paddingTop.value +
      paddingBottom.value +
      borderTop.value +
      borderBottom.value);

  // If input is single line (input) center icon to inputContentHeight
  if (!multiline) {
    return (
      paddingTop.value + borderTop.value + (inputContentHeight - iconHeight) / 2
    );
  }

  // If textarea, center the icon to the first line
  return (
    paddingTop.value +
    borderTop.value +
    (lineHeight * fontSize - iconHeight) / 2
  );
};

export const createCssVariablesForPadding = memoizeOne(
  (
    padding: Padding,
    iconPosition?: "LEFT" | "RIGHT" | undefined,
    iconSize?: number,
  ): { [key: `--${string}`]: string } => {
    let adjustedForIcon = {};
    if (iconPosition === "LEFT") {
      adjustedForIcon = {
        left: getIconPadding(padding.left, iconSize),
      };
    } else if (iconPosition === "RIGHT") {
      adjustedForIcon = {
        right: getIconPadding(padding.right, iconSize),
      };
    }
    return generatePaddingVariableAssignmentStyleObject({
      ...padding,
      ...adjustedForIcon,
    });
  },
);
