import {
  DatasourceMetadataDto,
  Table,
  TableType,
} from "@superblocksteam/shared";
import { getStore } from "store/dynamic";
import { Flag, selectFlagById } from "store/slices/featureFlags";
import logger from "utils/logger";
import {
  sendCodeCompletionSuggest,
  sendCodeCompletionFeedback,
} from "./client";

import { language } from "./codemirrorLanguages";
import { IdeInfo, LanguageServerClient } from "./common";
import { ContextFile } from "./context_utils";
import { TextAndOffsets, computeTextAndOffsets } from "./notebook";
import { EditorOptions, Language } from "./rpc/gen/exa/codeium_common_pb";
import {
  CompletionItem,
  CompletionPart,
  CompletionPartType,
  GetCompletionsRequest,
} from "./rpc/gen/exa/language_server_pb";
import { numUtf8BytesToNumCodeUnits } from "./utf";
import type CodeMirror from "codemirror";

function computeTextAndOffsetsForCodeMirror(
  isNotebook: boolean,
  textModels: CodeMirror.Doc[],
  currentTextModel: CodeMirror.Doc,
  currentTextModelWithOutput: CodeMirror.Doc | undefined,
): TextAndOffsets {
  return computeTextAndOffsets({
    textModels,
    currentTextModel,
    currentTextModelWithOutput: currentTextModelWithOutput,
    isNotebook: isNotebook,
    utf16CodeUnitOffset: currentTextModel.indexFromPos(
      currentTextModel.getCursor(),
    ),
    getText: (model) => model.getValue(),
    getLanguage: (model) => language(model, undefined),
  });
}

interface TextMarker {
  pos: CodeMirror.Position;
  marker: CodeMirror.TextMarker<CodeMirror.Position | CodeMirror.MarkerRange>;
  spanElement: HTMLSpanElement;
}

// Helps simulate a typing as completed effect. Will only work on the same line.
function maybeUpdateTextMarker(
  textMarker: TextMarker,
  ch: string,
  cursor: CodeMirror.Position,
  characterBeforeCursor: string,
): boolean {
  if (cursor.line !== textMarker.pos.line || cursor.ch !== textMarker.pos.ch) {
    return false;
  }
  if (ch === "Backspace") {
    if (characterBeforeCursor === "") {
      // We've changed lines, so remove all text markers as they're now stale.
      textMarker.marker.clear();
      return false;
    }
    const prev = characterBeforeCursor.replaceAll("\t", "  ");
    textMarker.spanElement.innerText = prev + textMarker.spanElement.innerText;
    textMarker.pos.ch -= prev.length;
    return true;
  }
  if (ch === "Enter") {
    return false;
  }
  const innerText = textMarker.spanElement.innerText;
  if (innerText.length === 1) {
    // TODO(prem): Why is this necessary?
    // This was necessary for the following case:
    // In GitHub, type "def fib(n)" and accept the completion.
    // Then go to a new line in the function and type "fib(5)".
    // On the ")", it should freeze.
    return false;
  }

  let textToInsert = "";
  if (innerText.startsWith(ch)) {
    textToInsert = ch;
  } else if (innerText.match(/^\s+/)) {
    if (ch === "Space") {
      textToInsert = " ";
    } else if (ch === "Tab") {
      // TODO: In the rare case that the user has a mix of tab and spaces and is
      // halfway through a tab by typing spaces, this will result in a jump
      // since in this case the Tab will result in only one space being typed
      // but we can't detect that here.
      textToInsert = "  "; // Tab should insert two spaces.
    } else {
      return false;
    }
  }
  textMarker.spanElement.innerText = textMarker.spanElement.innerText.substring(
    textToInsert.length,
  );
  textMarker.pos.ch += textToInsert.length;
  return textToInsert.length > 0;
}

const getSchemaMetadata = (metadata: DatasourceMetadataDto | undefined) => {
  const getCreateStatement = (table: Table): string => {
    const columns = table.columns.map((column) => {
      return `${column.name} ${column.type}`;
    });

    const tableName = table.schema
      ? `${table.schema}.${table.name}`
      : table.name;

    // Ignore views for now.
    if (table.type === TableType.VIEW) {
      return "";
    }

    return `CREATE ${table.type} ${tableName} (${columns.join(", ")});`;
  };

  if (metadata === undefined) return "";
  return (
    metadata.dbSchema?.tables
      .map((table) => getCreateStatement(table))
      .join("\n\n") + "\n"
  );
};

export class CodeMirrorManager {
  private client: LanguageServerClient;
  private ideInfo: IdeInfo;
  private currentCompletion?: {
    completionItem: CompletionItem;
    lineWidgets: CodeMirror.LineWidget[];
    textMarkers: TextMarker[];
    doc: CodeMirror.Doc;
    start: CodeMirror.Position;
    end: CodeMirror.Position;
    oldBlockText?: string; // Cached old value to avoid re-rendering line widgets.
    docState: string;
  };
  private feedbackEnabled: boolean = false;

  constructor(userId: string, ideInfo: IdeInfo) {
    this.client = new LanguageServerClient(userId);
    this.ideInfo = ideInfo;
    const store = getStore();
    this.feedbackEnabled = selectFlagById(
      store.getState(),
      Flag.UI_AI_CODE_COMPLETION_FEEDBACK_ENABLED,
    ) as boolean;
  }

  documentMatchesCompletion(): boolean {
    if (
      this.currentCompletion?.doc.getValue() !==
      this.currentCompletion?.docState
    ) {
      return false;
    }
    return true;
  }

  anyTextMarkerUpdated(
    ch: string,
    cursor: CodeMirror.Position,
    characterBeforeCursor: string,
  ): boolean {
    return (
      this.currentCompletion?.textMarkers.find((textMarker) =>
        maybeUpdateTextMarker(textMarker, ch, cursor, characterBeforeCursor),
      ) !== undefined
    );
  }

  async triggerCompletion(
    editor: CodeMirror.Editor,
    files: Record<string, ContextFile>,
    stepMetadata: any,
    stepName: string,
    variableNames: string[],
    prefix: string,
    suffix: string,
    mode: string,
    textModels: CodeMirror.Doc[],
    currentTextModel: CodeMirror.Doc,
    currentTextModelWithOutput: CodeMirror.Doc | undefined,
    editorOptions: EditorOptions,
    relativePath: string | undefined,
  ): Promise<void> {
    if (editor.state.placeholderLine?.widgets?.[0]) {
      editor.removeLineWidget(editor.state.placeholderLine.widgets[0]);
    }

    const cursor = currentTextModel.getCursor();
    const { text, utf8ByteOffset, additionalUtf8ByteOffset } =
      computeTextAndOffsetsForCodeMirror(
        false,
        textModels,
        currentTextModel,
        currentTextModelWithOutput,
      );
    const lang = language(currentTextModel, relativePath);
    const numUtf8Bytes = additionalUtf8ByteOffset + utf8ByteOffset;
    const input = {
      text,
      cursorOffset: numUtf8Bytes,
      language: mode.replaceAll("-sb", "").replaceAll("-js", ""),
    };

    // TODO: Clean this up.
    const getLangFromMode = (mode: string) => {
      if (mode.includes("python")) {
        return Language.PYTHON;
      } else if (mode.includes("javascript")) {
        return Language.JAVASCRIPT;
      } else if (mode.includes("sql")) {
        // TODO: There's a bug here.
        return Language.SQL;
      }
      return Language.JAVASCRIPT;
    };

    const extension = (lang: Language | "txt") => {
      switch (lang) {
        case Language.JAVASCRIPT:
          return ".js";
        case Language.PYTHON:
          return ".py";
        case Language.SQL:
          return ".sql";
        case "txt":
          return ".txt";
      }
    };
    if (lang === Language.SQL) {
      prefix += getSchemaMetadata(stepMetadata) ?? "";
    }

    const store = getStore();
    const debugLogging = selectFlagById(
      store.getState(),
      Flag.UI_AI_CODE_COMPLETION_DEBUG_LOGGING_ENABLED,
    ) as number;

    const numSchemaLines = prefix.split("\n").length;
    const request = new GetCompletionsRequest({
      metadata: this.client.getMetadata(this.ideInfo),
      document: {
        text: prefix + text + suffix,
        editorLanguage: mode.replaceAll("-sb", "").replaceAll("-js", ""),
        language: lang,
        cursorOffset: BigInt((prefix?.length ?? 0) + numUtf8Bytes),
        lineEnding: "\n",
        // We could use the regular path which could have a drive: prefix, but
        // this is probably unusual.
        workspaceUri: stepName + extension(lang),
      },
      otherDocuments: Object.entries(files)
        .filter(([name, file]) => {
          if (name === "requirements" && file.mode === "txt") {
            return lang === Language.PYTHON;
          }
          return true;
        })
        .map(([name, file]) => ({
          text: file.text,
          editorLanguage: file.mode.replaceAll("-sb", "").replaceAll("-js", ""),
          language: getLangFromMode(file.mode),
          cursorOffset: BigInt(0),
          lineEnding: "\n",
          workspaceUri: name + extension(getLangFromMode(file.mode)),
        })),
      editorOptions,
    });
    const response = await this.client.getCompletions(request);
    if (response === undefined) {
      return;
    }

    const tokens = response.completionItems[0].completion?.decodedTokens ?? [];
    const score = response.completionItems[0].completion?.score;
    const probs = response.completionItems[0].completion?.probabilities ?? [];
    const average = (arr: number[]) =>
      arr.length ? arr.reduce((sum, num) => sum + num, 0) / arr.length : 0;
    const avg = average(probs.slice(0, 7));

    const prefixProbs = tokens
      // Don't look at the probability of the stop token
      .filter((token) => token !== "<|endofmiddle|>")
      // Filter out tokens that don't contain alphanumeric characters
      .filter((token) => /[a-zA-Z0-9]/.test(token))
      .slice(0, 5)
      .map((_, i) => probs[i]);
    const minPrefix = Math.min(...prefixProbs);

    let output = "";
    if (response?.completionItems[0]?.completion?.decodedTokens) {
      output = response.completionItems[0].completion?.decodedTokens.join("");
    }
    // Sometimes the completion adds a module.exports statement at the end.
    const moduleExportsIndex = output.indexOf("module.exports");
    if (moduleExportsIndex !== -1) {
      output = output.substring(0, moduleExportsIndex);
    }

    // Filter out any lint tokens that might come back.
    const lintTokens = ["@ts", "eslint"];
    output = output
      .split("\n")
      .filter((line) => !lintTokens.some((token) => line.includes(token)))
      .join("\n");
    if (output.length === 0) {
      // Removed all lines that had bad tokens.
      return;
    }

    // TODO: Sometimes it tries to import all of the libraries. This is also usually bad.
    // We should do something similar to the variableNames match.
    const numVariables = variableNames.length;
    const numMatchesInOutput = variableNames.filter((variable) =>
      output.includes(variable),
    ).length;
    if (debugLogging) {
      console.log(
        tokens.reduce(
          (acc, x, i) => {
            acc.push({
              token: x,
              prob: response.completionItems[0].completion?.probabilities[i],
            });
            return acc;
          },
          [] as { token: string; prob: number | undefined }[],
        ),
      );
      console.log("score", score);
      console.log("avg", avg);
      console.log("minPrefix", minPrefix);
      console.log("output", output);
    }
    if (numVariables > 0 && numMatchesInOutput >= numVariables / 2) {
      debugLogging && logger.debug("too many variables match");
      return;
    }

    const prefixThreshold = selectFlagById(
      store.getState(),
      Flag.UI_AI_CODE_COMPLETION_PREFIX_THRESHOLD,
    ) as number;
    const avgThreshold = selectFlagById(
      store.getState(),
      Flag.UI_AI_CODE_COMPLETION_AVG_THRESHOLD,
    ) as number;
    const scoreThreshold = selectFlagById(
      store.getState(),
      Flag.UI_AI_CODE_COMPLETION_SCORE_THRESHOLD,
    ) as number;
    const prefixThresholdTier2 = selectFlagById(
      store.getState(),
      Flag.UI_AI_CODE_COMPLETION_PREFIX_THRESHOLD_TIER_2,
    ) as number;
    const avgThresholdTier2 = selectFlagById(
      store.getState(),
      Flag.UI_AI_CODE_COMPLETION_AVG_THRESHOLD_TIER_2,
    ) as number;

    if (avg < avgThreshold) {
      debugLogging && logger.debug("avg threshold");
      return;
    }
    if (minPrefix < prefixThreshold) {
      debugLogging && logger.debug("min prefix threshold");
      return;
    }
    if (score && score < scoreThreshold) {
      if (avg < avgThresholdTier2) {
        debugLogging && logger.debug("avg threshold tier 2");
        return;
      }
      if (minPrefix < prefixThresholdTier2) {
        debugLogging && logger.debug("min prefix threshold tier 2");
        return;
      }
    }

    // No more await allowed below this point, given that we've checked for
    // abort, so this must be the latest debounced request.
    const newCursor = currentTextModel.getCursor();
    if (newCursor.ch !== cursor.ch || newCursor.line !== cursor.line) {
      // TODO(prem): Is this check necessary?
      return;
    }
    if (response.completionItems.length === 0) {
      return;
    }
    const completionItem = response.completionItems[0];
    if (!completionItem.completion || !completionItem.range) {
      return;
    }

    const lastTypedChar = currentTextModel
      .getLine(cursor.line)
      .slice(cursor.ch - 1, cursor.ch);
    const firstCharOfOldCompletion =
      this.currentCompletion?.completionItem?.completion?.decodedTokens[0].slice(
        0,
        1,
      );
    if (this.currentCompletion) {
      if (lastTypedChar === firstCharOfOldCompletion) {
        // The user started typing the same thing as the completion.
        this.feedbackEnabled &&
          this.currentCompletion &&
          sendCodeCompletionFeedback({
            action: "accept",
            reason: "type over",
          });
      } else {
        // The user typed something different than the start of the completion.
        this.feedbackEnabled &&
          this.currentCompletion &&
          sendCodeCompletionFeedback({
            action: "reject",
            reason: "type over",
          });
      }
    }

    this.feedbackEnabled &&
      sendCodeCompletionSuggest({
        input,
        output,
        context: prefix,
        metadata: {
          avgProbability: avg,
          probabilities: probs,
          minPrefix: minPrefix,
          score: score,
        },
      });

    // Adjust the offsets to account for the prefix.
    completionItem.completionParts = completionItem.completionParts.map(
      (part: CompletionPart) => {
        part.offset = BigInt(Number(part.offset) - prefix.length);
        part.line = BigInt(Number(part.line) - numSchemaLines);
        return part;
      },
    );
    completionItem.range.startOffset =
      BigInt(completionItem.range.startOffset) - BigInt(prefix?.length ?? 0);
    completionItem.range.endOffset =
      BigInt(completionItem.range.endOffset) - BigInt(prefix?.length ?? 0);

    // If there is a block completion
    // AND there are characters to the right of the replace range
    // (meaning that the end of the replace range is before the end of the line)
    this.renderCompletion(
      currentTextModel,
      completionItem,
      additionalUtf8ByteOffset,
    );
  }

  clearCompletion(reason: string): boolean {
    const currentCompletion = this.currentCompletion;
    if (currentCompletion === undefined) {
      return false;
    }
    currentCompletion.lineWidgets.forEach((widget) => {
      widget.clear();
    });
    currentCompletion.textMarkers.forEach((marker) => {
      marker.marker.clear();
    });
    this.currentCompletion = undefined;
    return true;
  }

  renderCompletion(
    doc: CodeMirror.Doc,
    completionItem: CompletionItem,
    additionalUtf8ByteOffset: number,
  ): void {
    // Clear inline text markers.
    this.currentCompletion?.textMarkers.forEach((marker) => {
      marker.marker.clear();
    });

    // This flag keeps track if we've yet cleared the line widgets on the
    // editor. This is used as an optimization to not remove and re-add the same
    // widget if the content is the same. This helps avoid flickering.
    let clearedBlocks = false;
    const startOffsetUtf8Bytes =
      Number(completionItem.range?.startOffset ?? 0) - additionalUtf8ByteOffset;
    const endOffsetUtf8Bytes =
      Number(completionItem.range?.endOffset ?? 0) - additionalUtf8ByteOffset;
    const currentCompletion: typeof this.currentCompletion = {
      completionItem,
      lineWidgets: [],
      textMarkers: [],
      doc,
      start: doc.posFromIndex(
        numUtf8BytesToNumCodeUnits(doc.getValue(), startOffsetUtf8Bytes),
      ),
      end: doc.posFromIndex(
        numUtf8BytesToNumCodeUnits(doc.getValue(), endOffsetUtf8Bytes),
      ),
      docState: doc.getValue(),
    };

    const cursor = doc.getCursor();

    let textToAdd = "";
    const hasBlock = !!completionItem.completionParts.find((x) => x.type === 2);
    const endPos = completionItem.range?.endPosition;
    if (hasBlock && endPos !== undefined) {
      const line = doc.getLine(cursor.line);
      const curLineLength = line.length;
      const hasMoreText = endPos.col < curLineLength;
      if (hasMoreText) {
        textToAdd = line.slice(Number(endPos.col));
        const marker = doc.markText(
          { line: cursor.line, ch: Number(endPos.col) },
          { line: cursor.line, ch: Number(line.length) },
          {
            className: "hide-text",
          },
        );
        currentCompletion.textMarkers.push({
          marker: marker,
          pos: doc.posFromIndex(Number(endPos.col)),
          spanElement: document.createElement("span"),
        });
      }
    }

    let createdInlineAtCursor = false;
    completionItem.completionParts.forEach((part: CompletionPart) => {
      if (part.type === CompletionPartType.INLINE) {
        const line = doc.getLine(cursor.line);
        const prefix = line.slice(0, currentCompletion.start.ch);
        const suffix = line.slice(currentCompletion.end.ch);
        const newLine = (
          prefix +
          (completionItem.completion?.text ?? "") +
          suffix
        ).replaceAll("\t", "  ");
        const linePrefix = line.slice(0, cursor.ch).replaceAll("\t", "  ");
        const startText = newLine.indexOf(part.text);
        let whitespace = newLine.slice(linePrefix.length, startText);
        if (!whitespace.match(/^\s+$/)) {
          whitespace = "";
        }

        const bookmarkElement = document.createElement("span");
        bookmarkElement.classList.add("codeium-ghost");
        bookmarkElement.innerText = whitespace + part.text;
        const partOffsetBytes = Number(part.offset) - additionalUtf8ByteOffset;
        const partOffset = numUtf8BytesToNumCodeUnits(
          doc.getValue(),
          partOffsetBytes,
        );
        const pos = doc.posFromIndex(partOffset);
        const bookmarkWidget = doc.setBookmark(pos, {
          widget: bookmarkElement,
          insertLeft: true,
          // We need all widgets to have handleMouseEvents true for the glitches
          // where the completion doesn't disappear.
          handleMouseEvents: true,
        });
        currentCompletion.textMarkers.push({
          marker: bookmarkWidget,
          pos,
          spanElement: bookmarkElement,
        });
        if (pos.line === cursor.line && pos.ch === cursor.ch) {
          createdInlineAtCursor = true;
        }
      } else if (part.type === CompletionPartType.BLOCK) {
        const editor = doc.getEditor();
        if (editor) {
          editor.state.renderHintAbove = true;
        }
        if (!clearedBlocks) {
          clearedBlocks = true;
          if (this.currentCompletion?.oldBlockText === part.text) {
            currentCompletion.lineWidgets = this.currentCompletion.lineWidgets;
            return; // Text hasn't changed - no need to rerender the block.
          }

          this.currentCompletion?.lineWidgets.forEach((widget) => {
            widget.clear();
          });
        }

        // We use CodeMirror's LineWidget feature to render the block ghost text element.
        const lineElement = document.createElement("div");
        lineElement.classList.add("codeium-ghost");
        const lineCount = part.text.split("\n").length;
        part.text.split("\n").forEach((line, i) => {
          const preElement = document.createElement("pre");
          preElement.classList.add("CodeMirror-line", "codeium-ghost-line");
          if (line === "") {
            line = " ";
          }
          // FIXME(pbardea): The tab spacing was working correctly before, but now it's not.
          preElement.innerText = line.replaceAll("\t", "  ");
          if (i === lineCount - 1) {
            preElement.innerText += textToAdd;
          }
          lineElement.appendChild(preElement);
        });

        const lineWidget = doc.addLineWidget(cursor.line, lineElement, {
          handleMouseEvents: true,
        });
        currentCompletion.lineWidgets.push(lineWidget);
        currentCompletion.oldBlockText = part.text;
      }
    });
    if (!clearedBlocks) {
      clearedBlocks = true;
      this.currentCompletion?.lineWidgets.forEach((widget) => {
        widget.clear();
      });
    }
    if (!createdInlineAtCursor) {
      // This is to handle the edge case of faking typing as completed but with
      // a backspace at the end of the line, where there might not be an INLINE
      // completion part.
      const bookmarkElement = document.createElement("span");
      bookmarkElement.classList.add("codeium-ghost");
      bookmarkElement.innerText = "";
      const bookmarkWidget = doc.setBookmark(cursor, {
        widget: bookmarkElement,
        insertLeft: true,
      });
      currentCompletion.textMarkers.push({
        marker: bookmarkWidget,
        pos: cursor,
        spanElement: bookmarkElement,
      });
    }
    this.currentCompletion = currentCompletion;
  }

  acceptCompletion(): boolean {
    const completion = this.currentCompletion;
    if (completion === undefined) {
      return false;
    }
    // If the start of the preview text is whitespace, we should just "accept" the whitespace
    const cursor = completion.doc.getCursor();
    const linePrefix = completion.doc.getLine(cursor.line).slice(0, cursor.ch);
    if (
      linePrefix.match(/^\s*$/) && // Line prefix is entirely whitespace
      this.currentCompletion?.textMarkers.some(
        (x) => x.spanElement.innerText.match(/^\s+/), // The current completion starts with whitespace
      )
    ) {
      // If the line up until the cursor is all whitespace, and the start of the
      // completion is more whitespace, we're not going to accept it. We're just
      // going to type the tab.
      return false;
    }
    this.feedbackEnabled &&
      this.currentCompletion &&
      sendCodeCompletionFeedback({ action: "accept", reason: "user accepted" });
    this.clearCompletion("about to accept completions");
    const completionProto = completion.completionItem.completion;
    if (completionProto === undefined) {
      console.error("Empty completion");
      return true;
    }
    const doc = completion.doc;
    // This is a hack since we have the fake typing as completed logic.
    doc.setCursor(completion.start);
    doc.replaceRange(completionProto.text, completion.start, completion.end);
    if (
      completion.completionItem.suffix !== undefined &&
      completion.completionItem.suffix.text.length > 0
    ) {
      doc.replaceRange(completion.completionItem.suffix.text, doc.getCursor());
      const currentCursor = doc.getCursor();
      const newOffset =
        doc.indexFromPos(currentCursor) +
        Number(completion.completionItem.suffix.deltaCursorOffset);
      doc.setCursor(doc.posFromIndex(newOffset));
    }
    this.client.acceptedLastCompletion(
      this.ideInfo,
      completionProto.completionId,
    );
    return true;
  }

  // If this returns false, don't consume the event.
  // If true, consume the event.
  // Otherwise, keep going with other logic.
  beforeMainKeyHandler(
    doc: CodeMirror.Doc,
    cleanup: () => void,
    event: KeyboardEvent,
    alsoHandle: { tab: boolean; escape: boolean },
    tabKey: string = "Tab",
  ): { consumeEvent: boolean | undefined; forceTriggerCompletion: boolean } {
    let forceTriggerCompletion = false;
    if (event.ctrlKey) {
      if (event.key === " ") {
        forceTriggerCompletion = true;
      } else {
        return { consumeEvent: false, forceTriggerCompletion };
      }
    }
    // Classic notebook may autocomplete these.
    if ("\"')}]".includes(event.key)) {
      forceTriggerCompletion = true;
    }
    if (event.isComposing) {
      this.clearCompletion("composing");
      this.feedbackEnabled &&
        this.currentCompletion &&
        sendCodeCompletionFeedback({ action: "dismiss", reason: "composing" });
      return { consumeEvent: false, forceTriggerCompletion };
    }
    if (!event.metaKey && !event.ctrlKey && !event.altKey && !event.shiftKey) {
      if (alsoHandle.tab && event.key === tabKey && this.acceptCompletion()) {
        cleanup();
        forceTriggerCompletion = true;
        return { consumeEvent: true, forceTriggerCompletion };
      }
      if (
        alsoHandle.escape &&
        event.key === "Escape" &&
        this.clearCompletion("user dismissed")
      ) {
        this.feedbackEnabled &&
          this.currentCompletion &&
          sendCodeCompletionFeedback({ action: "reject", reason: "esc" });
        return { consumeEvent: false, forceTriggerCompletion };
      }
    }
    switch (event.key) {
      case "Delete":
      case "ArrowLeft":
      case "ArrowRight":
      case "Home":
      case "End":
      case "PageDown":
      case "PageUp":
        this.clearCompletion(`key: ${event.key}`);
        this.feedbackEnabled &&
          this.currentCompletion &&
          sendCodeCompletionFeedback({ action: "reject", reason: event.key });
        return { consumeEvent: false, forceTriggerCompletion };
    }

    // For arrow up/arrow down and enter need to check if hinter is open
    if (event.key === "ArrowUp" || event.key === "ArrowDown") {
      const editor = doc.getEditor();
      if (editor) {
        const completionOpen = !!editor.state.completionActive;
        if (!completionOpen) {
          this.clearCompletion(`key: ${event.key}`);
          this.feedbackEnabled &&
            this.currentCompletion &&
            sendCodeCompletionFeedback({ action: "reject", reason: event.key });
          return { consumeEvent: false, forceTriggerCompletion };
        }
      }
    }

    const cursor = doc.getCursor();
    const characterBeforeCursor =
      cursor.ch === 0
        ? ""
        : doc.getRange({ line: cursor.line, ch: cursor.ch - 1 }, cursor);
    const anyTextMarkerUpdated = this.anyTextMarkerUpdated(
      event.key,
      cursor,
      characterBeforeCursor,
    );
    // We don't want caps lock to trigger a clearing of the completion, for example.
    if (!anyTextMarkerUpdated && event.key.length === 1) {
      this.clearCompletion(
        "didn't update text marker and key is a single character",
      );
      const editor = doc.getEditor();
      if (editor) {
        editor.closeHint();
      }
    }
    if (event.key === "Enter") {
      this.clearCompletion("enter");
      this.feedbackEnabled &&
        this.currentCompletion &&
        sendCodeCompletionFeedback({ action: "reject", reason: "enter" });
    }
    return { consumeEvent: undefined, forceTriggerCompletion };
  }

  clearCompletionInitHook(): (editor: CodeMirror.Editor) => void {
    const editors = new WeakSet<CodeMirror.Editor>();
    return (editor: CodeMirror.Editor) => {
      if (editors.has(editor)) {
        return;
      }
      editors.add(editor);
      const el = editor.getInputField().closest(".CodeMirror");
      if (el === null) {
        return;
      }
      const div = el as HTMLDivElement;
      div.addEventListener("focusout", () => {
        this.feedbackEnabled &&
          this.currentCompletion &&
          sendCodeCompletionFeedback({ action: "dismiss", reason: "focusout" });
        this.clearCompletion("focusout");
      });
      div.addEventListener("mousedown", () => {
        this.feedbackEnabled &&
          this.currentCompletion &&
          sendCodeCompletionFeedback({
            action: "dismiss",
            reason: "mousedown",
          });
        this.clearCompletion("mousedown");
        editor.closeHint();
      });
      const mutationObserver = new MutationObserver(() => {
        // Check for jupyterlab-vim command mode.
        if (div.classList.contains("cm-fat-cursor")) {
          this.feedbackEnabled &&
            this.currentCompletion &&
            sendCodeCompletionFeedback({ action: "dismiss", reason: "vim" });
          this.clearCompletion("vim");
        }
      });
      mutationObserver.observe(div, {
        attributes: true,
        attributeFilter: ["class"],
      });
      const completer = document.body.querySelector(".jp-Completer");
      if (completer !== null) {
        const completerMutationObserver = new MutationObserver(() => {
          if (!completer?.classList.contains("lm-mod-hidden")) {
            this.clearCompletion("completer");
          }
        });
        completerMutationObserver.observe(completer, {
          attributes: true,
          attributeFilter: ["class"],
        });
      }
    };
  }
}
