import { Classes } from "@blueprintjs/core";
import {
  MultiSelect,
  Classes as MultiSelectClasses,
} from "@blueprintjs/select";
import useResizeObserver from "@react-hook/resize-observer";
import equal from "@superblocksteam/fast-deep-equal/es6";
import { Padding } from "@superblocksteam/shared";
import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { flushSync } from "react-dom";
import styled from "styled-components";
import { useDebounce } from "hooks/ui";
import { BlueprintInputTransform } from "legacy/constants/DefaultTheme";
import { LegacyNamedColors } from "legacy/constants/LegacyNamedColors";
import { NOOP } from "utils/function";
import { generatePaddingVariableDeclarationCss } from "../base/generatePaddingStyle";
import { DropdownOption } from "./types";
import { getTagElementSpace } from "./utils";

interface StyledMultiDropdownProps {
  labelHeight: number;
  width?: number;
  isValid?: boolean;
  canvasMode?: boolean;
  hasIcon?: boolean;
  inputPadding?: Padding;
  overflowTags?: boolean;
  query?: string;
}

const TAG_HEIGHT = 26;
const INPUT_SIZE = 36;
const MORE_TAGS_SIZE = 90;

export const MORE_TAGS_VALUE = "__SB_EXTRA_TAGS__";

const BlueprintMultiDropDown = MultiSelect.ofType<DropdownOption>();

const StyledMultiDropDown = styled(
  BlueprintMultiDropDown,
)<StyledMultiDropdownProps>`
  svg[data-icon="chevron-down"] {
    display: none !important;
  }
  div {
    flex: 1 1 auto;
    .${MultiSelectClasses.MULTISELECT} {
      position: relative;
      min-width: 0;
      min-height: 0;
    }
  }

  && {
    ${BlueprintInputTransform}
    height: 100%;
    .${Classes.TAG_INPUT}::-webkit-scrollbar {
      display: none;
    }
    .${Classes.TAG_INPUT} {
      display: flex;
      width: 100%;
      height: 100%;
      align-items: center;
      justify-content: space-between;
      text-overflow: ellipsis;
      overflow: auto;
      ${generatePaddingVariableDeclarationCss()};

      .${Classes.TAG_INPUT_VALUES} {
        margin-right: 0;
        margin-top: 0;
        display: flex;
        align-items: center;
        align-self: center;
        scrollbar-width: none;
        max-height: 100%;
        gap: 3px;
      }

      .${Classes.TAG} {
        max-width: ${(props) =>
          props.canvasMode && props.width ? props.width * 0.85 + "px" : "100%"};
        height: ${TAG_HEIGHT}px;
        margin-bottom: 0;
        margin-right: 0;

        &[data-tag-value="${MORE_TAGS_VALUE}"] {
          &:hover,
          &:focus {
            cursor: auto;
            background-color: transparent;
          }

          .${Classes.TAG_REMOVE} {
            display: none;
          }
        }
      }

      & > .${Classes.ICON} {
        align-self: center;
        margin-right: 0px;
        color: ${LegacyNamedColors.SLATE_GRAY};
      }
      .${Classes.INPUT_GHOST}:first-child {
        padding-left: 0;
      }
      .${Classes.INPUT_GHOST} {
        margin: 0;
        display: flex;
        height: ${TAG_HEIGHT}px;
        flex: 1;
        padding-left: 5px;
        width: ${({ query }) => `${(query?.length ?? 0) * 1}ch`};

        &:not(:placeholder-shown) {
          height: 0;
        }

        &:focus {
          height: auto;
        }
      }
    }
  }
`;

const debounceOptions = { leading: false, trailing: true };

export function MultiDropDown({
  selectedItems,
  ...props
}: React.ComponentProps<typeof BlueprintMultiDropDown> &
  StyledMultiDropdownProps) {
  const { inputPadding, overflowTags } = props;

  const containerRef = useRef<HTMLDivElement | null>(null);

  const lastSelectedItemsRef = useRef<DropdownOption[]>([]);
  const lastInputContainerSize = useRef({ width: 0, height: 0 });
  const lastInputPaddingRef = useRef<Padding | undefined>(inputPadding);

  const [itemsToRender, setItemsToRender] = useState(selectedItems);

  const calculateItemsToRender = useCallback(() => {
    const items = selectedItems as DropdownOption[];

    const inputContainer = containerRef.current?.querySelector(
      `.${Classes.INPUT}.${Classes.TAG_INPUT}`,
    );
    if (!inputContainer) return;

    const tagContainer = inputContainer.querySelector(
      `.${Classes.TAG_INPUT_VALUES}`,
    );
    if (!tagContainer || tagContainer.clientWidth === 0) return;

    const tagEls = Array.from(
      inputContainer.querySelectorAll(
        `span.${Classes.TAG}:not(:has([data-value="${MORE_TAGS_VALUE}"]))`,
      ),
    ) as HTMLSpanElement[];

    const availableHeight = inputContainer.clientHeight;
    let lines = Math.floor(availableHeight / (TAG_HEIGHT + 4));
    if (lines <= 0) {
      lines = 1;
    }

    // we can treat the width of the dropdown as the width of the input and the number of lines, because we
    // are flex wrapping. this lets us simplify the calculation of the available space for tags. we do
    // have to account for the min space of the search input and the + more tag
    let availableTagSpace = lines * tagContainer.clientWidth - INPUT_SIZE;

    const itemsToKeep: DropdownOption[] = [];

    const neededSpace = items.reduce((space, _item, index) => {
      const tagEl = tagEls[index];
      if (!tagEl) return space;
      const tagWidth = getTagElementSpace(tagEl);
      return space + tagWidth;
    }, 0);

    if (neededSpace > availableTagSpace) {
      availableTagSpace -= MORE_TAGS_SIZE;
    }

    for (const [index, item] of items.entries()) {
      const tagEl = tagEls[index];
      if (!tagEl) continue;

      const hasVerticalSpace =
        tagEl.offsetTop + tagEl.offsetHeight < availableHeight;

      const tagWidth = getTagElementSpace(tagEl);

      if (hasVerticalSpace && availableTagSpace > tagWidth) {
        availableTagSpace -= tagWidth;
        itemsToKeep.push(item);
      }
    }

    if (itemsToKeep.length < items.length) {
      itemsToKeep.push({
        label: `+${items.length - itemsToKeep.length} items`,
        value: MORE_TAGS_VALUE,
        hiddenLabels: items
          .filter((item) => !itemsToKeep.includes(item))
          .map((item) => item.label),
      });
      setItemsToRender(itemsToKeep);
    }
  }, [selectedItems]);

  const handleSizeChange = useCallback(() => {
    if (!overflowTags) return;

    const inputContainer = containerRef.current?.querySelector(
      `.${Classes.INPUT}.${Classes.TAG_INPUT}`,
    );
    if (!inputContainer) return;

    if (
      inputContainer.clientHeight === 0 ||
      inputContainer.clientWidth === 0 ||
      (lastInputContainerSize.current.width === inputContainer.clientWidth &&
        lastInputContainerSize.current.height === inputContainer.clientHeight)
    ) {
      return;
    }

    lastInputContainerSize.current = {
      width: inputContainer.clientWidth,
      height: inputContainer.clientHeight,
    };

    flushSync(() => {
      setItemsToRender(lastSelectedItemsRef.current);
    });
    calculateItemsToRender?.();
  }, [calculateItemsToRender, overflowTags]);

  const debouncedHandleSizeChange = useDebounce(
    handleSizeChange,
    200,
    debounceOptions,
  );

  useLayoutEffect(() => {
    if (equal(lastSelectedItemsRef.current, selectedItems)) return;

    lastSelectedItemsRef.current = selectedItems;

    if (overflowTags) {
      calculateItemsToRender?.();
    }
  }, [selectedItems, overflowTags, calculateItemsToRender]);

  useEffect(() => {
    if (!overflowTags || equal(inputPadding, lastInputPaddingRef.current))
      return;

    debouncedHandleSizeChange?.();
    lastInputPaddingRef.current = inputPadding;
  }, [inputPadding, overflowTags, debouncedHandleSizeChange]);

  useResizeObserver(containerRef, debouncedHandleSizeChange ?? NOOP);

  // probably a weird pattern, but if the selected items change, we need to immediately render the new items
  // so we can get the accurate tag sizes.
  if (
    !equal(lastSelectedItemsRef.current, selectedItems) &&
    !equal(itemsToRender, selectedItems)
  ) {
    setItemsToRender(selectedItems);
    return null;
  }

  return (
    <div
      ref={containerRef}
      style={{
        height: "100%",
        width: "100%",
      }}
    >
      <StyledMultiDropDown selectedItems={itemsToRender} {...props} />
    </div>
  );
}
