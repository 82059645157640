import { ApplicationScope } from "@superblocksteam/shared";
import React from "react";
import {
  getDerivedPropertiesWithErrors,
  getErrorMessagesList,
} from "legacy/components/editorComponents/ErrorUtils";
import { EventType } from "legacy/constants/ActionConstants";
import { type PropertyPaneConfig } from "legacy/constants/PropertyControlConstants";
import { WidgetType } from "legacy/constants/WidgetConstants";
import { VALIDATION_TYPES } from "legacy/constants/WidgetValidation";
import {
  WidgetPropertyValidationType,
  BASE_WIDGET_VALIDATION,
} from "legacy/constants/WidgetValidation";
import { createRunEventHandlersPayloadOptional } from "legacy/utils/actions";
import BaseWidget, { WidgetState } from "../BaseWidget";

import withMeta from "../withMeta";
import { CheckboxComponentWithLayoutManaged } from "./CheckboxComponent";
import CheckboxWidgetPropertyCategories from "./CheckboxWidgetPropertyCategories";
import { CheckboxWidgetProps } from "./types";
import type { DerivedPropertiesMap } from "../Factory";

class CheckboxWidget extends BaseWidget<CheckboxWidgetProps, WidgetState> {
  static getNewPropertyPaneConfig():
    | PropertyPaneConfig<CheckboxWidgetProps>[]
    | undefined {
    return CheckboxWidgetPropertyCategories;
  }

  static getPropertyPaneConfig(): PropertyPaneConfig<CheckboxWidgetProps>[] {
    throw new Error("Deprecated config should not be called");
  }

  static getPropertyValidationMap(): WidgetPropertyValidationType {
    return {
      ...BASE_WIDGET_VALIDATION,
      label: VALIDATION_TYPES.TEXT,
      defaultCheckedState: VALIDATION_TYPES.BOOLEAN,
      isRequired: VALIDATION_TYPES.BOOLEAN,
      customValidationRule: VALIDATION_TYPES.TEXT,
      customErrorMessage: VALIDATION_TYPES.TEXT,
    };
  }

  static getDefaultPropertiesMap(): Record<string, string> {
    return {
      isChecked: "defaultCheckedState",
    };
  }

  static getDerivedPropertiesMap(): DerivedPropertiesMap {
    return getDerivedPropertiesWithErrors({
      validationErrors: `{{
        (() => {
          let errors = {};
          if (this.isRequired && !this.isChecked) {
            errors.isRequired = "Required";
          }
          
          if (
            this.customValidationRule &&
            this.customValidationRule.length &&
            this.customValidationRule === "false"
          ) {
            errors.customError = this.customErrorMessage || "Invalid";
          }
          return errors;
        })()
      }}`,
      value: `{{this.isChecked}}`,
    });
  }

  static getMetaPropertiesMap(): Record<string, any> {
    return {
      isChecked: undefined,
      isTouched: false,
    };
  }

  getPageView() {
    return (
      <CheckboxComponentWithLayoutManaged
        {...this.props}
        onCheckChange={this.onCheckChange}
        errorMessages={getErrorMessagesList(
          this.props.validationErrors,
          this.props.showError,
        )}
        showError={this.props.showError}
      />
    );
  }

  onCheckChange = (isChecked: boolean) => {
    if (!this.props.isTouched) {
      this.props.updateWidgetMetaProperty("isTouched", true);
    }
    this.props.updateWidgetMetaProperty(
      "isChecked",
      isChecked,
      createRunEventHandlersPayloadOptional({
        steps: this.props.onCheckChange,
        currentScope: ApplicationScope.PAGE,
        type: EventType.ON_CHECK_CHANGE,
        entityName: this.props.widgetName,
      }),
    );
  };

  getWidgetType(): WidgetType {
    return "CHECKBOX_WIDGET";
  }
}

export default CheckboxWidget;
export const ConnectedCheckboxWidget = withMeta(CheckboxWidget);
