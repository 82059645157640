import {
  CustomComponentProperty,
  RegisteredComponents,
} from "@superblocksteam/shared";
import { uniqBy } from "lodash";
import { registerCustomComponentAutoCompleteDefinitions } from "autocomplete/dataTreeTypeDefCreator";
import { registerCustomComponentSettableProperties } from "legacy/constants/EventTriggerPropertiesConstants";
import {
  PropsPanelCategory,
  type PropertyPaneControlConfig,
} from "legacy/constants/PropertyControlConstants";
import { WidgetType, WidgetTypes } from "legacy/constants/WidgetConstants";
import { WidgetPropertyValidationType } from "legacy/constants/WidgetValidation";
import { registerCustomComponentConfigResponse } from "legacy/mockResponses/WidgetConfigResponse";
import { encodeJsExpr } from "utils/string";
import ButtonWidget, {
  ConnectedButtonWidget,
} from "./ButtonWidget/ButtonWidget";
import { ButtonWidgetProps } from "./ButtonWidget/types";
import CalloutWidget, {
  ConnectedCalloutWidget,
} from "./CalloutWidget/CalloutWidget";
import { CalloutWidgetProps } from "./CalloutWidget/types";
import CanvasWidget, {
  CanvasWidgetProps,
  ConnectedCanvasWidget,
} from "./CanvasWidget";
import ChartWidget, {
  ChartWidgetProps,
  ConnectedChartWidget,
} from "./ChartWidget/ChartWidget";
import ChatWidget, { ConnectedChatWidget } from "./ChatWidget/ChatWidget";
import { ChatWidgetProps } from "./ChatWidget/Constants";
import CheckboxWidget, {
  ConnectedCheckboxWidget,
} from "./CheckboxWidget/CheckboxWidget";
import { CheckboxWidgetProps } from "./CheckboxWidget/types";
import CodeWidget, { ConnectedCodeWidget } from "./CodeWidget/CodeWidget";
import { CodeWidgetProps } from "./CodeWidget/types";
import ContainerWidget, { ContainerWidgetProps } from "./ContainerWidget";
import { customComponentType } from "./CustomComponentTypeUtil";
import ConnectedCustomWidget, {
  CustomWidget,
  CustomWidgetProps,
} from "./CustomWidget/CustomWidget";
import { getCCPropertyValidationType } from "./CustomWidget/propertyValueValidation";
import DatePickerWidget, {
  ConnectedDatePickerWidget,
} from "./DatePickerWidget/DatePickerWidget";
import DiffWidget, {
  DiffWidgetProps,
  ConnectedDiffWidget,
} from "./DiffWidget/DiffWidget";
import DropdownWidget, {
  DropdownWidgetProps,
  ConnectedDropDownWidget,
} from "./DropdownWidget/DropdownWidget";
import WidgetFactory, { WidgetStaticFunctions } from "./Factory";
import FilePickerWidget, {
  ConnectedFilePickerWidget,
  FilePickerWidgetProps,
} from "./FilepickerWidget/FilepickerWidget";
import FormButtonWidget, {
  FormButtonWidgetProps,
  ConnectedFormButtonWidget,
} from "./FormButtonWidget";
import FormWidget, { FormWidgetProps, ConnectedFormWidget } from "./FormWidget";
import GridWidget, { GridWidgetProps, ConnectedGridWidget } from "./GridWidget";
import IFrameWidget, {
  IFrameWidgetProps,
  ConnectedIFrameWidget,
} from "./IFrameWidget/IFrameWidget";
import IconWidget, {
  ConnectedIconWidget,
  IconWidgetProps,
} from "./IconWidget/IconWidget";
import ImageWidget, {
  ConnectedImageWidget,
  ImageWidgetProps,
} from "./ImageWidget/ImageWidget";
import InputWidget, { ConnectedInputWidget } from "./InputWidget/InputWidget";
import { ConnectedKeyValueWidget } from "./KeyValueWidget/KeyValueWidget";
import KeyValueWidget from "./KeyValueWidget/KeyValueWidget";
import { KeyValueWidgetProps } from "./KeyValueWidget/types";
import LinkWidget, { ConnectedLinkWidget } from "./LinkWidget/LinkWidget";
import { LinkWidgetProps } from "./LinkWidget/types";
import MapWidget, {
  MapWidgetProps,
  ConnectedMapWidget,
} from "./MapWidget/MapWidget";
import MenuWidget, { ConnectedMenuWidget, MenuWidgetProps } from "./MenuWidget";
import ModalWidget, {
  ConnectedModalWidget,
  ModalWidgetProps,
} from "./ModalWidget/";
import PDFViewerWidget, {
  PDFViewerWidgetProps,
} from "./PDFViewerWidget/PDFViewerWidget";
import PageWidget from "./PageWidget";
import RadioGroupWidget, {
  ConnectedRadioGroupWidget,
} from "./RadioGroupWidget/RadioGroupWidget";
import { RadioGroupWidgetProps } from "./RadioGroupWidget/types";
import RichTextEditorWidget, {
  ConnectedRichTextEditorWidget,
} from "./RichTextEditorWidget/RichTextEditorWidget";
import { RichTextEditorWidgetProps } from "./RichTextEditorWidget/types";
import SectionWidget, {
  SectionWidgetProps,
} from "./SectionWidget/SectionWidget";
import SkeletonWidget, { SkeletonWidgetProps } from "./SkeletonWidget";
import SlideoutWidget, {
  ConnectedSlideoutWidget,
  SlideoutWidgetProps,
} from "./SlideoutWidget";
import SwitchWidget, {
  ConnectedSwitchWidget,
} from "./SwitchWidget/SwitchWidget";
import { SwitchWidgetProps } from "./SwitchWidget/types";
import ConnectedTableWidget, { TableWidget } from "./TableWidget/TableWidget";
import TabsWidget, { ConnectedTabsWidget } from "./TabsWidget/TabsWidget";
import { TabContainerWidgetProps, TabsWidgetProps } from "./TabsWidget/types";
import TextWidget from "./TextWidget/TextWidget";
import { TextWidgetProps } from "./TextWidget/index";
import UnregisteredCustomWidget, {
  UnregisteredCustomWidgetProps,
} from "./UnregisteredCustomWidget";
import VideoWidget, {
  ConnectedVideoWidget,
  VideoWidgetProps,
} from "./VideoWidget/VideoWidget";
import { sizeProperties, visibleProperties } from "./basePropertySections";
import { getPopoverConfig } from "./eventHandlerPanel";
import withComputedHeight, {
  USE_DEFAULT_COMPUTE_HEIGHT,
} from "./withComputedHeight";
import withWidgetProps from "./withWidgetProps";
import type { DatePickerWidgetProps } from "./DatePickerWidget/types";
import type { InputWidgetProps } from "./InputWidget/types";
import type { TableWidgetProps } from "./TableWidget/TableWidgetConstants";

export type WidgetTypeToPropType = {
  [WidgetTypes.CONTAINER_WIDGET]: ContainerWidgetProps;
  [WidgetTypes.TEXT_WIDGET]: TextWidgetProps;
  [WidgetTypes.BUTTON_WIDGET]: ButtonWidgetProps;
  [WidgetTypes.INPUT_WIDGET]: InputWidgetProps;
  [WidgetTypes.CHECKBOX_WIDGET]: CheckboxWidgetProps;
  [WidgetTypes.SWITCH_WIDGET]: SwitchWidgetProps;
  [WidgetTypes.DROP_DOWN_WIDGET]: DropdownWidgetProps;
  [WidgetTypes.RADIO_GROUP_WIDGET]: RadioGroupWidgetProps;
  [WidgetTypes.IMAGE_WIDGET]: ImageWidgetProps;
  [WidgetTypes.TABLE_WIDGET]: TableWidgetProps;
  [WidgetTypes.VIDEO_WIDGET]: VideoWidgetProps;
  [WidgetTypes.FILE_PICKER_WIDGET]: FilePickerWidgetProps;
  [WidgetTypes.DATE_PICKER_WIDGET]: DatePickerWidgetProps;
  [WidgetTypes.TABS_WIDGET]: TabsWidgetProps<TabContainerWidgetProps>;
  [WidgetTypes.MODAL_WIDGET]: ModalWidgetProps;
  [WidgetTypes.SLIDEOUT_WIDGET]: SlideoutWidgetProps;
  [WidgetTypes.RICH_TEXT_EDITOR_WIDGET]: RichTextEditorWidgetProps;
  [WidgetTypes.CHART_WIDGET]: ChartWidgetProps;
  [WidgetTypes.FORM_WIDGET]: FormWidgetProps;
  [WidgetTypes.FORM_BUTTON_WIDGET]: FormButtonWidgetProps;
  [WidgetTypes.MAP_WIDGET]: MapWidgetProps;
  [WidgetTypes.CANVAS_WIDGET]: CanvasWidgetProps;
  [WidgetTypes.PAGE_WIDGET]: ContainerWidgetProps;
  [WidgetTypes.SECTION_WIDGET]: SectionWidgetProps;
  [WidgetTypes.ICON_WIDGET]: IconWidgetProps;
  [WidgetTypes.SKELETON_WIDGET]: SkeletonWidgetProps;
  [WidgetTypes.GRID_WIDGET]: GridWidgetProps;
  [WidgetTypes.CODE_WIDGET]: CodeWidgetProps;
  [WidgetTypes.PDF_VIEWER_WIDGET]: PDFViewerWidgetProps;
  [WidgetTypes.IFRAME_WIDGET]: IFrameWidgetProps;
  [WidgetTypes.DIFF_WIDGET]: DiffWidgetProps;
  [WidgetTypes.CHAT_WIDGET]: ChatWidgetProps;
  [WidgetTypes.CALLOUT_WIDGET]: CalloutWidgetProps;
  [WidgetTypes.MENU_WIDGET]: MenuWidgetProps;
  [WidgetTypes.LINK_WIDGET]: LinkWidgetProps;
  [WidgetTypes.KEY_VALUE_WIDGET]: KeyValueWidgetProps;
  [WidgetTypes.CUSTOM_WIDGET]: CustomWidgetProps;
  [WidgetTypes.UNREGISTERED_CUSTOM_WIDGET]: UnregisteredCustomWidgetProps;
};

export default class WidgetBuilderRegistry {
  static registerWidgetBuilders() {
    WidgetBuilderRegistry.register(
      WidgetTypes.CONTAINER_WIDGET,
      ContainerWidget,
      ContainerWidget,
    );

    WidgetBuilderRegistry.register(
      WidgetTypes.TEXT_WIDGET,
      TextWidget,
      TextWidget,
    );

    WidgetBuilderRegistry.register(
      WidgetTypes.BUTTON_WIDGET,
      ConnectedButtonWidget,
      ButtonWidget,
    );

    WidgetBuilderRegistry.register(
      WidgetTypes.INPUT_WIDGET,
      ConnectedInputWidget,
      InputWidget,
    );

    WidgetBuilderRegistry.register(
      WidgetTypes.CHECKBOX_WIDGET,
      ConnectedCheckboxWidget,
      CheckboxWidget,
    );

    WidgetBuilderRegistry.register(
      WidgetTypes.SWITCH_WIDGET,
      ConnectedSwitchWidget,
      SwitchWidget,
    );

    WidgetBuilderRegistry.register(
      WidgetTypes.DROP_DOWN_WIDGET,
      ConnectedDropDownWidget,
      DropdownWidget,
    );

    WidgetBuilderRegistry.register(
      WidgetTypes.RADIO_GROUP_WIDGET,
      ConnectedRadioGroupWidget,
      RadioGroupWidget,
    );

    WidgetBuilderRegistry.register(
      WidgetTypes.IMAGE_WIDGET,
      ConnectedImageWidget,
      ImageWidget,
    );
    WidgetBuilderRegistry.register(
      WidgetTypes.TABLE_WIDGET,
      ConnectedTableWidget,
      TableWidget,
    );

    WidgetBuilderRegistry.register(
      WidgetTypes.VIDEO_WIDGET,
      ConnectedVideoWidget,
      VideoWidget,
    );

    WidgetBuilderRegistry.register(
      WidgetTypes.FILE_PICKER_WIDGET,
      ConnectedFilePickerWidget,
      FilePickerWidget,
    );

    WidgetBuilderRegistry.register(
      WidgetTypes.DATE_PICKER_WIDGET,
      ConnectedDatePickerWidget,
      DatePickerWidget,
    );
    WidgetBuilderRegistry.register(
      WidgetTypes.TABS_WIDGET,
      ConnectedTabsWidget,
      TabsWidget,
    );
    WidgetBuilderRegistry.register(
      WidgetTypes.MODAL_WIDGET,
      ConnectedModalWidget,
      ModalWidget,
    );
    WidgetBuilderRegistry.register(
      WidgetTypes.SLIDEOUT_WIDGET,
      ConnectedSlideoutWidget,
      SlideoutWidget,
    );
    WidgetBuilderRegistry.register(
      WidgetTypes.RICH_TEXT_EDITOR_WIDGET,
      ConnectedRichTextEditorWidget,
      RichTextEditorWidget,
    );
    WidgetBuilderRegistry.register(
      WidgetTypes.CHART_WIDGET,
      ConnectedChartWidget,
      ChartWidget,
    );
    WidgetBuilderRegistry.register(
      WidgetTypes.FORM_WIDGET,
      ConnectedFormWidget,
      FormWidget,
    );

    WidgetBuilderRegistry.register(
      WidgetTypes.FORM_BUTTON_WIDGET,
      ConnectedFormButtonWidget,
      FormButtonWidget,
    );

    WidgetBuilderRegistry.register(
      WidgetTypes.MAP_WIDGET,
      ConnectedMapWidget,
      MapWidget,
    );

    WidgetBuilderRegistry.register(
      WidgetTypes.CANVAS_WIDGET,
      ConnectedCanvasWidget,
      CanvasWidget,
    );

    WidgetBuilderRegistry.register(
      WidgetTypes.PAGE_WIDGET,
      PageWidget,
      PageWidget,
    );

    WidgetBuilderRegistry.register(
      WidgetTypes.SECTION_WIDGET,
      SectionWidget,
      SectionWidget,
    );

    WidgetBuilderRegistry.register(
      WidgetTypes.ICON_WIDGET,
      ConnectedIconWidget,
      IconWidget,
    );

    WidgetBuilderRegistry.register(
      WidgetTypes.SKELETON_WIDGET,
      SkeletonWidget,
      SkeletonWidget,
    );

    WidgetBuilderRegistry.register(
      WidgetTypes.GRID_WIDGET,
      ConnectedGridWidget,
      GridWidget,
    );

    WidgetBuilderRegistry.register(
      WidgetTypes.CODE_WIDGET,
      ConnectedCodeWidget,
      CodeWidget,
    );

    WidgetBuilderRegistry.register(
      WidgetTypes.PDF_VIEWER_WIDGET,
      PDFViewerWidget,
      PDFViewerWidget,
    );

    WidgetBuilderRegistry.register(
      WidgetTypes.IFRAME_WIDGET,
      ConnectedIFrameWidget,
      IFrameWidget,
    );

    WidgetBuilderRegistry.register(
      WidgetTypes.DIFF_WIDGET,
      ConnectedDiffWidget,
      DiffWidget,
    );

    WidgetBuilderRegistry.register(
      WidgetTypes.CHAT_WIDGET,
      ConnectedChatWidget,
      ChatWidget,
    );

    WidgetBuilderRegistry.register(
      WidgetTypes.CALLOUT_WIDGET,
      ConnectedCalloutWidget,
      CalloutWidget,
    );

    WidgetBuilderRegistry.register(
      WidgetTypes.MENU_WIDGET,
      ConnectedMenuWidget,
      MenuWidget,
    );

    WidgetBuilderRegistry.register(
      WidgetTypes.LINK_WIDGET,
      ConnectedLinkWidget,
      LinkWidget,
    );

    WidgetBuilderRegistry.register(
      WidgetTypes.KEY_VALUE_WIDGET,
      ConnectedKeyValueWidget,
      KeyValueWidget,
    );

    // this is a special widget, it does not need to be wrapped with `withWidgetProps` because it doesn't get evaluated
    // so we do not register it with the `WidgetBuilderRegistry.register`
    WidgetFactory.registerWidgetBuilder(
      WidgetTypes.UNREGISTERED_CUSTOM_WIDGET,
      UnregisteredCustomWidget,
      UnregisteredCustomWidget,
    );
  }

  static registerCustomComponents(customComponents: RegisteredComponents) {
    Object.values(customComponents ?? {}).forEach((value) => {
      // dedupe properties by path
      const properties = uniqBy(value.properties ?? [], "path");
      // dedupe event handlers by path
      const eventHandlers = uniqBy(value.events ?? [], "path");

      const ccControlTypeToSbControlType = (
        property: CustomComponentProperty,
      ) => {
        switch (property.propertiesPanelDisplay?.controlType) {
          case "js-expr":
            return "INPUT_JS_EXPR";
          case "switch":
            return "SWITCH";
          default:
            return "INPUT_TEXT";
        }
      };

      const propertiesSection: PropertyPaneControlConfig[] = properties.flatMap(
        (property) =>
          property.propertiesPanelDisplay === undefined
            ? []
            : [
                {
                  propertyName: `default_${property.path}`,
                  propertyCategory: PropsPanelCategory.Content,
                  helpText: property.description,
                  label: property.propertiesPanelDisplay.label,
                  controlType: ccControlTypeToSbControlType(property),
                  isJSConvertible:
                    property.dataType === "boolean" &&
                    property.propertiesPanelDisplay?.controlType === "switch",
                  placeholderText: property.propertiesPanelDisplay.placeholder,
                  ccExpectedType: property.propertiesPanelDisplay.expectedType,
                  ccExampleData: property.propertiesPanelDisplay.exampleData,
                  isBindProperty: true,
                  isTriggerProperty: false,
                } satisfies PropertyPaneControlConfig,
              ],
      );

      propertiesSection.push(
        ...sizeProperties({
          widthSupportsFitContent: true,
          heightSupportsFitContent: true,
        }),
      );
      propertiesSection.push(...visibleProperties({ useJsExpr: false }));
      const eventHandlersSection = eventHandlers.map((val) =>
        getPopoverConfig(
          val.path,
          "",
          undefined,
          undefined,
          undefined,
          val.label,
        ),
      );

      const propertiesPanel = [
        {
          sectionName: "General",
          children: [...propertiesSection, ...eventHandlersSection],
        },
      ];

      const metaProps = properties.reduce(
        (acc: Record<string, unknown>, val) => {
          acc[val.path] = undefined;
          return acc;
        },
        {},
      );

      const defaultPropertiesMap = properties.reduce(
        (acc, val) => {
          acc[val.path] = `default_${val.path}`;
          return acc;
        },
        {} as Record<string, string>,
      );

      const propertyValidationMap = properties.reduce((acc, val) => {
        acc[`default_${val.path}`] = getCCPropertyValidationType(val.dataType);
        return acc;
      }, {} as WidgetPropertyValidationType);

      WidgetFactory.registerCustomComponentWidgetBuilder(
        customComponentType(value.id) as WidgetType,
        withWidgetProps(ConnectedCustomWidget),
        CustomWidget,
        propertyValidationMap,
        {},
        defaultPropertiesMap,
        metaProps,
        propertiesPanel,
      );

      const defaultValues: Record<string, unknown> = {};
      properties.forEach((property) => {
        if (property.propertiesPanelDisplay?.defaultValue) {
          const value =
            property.propertiesPanelDisplay.controlType === "js-expr"
              ? encodeJsExpr(
                  String(property.propertiesPanelDisplay.defaultValue),
                )
              : property.propertiesPanelDisplay.defaultValue;
          if (value !== undefined) {
            defaultValues[`default_${property.path}`] = value;
          }
        }
      });
      registerCustomComponentConfigResponse(
        customComponentType(value.id) as WidgetType,
        value.name,
        defaultValues,
        value.gridDimensions === undefined
          ? undefined
          : {
              columns: value.gridDimensions.initialColumns,
              rows: value.gridDimensions.initialRows,
            },
      );

      registerCustomComponentAutoCompleteDefinitions(
        customComponentType(value.id) as WidgetType,
        properties,
      );

      registerCustomComponentSettableProperties(
        customComponentType(value.id) as WidgetType,
        properties,
      );
    });
  }

  static register(
    widgetType: WidgetType,
    widget: React.ComponentType<React.PropsWithChildren<any>>,
    widgetClass: React.ComponentClass<any> & WidgetStaticFunctions,
  ) {
    widget =
      widgetClass.computeMinHeightFromProps &&
      widgetClass.computeMinHeightFromProps !== USE_DEFAULT_COMPUTE_HEIGHT
        ? withComputedHeight(widget)
        : widget;
    widget = withWidgetProps(widget);
    WidgetFactory.registerWidgetBuilder(widgetType, widget, widgetClass);
  }
}
