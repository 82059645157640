import equal from "@superblocksteam/fast-deep-equal/react";
import { Dimension, Padding } from "@superblocksteam/shared";
import React, { useCallback, useEffect, useMemo, useRef } from "react";
import { useDrop, DropTargetMonitor } from "react-dnd";
import { useStore } from "react-redux";
import { useSelector } from "react-redux";
import styled from "styled-components";
import tinycolor from "tinycolor2";
import { useWidgetAllowedDropTypeFn } from "hooks/ui/useWidgetAllowedDropTypeFn";
import { Layers } from "legacy/constants/Layers";
import {
  CanvasDistribution,
  CanvasAlignment,
  CanvasLayout,
  WidgetTypes,
  FIT_CONTENT_WIDTH_WIDGETS,
  FIT_CONTENT_DEFAULT_PX_WIDTHS,
} from "legacy/constants/WidgetConstants";
import { useWidgetDragResize } from "legacy/hooks/dragResizeHooks";
import {
  selectIsDragging,
  selectIsResizing,
} from "legacy/selectors/dndSelectors";
import {
  getFlattenedCanvasWidget,
  getStackedWidgets,
} from "legacy/selectors/editorSelectors";
import {
  getSelectedWidgets,
  getSingleSelectedWidget,
} from "legacy/selectors/sagaSelectors";
import { selectGeneratedTheme } from "legacy/selectors/themeSelectors";
import { generateDropTargetElementId } from "legacy/utils/generators";
import { useAppSelector } from "store/helpers";
import { hstackParentColumnSpace } from "utils/size";
import { WidgetPropsRuntime } from "../BaseWidget";
import { DropTargetContext } from "../base/DropTargetUtils";
import { useEdgeScrollEffect } from "../base/useEdgeScrollEffect";
import { DROP_LINE_THICKNESS } from "./constants";
import { useDimensionAdjustmentsOnDrop } from "./stackScrollAvoidance";
import { useStackDragLayer } from "./useStackDragLayer";
import { useHandleStackDrop } from "./useStackDrop";
import { useStackDropProps } from "./useStackDropProps";
import type { WidgetConfigProps } from "legacy/mockResponses/WidgetConfigResponse";
import type { AppState } from "store/types";

const StyledBaseTargetContainer = styled.div`
  position: relative;
  width: 100%;

  // this should match the style in CanvasWidget.getPageView
  &[data-hstack="true"] {
    width: auto;
  }
`;

const PlaceholderContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: ${Layers.vstackDropPlaceholder};
  pointer-events: none;
`;

// todo handle hstack
const StyledDropLine = styled.div`
  position: absolute;
  width: 100%;
  box-sizing: border-box;
  height: ${DROP_LINE_THICKNESS}px;
  z-index: ${Layers.vstackDropPlaceholder};

  &[data-theme-mode="LIGHT"] {
    background-color: ${(props) => props.theme.colors.DRAG_PREVIEW_BLUE};
  }
  &[data-theme-mode="DARK"] {
    background-color: ${(props) => props.theme.colors.DRAG_PREVIEW_BLUE};
  }
  &[data-isactive="true"] {
    box-shadow: inset 0 0 0 3px
      ${({ theme }) =>
        tinycolor(theme.colors.ACCENT_BLUE_NEW_DARKER)
          .setAlpha(0.16)
          .toRgbString()};
    border: 1px solid ${(props) => props.theme.colors.ACCENT_BLUE_NEW_DARKER};
    background-color: ${(props) => props.theme.colors.DRAG_PREVIEW_BLUE};
  }
`;

const computePosFromDistribution = (
  layout: CanvasLayout,
  distribution: CanvasDistribution,
  alignment: CanvasAlignment,
  padding?: Padding,
) => {
  if (layout === CanvasLayout.VSTACK) {
    const left = padding?.left?.value ?? 0;
    const minus = Padding.x(padding).value ?? 0;
    switch (distribution ?? CanvasDistribution.TOP) {
      case CanvasDistribution.TOP:
        return {
          top: `${padding?.top?.value ?? 0}px`,
          height: `${DROP_LINE_THICKNESS}px`,
          width: `calc(100% - ${minus}px)`,
          left: `${left}px`,
        };
      case CanvasDistribution.CENTER:
        return {
          top: "50%",
          height: `${DROP_LINE_THICKNESS}px`,
          width: `calc(100% - ${minus}px)`,
          left: `${left}px`,
        };
      case CanvasDistribution.BOTTOM:
        return {
          bottom: `${padding?.bottom?.value ?? 0}px`,
          height: `${DROP_LINE_THICKNESS}px`,
          width: `calc(100% - ${minus}px)`,
          left: `${left}px`,
        };
    }
  } else {
    const top = padding?.top?.value ?? 0;
    const minus = Padding.y(padding).value ?? 0;
    switch (alignment ?? CanvasAlignment.LEFT) {
      case CanvasAlignment.LEFT:
        return {
          left: `${padding?.left?.value ?? 0}px`,
          width: `${DROP_LINE_THICKNESS}px`,
          top: `${top}px`,
          height: `calc(100% - ${minus}px)`,
        };
      case CanvasAlignment.CENTER:
        return {
          left: "50%",
          width: `${DROP_LINE_THICKNESS}px`,
          top: `${top}px`,
          height: `calc(100% - ${minus}px)`,
        };
      case CanvasAlignment.RIGHT:
        return {
          right: `${padding?.right?.value ?? 0}px`,
          width: `${DROP_LINE_THICKNESS}px`,
          top: `${top}px`,
          height: `calc(100% - ${minus}px)`,
        };
    }
  }
};

const EMPTY_ARR: string[] = [];

const isNoXPadding = (padding: Padding) => {
  return padding?.left?.value === 0 && padding?.right?.value === 0;
};

const isNoYPadding = (padding: Padding) => {
  return padding?.top?.value === 0 && padding?.bottom?.value === 0;
};

const StackDropTargetComponent = React.memo(
  (
    props: Omit<WidgetPropsRuntime, "children"> & {
      children: React.ReactNode;
    },
  ) => {
    const { contextValue, allowedChildTypes, acceptTypes } =
      useStackDropProps(props);
    const { setCurrentDropTarget } = useWidgetDragResize();

    const {
      widgetId: parentWidgetId,
      layout = CanvasLayout.VSTACK,
      distribution = CanvasDistribution.TOP,
      alignment = CanvasAlignment.LEFT,
      children,
      width,
      height,
      parentId,
    } = props;

    const generatedTheme = useSelector(selectGeneratedTheme);
    const store = useStore<AppState>();

    // this is the canvas widget
    const widget = useSelector((state: AppState) =>
      getFlattenedCanvasWidget(state, parentWidgetId),
    );
    const parentWidget = useSelector((state: AppState) =>
      getFlattenedCanvasWidget(state, parentId),
    );

    const noXPadding = isNoXPadding(props.padding ?? {});
    const noYPadding = isNoYPadding(props.padding ?? {});
    const isColumn = parentWidget?.type === WidgetTypes.SECTION_WIDGET;

    const widthToCheck = isColumn ? width : parentWidget?.width;
    const heightToCheck = isColumn ? height : parentWidget?.height;

    const fitOrFillOnLayoutAxis =
      (layout === CanvasLayout.VSTACK &&
        ["fitContent", "fillParent"].includes(heightToCheck.mode)) ||
      (layout === CanvasLayout.HSTACK &&
        ["fitContent", "fillParent"].includes(widthToCheck.mode));

    const childWidgetIds = widget?.children ?? EMPTY_ARR;

    const handleDrop = useHandleStackDrop({
      parentWidget: props,
    });

    const computeDropAdjustments = useDimensionAdjustmentsOnDrop({
      parentWidgetId,
    });

    const [dropInfo, computeDragInfo] = useStackDragLayer({
      parentWidgetId,
      childWidgetIds,
      layout,
    });

    const selectedWidgets = useAppSelector(getSelectedWidgets);
    const getIsAllowedType = useWidgetAllowedDropTypeFn(
      allowedChildTypes,
      selectedWidgets,
    );

    const [{ isExactlyOver, isAllowedType }, dropRef] = useDrop({
      accept: acceptTypes,
      options: {
        arePropsEqual: () => {
          return true;
        },
      },
      drop(widget_: WidgetPropsRuntime & Partial<WidgetConfigProps>, monitor) {
        if (!isExactlyOver || !isAllowedType) {
          return undefined;
        }

        // If the widget is new, set the width to fit content for certain widgets
        const isNew = widget_.widgetName == null;
        const widget = { ...widget_ };
        if (isNew) {
          if (
            FIT_CONTENT_WIDTH_WIDGETS.includes(widget.type) &&
            widget.internalWidth
          ) {
            const stackedWidgets = getStackedWidgets(store.getState());

            const pcs = hstackParentColumnSpace({
              parentWidget: widget,
              children: stackedWidgets,
              numVisibleChildren: childWidgetIds.length + 1, // note: this will be correct unless we have legacy modals/slideouts inside the container
              parentWidth: widget.internalWidth,
            });
            const pxWidth = FIT_CONTENT_DEFAULT_PX_WIDTHS[widget.type];
            widget.width = Dimension.fitContent(pxWidth / pcs);
            if (widget.height.mode === "fitContent") {
              // Convert height to grid unit because both cannot be fit content
              widget.height = Dimension.gridUnit(widget.height.value);
            }
          }
        }

        const dragInfo = computeDragInfo({
          currentOffset: monitor.getClientOffset(),
          draggedWidgetId: monitor.getItem()?.widgetId,
        });

        const adjustments = computeDropAdjustments({
          widget,
          insertionIndex: dragInfo?.insertionIndex ?? 0,
        });

        handleDrop({
          widget,
          insertionIndex: dragInfo?.insertionIndex ?? 0,
          adjustments,
        });
      },
      // Collect isOver for ui transforms when hovering over this component
      collect: (monitor: DropTargetMonitor) => ({
        isExactlyOver: monitor.isOver({ shallow: true }),
        isAllowedType: getIsAllowedType(monitor.getItemType() as WidgetTypes),
        draggedWidgetId: monitor.getItem<any>()?.widgetId,
      }),
      // Only allow drop if the drag object is directly over this component
      canDrop: (widget, monitor) => {
        if (isExactlyOver && isAllowedType) {
          return true;
        }
        return false;
      },
    });

    const showPlaceholder = isExactlyOver && isAllowedType;

    const placeholders = useMemo(() => {
      if (dropInfo == null) {
        return [];
      }
      if (dropInfo.placeholders) {
        const { placeholders } = dropInfo;
        return (
          <>
            {placeholders.map((placeholderPos, idx) => {
              const isFirst = idx === 0;
              const isLast = idx === placeholders.length - 1;

              const style = {
                top: placeholderPos.top,
                left: placeholderPos.left,
                width:
                  placeholderPos.width ??
                  (layout === CanvasLayout.VSTACK
                    ? "100%"
                    : DROP_LINE_THICKNESS),
                height:
                  placeholderPos.height ??
                  (layout === CanvasLayout.VSTACK
                    ? DROP_LINE_THICKNESS
                    : "100%"),
              };

              const styleForEdgeLines: React.CSSProperties = {};

              if (
                fitOrFillOnLayoutAxis &&
                (noXPadding || noYPadding) &&
                (isFirst || isLast)
              ) {
                if (layout === CanvasLayout.HSTACK && noXPadding) {
                  const offset = isFirst ? "+ 2px" : "- 2px";
                  styleForEdgeLines.left = `calc(${placeholderPos.left}px ${offset})`;
                } else if (layout === CanvasLayout.VSTACK && noYPadding) {
                  const offset = isFirst ? "+ 2px" : "- 2px";
                  styleForEdgeLines.top = `calc(${placeholderPos.top}px ${offset})`;
                }
              }

              return (
                <StyledDropLine
                  key={idx}
                  style={{
                    ...style,
                    ...styleForEdgeLines,
                  }}
                  data-test="stack-drop-line"
                  data-isactive={placeholderPos.isActive}
                  data-theme-mode={generatedTheme.mode}
                />
              );
            })}
          </>
        );
      } else {
        // This is for when dropping an item into an empty stack
        const style = computePosFromDistribution(
          layout,
          distribution,
          alignment,
          props.padding,
        );
        return (
          <StyledDropLine
            data-test="stack-drop-line"
            style={style}
            data-isactive={true}
          />
        );
      }
    }, [
      dropInfo,
      distribution,
      alignment,
      layout,
      noXPadding,
      noYPadding,
      fitOrFillOnLayoutAxis,
      props.padding,
      generatedTheme.mode,
    ]);

    const singleSelectedWidget = useSelector(getSingleSelectedWidget);
    const isResizing = useSelector(selectIsResizing);
    const isDragging = useSelector(selectIsDragging);
    const isChildFocused =
      !!singleSelectedWidget &&
      singleSelectedWidget.parentId === props.widgetId;
    const isChildResizing = !!isResizing && isChildFocused;
    useEffect(() => {
      if (isDragging) {
        if (isExactlyOver) {
          setCurrentDropTarget(props.widgetId);
        }
      } else if (isResizing) {
        if (isChildResizing) {
          setCurrentDropTarget(props.widgetId);
        }
      } else {
        setCurrentDropTarget(undefined);
      }
    }, [
      isChildResizing,
      isDragging,
      isExactlyOver,
      isResizing,
      props.widgetId,
      setCurrentDropTarget,
    ]);

    const rawRef = useRef<HTMLDivElement | null>(null);
    useEdgeScrollEffect(rawRef.current, isDragging && isExactlyOver);

    const sharedRef = useCallback(
      (node: HTMLDivElement) => {
        rawRef.current = node;
        dropRef(node);
      },
      [dropRef],
    );

    return (
      <DropTargetContext.Provider value={contextValue}>
        <StyledBaseTargetContainer
          ref={sharedRef}
          data-test="drop-target"
          data-hstack={layout === CanvasLayout.HSTACK}
          id={generateDropTargetElementId(parentWidgetId)}
        >
          <PlaceholderContainer>
            {showPlaceholder && placeholders}
          </PlaceholderContainer>
          {children}
        </StyledBaseTargetContainer>
      </DropTargetContext.Provider>
    );
  },
  (prevProps, nextProps) => {
    return equal(prevProps, nextProps);
  },
);

StackDropTargetComponent.displayName = "StackDropTargetComponent";

export default StackDropTargetComponent;
