import { get } from "lodash";
import {
  PropsPanelCategory,
  type PropertyPaneCategoryConfig,
} from "legacy/constants/PropertyControlConstants";
import { PanelCategory } from "legacy/pages/Editor/PropertyPane/propertyPaneCategoryUtils";
import { DEFAULT_HEADER_TEXT_STYLE_VARIANT } from "legacy/themes/typographyConstants";
import { DATE_INPUT_FORMATS } from "legacy/utils/FormatUtils";
import { getParentPath } from "utils/dottedPaths";
import { sizeProperties, visibleProperties } from "../basePropertySections";
import { getPopoverConfig } from "../eventHandlerPanel";
import { typographyProperties } from "../styleProperties";
import {
  PREFERRED_NAME_FIELDS,
  PREFERRED_MESSAGE_FIELDS,
  type ChatWidgetProps,
  PREFERRED_DATE_FIELDS,
  PREFERRED_AVATAR_FIELDS,
} from "./Constants";
const config: PropertyPaneCategoryConfig<ChatWidgetProps>[] = [
  PanelCategory(PropsPanelCategory.Content, [
    {
      propertyName: "header",
      label: "Header",
      controlType: "INPUT_TEXT",
      placeholderText: "Enter header text",
      isBindProperty: true,
      isTriggerProperty: false,
      defaultValue: "Chat",
      visibility: "SHOW_NAME",
      isRemovable: true,
      propertyCategory: PropsPanelCategory.Content,
    },
    {
      propertyName: "messageHistory",
      label: "Message history",
      helpText:
        "Array containing the message history. Each object in the array represents a message.",
      controlType: "INPUT_TEXT",
      placeholderText: `[{ name: "", text: "" }]`,
      forceVertical: true,
      isJSConvertible: true,
      isBindProperty: true,
      isTriggerProperty: false,
      propertyCategory: PropsPanelCategory.Content,
    },
    {
      helpText: "Field name to use to access the message",
      propertyName: "messageField",
      label: "Message",
      controlType: "OBJECT_INDEXER_CONTROL",
      isBindProperty: true,
      isTriggerProperty: false,
      fieldToGetKeysOf: "messageHistory",
      preferredOptions: PREFERRED_MESSAGE_FIELDS,
      isRequired: true,
      propertyCategory: PropsPanelCategory.Content,
    },

    {
      helpText: "Field name to use to access the user's display name",
      propertyName: "displayNameField",
      label: "Name",
      controlType: "OBJECT_INDEXER_CONTROL",
      fieldToGetKeysOf: "messageHistory",
      isBindProperty: true,
      isTriggerProperty: false,
      preferredOptions: PREFERRED_NAME_FIELDS,
      isRequired: true,
      propertyCategory: PropsPanelCategory.Content,
    },

    {
      helpText: "Field name to use to access the avatar image URL",
      propertyName: "avatarField",
      label: "Avatar URL",
      controlType: "OBJECT_INDEXER_CONTROL",
      fieldToGetKeysOf: "messageHistory",
      isBindProperty: true,
      isTriggerProperty: false,
      preferredOptions: PREFERRED_AVATAR_FIELDS,
      isRequired: false,
      propertyCategory: PropsPanelCategory.Content,
    },
    {
      helpText: "Field name to use to access the timestamp of a message",
      propertyName: "timestampField",
      label: "Timestamp",
      controlType: "OBJECT_INDEXER_CONTROL",
      fieldToGetKeysOf: "messageHistory",
      isBindProperty: true,
      isTriggerProperty: false,
      preferredOptions: PREFERRED_DATE_FIELDS,
      isRequired: false,
      propertyCategory: PropsPanelCategory.Content,
    },
  ]),
  PanelCategory(PropsPanelCategory.Appearance, [
    ...typographyProperties({
      defaultVariant: DEFAULT_HEADER_TEXT_STYLE_VARIANT,
      textStyleParentDottedPath: "headerProps",
      propertyNameForHumans: "Header",
      hiddenIfPropertyNameIsNullOrFalse: "header",
    }),
    {
      propertyName: "placeholderText",
      label: "Placeholder text",
      helpText: "Sets the placeholder text for the chat input",
      controlType: "INPUT_TEXT",
      placeholderText: "Enter placeholder",
      isBindProperty: true,
      isTriggerProperty: false,
      propertyCategory: PropsPanelCategory.Appearance,
      visibility: "SHOW_NAME",
      isRemovable: true,
      defaultValue: "",
    },
    {
      helpText: "Sets the format of the timestamp",
      propertyName: "timestampFormat",
      label: "Timestamp format",
      controlType: "DROP_DOWN",
      options: [...DATE_INPUT_FORMATS, { label: "hh:mm a", value: "hh:mm a" }],
      isBindProperty: true,
      isTriggerProperty: false,
      isJSConvertible: true,
      propertyCategory: PropsPanelCategory.Appearance,
    },
    {
      propertyName: "showPendingMessage",
      helpText:
        "While the onSendMessage action is running, show the message as pending",
      label: "Show pending message",
      controlType: "SWITCH",
      isBindProperty: true,
      isTriggerProperty: false,
      isJSConvertible: false,
      propertyCategory: PropsPanelCategory.Appearance,
    },
    {
      propertyName: "enablePendingState",
      helpText:
        "Enables the pending state for the chat widget after a message has been sent",
      label: "Enable pending state",
      controlType: "SWITCH",
      isBindProperty: true,
      isTriggerProperty: false,
      isJSConvertible: false,
      propertyCategory: PropsPanelCategory.Appearance,
    },
    {
      propertyName: "pendingStateText",
      helpText: "Sets the text for the pending state",
      label: "Pending state text",
      controlType: "INPUT_TEXT",
      placeholderText: "Enter text",
      isBindProperty: true,
      isTriggerProperty: false,
      isJSConvertible: false,
      hidden: (props: ChatWidgetProps, propertyPath: string) => {
        const parentPath = getParentPath(propertyPath);
        const enablePendingState = get(
          props,
          `${
            parentPath
              ? `${parentPath}.enablePendingState`
              : "enablePendingState"
          }`,
          "",
        );
        return !enablePendingState;
      },
      propertyCategory: PropsPanelCategory.Appearance,
    },
    {
      propertyName: "pendingStateTimeout",
      helpText:
        "Length of time between sending the message and showing the pending state in seconds",
      label: "Pending state timeout",
      controlType: "TIMER_INTERVAL",
      isBindProperty: true,
      isTriggerProperty: false,
      isJSConvertible: false,
      minValue: 0,
      hidden: (props: ChatWidgetProps, propertyPath: string) => {
        const parentPath = getParentPath(propertyPath);
        const enablePendingState = get(
          props,
          `${
            parentPath
              ? `${parentPath}.enablePendingState`
              : "enablePendingState"
          }`,
          "",
        );
        return !enablePendingState;
      },
      propertyCategory: PropsPanelCategory.Appearance,
    },

    {
      helpText: "Show emoji menu in editor.",
      propertyName: "enableEmojis",
      label: "Allow emojis",
      controlType: "SWITCH",
      isBindProperty: true,
      isJSConvertible: true,
      isTriggerProperty: false,
      propertyCategory: PropsPanelCategory.Appearance,
    },
  ]),
  PanelCategory(PropsPanelCategory.Layout, [
    ...sizeProperties(),
    ...visibleProperties({ useJsExpr: false }),
  ]),
  PanelCategory(PropsPanelCategory.Interaction, [
    {
      helpText: "Allow users to search for messages",
      propertyName: "enableChatSearch",
      label: "Search",
      controlType: "SWITCH",
      isBindProperty: true,
      isTriggerProperty: false,
      isJSConvertible: true,
      propertyCategory: PropsPanelCategory.Interaction,
    },
    {
      helpText: "Hitting enter will send the message",
      propertyName: "enableEnterToSend",
      label: "Send on 'Enter'",
      controlType: "SWITCH",
      isBindProperty: true,
      isJSConvertible: true,
      isTriggerProperty: false,
      propertyCategory: PropsPanelCategory.Interaction,
    },
    {
      helpText: "Allow users to add images to their messages",
      propertyName: "enableImages",
      label: "Allow images",
      controlType: "SWITCH",
      isBindProperty: true,
      isJSConvertible: true,
      isTriggerProperty: false,
      propertyCategory: PropsPanelCategory.Interaction,
    },
  ]),
  PanelCategory(PropsPanelCategory.EventHandlers, [
    getPopoverConfig("onMessageSend", ""),
  ]),
];
export default config;
