import { Dimension } from "@superblocksteam/shared";
import {
  PropsPanelCategory,
  type PropertyPaneCategoryConfig,
} from "legacy/constants/PropertyControlConstants";
import { WidgetLabelPosition } from "legacy/constants/WidgetConstants";
import { PanelCategory } from "legacy/pages/Editor/PropertyPane/propertyPaneCategoryUtils";
import {
  customValidationProperties,
  errorMessagePlacementProperty,
  labelWidthProperty,
} from "../FormInputs/formInputsProperties";
import {
  isDisabledProperty,
  labelPositionProperty,
  sizeProperties,
  visibleProperties,
} from "../basePropertySections";
import { getPopoverConfig } from "../eventHandlerPanel";
import { typographyProperties } from "../styleProperties";
import { DEFAULT_SWITCH_WIDGET_LABEL_TEXT_STYLE_VARIANT } from "./constants";
import { type SwitchWidgetProps } from "./types";

const config: PropertyPaneCategoryConfig<SwitchWidgetProps>[] = [
  PanelCategory(PropsPanelCategory.Content, [
    {
      propertyName: "label",
      label: "Label",
      controlType: "INPUT_TEXT",
      helpText: "The label to be displayed besides the switch",
      placeholderText: "Enter label text",
      isBindProperty: true,
      isTriggerProperty: false,
      visibility: "SHOW_NAME",
      isRemovable: true,
      defaultValue: "Label",
      propertyCategory: PropsPanelCategory.Content,
    },
    {
      propertyName: "defaultSwitchState",
      label: "Default toggled",
      helpText: "Whether the switch is toggled on by default",
      controlType: "SWITCH",
      isJSConvertible: true,
      isBindProperty: true,
      isTriggerProperty: false,
      propertyCategory: PropsPanelCategory.Content,
    },
  ]),
  PanelCategory(PropsPanelCategory.Appearance, [
    labelPositionProperty({ orientation: "horizontal" }),
    ...typographyProperties({
      defaultVariant: DEFAULT_SWITCH_WIDGET_LABEL_TEXT_STYLE_VARIANT,
      textStyleParentDottedPath: "labelProps",
      propertyNameForHumans: "Label",
      hiddenIfPropertyNameIsNullOrFalse: "label",
    }),
  ]),
  PanelCategory(PropsPanelCategory.Layout, [
    ...sizeProperties({
      widthSupportsFitContent: true,
      heightSupportsFitContent: true,
    }),
    labelWidthProperty({
      options: [
        {
          label: "Fit Content",
          labelWhenSelected: "Fit Content",
          value: "fitContent",
          subText: "Automatically adjust the label width based on the content",
        },
        {
          label: "Fill parent",
          value: "fillParent",
          subText: "Label expands to fill the remaining width in the component",
        },
      ],
      defaultValue: Dimension.fitContent(1),
      hidden: (props: SwitchWidgetProps) =>
        !props.label ||
        !props.labelPosition ||
        props.labelPosition === WidgetLabelPosition.RIGHT,
    }),
    ...visibleProperties({ useJsExpr: false }),
  ]),
  PanelCategory(PropsPanelCategory.Interaction, [
    {
      propertyName: "isRequired",
      label: "Required",
      helpText: "Requires that the switch is toggled on for form submission",
      controlType: "SWITCH",
      isJSConvertible: true,
      isBindProperty: true,
      isTriggerProperty: false,
      propertyCategory: PropsPanelCategory.Interaction,
    },
    isDisabledProperty({ useJsExpr: false }),
    errorMessagePlacementProperty(),
    ...customValidationProperties(),
  ]),
  PanelCategory(PropsPanelCategory.EventHandlers, [
    getPopoverConfig(
      "onSwitchChange",
      "Triggers an action when the switch state is changed",
    ),
  ]),
];
export default config;
