import { Placement } from "@popperjs/core";
import { Input, InputNumber, Tooltip } from "antd";
import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { CustomPicker, ColorChangeHandler } from "react-color";
import { Hue, Saturation, Alpha } from "react-color/lib/components/common";
import tinycolor from "tinycolor2";
import { ReactComponent as UndoIcon } from "assets/icons/common/undo.svg";
import Popper from "components/ui/Popper";
import { usePointerDownOutside } from "hooks/ui";
import { colors } from "styles/colors";
import { styleAsClass } from "styles/styleAsClass";
import IconButton from "./IconButtons/IconButton";

export const hexToHexString = (hex: string) => {
  if (hex.startsWith("#")) {
    return hex;
  }
  return `#${hex}`;
};

const hexStringToHex = (hexString: string) => {
  if (hexString.startsWith("#")) {
    return hexString.slice(1);
  }
  return hexString;
};

// a repeating checkerboard
const transparentCheckerStyle = {
  backgroundImage:
    "linear-gradient(45deg, #aaa7 25%, transparent 25%), linear-gradient(-45deg, #aaa7 25%, transparent 25%), linear-gradient(45deg, transparent 75%, #aaa7 75%), linear-gradient(-45deg, transparent 75%, #aaa7 75%)",
  backgroundSize: "6px 6px",
  transform: "rotate(45deg)",
};

const colorIsTransparent = (color: string) => {
  try {
    return tinycolor(color).getAlpha() === 0;
  } catch (e) {
    return false;
  }
};

export type PresetOption = {
  hexValue: string; // used for displaying preview of color
  displayName: string; // used for displaying name of color
  value: string; // used for storing value of color
  key?: string;
};

export type ColorPickerProps = {
  presetColorChoices?: Array<{
    title: string;
    options: Array<PresetOption>;
  }>;
  selectedColor?: PresetOption | string;
  defaultSelectedColor?: PresetOption | string | null;
  onColorSelect: (colorValue?: string, colorHex?: string) => void;
  onColorPickerClose?: () => void;
  onColorPickerOpen?: () => void;
  popoverPlacement?: Placement;
  showOpacity?: boolean;
  showInputsInPopover?: boolean;
  compact?: boolean;
  isClearable?: boolean;
  disabled?: boolean;
  clearBehavior?: "clear" | "reset"; // when set to clear, the color picker will clear the color, when set to reset, the color picker will reset to the default color
  dataTest?: string;
  placeholder?: string;
};

const StyledInput = styleAsClass`
  padding-left: 7px;
  input, &.ant-input-affix-wrapper > .ant-input {
    font-size: 12px;
    line-height: 12px;
  }
  span {
    font-size: 12px;
    line-height: 12px;
  }
  &.ant-input-affix-wrapper {
    padding: 4px 8px;
  }
`;

const InputNoCursor = styleAsClass`
  cursor: pointer;
  caret-color: transparent;

  &.ant-input-affix-wrapper {
    padding: 4px 8px;
  }
  input {
    cursor: pointer;
  }
`;

const Preview = styleAsClass`
  border-radius: 50%;
  height: 16px;
  width: 16px;
  border: 1px solid;
  overflow: hidden;
  position: relative;
  &[data-size="LARGE"] {
    height: 20px;
    width: 20px;
  }
`;

const Wrapper = styleAsClass`
  box-shadow: 0px 0px 1px 0px rgba(34, 39, 47, 0.32),
    0px 12px 32px -8px rgba(34, 39, 47, 0.16),
    0px 1px 3px 0px rgba(34, 39, 47, 0.12);
  border-radius: 4px;
  background: ${colors.WHITE};
  padding: 6px 12px 12px;
  width: 267px;
  position: relative;
  .Header {
    color: ${colors.GREY_800};
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    padding: 4px 0px;
  }
  .PresetColors {
    display: flex;
    flex-wrap: wrap;
    gap: 6px;
  }
  .SaturationWrapper {
    cursor: pointer;
    position: relative;
    height: 146px;
    width: 100%;
    margin-bottom: 12px;
    & > div {
      border-radius: 4px;
    }
    .saturation-white,
    .saturation-black {
      border-radius: 4px;
    }
  }
  .HueWrapper {
    cursor: pointer;
    height: 8px;
    width: 100%;
    position: relative;

    margin-bottom: 12px;
    .hue-horizontal {
      border-radius: 10px;
    }
  }
  .CustomPointer {
    width: 8px;
    height: 8px;
    cursor: pointer;
    box-shadow: rgb(255, 255, 255) 0px 0px 0px 3px,
      rgba(0, 0, 0, 0.4) 0px 0px 2px 4px;
    border-radius: 50%;
    &.hue-pointer {
      transform: translate(-4px, 0px);
    }
    &.saturation-pointer {
      transform: translate(-4px, -4px);
    }
    &.alpha-pointer {
      transform: translate(-6px, 0px);
    }
  }

  .InputLabel {
    font-size: 12px;
    font-weight: 500;
    margin-bottom: 4px;
  }

  .Inputs {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 12px;
  }
`;

function HexPointer(props: { hex: string }) {
  return (
    <div
      className={`CustomPointer saturation-pointer`}
      style={{
        backgroundColor: props.hex,
      }}
    />
  );
}

function AlphaPointer(props: { hex: string }) {
  return (
    <div
      className={`CustomPointer alpha-pointer`}
      style={{
        backgroundColor: props.hex,
      }}
    />
  );
}

function HueOnlyPointer(props: { hsv: { h: number } }) {
  return (
    <div
      className={`CustomPointer hue-pointer`}
      style={{
        backgroundColor: `${tinycolor({
          h: props.hsv.h,
          s: 1,
          v: 1,
          a: 1,
        }).toHexString()}`,
      }}
    />
  );
}

class ColorPickerPopover extends React.Component<
  ColorPickerProps & {
    hexColor: string;
  },
  {
    hsv: { h: number; s: number; v: number; a: number };
    hsl: { h: number; s: number; l: number; a: number };
    rgb: { r: number; g: number; b: number; a: number };
    alphaInput: number;
    hex: string;
    dirty: boolean;
  }
> {
  state = {
    hsl: {
      h: 0,
      s: 0,
      l: 0,
      a: 1,
    },
    hsv: {
      h: 0,
      s: 0,
      v: 0,
      a: 1,
    },
    rgb: {
      r: 0,
      g: 0,
      b: 0,
      a: 1,
    },
    alphaInput: 1,
    hex: "aaaaaa",
    dirty: false,
  };

  updateColorHex = (hex: string) => {
    this.props.onColorSelect(hex, hex);
    this.setState({ dirty: false });
  };

  generateColorFromHex = (hex?: string) => {
    if (!hex) return;
    const color = tinycolor(this.props.hexColor);
    this.setState({
      hsv: color.toHsv(),
      hsl: color.toHsl(),
      hex: this.toHex(color),
      rgb: color.toRgb(),
      alphaInput: color.getAlpha(),
    });
  };

  toHex = (color: tinycolor.Instance) => {
    if (this.props.showOpacity && color.getAlpha() !== 1) {
      return color.toHex8String();
    }
    return color.toHexString();
  };

  componentDidMount(): void {
    this.generateColorFromHex(this.props.hexColor);
  }

  componentDidUpdate(
    prevProps: Readonly<ColorPickerProps & { hexColor: string }>,
  ): void {
    if (prevProps.hexColor !== this.props.hexColor) {
      this.generateColorFromHex(this.props.hexColor);
    }
  }

  handleHueChange = (hue: {
    a: number;
    h: number;
    l: number;
    s: number;
    source: "hsl";
  }) => {
    const color = tinycolor(hue);
    this.updateColorHex(this.toHex(color));
  };

  handleSaturationChange = (hsv: {
    a: number;
    h: number;
    v: number;
    s: number;
    source: "hsv";
  }) => {
    const color = tinycolor(hsv);
    this.updateColorHex(this.toHex(color));
  };

  handleAlphaChange = (hsl: {
    h: number;
    s: number;
    l: number;
    a: number;
    source: "rgb";
  }) => {
    const color = tinycolor(hsl);
    this.updateColorHex(this.toHex(color));
  };

  handleHexInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    this.setState({ hex: event.target.value, dirty: true });
  };

  handleHexInputBlur = () => {
    if (!this.state.dirty) return;
    const color = tinycolor(this.state.hex);
    this.updateColorHex(this.toHex(color));
  };

  handleAlphaInputChange = (value: number | null) => {
    this.setState({ alphaInput: value ?? 1, dirty: true });
  };

  handleAlphaInputBlur = () => {
    if (!this.state.dirty) return;
    const color = tinycolor(this.props.hexColor);
    const hex = this.toHex(color.setAlpha(this.state.alphaInput));
    this.updateColorHex(hex);
  };

  componentWillUnmount = () => {
    if (!this.state.dirty) return;
    const color = tinycolor(this.state.hex);
    const hex = this.toHex(color.setAlpha(this.state.alphaInput));
    this.updateColorHex(hex);
  };

  render() {
    return (
      <div className={Wrapper}>
        {this.props.presetColorChoices &&
          this.props.presetColorChoices.map((section, i) => (
            <div
              key={section.title}
              style={i !== 0 ? { marginTop: "8px" } : {}}
            >
              <div className={"Header"}>{section.title}</div>
              <div className={"PresetColors"}>
                {section.options.map((option: PresetOption) => {
                  const isTransparent = colorIsTransparent(option.hexValue);
                  const content = (
                    <div
                      data-test={`preset-color-${option.displayName}`}
                      className={Preview}
                      onClick={(e) => {
                        this.props.onColorSelect(option.value, option.hexValue);
                        this.setState({ dirty: false });
                      }}
                      key={option.value}
                      style={{
                        cursor: "pointer",
                        ...(isTransparent
                          ? transparentCheckerStyle
                          : { background: option.hexValue }),
                        borderColor:
                          option.hexValue === "#FFFFFF" || isTransparent
                            ? "#D0D7DD"
                            : option.hexValue,
                      }}
                      data-size="LARGE"
                    />
                  );
                  if (option.displayName) {
                    return (
                      <Tooltip
                        title={option.displayName}
                        mouseEnterDelay={0.4}
                        key={option.displayName}
                      >
                        {content}
                      </Tooltip>
                    );
                  }
                  return content;
                })}
              </div>
            </div>
          ))}
        <div>
          <div className={"Header"} style={{ marginTop: "8px" }}>
            Custom
          </div>
          <div
            className={"SaturationWrapper"}
            data-test="color-picker-saturation"
          >
            <Saturation
              {...this.state}
              onChange={
                this.handleSaturationChange as any as ColorChangeHandler
              }
              pointer={HexPointer as any}
            />
          </div>
          <div className={"HueWrapper"}>
            <Hue
              {...this.state}
              onChange={this.handleHueChange as any as ColorChangeHandler}
              direction={"horizontal"}
              pointer={HueOnlyPointer as any}
            />
          </div>
          {this.props.showOpacity && (
            <div className={"HueWrapper"}>
              <Alpha
                {...this.state}
                onChange={this.handleAlphaChange as any as ColorChangeHandler}
                pointer={AlphaPointer as any}
                // @ts-expect-error the types file is out of date, this prop is correct
                radius={"10px"}
              />
            </div>
          )}
          {this.props.showInputsInPopover && (
            <div className={"Inputs"}>
              <div>
                <span className={"InputLabel"}>HEX</span>
                <Input
                  data-test={`${this.props.dataTest}-popover-hex-input`}
                  onChange={this.handleHexInputChange}
                  onBlur={this.handleHexInputBlur}
                  onPressEnter={this.handleHexInputBlur}
                  value={hexStringToHex(this.state.hex).substring(0, 6)}
                  placeholder="Select a color"
                  prefix="#"
                  className={StyledInput}
                />
              </div>
              {this.props.showOpacity && (
                <div>
                  <span className={"InputLabel"}>Opacity</span>
                  <InputNumber
                    controls={false}
                    onChange={this.handleAlphaInputChange}
                    onBlur={this.handleAlphaInputBlur}
                    onPressEnter={this.handleAlphaInputBlur}
                    value={this.state.alphaInput}
                    placeholder="Opacity"
                    max={1}
                    min={0}
                    step={0.1}
                    precision={2}
                    className={StyledInput}
                  />
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    );
  }
}

const WrappedColorPickerPopover = CustomPicker(ColorPickerPopover);

export const ColorPicker = (props: ColorPickerProps) => {
  const {
    selectedColor,
    onColorSelect,
    onColorPickerClose,
    onColorPickerOpen,
    popoverPlacement,
    compact,
    defaultSelectedColor,
    disabled,
    placeholder,
  } = props;
  const inputWrapperRef = useRef<HTMLDivElement>(null);
  const [showColorPicker, setShowColorPicker] = useState(false);
  const selectedOption: null | PresetOption = useMemo(() => {
    if (!selectedColor) return null;
    if (typeof selectedColor === "string") {
      return {
        displayName: selectedColor.toUpperCase(),
        hexValue: selectedColor,
        value: selectedColor,
      };
    }

    return selectedColor;
  }, [selectedColor]);

  const defaultSelectedOption: null | PresetOption = useMemo(() => {
    if (!defaultSelectedColor) return null;
    if (typeof defaultSelectedColor === "string") {
      return {
        displayName: defaultSelectedColor,
        hexValue: defaultSelectedColor,
        value: defaultSelectedColor,
      };
    }

    return defaultSelectedColor;
  }, [defaultSelectedColor]);

  const [localHexValue, setLocalHexValue] = useState(
    selectedOption?.hexValue.toUpperCase() ?? "",
  );
  const [isDirty, setIsDirty] = useState(false);

  // Recommended approach for adjusting partial state when a prop changes
  const [prevSelectedOptionHexValue, setPrevSelectedOptionHexValue] = useState(
    selectedOption?.hexValue,
  );
  if (
    !showColorPicker &&
    selectedOption?.hexValue !== prevSelectedOptionHexValue
  ) {
    setPrevSelectedOptionHexValue(selectedOption?.hexValue);
    setLocalHexValue(
      selectedOption?.hexValue ? selectedOption?.hexValue.toUpperCase() : "",
    );
  }

  const handleClose = useCallback(() => {
    setShowColorPicker(false);
    onColorPickerClose?.();
  }, [onColorPickerClose]);

  usePointerDownOutside({
    wrapperRefs: [inputWrapperRef],
    wrapperSelectors: [".color-picker-wrapper"],
    onClickOutside: handleClose,
  });

  const handleInputChange = useCallback(
    (event: any) => {
      if (props.showInputsInPopover) return; // don't allow editing base input
      setLocalHexValue(event.target.value.toUpperCase());
      setIsDirty(true);
      if (tinycolor(event.target.value).isValid()) {
        onColorSelect(event.target.value.toUpperCase());
        setIsDirty(false);
      }
    },
    [onColorSelect, props.showInputsInPopover],
  );

  const handleInputBlur = useCallback(() => {
    if (!isDirty) return;
    onColorSelect(hexStringToHex(localHexValue).toUpperCase());
  }, [isDirty, localHexValue, onColorSelect]);

  const handleInputFocus = useCallback(() => {
    setShowColorPicker(true);
    onColorPickerOpen?.();
  }, [onColorPickerOpen]);

  const handleColorSelect = useCallback(
    (colorValue?: string, colorHex?: string) => {
      // if switching from transparent to a color, set the alpha to 1
      if (
        colorValue !== "transparent" &&
        colorHex === colorValue &&
        colorHex != null &&
        colorIsTransparent(colorHex)
      ) {
        colorHex = new tinycolor(colorHex).setAlpha(1).toRgbString();
      }
      if (colorValue) {
        onColorSelect(colorValue);
        setLocalHexValue((colorHex ?? colorValue).toUpperCase());
      } else {
        onColorSelect(undefined);
        setLocalHexValue("");
      }
    },
    [onColorSelect],
  );
  const [displayedValue, displayedColor] = useMemo(() => {
    if (props.showInputsInPopover) {
      const displayedValue = selectedOption?.displayName
        ? selectedOption.displayName
        : defaultSelectedOption
          ? defaultSelectedOption.displayName
          : "";
      const displayedColor = selectedOption?.hexValue
        ? selectedOption.hexValue
        : defaultSelectedOption
          ? defaultSelectedOption.hexValue
          : "";

      return [
        displayedValue,
        displayedColor ? hexToHexString(displayedColor) : displayedColor,
      ];
    }
    return [localHexValue, localHexValue];
  }, [
    localHexValue,
    props.showInputsInPopover,
    selectedOption,
    defaultSelectedOption,
  ]);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (showColorPicker && event.key === "Escape") {
        event.stopPropagation();
        event.preventDefault();
        handleClose();
      }
    };
    window.addEventListener("keydown", handleKeyDown, true);
    return () => {
      window.removeEventListener("keydown", handleKeyDown, true);
    };
  }, [showColorPicker, handleClose]);

  return (
    <>
      <div ref={inputWrapperRef} style={{ position: "relative" }}>
        {compact ? (
          <div
            onClick={
              disabled
                ? undefined
                : () => {
                    setShowColorPicker(true);
                  }
            }
            style={{
              border: `1px solid ${colors.GREY_100}`,
              borderRadius: "4px",
              padding: "6px",
              cursor: disabled ? "not-allowed" : "pointer",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
            className="compact-color-picker-box"
          >
            <div
              className={Preview}
              data-size="SMALL"
              data-test="color-preview"
              style={{
                ...(colorIsTransparent(displayedColor)
                  ? { ...transparentCheckerStyle, borderColor: "#aaa7" }
                  : {
                      background: displayedColor,
                      borderColor: new tinycolor(displayedColor)
                        .darken(20)
                        .toHexString(),
                    }),
              }}
            />
          </div>
        ) : (
          <>
            <Input
              data-test={props.dataTest ?? "color-picker-input"}
              onFocus={handleInputFocus}
              onClick={handleInputFocus}
              onChange={handleInputChange}
              onBlur={handleInputBlur}
              onPressEnter={handleInputBlur}
              value={displayedValue}
              placeholder={placeholder ?? "Select a color"}
              className={`${StyledInput} ${
                props.showInputsInPopover ? InputNoCursor : ""
              }`}
              disabled={disabled}
              prefix={
                <div
                  className={Preview}
                  data-size="SMALL"
                  data-test="color-preview"
                  style={{
                    ...(colorIsTransparent(displayedColor)
                      ? { ...transparentCheckerStyle, borderColor: "#aaa7" }
                      : {
                          background: displayedColor,
                          borderColor: new tinycolor(displayedColor)
                            .darken(20)
                            .toHexString(),
                        }),
                    marginRight: "4px",
                  }}
                />
              }
            />
            {props.isClearable && selectedColor && !disabled ? (
              <div
                style={{
                  position: "absolute",
                  right: "4px",
                  top: "0px",
                  zIndex: 2,
                }}
              >
                <Tooltip title="Reset to default" placement="topLeft">
                  <IconButton
                    style={{ marginTop: "4px" }}
                    icon={<UndoIcon style={{ color: colors.GREY_300 }} />}
                    onClick={(e) => {
                      e.preventDefault();
                      if (props.clearBehavior === "reset") {
                        handleColorSelect(defaultSelectedOption?.hexValue);
                      } else {
                        handleColorSelect(undefined);
                      }
                    }}
                    disabled={disabled}
                    data-test={"color-picker-clear"}
                  />
                </Tooltip>
              </div>
            ) : undefined}
          </>
        )}
      </div>
      {showColorPicker && inputWrapperRef.current && (
        <Popper
          isOpen={true}
          targetNode={inputWrapperRef.current}
          placement={popoverPlacement ?? "bottom-start"}
          zIndex={10}
        >
          <div
            className="color-picker-wrapper"
            data-test={"color-picker-popper"}
          >
            <WrappedColorPickerPopover
              {...props}
              selectedColor={(localHexValue || displayedColor) ?? ""}
              onColorSelect={handleColorSelect}
              hexColor={(localHexValue || displayedColor) ?? ""}
            />
          </div>
        </Popper>
      )}
    </>
  );
};
