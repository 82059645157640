// returns a setter function that makes deep sets on the provided object
// the return value of the setter function is a shallow copy of the object
const getSpreadDetails = (nextPart: unknown, nextKey: string | number) => {
  const isArrayType =
    typeof nextKey === "number" &&
    (Array.isArray(nextPart) ||
      typeof nextPart !== "object" ||
      nextPart == null);
  const canBeSpread =
    typeof nextPart === "object" &&
    nextPart !== null &&
    (!isArrayType || Array.isArray(nextPart));
  return { canBeSpread, isArrayType };
};

export const makeShallowSetter = <T extends Record<string, any> | Array<any>>(
  object: T,
): ((path: Array<string | number>, value: unknown) => T) => {
  const wrappingObject = {
    ROOT: object,
  };
  const deepShallowSet = (path: Array<string | number>, value: unknown) => {
    if (path.length === 0) {
      throw new Error("Path cannot be empty");
    }

    let currPart: any = wrappingObject;
    for (let i = -1; i < path.length - 1; i++) {
      const key = i === -1 ? "ROOT" : path[i];
      const nextKey = path[i + 1];
      const nextPart = currPart[key];
      const { canBeSpread, isArrayType } = getSpreadDetails(nextPart, nextKey);
      if (canBeSpread) {
        currPart[key] = isArrayType ? [...nextPart] : { ...nextPart };
      } else {
        currPart[key] = isArrayType ? [] : {};
      }
      currPart = currPart[key];
    }
    currPart[path[path.length - 1]] = value;

    return wrappingObject.ROOT;
  };

  return deepShallowSet;
};
