import { Classes } from "@blueprintjs/core";
import { isArray } from "lodash";
import React, { createRef } from "react";
import { FormInputWithErrorWrapperStyle } from "legacy/components/editorComponents/ErrorInlineMessage";
import WidgetErrorsWrapper from "legacy/components/editorComponents/WidgetErrorsWrapper";
import { WIDGET_PADDING } from "legacy/constants/WidgetConstants";
import { CLASS_NAMES } from "legacy/themes/classnames";
import { getTextCssClassFromVariant } from "legacy/themes/utils";
import { styleAsClass } from "styles/styleAsClass";
import logger from "utils/logger";
import {
  getLabelWidthCssValue,
  labelStyleRaw,
} from "../Shared/widgetLabelStyles";
import { TypographyProps, useTypographyStyling } from "../typographyHooks";
import { createStyleClassName } from "../typographyUtils";
import { RadioInput } from "./RadioInput";
import {
  DEFAULT_RADIO_GROUP_WIDGET_LABEL_TEXT_STYLE_VARIANT,
  DEFAULT_RADIO_GROUP_WIDGET_OPTION_TEXT_STYLE_VARIANT,
} from "./constants";
import type {
  RadioGroupComponentWithLayoutManagedProps,
  RadioGroupComponentProps,
} from "./types";

const RadioGroupWrapper = styleAsClass`
  display: flex;
  width: 100%;

  label.radio-group-label {
    flex: 0 0 auto;
    align-self: flex-start;
    white-space: normal;

    ${labelStyleRaw.vertical}

    &:not(.horizontal) {
      width: fit-content;
    }

    &.horizontal {
      ${labelStyleRaw.horizontal}
      margin-top: 7px;
      flex-basis: var(--label-width);
      max-width: calc(var(--label-width) - ${String(WIDGET_PADDING)}px);
    }
  }

  .radio-group-list {
    display: flex;
    flex-direction: column;
    width: max-content;
    max-width: 100%;
    width: fit-content;
  }
`;

export const StaticTypographyRadioGroupLabelProps: TypographyProps = {
  textStyleVariant: DEFAULT_RADIO_GROUP_WIDGET_LABEL_TEXT_STYLE_VARIANT,
};

export const StaticTypographyRadioGroupOptionProps: TypographyProps = {
  textStyleVariant: DEFAULT_RADIO_GROUP_WIDGET_OPTION_TEXT_STYLE_VARIANT,
};

class RadioGroupComponent extends React.Component<RadioGroupComponentProps> {
  containerRef = createRef<HTMLDivElement>();

  render() {
    if (!isArray(this.props.options)) {
      try {
        // this error occurs very rarely, will keep this log until we can find the root cause
        logger.error(
          `RadioGroupComponent: options is not an array: ${JSON.stringify(
            this.props.options,
          )}`,
        );
      } catch (error: any) {
        logger.error(
          `RadioGroupComponent: options cannot be serialized, ${error?.message}`,
        );
      }
    }

    const labelClass = createStyleClassName({
      textStyleVariant: this.props.labelTypographyProps.textStyleVariant,
      isDisabled: this.props.isDisabled,
      type: "label",
    });

    const optionClass = getTextCssClassFromVariant(
      this.props.optionTypographyProps.textStyleVariant,
    );

    const styleVars: Record<string, unknown> = {
      "--label-width": getLabelWidthCssValue(this.props.labelWidth),
    };

    return (
      <WidgetErrorsWrapper
        widgetId={this.props.widgetId}
        showError={this.props.showError ?? false}
        messages={this.props.errorMessages}
        errorMessagePlacement={this.props.errorMessagePlacement}
        inlineErrorProps={{
          isFitContentHeight: this.props.isFitContentHeight,
        }}
        customTarget
      >
        {(inlineError, onTargetMouseOver, onTargetMouseLeave) => (
          <div
            style={
              this.props.vertical
                ? { flexDirection: "column" }
                : { flexDirection: "row" }
            }
            className={
              this.props.isLoading ? Classes.SKELETON : RadioGroupWrapper
            }
          >
            <>
              {this.props.label && (
                <label
                  className={`${labelClass} radio-group-label ${this.props.vertical ? "vertical" : "horizontal"}`}
                  style={{
                    ...this.props.labelTypographyProps.style,
                    ...styleVars,
                  }}
                  onMouseOver={onTargetMouseOver}
                  onMouseLeave={onTargetMouseLeave}
                >
                  {this.props.isRequired &&
                    this.props.label.indexOf("*") === -1 && (
                      <span className={`${CLASS_NAMES.ERROR_MODIFIER}`}>
                        *{" "}
                      </span>
                    )}
                  {this.props.label}
                </label>
              )}
              <div
                ref={this.containerRef}
                style={FormInputWithErrorWrapperStyle}
              >
                <div
                  className={`radio-group-list`}
                  tabIndex={0}
                  style={
                    this.props.vertical
                      ? { flexDirection: "column" }
                      : {
                          flexDirection: "row",
                          alignItems: "center",
                          gap: "12px",
                          height: "35px",
                        }
                  }
                  data-test="radio-group-list"
                  onMouseOver={onTargetMouseOver}
                  onMouseLeave={onTargetMouseLeave}
                >
                  {isArray(this.props.options) &&
                    this.props.options.map((option, idx) => {
                      return (
                        <RadioInput
                          isSelected={
                            this.props.selectedOptionValue === option.value
                          }
                          isDisabled={this.props.isDisabled === true}
                          onClick={this.props.onRadioSelectionChange}
                          option={option}
                          radioGroupName={this.props.widgetId}
                          key={idx}
                          textClassName={optionClass}
                          textStyle={this.props.optionTypographyProps.style}
                          hasError={this.props.showError}
                        />
                      );
                    })}
                </div>
                {inlineError}
              </div>
            </>
          </div>
        )}
      </WidgetErrorsWrapper>
    );
  }
}

export const RadioGroupComponentWithLayoutManaged = (
  props: RadioGroupComponentWithLayoutManagedProps,
) => {
  const labelTypographyProps = useTypographyStyling({
    textStyle: props.labelProps?.textStyle,
    defaultTextStyleVariant:
      DEFAULT_RADIO_GROUP_WIDGET_LABEL_TEXT_STYLE_VARIANT,
  });
  const optionTypographyProps = useTypographyStyling({
    textStyle: props.optionProps?.textStyle,
    defaultTextStyleVariant:
      DEFAULT_RADIO_GROUP_WIDGET_OPTION_TEXT_STYLE_VARIANT,
  });

  return (
    <RadioGroupComponent
      widgetId={props.widgetId}
      labelTypographyProps={labelTypographyProps}
      labelWidth={props.labelProps?.width}
      label={props.label}
      options={props.options}
      onRadioSelectionChange={props.onRadioSelectionChange}
      selectedOptionValue={props.selectedOptionValue}
      isLoading={props.isLoading}
      isRequired={props.isRequired}
      vertical={props.isVertical}
      optionTypographyProps={optionTypographyProps}
      isDisabled={props.isDisabled}
      errorMessagePlacement={props.errorMessagePlacement}
      errorMessages={props.errorMessages}
      showError={props.showError}
      isTouched={props.isTouched}
      isFitContentHeight={props.height?.mode === "fitContent"}
    />
  );
};

export default RadioGroupComponent;
