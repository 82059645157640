import {
  Border,
  CUSTOM_THEME_TYPOGRAPHY_KEY,
  Dimension,
  PerSideBorder,
} from "@superblocksteam/shared";
import { get } from "lodash";
import { TextAlign } from "legacy/constants/WidgetConstants";
import { GeneratedTheme } from "legacy/themes";
import { BUTTON_PADDING } from "legacy/themes/constants";
import { createPerCornerBorderRadius } from "pages/Editors/AppBuilder/Sidebar/BorderRadiusEditor";
import { buildLocalUrlFromRoute } from "utils/navigation/localNavigation";
import { buildURL } from "utils/navigation/shared";
import { ZERO_PADDING } from "../base/sizing";
import { LinkToType } from "../navigationProperties";
import { getLocalizedUrl } from "./localizeUrl";
import { LinkWidgetProps, Context } from "./types";

export const textAlignmentDefaultValue = (props: LinkWidgetProps) =>
  props.linkStyle === "LINK" ? TextAlign.LEFT : TextAlign.CENTER;

export const textVariantDefaultValue = (props: LinkWidgetProps) =>
  props.linkStyle === "LINK" ? "label" : "buttonLabel";

export const textColorDefaultValue = (
  props: LinkWidgetProps,
  theme?: GeneratedTheme,
) => {
  // handle custom variant color
  const variant = props.textProps?.textStyle?.variant;
  if (variant && variant.startsWith(CUSTOM_THEME_TYPOGRAPHY_KEY)) {
    const customVariantColor = get(
      theme?.typographies,
      `${variant}.textColor.default`,
    );
    if (customVariantColor) {
      return customVariantColor;
    }
  }

  switch (props.linkStyle) {
    case "PRIMARY_BUTTON":
      return theme?.buttons.primary.textColor.default;
    case "SECONDARY_BUTTON":
      return theme?.buttons.secondary.textColor.default;
    case "TERTIARY_BUTTON":
      return theme?.buttons.tertiary.textColor.default;
    case "LINK":
      return theme?.typographies.link.textColor.default;
    default:
      return theme?.buttons.primary.textColor.default;
  }
};
export const backgroundColorDefaultValue = (
  props: LinkWidgetProps,
  theme?: GeneratedTheme,
) => {
  switch (props.linkStyle) {
    case "PRIMARY_BUTTON":
      return theme?.colors.primary500;
    case "SECONDARY_BUTTON":
    case "TERTIARY_BUTTON":
    case "LINK":
      return "transparent";
    default:
      return theme?.colors.primary500;
  }
};

export const paddingDefaultValue = (props: LinkWidgetProps) =>
  props.linkStyle === "LINK" ? ZERO_PADDING : BUTTON_PADDING;

export const borderRadiusDefaultValue = (
  props: LinkWidgetProps,
  theme?: GeneratedTheme,
) => {
  return createPerCornerBorderRadius(theme?.borderRadius ?? Dimension.px(4));
};

const DEFAULT_LINK_BORDER: Border = {
  width: Dimension.px(1),
  style: "solid",
  color: "transparent",
};

const createBorderObject = (override?: Partial<Border>): PerSideBorder => {
  return {
    left: { ...DEFAULT_LINK_BORDER, ...override },
    right: { ...DEFAULT_LINK_BORDER, ...override },
    top: { ...DEFAULT_LINK_BORDER, ...override },
    bottom: { ...DEFAULT_LINK_BORDER, ...override },
  };
};

const DEFAULT_LINK_BORDER_OBJECT = createBorderObject();

export const borderDefaultValue = (
  props: LinkWidgetProps,
  theme?: GeneratedTheme,
) =>
  props.linkStyle === "SECONDARY_BUTTON"
    ? createBorderObject({ color: theme?.colors.primary500 })
    : DEFAULT_LINK_BORDER_OBJECT;

export const getLinkProperties = (
  props: Partial<LinkWidgetProps>,
  context: Context,
): {
  url: string;
  urlForInternalNavigation?: string;
  hasMissingRouteParams: boolean;
} => {
  let url = "";
  let urlForInternalNavigation: string | undefined = undefined;
  let hasMissingRouteParams = false;

  if (props.linkTo === LinkToType.Page && props.routeId) {
    let route = context.routes[props.routeId];

    if (!route && context.currentRoute) {
      // in case of error, leave the URL the same as the current URL
      route = context.currentRoute;
    }

    const result = buildLocalUrlFromRoute(route, {
      routePathDescriptor: props.routePathDescriptor,
      keepQueryParams: props.keepExistingQueryParams,
      queryParams: props.queryParams,
      routeParams: props.routeParams,
      currentQueryParamsToKeep: context.currentPageUrlState.queryParams,
    });

    if (result.ok) {
      hasMissingRouteParams = false;

      const localizedUrl = getLocalizedUrl({
        inputUrl: result.value.pathname + result.value.search,
        keepExistingQueryParams: false, // already applied
        currentPageUrlState: context.currentPageUrlState,
        systemQueryParams: context.systemQueryParams,
        appMode: context.appMode,
      });

      url = localizedUrl.url;
      urlForInternalNavigation = localizedUrl.urlForInternalNavigation;
    } else {
      hasMissingRouteParams = true;
    }
  } else if (props.linkTo === LinkToType.App) {
    const queryParams = props.queryParams || {};

    if (props.targetApp) {
      const appUrl = buildURL({
        targetPath: props.targetApp.url,
        params: queryParams,
      });

      const { url: _url } = getLocalizedUrl({
        inputUrl: appUrl.pathname + appUrl.search,
        keepExistingQueryParams: props.keepExistingQueryParams,
        currentPageUrlState: context.currentPageUrlState,
        systemQueryParams: context.systemQueryParams,
        appMode: context.appMode,
      });

      url = _url;
    }
  } else if (props.linkTo === LinkToType.CustomUrl) {
    const { url: _url, urlForInternalNavigation: _urlForInternalNavigation } =
      getLocalizedUrl({
        inputUrl: props.url,
        keepExistingQueryParams: false,
        currentPageUrlState: context.currentPageUrlState,
        systemQueryParams: context.systemQueryParams,
        appMode: context.appMode,
      });

    url = _url;
    urlForInternalNavigation = _urlForInternalNavigation;
  }

  return {
    url,
    urlForInternalNavigation,
    hasMissingRouteParams,
  };
};
