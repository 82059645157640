import { ApplicationScope } from "@superblocksteam/shared";
import moment from "moment-timezone";
import React from "react";
import { put, select } from "redux-saga/effects";
import {
  UpdateWidgetPropertiesPayload,
  updateWidgetProperties,
} from "legacy/actions/controlActions";
import { setMetaProp } from "legacy/actions/metaActions";
import { getErrorMessagesList } from "legacy/components/editorComponents/ErrorUtils";
import { EventType } from "legacy/constants/ActionConstants";
import { type PropertyPaneConfig } from "legacy/constants/PropertyControlConstants";
import { ReduxAction } from "legacy/constants/ReduxActionConstants";
import { WidgetType, WidgetTypes } from "legacy/constants/WidgetConstants";
import {
  ISO_DATE_FORMAT,
  VALIDATION_TYPES,
} from "legacy/constants/WidgetValidation";
import {
  WidgetPropertyValidationType,
  BASE_WIDGET_VALIDATION,
} from "legacy/constants/WidgetValidation";
import { getWidgetMetaProps, getWidgets } from "legacy/selectors/sagaSelectors";

import { createRunEventHandlersPayloadOptional } from "legacy/utils/actions";
import { selectAiDataTreeChangesById } from "store/slices/ai/selectors";
import BaseWidget, { WidgetState } from "../BaseWidget";

import withMeta from "../withMeta";

import { DEFAULT_DATE_FORMAT } from "./Constants";
import { DatePickerComponentWithLayoutManaged } from "./DatePickerComponentWithLayoutManaged";
import DatePickerWidgetCategories from "./DatePickerWidgetCategories";
import type {
  DerivedPropertiesMap,
  WidgetActionHook,
  WidgetActionResponse,
  CanvasWidgetsReduxState,
} from "../Factory";
import type { DatePickerWidgetProps } from "./types";

class DatePickerWidget extends BaseWidget<DatePickerWidgetProps, WidgetState> {
  static getPropertyPaneConfig(): PropertyPaneConfig[] {
    throw new Error("Deprecated config should not be called");
  }

  static getNewPropertyPaneConfig():
    | PropertyPaneConfig<DatePickerWidgetProps>[]
    | undefined {
    return DatePickerWidgetCategories;
  }

  static getPropertyValidationMap(): WidgetPropertyValidationType {
    return {
      ...BASE_WIDGET_VALIDATION,
      defaultDate: VALIDATION_TYPES.DEFAULT_DATE,
      timezone: VALIDATION_TYPES.TEXT,
      enableTimePicker: VALIDATION_TYPES.BOOLEAN,
      dateFormat: VALIDATION_TYPES.TEXT,
      label: VALIDATION_TYPES.TEXT,
      datePickerType: VALIDATION_TYPES.TEXT,
      maxDate: VALIDATION_TYPES.MAX_DATE,
      minDate: VALIDATION_TYPES.MIN_DATE,
      isRequired: VALIDATION_TYPES.BOOLEAN,
      isDisabled: VALIDATION_TYPES.BOOLEAN,
      "inputProps.border": VALIDATION_TYPES.OBJECT_OR_UNDEFINED,
      "inputProps.borderRadius": VALIDATION_TYPES.OBJECT_OR_UNDEFINED,
    };
  }

  static getDerivedPropertiesMap(): DerivedPropertiesMap {
    return {
      value: `{{ this.selectedDate ? this.selectedDate : undefined }}`,
      validationErrors: /*javascript*/ `{{ (() => {
        const errors = {};

        if (this.isRequired && !this.selectedDate) {
          errors.isRequired = "Required";
        }

        if (
          this.selectedDate &&
          !moment(this.selectedDate, this.dateFormat, true).isValid()
        ) {
          errors.dateInvalid = "Invalid date";
        }

        if (this.minDate && this.selectedDate && moment(this.selectedDate, this.dateFormat).isBefore(moment(this.minDate, this.dateFormat))) {
          errors.minDate = "Date must be greater than or equal to " + this.minDate;
        }

        if (this.maxDate && this.selectedDate && moment(this.selectedDate, this.dateFormat).isAfter(moment(this.maxDate, this.dateFormat))) {
          errors.maxDate = "Date must be less than or equal to " + this.maxDate;
        }

        if (this.customValidationRule === false) {
          errors.customError = this.customErrorMessage || "Invalid";
        }

        return errors;
      })() }}`,
      isValid: `{{ Object.keys(this.validationErrors ?? {}).length === 0 }}`,
      outputDateLocal: `{{ (() => moment(this.selectedDate, this.dateFormat).format('YYYY-MM-DDTHH:mm:ss.SSSZ'))() }}`,
      outputDateUtc: `{{ (() =>  moment(this.selectedDate, this.dateFormat).toISOString())() }}`,
      showError: /*javascript*/ `{{
        (() => {
          const hasValue = !!this.value;
          if (hasValue) {
            return !this.isValid;
          }
      
          return !this.isValid && this.isTouched;
        })()
      }}`,
    };
  }

  static getDefaultPropertiesMap(): Record<string, string> {
    return {
      selectedDate: "defaultDate",
    };
  }

  static getMetaPropertiesMap(): Record<string, any> {
    return {
      isValid: true,
      selectedDate: undefined,
      isTouched: false,
      validationErrors: {},
    };
  }

  static applyActionHook: WidgetActionHook = function* (params: {
    widgetId: string;
    widgets: Readonly<CanvasWidgetsReduxState>;
    action: ReduxAction<UpdateWidgetPropertiesPayload>;
  }): Generator<any, any, any> {
    const { widgetId, widgets, action } = params;
    if (
      widgets[widgetId].type !== WidgetTypes.DATE_PICKER_WIDGET ||
      widgetId !== action.payload.widgetId
    ) {
      return;
    }

    const widget = widgets[widgetId] as DatePickerWidgetProps;
    const newUpdates: WidgetActionResponse = [];
    switch (action.type) {
      case updateWidgetProperties.type: {
        if (!updateWidgetProperties.match(action)) break;
        const updates = action.payload.updates;
        if (updates.dateFormat || updates.defaultDate) {
          const widgetMeta: ReturnType<typeof getWidgetMetaProps> =
            yield select(getWidgetMetaProps, widgetId);
          const previousWidgets = yield select(getWidgets);
          const previousAiWidgets = yield select(selectAiDataTreeChangesById);
          const previousWidget = {
            ...previousWidgets[widgetId],
            ...previousAiWidgets?.[widgetId],
          };

          const previousDateFormat =
            previousWidget.dateFormat || ISO_DATE_FORMAT;
          const newDateFormat = widget.dateFormat || ISO_DATE_FORMAT;
          const timezone = widget.timezone;
          const defaultDateIsDynamic = (
            widget.dynamicPropertyPathList ?? []
          ).some((binding) => binding.key === "defaultDate");
          // Update defaultDate with newFormat
          if (!defaultDateIsDynamic && widget.defaultDate) {
            const defaultDate = timezone
              ? moment.tz(
                  widget.defaultDate,
                  [previousDateFormat, DEFAULT_DATE_FORMAT],
                  timezone,
                )
              : moment(widget.defaultDate, [
                  previousDateFormat,
                  DEFAULT_DATE_FORMAT,
                ]);
            if (defaultDate.isValid()) {
              newUpdates.push({
                widgetId,
                widget: {
                  ...widget,
                  defaultDate: defaultDate.format(newDateFormat),
                },
              });
            }
          }
          // Update selectedDate with newFormat
          const selectedDate =
            widgetMeta && (widgetMeta.selectedDate as string);
          if (selectedDate) {
            const metaDate = timezone
              ? moment.tz(selectedDate, previousDateFormat, timezone)
              : moment(selectedDate, previousDateFormat);
            if (metaDate.isValid()) {
              yield put(
                setMetaProp(
                  widgetId,
                  "selectedDate",
                  metaDate.format(newDateFormat),
                ),
              );
            }
          }
          break;
        } else if (updates.minDate || updates.maxDate) {
          const previousWidgets = yield select(getWidgets);
          const previousAiWidgets = yield select(selectAiDataTreeChangesById);
          const previousWidget = {
            ...previousWidgets[widgetId],
            ...previousAiWidgets?.[widgetId],
          };

          const defaultDateStr =
            updates.defaultDate || previousWidget.defaultDate;
          const dateFormat = updates.dateFormat || previousWidget.dateFormat;
          if (defaultDateStr) {
            const defaultDate = moment(defaultDateStr, dateFormat);

            if (
              updates.minDate &&
              defaultDate.isBefore(moment(updates.minDate, dateFormat))
            ) {
              newUpdates.push({
                widgetId,
                widget: {
                  ...widget,
                  defaultDate: updates.minDate as string,
                },
              });
            }

            if (
              updates.maxDate &&
              defaultDate.isAfter(moment(updates.maxDate, dateFormat))
            ) {
              newUpdates.push({
                widgetId,
                widget: {
                  ...widget,
                  defaultDate: updates.maxDate as string,
                },
              });
            }
          }
        }
      }
    }
    return newUpdates;
  };

  handleDatepickerClose = () => {
    if (!this.props.isTouched) {
      this.props.updateWidgetMetaProperty("isTouched", true);
    }
  };

  getPageView() {
    return (
      <DatePickerComponentWithLayoutManaged
        {...this.props}
        onDateSelected={this.onDateSelected}
        onDatePickerClosed={this.handleDatepickerClose}
        errorMessages={getErrorMessagesList(
          this.props.validationErrors,
          this.props.showError,
        )}
      />
    );
  }

  onDateSelected = (selectedDate: string) => {
    const { onDateSelected } = this.props;

    this.props.updateWidgetMetaProperty(
      "selectedDate",
      selectedDate,
      createRunEventHandlersPayloadOptional({
        steps: onDateSelected,
        currentScope: ApplicationScope.PAGE,
        type: EventType.ON_DATE_SELECTED,
        entityName: this.props.widgetName,
      }),
    );
  };

  getWidgetType(): WidgetType {
    return WidgetTypes.DATE_PICKER_WIDGET;
  }
}
export default DatePickerWidget;
export const ConnectedDatePickerWidget = withMeta(DatePickerWidget);
