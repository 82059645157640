import { ApplicationScope } from "@superblocksteam/shared";
import React from "react";
import { getErrorMessagesList } from "legacy/components/editorComponents/ErrorUtils";
import { EventType } from "legacy/constants/ActionConstants";
import { type PropertyPaneConfig } from "legacy/constants/PropertyControlConstants";
import { WidgetType, WidgetTypes } from "legacy/constants/WidgetConstants";
import { VALIDATION_TYPES } from "legacy/constants/WidgetValidation";
import {
  WidgetPropertyValidationType,
  BASE_WIDGET_VALIDATION,
} from "legacy/constants/WidgetValidation";
import { createRunEventHandlersPayloadOptional } from "legacy/utils/actions";
import BaseWidget, { WidgetState } from "../BaseWidget";
import withMeta from "../withMeta";
import { SwitchComponentWithLayoutManaged } from "./SwitchComponent";
import SwitchWidgetPropertyCategories from "./SwitchWidgetPropertyCategories";
import type { DerivedPropertiesMap } from "../Factory";
import type { SwitchWidgetProps } from "./types";

class SwitchWidget extends BaseWidget<SwitchWidgetProps, WidgetState> {
  static getNewPropertyPaneConfig():
    | PropertyPaneConfig<SwitchWidgetProps>[]
    | undefined {
    return SwitchWidgetPropertyCategories;
  }

  static getPropertyPaneConfig(): PropertyPaneConfig[] {
    throw new Error("Deprecated config should not be called");
  }

  static getPropertyValidationMap(): WidgetPropertyValidationType {
    return {
      ...BASE_WIDGET_VALIDATION,
      label: VALIDATION_TYPES.TEXT,
      labelPosition: VALIDATION_TYPES.TEXT,
      defaultSwitchState: VALIDATION_TYPES.BOOLEAN,
      isRequired: VALIDATION_TYPES.BOOLEAN,
    };
  }

  static getDefaultPropertiesMap(): Record<string, string> {
    return {
      isToggledOn: "defaultSwitchState",
    };
  }

  static getDerivedPropertiesMap(): DerivedPropertiesMap {
    return {
      value: `{{this.isToggledOn}}`,
      validationErrors: /*javascript*/ `{{ (() => {
        const errors = {};
        if (this.isRequired && !this.isToggledOn) {
          errors.isRequired = "Required";
        }

        if (this.customValidationRule === false) {
          errors.customError = this.customErrorMessage || "Invalid value";
        }

        return errors;
      })() }}`,
      isValid: `{{ Object.keys(this.validationErrors ?? {}).length === 0 }}`,
      showError: /*javascript*/ `{{
        (() => {
          const hasValue = !!this.value;
          if (hasValue) {
            return !this.isValid;
          }
      
          return !this.isValid && this.isTouched;
        })()
      }}`,
    };
  }

  static getMetaPropertiesMap(): Record<string, any> {
    return {
      isToggledOn: undefined,
      isTouched: false,
      validationErrors: {},
    };
  }

  getPageView() {
    return (
      <SwitchComponentWithLayoutManaged
        {...this.props}
        onSwitchChange={this.onSwitchChange}
        errorMessages={getErrorMessagesList(
          this.props.validationErrors,
          this.props.showError,
        )}
      />
    );
  }

  onSwitchChange = (isToggledOn: boolean) => {
    if (!this.props.isTouched) {
      this.props.updateWidgetMetaProperty("isTouched", true);
    }
    this.props.updateWidgetMetaProperty(
      "isToggledOn",
      isToggledOn,
      createRunEventHandlersPayloadOptional({
        steps: this.props.onSwitchChange,
        currentScope: ApplicationScope.PAGE,
        type: EventType.ON_CHECK_CHANGE,
        entityName: this.props.widgetName,
      }),
    );
  };

  getWidgetType(): WidgetType {
    return WidgetTypes.SWITCH_WIDGET;
  }
}

export default SwitchWidget;
export const ConnectedSwitchWidget = withMeta(SwitchWidget);
