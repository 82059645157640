import { MultiStepDef } from "legacy/constants/ActionConstants";
import { TextStyleWithVariant } from "legacy/themes";
import { WidgetPropsRuntime } from "../BaseWidget";

type Message = Record<string, any>;
export type UserMessageObject = {
  plainText: string;
  richText: string;
  images: Array<string>;
};

export interface ChatWidgetProps extends WidgetPropsRuntime {
  // properties
  header: string;
  placeholderText: string;
  messageHistory: Array<Message> | string;
  timestampField: string;
  timestampFormat: string;
  avatarField: string;
  messageField: string;
  displayNameField: string;
  enableChatSearch: boolean;
  enableEnterToSend: boolean;
  enableImages: boolean;
  enableEmojis: boolean;
  onMessageSend: MultiStepDef;
  enablePendingState: boolean;
  showPendingMessage: boolean;
  pendingStateText?: string;
  pendingStateTimeout?: number;
  isDisabled?: boolean;
  // style props
  headerProps?: {
    textStyle: TextStyleWithVariant;
  };

  // meta properties
  userMessageText?: string;
  userMessageRichText?: string;
  userMessageImages?: Array<string>;
}

export const MIN_CHAT_INPUT_HEIGHT = 76;
export const MAX_CHAT_INPUT_HEIGHT = 200;
export const CHAT_INPUT_ROW_SIZE = 20;

export const PREFERRED_MESSAGE_FIELDS = ["text", "content", "message"];
export const PREFERRED_NAME_FIELDS = [
  "name",
  "role",
  "id",
  "user",
  "speaker",
  "sender",
];
export const PREFERRED_AVATAR_FIELDS = ["avatar", "image", "photo"];
export const PREFERRED_DATE_FIELDS = ["date", "time", "timestamp"];
