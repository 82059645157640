import {
  PropsPanelCategory,
  type PropertyPaneCategoryConfig,
} from "legacy/constants/PropertyControlConstants";
import { PanelCategory } from "legacy/pages/Editor/PropertyPane/propertyPaneCategoryUtils";
import { ANIMATE_LOADING_PROPERTY_CONTROL_HELP_TEXT } from "pages/Editors/AppBuilder/Sidebar/PropertyControlCommons";
import {
  customValidationProperties,
  errorMessagePlacementProperty,
  labelWidthProperty,
} from "../FormInputs/formInputsProperties";
import {
  labelPositionProperty,
  sizeSection,
  visibleProperties,
} from "../basePropertySections";
import { getPopoverConfig } from "../eventHandlerPanel";
import { updateHeightOnAddOrRemove } from "../propsPanelUtils";
import { typographyProperties } from "../styleProperties";
import {
  DEFAULT_RADIO_GROUP_WIDGET_LABEL_TEXT_STYLE_VARIANT,
  DEFAULT_RADIO_GROUP_WIDGET_OPTION_TEXT_STYLE_VARIANT,
} from "./constants";
import { type RadioGroupWidgetProps } from "./types";

const config: PropertyPaneCategoryConfig<RadioGroupWidgetProps>[] = [
  PanelCategory(PropsPanelCategory.Content, [
    {
      helpText: "Text to be shown above the radio group",
      propertyName: "label",
      label: "Label",
      controlType: "INPUT_TEXT",
      placeholderText: "Enter placeholder text",
      isBindProperty: true,
      isTriggerProperty: false,
      visibility: "SHOW_NAME",
      isRemovable: true,
      defaultValue: "Label",
      updateHook: updateHeightOnAddOrRemove,
    },
    {
      helpText:
        "Displays a list of options for a user to select. Values must be unique",
      propertyName: "options",
      label: "Options",
      controlType: "OPTION_INPUT",
      isJSConvertible: true,
      isBindProperty: true,
      isTriggerProperty: false,
      headerControlType: "ADD_OPTION",
    },
    {
      helpText: "Selects a value of the options entered by default",
      propertyName: "defaultOptionValue",
      label: "Default selected value",
      placeholderText: "Enter option value",
      controlType: "INPUT_TEXT",
      isBindProperty: true,
      isTriggerProperty: false,
      visibility: "SHOW_NAME",
      isRemovable: true,
      defaultValue: "",
    },
  ]),
  PanelCategory(PropsPanelCategory.Appearance, [
    labelPositionProperty({ orientation: "legacy" }),
    ...typographyProperties({
      defaultVariant: DEFAULT_RADIO_GROUP_WIDGET_LABEL_TEXT_STYLE_VARIANT,
      textStyleParentDottedPath: "labelProps",
      propertyNameForHumans: "Label",
      hiddenIfPropertyNameIsNullOrFalse: "label",
    }),
    ...typographyProperties({
      defaultVariant: DEFAULT_RADIO_GROUP_WIDGET_OPTION_TEXT_STYLE_VARIANT,
      textStyleParentDottedPath: "optionProps",
      propertyNameForHumans: "Option",
    }),
    {
      helpText: ANIMATE_LOADING_PROPERTY_CONTROL_HELP_TEXT,
      propertyName: "animateLoading",
      label: "Loading animation",
      controlType: "SWITCH",
      isJSConvertible: true,
      isBindProperty: true,
      isTriggerProperty: false,
    },
  ]),
  PanelCategory(PropsPanelCategory.Layout, [
    sizeSection({ heightSupportsFitContent: true }),
    labelWidthProperty({
      hidden: (props: RadioGroupWidgetProps) =>
        !!props.isVertical || !props.label,
    }),
    ...visibleProperties({ useJsExpr: false }),
  ]),
  PanelCategory(PropsPanelCategory.Interaction, [
    {
      propertyName: "isRequired",
      label: "Required",
      helpText: "Makes input to the component mandatory",
      controlType: "SWITCH",
      isJSConvertible: true,
      isBindProperty: true,
      isTriggerProperty: false,
    },
    {
      propertyName: "isDisabled",
      label: "Disabled",
      helpText: "Disables input to this component",
      controlType: "SWITCH",
      isJSConvertible: true,
      isBindProperty: true,
      isTriggerProperty: false,
    },
    errorMessagePlacementProperty(),
    ...customValidationProperties(),
  ]),
  PanelCategory(PropsPanelCategory.EventHandlers, [
    getPopoverConfig(
      "onSelectionChange",
      "Triggers an action when a user changes the selected option",
    ),
  ]),
];

export default config;
