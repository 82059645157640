import {
  NonCustomTypography,
  PerCornerBorderRadius,
  PerSideBorder,
  TextStyleBlock,
  Typographies,
} from "@superblocksteam/shared";
import { get } from "lodash";
import {
  createThemeOption,
  createThemeTooltip,
} from "legacy/components/propertyControls/ManageThemeHelpers";
import { selectTypographyVariantsAsDropDownControlOptions } from "legacy/components/propertyControls/TextStyle/selectTypographyVariants";
import { OptionsCustomizerFn } from "legacy/components/propertyControls/TextStyle/types";
import {
  PropsPanelCategory,
  ThemeValueFunction,
  type Hidden,
  type PropertyPaneControlConfig,
  type UpdateHookArgs,
  type UpdateHookReturnUpdateItem,
} from "legacy/constants/PropertyControlConstants";
import {
  FONT_SIZE_UNIT_OPTIONS,
  FONT_STYLE_OPTIONS,
  LETTER_SPACING_UNIT_OPTIONS,
  LINE_HEIGHT_UNIT_OPTIONS,
  TEXT_TRANSFORM_OPTIONS,
  getFontWeightOptions,
  getNewValueOnUnitChange,
} from "legacy/pages/Editor/Explorer/Theme/TypographyControls";
import { PanelCategory } from "legacy/pages/Editor/PropertyPane/propertyPaneCategoryUtils";
import {
  selectAvailableFonts,
  selectGeneratedTheme,
  selectGeneratedThemeFontFamily,
  selectGeneratedThemeTypographies,
  selectStoredThemeTypographies,
} from "legacy/selectors/themeSelectors";
import { GeneratedTheme } from "legacy/themes";
import { DEFAULT_FONT_WEIGHTS } from "legacy/themes/typefaceConstants";
import {
  getAvailableFontFamilies,
  getFontFamilyOptions,
} from "legacy/themes/typefaces/utils";
import { SB_CUSTOM_TEXT_STYLE } from "legacy/themes/typographyConstants";
import { getLineHeightInPixels, isColorToken } from "legacy/themes/utils";
import { extractPixels } from "legacy/themes/utils";
import { removeLastPathSegments } from "utils/dottedPaths";
import { type NestedTextStylePropertyNames } from "./BaseWidget";

export const LINE_HEIGHT_WARNING =
  "Line height is smaller than font size. This may cause text to clip";

const FONT_WEIGHT_OPTIONS = getFontWeightOptions();

export const backgroundColorProperty = ({
  propertyName = "backgroundColor",
  propertyNamespaceDottedPath,
  label = "Background",
  helpText = "Changes the color of the background",
  hidden,
  getAdditionalHiddenData,
  themeValue,
  defaultValue,
  defaultValueFn,
}: {
  propertyName?: string;
  propertyNamespaceDottedPath?: string;
  label?: string;
  helpText?: string;
  hidden?: Hidden;
  getAdditionalHiddenData?: PropertyPaneControlConfig<any>["getAdditionalHiddenData"];
  themeValue?: PropertyPaneControlConfig["themeValue"];
  defaultValue?: PropertyPaneControlConfig["defaultValue"];
  defaultValueFn?: PropertyPaneControlConfig["defaultValueFn"];
}) =>
  ({
    propertyName: propertyNamespaceDottedPath
      ? `${propertyNamespaceDottedPath}.${propertyName}`
      : propertyName,
    label,
    helpText,
    controlType: "COLOR_PICKER",
    propertyCategory: PropsPanelCategory.Appearance,
    themeValue,
    defaultValue,
    defaultValueFn,
    isJSConvertible: true,
    isBindProperty: true,
    isTriggerProperty: false,
    hidden,
    getAdditionalHiddenData,
    isRemovable: true,
    visibility: "SHOW_NAME",
  }) satisfies PropertyPaneControlConfig;

export const borderProperty = ({
  propertyName = "border",
  propertyNamespaceDottedPath,
  label = "Border",
  helpText = "Controls the border of the component",
  hidden,
  themeValue,
  defaultValue,
  defaultValueFn,
  getAdditionalHiddenData,
}: {
  propertyName?: string;
  propertyNamespaceDottedPath?: string;
  label?: string;
  helpText?: string;
  hidden?: Hidden;
  themeValue?: PropertyPaneControlConfig["themeValue"];
  defaultValue?: PerSideBorder;
  defaultValueFn?: PropertyPaneControlConfig["defaultValueFn"];
  getAdditionalHiddenData?: PropertyPaneControlConfig<any>["getAdditionalHiddenData"];
}) =>
  ({
    propertyName: propertyNamespaceDottedPath
      ? `${propertyNamespaceDottedPath}.${propertyName}`
      : propertyName,
    label,
    helpText,
    controlType: "BORDER_CONTROL",
    propertyCategory: PropsPanelCategory.Appearance,
    isJSConvertible: false,
    isBindProperty: true,
    isTriggerProperty: false,
    hidden,
    defaultValue,
    defaultValueFn,
    themeValue,
    getAdditionalHiddenData,
    isRemovable: true,
    visibility: "SHOW_NAME",
  }) satisfies PropertyPaneControlConfig;

export const borderRadiusProperty = ({
  label = "Border radius",
  propertyName = "borderRadius",
  helpText = "Controls the border radius of the component",
  hidden,
  defaultValue,
  defaultValueFn,
  themeValue,
  getAdditionalHiddenData,
  propertyNamespaceDottedPath,
}: {
  label?: string;
  propertyNamespaceDottedPath?: string;
  helpText?: string;
  hidden?: Hidden;
  defaultValue?: PerCornerBorderRadius;
  defaultValueFn?: PropertyPaneControlConfig["defaultValueFn"];
  themeValue?: PropertyPaneControlConfig["themeValue"];
  getAdditionalHiddenData?: PropertyPaneControlConfig<any>["getAdditionalHiddenData"];
  propertyName?: string;
}) =>
  ({
    propertyName: propertyNamespaceDottedPath
      ? `${propertyNamespaceDottedPath}.${propertyName}`
      : propertyName,
    label,
    helpText,
    controlType: "BORDER_RADIUS_CONTROL",
    propertyCategory: PropsPanelCategory.Appearance,
    isJSConvertible: false,
    isBindProperty: true,
    isTriggerProperty: false,
    hidden,
    defaultValue,
    defaultValueFn,
    themeValue,
    getAdditionalHiddenData,
    isRemovable: true,
    visibility: "SHOW_NAME",
  }) satisfies PropertyPaneControlConfig;

const hiddenCustomStyles =
  (
    propertyNamespaceDottedPath: string,
  ): NonNullable<PropertyPaneControlConfig["hidden"]> =>
  (props: any) => {
    return (
      get(props, `${propertyNamespaceDottedPath}.textStyle.variant`) !==
      SB_CUSTOM_TEXT_STYLE
    );
  };

export const customStylesProperties = <NestedPath extends string>({
  textStyleParentDottedPath,
  additionalHidden,
  overrideHidden,
  getDynamicTextStyleParentDottedPath,
  forceFullPropertyNamePath,
  isPopover,
}: {
  textStyleParentDottedPath: NestedPath;
  additionalHidden?: Hidden; // if return true, then hide. If false, then we check to hide when custom styles is actually active
  overrideHidden?: Hidden; // use this value regardless of if we determine custom styles are actually active
  getDynamicTextStyleParentDottedPath?: (path: string) => string;
  forceFullPropertyNamePath: boolean; // Set to `true` when used outside of property panel popovers, or when used in updateHooks
  isPopover: boolean;
}) => {
  const pathToProperty = (
    propertyName: keyof Omit<TextStyleBlock, "textColor">,
  ) => {
    // Popover panels get then namespace added later on (`openPanel`)
    // However, we need the full property path when used in an updateHook
    return (
      forceFullPropertyNamePath
        ? `${textStyleParentDottedPath}.textStyle.${propertyName}`
        : propertyName
    ) as keyof NestedTextStylePropertyNames<NestedPath>;
  };

  /**
   * This is a temporary workaround.
   *  TODO: AlexL will remove this once we refactor the V2 property pane out to remove the feature flag.
   *
   * - When these props are used in the regular property panel (only in the table's column property panel for cells)
   *   we want to show them in the Appearance section.
   *
   * - When these props are used in the Text Style Control popover, we need to remove them from the Appearance section
   *   otherwise the section gets collapsed when the Appearance section is collapsed - and in this case there is no toggle
   *   to expand the section because it's one single section.
   **/

  const category: PropsPanelCategory = isPopover
    ? PropsPanelCategory.Uncategorized
    : PropsPanelCategory.Appearance;

  const properties: PropertyPaneControlConfig<
    NestedTextStylePropertyNames<NestedPath>
  >[] = [
    {
      propertyName: pathToProperty("fontFamily"),
      label: "Typeface",
      controlType: "DROP_DOWN",
      propertyCategory: category,
      options: [],
      getAdditionalDataForPropFunc: {
        theme: selectGeneratedTheme,
      },
      optionsFuncWithAdditionalData: ({
        props,
        additionalDataForPropFunc,
      }: {
        props: any;
        additionalDataForPropFunc?: Record<string, any>;
      }) => {
        const options = getFontFamilyOptions(
          additionalDataForPropFunc?.theme?.availableFonts,
        );

        options.unshift({
          label: `Theme default (${additionalDataForPropFunc?.theme?.fontFamily})`,
          value: "inherit",
        });
        return options;
      },
      isBindProperty: false,
      isTriggerProperty: false,
      defaultValue: "inherit",
      updateHook: (args) => {
        const {
          props,
          propertyValue,
          additionalDataForPropFunc: additionalData,
        } = args;
        if (!additionalData) return [];
        let fontFamily = propertyValue;
        if (fontFamily === "inherit") {
          fontFamily = additionalData?.theme?.fontFamily;
        }
        const availableFonts = getAvailableFontFamilies(
          additionalData.theme.availableFonts,
        );
        // check if the font family has the font weight
        const fontWeight = get(props, pathToProperty("fontWeight"));
        const availableWeights =
          availableFonts[fontFamily]?.weights ?? DEFAULT_FONT_WEIGHTS;
        if (!availableWeights.includes(Number(fontWeight))) {
          return [
            {
              propertyPath: pathToProperty("fontWeight"),
              propertyValue: availableWeights[0],
            },
          ];
        }
      },
    },
    {
      propertyName: pathToProperty("fontSize"),
      label: "Font size",
      defaultUnit: "px",
      unitOptions: FONT_SIZE_UNIT_OPTIONS,
      precision: 0,
      controlType: "INPUT_NUMBER",
      propertyCategory: category,
      isBindProperty: false,
      isTriggerProperty: false,
      minValue: 1,
    },
    {
      propertyName: pathToProperty("fontWeight"),
      label: "Font weight",
      controlType: "DROP_DOWN",
      propertyCategory: category,
      getAdditionalDataForPropFunc: {
        themeTypographies: selectGeneratedThemeTypographies,
        themeFontFamily: selectGeneratedThemeFontFamily,
        availableFonts: selectAvailableFonts,
      },
      optionsFuncWithAdditionalData: ({
        props,
        additionalDataForPropFunc,
        propertyName,
      }: {
        props: any;
        additionalDataForPropFunc?: Record<string, any>;
        propertyName: string;
      }) => {
        let fontFamily = get(props, pathToProperty("fontFamily"));

        if (getDynamicTextStyleParentDottedPath) {
          fontFamily = get(
            props,
            getDynamicTextStyleParentDottedPath?.(propertyName) +
              ".textStyle.fontFamily",
          );
        }

        if (fontFamily === "inherit") {
          fontFamily = additionalDataForPropFunc?.themeFontFamily;
        }
        const availableFonts = getAvailableFontFamilies(
          additionalDataForPropFunc?.availableFonts,
        );
        const availableWeights =
          availableFonts[fontFamily]?.weights ?? DEFAULT_FONT_WEIGHTS;

        const filteredFontWeightOptions = FONT_WEIGHT_OPTIONS.filter((option) =>
          availableWeights.includes(Number(option.value)),
        );
        return filteredFontWeightOptions;
      },
      isBindProperty: false,
      isTriggerProperty: false,
    },
    {
      propertyName: pathToProperty("fontStyle"),
      label: "Font style",
      controlType: "DROP_DOWN",
      propertyCategory: category,
      options: FONT_STYLE_OPTIONS,
      isBindProperty: false,
      isTriggerProperty: false,
    },
    {
      propertyName: pathToProperty("lineHeight"),
      label: "Line height",
      controlType: "INPUT_NUMBER",
      propertyCategory: category,
      defaultUnit: "",
      unitOptions: LINE_HEIGHT_UNIT_OPTIONS,
      transformValueOnUnitChange: ({
        oldUnit,
        newUnit,
        value,
        widgetProperties,
        path,
      }: {
        oldUnit: string | undefined;
        newUnit: string;
        value: unknown;
        widgetProperties?: any;
        path: string;
      }) => {
        let fontSize = get(widgetProperties, pathToProperty("fontSize"));
        if (getDynamicTextStyleParentDottedPath) {
          fontSize = get(
            widgetProperties,
            getDynamicTextStyleParentDottedPath?.(path) + ".textStyle.fontSize",
          );
        }
        if (fontSize == null) {
          return value as number;
        }

        const currentFontSize: number = extractPixels(fontSize);

        return getNewValueOnUnitChange({
          oldUnit,
          newUnit,
          value,
          currentFontSize,
          defaultRatio: 1.2,
        });
      },
      warningFunc: ({ props, propertyName }) => {
        let fontSize = get(props, pathToProperty("fontSize"));
        if (getDynamicTextStyleParentDottedPath) {
          fontSize = get(
            props,
            getDynamicTextStyleParentDottedPath?.(propertyName) +
              ".textStyle.fontSize",
          );
        }

        if (typeof fontSize !== "string") {
          return;
        }

        const currentFontSize: number = extractPixels(fontSize);

        let lineHeight = get(props, pathToProperty("lineHeight"));
        if (getDynamicTextStyleParentDottedPath) {
          lineHeight = get(
            props,
            getDynamicTextStyleParentDottedPath?.(propertyName) +
              ".textStyle.lineHeight",
          );
        }

        if (lineHeight == null) {
          return;
        }
        const lineHeightInpx = getLineHeightInPixels(
          lineHeight,
          currentFontSize,
        );

        if (lineHeightInpx < currentFontSize) {
          return LINE_HEIGHT_WARNING;
        }
      },
      isBindProperty: false,
      isTriggerProperty: false,
    },
    {
      propertyName: pathToProperty("letterSpacing"),
      label: "Letter spacing",
      defaultUnit: "em",
      unitOptions: LETTER_SPACING_UNIT_OPTIONS,
      transformValueOnUnitChange: ({
        oldUnit,
        newUnit,
        value,
        widgetProperties,
        path,
      }: {
        oldUnit: string | undefined;
        newUnit: string;
        value: unknown;
        widgetProperties?: any;
        path: string;
      }) => {
        let fontSize = get(widgetProperties, pathToProperty("fontSize"));

        if (getDynamicTextStyleParentDottedPath) {
          fontSize = get(
            widgetProperties,
            getDynamicTextStyleParentDottedPath?.(path) +
              ".textStyle.fontFamily",
          );
        }
        if (fontSize == null) {
          return value as number;
        }

        const currentFontSize: number = extractPixels(fontSize);

        return getNewValueOnUnitChange({
          oldUnit,
          newUnit,
          value,
          currentFontSize,
          defaultRatio: 0,
        });
      },
      controlType: "INPUT_NUMBER",
      propertyCategory: category,
      isBindProperty: false,
      isTriggerProperty: false,
    },
    {
      propertyName: pathToProperty("textTransform"),
      label: "Text transform",
      controlType: "DROP_DOWN",
      propertyCategory: category,
      options: TEXT_TRANSFORM_OPTIONS,
      isBindProperty: false,
      isTriggerProperty: false,
    },
  ];

  if (isPopover) {
    return properties satisfies PropertyPaneControlConfig<
      NestedTextStylePropertyNames<NestedPath>
    >[];
  }

  const defaultHidden = hiddenCustomStyles(textStyleParentDottedPath);
  const compoundHidden: PropertyPaneControlConfig["hidden"] = (
    props,
    propertyPath,
    featureFlags,
    additionalHiddenData,
    theme,
  ) => {
    if (overrideHidden) {
      return overrideHidden(
        props,
        propertyPath,
        featureFlags,
        additionalHiddenData,
        theme,
      );
    }

    if (
      additionalHidden &&
      additionalHidden(
        props,
        propertyPath,
        featureFlags,
        additionalHiddenData,
        theme,
      )
    ) {
      return true;
    }

    return defaultHidden(
      props,
      propertyPath,
      featureFlags,
      additionalHiddenData,
      theme,
    );
  };

  const propsWithHidden = properties.map((property) => ({
    ...property,
    hidden: compoundHidden,
  }));

  return propsWithHidden satisfies PropertyPaneControlConfig<
    NestedTextStylePropertyNames<NestedPath>
  >[];
};

// set default value for custom styles based on theme if missing from variant or has value not good for display directly
const setDefaultValueForCustomStyles = ({
  propertyNameShort,
  valueFromVariant,
  additionalDataForPropFunc,
}: {
  propertyNameShort: string | undefined;
  valueFromVariant: string;
  additionalDataForPropFunc: Record<string, any> | undefined;
}) => {
  const value: string = valueFromVariant;
  switch (propertyNameShort) {
    case "fontFamily":
      if (value === "inherit" || !value) {
        return additionalDataForPropFunc?.themeFontFamily;
      }
      return value;
    case "fontStyle":
      return value ?? "normal";
    case "textTransform":
      return value ?? "none";
  }
  return value;
};

type DefaultValueFn<T> = (
  ...options: Parameters<
    NonNullable<PropertyPaneControlConfig["defaultValueFn"]>
  >
) => T;

export const styleProperties = ({
  labelNamespace,
  propertyNamespaceDottedPath,
  backgroundColorThemeValue,
  backgroundColorDefaultValue,
  defaultBorderProperty,
  borderThemeValue,
  defaultBorderRadiusProperty,
  borderRadiusThemeValue,
  hidden,
}: {
  labelNamespace?: string;
  propertyNamespaceDottedPath?: string;
  backgroundColorDefaultValue?: string;
  backgroundColorThemeValue?: PropertyPaneControlConfig["themeValue"];
  defaultBorderProperty?: PerSideBorder;
  borderThemeValue?: PropertyPaneControlConfig["themeValue"];
  defaultBorderRadiusProperty?:
    | PerCornerBorderRadius
    | DefaultValueFn<PerCornerBorderRadius | undefined>;
  borderRadiusThemeValue?: PropertyPaneControlConfig["themeValue"];
  hidden?: PropertyPaneControlConfig["hidden"];
}) =>
  [
    backgroundColorProperty({
      themeValue: backgroundColorThemeValue,
      defaultValue: backgroundColorDefaultValue,
      label: labelNamespace ? `${labelNamespace} background color` : undefined,
      propertyNamespaceDottedPath,
      hidden,
    }),
    borderProperty({
      themeValue: borderThemeValue,
      defaultValue: defaultBorderProperty,
      label: labelNamespace ? `${labelNamespace} border` : undefined,
      propertyNamespaceDottedPath,
      hidden,
    }),
    borderRadiusProperty({
      themeValue: borderRadiusThemeValue,
      label: labelNamespace ? `${labelNamespace} border radius` : undefined,
      propertyNamespaceDottedPath,
      hidden,
      ...(typeof defaultBorderRadiusProperty === "function"
        ? { defaultValueFn: defaultBorderRadiusProperty }
        : { defaultValue: defaultBorderRadiusProperty }),
    }),
  ] satisfies PropertyPaneControlConfig[];

// Set a default value based on theme if value is undefined or not in the dropdown options
const getValueFromVariant = ({
  propertyNameShort,
  defaultVariant,
  currentVariant,
  themeTypographies,
  props,
}: {
  propertyNameShort: string | undefined;
  defaultVariant?: string;
  currentVariant: string;
  themeTypographies: unknown;
  props: unknown;
}) => {
  if (!propertyNameShort) {
    return undefined;
  }
  const fallbackValue = get(
    themeTypographies,
    `${defaultVariant}.${propertyNameShort}`,
  );
  const value = get(
    themeTypographies,
    `${currentVariant}.${propertyNameShort}`,
  );
  return value ?? fallbackValue;
};

type StylePropertyUpdateHook = (
  params: UpdateHookArgs,
) => Array<UpdateHookReturnUpdateItem> | undefined;

function createTypographyVariantUpdateHook<NestedPath extends string>({
  textStyleParentDottedPath,
  defaultValueFn,
  getDynamicTextStyleParentDottedPath,
  updateHook,
}: {
  textStyleParentDottedPath: NestedPath;
  defaultValueFn?: PropertyPaneControlConfig["defaultValueFn"];
  getDynamicTextStyleParentDottedPath?: (path: string) => NestedPath;
  updateHook?: (
    args: UpdateHookArgs,
  ) => Array<{ propertyPath: string; propertyValue: any }> | undefined;
}): PropertyPaneControlConfig["updateHook"] {
  return (args: UpdateHookArgs) => {
    const {
      props,
      propertyPath,
      propertyValue,
      additionalDataForPropFunc,
      flags,
    } = args;

    const defaultVariant = defaultValueFn
      ? defaultValueFn({ props, propertyName: propertyPath, flags })
      : undefined;
    const currentVariant = get(props, propertyPath, defaultVariant);
    const dynamicTextStyleParentDottedPath =
      getDynamicTextStyleParentDottedPath?.(propertyPath) ||
      textStyleParentDottedPath;

    let allCustomStyleUpdates: Array<{
      propertyPath: string;
      propertyValue: any;
    }> = [];

    if (
      currentVariant === SB_CUSTOM_TEXT_STYLE &&
      propertyValue !== SB_CUSTOM_TEXT_STYLE
    ) {
      // set custom style props to undefined
      const customStylesUpdates = customStylesProperties<NestedPath>({
        textStyleParentDottedPath: dynamicTextStyleParentDottedPath,
        forceFullPropertyNamePath: true,
        isPopover: true,
      }).map((property) => ({
        propertyPath: property.propertyName as string,
        propertyValue: undefined,
      }));

      allCustomStyleUpdates = allCustomStyleUpdates.concat(customStylesUpdates);
    }

    if (
      currentVariant !== SB_CUSTOM_TEXT_STYLE &&
      propertyValue === SB_CUSTOM_TEXT_STYLE
    ) {
      const themeTypographies = additionalDataForPropFunc?.themeTypographies;
      // set custom style props to last variant values
      const customStylesUpdates = customStylesProperties<NestedPath>({
        textStyleParentDottedPath: dynamicTextStyleParentDottedPath,
        forceFullPropertyNamePath: true,
        isPopover: true,
      }).map((property) => {
        const propertyNameShort = (property.propertyName as string | undefined)
          ?.split(".")
          .pop();
        const valueFromVariant = getValueFromVariant({
          propertyNameShort,
          themeTypographies,
          currentVariant,
          defaultVariant,
          props,
        });

        const value = setDefaultValueForCustomStyles({
          propertyNameShort: propertyNameShort,
          valueFromVariant,
          additionalDataForPropFunc,
        });

        return {
          propertyPath: property.propertyName as string,
          propertyValue: value,
        };
      });

      // update custom text color to last variant value if not user overridden
      if (
        get(
          props,
          `${dynamicTextStyleParentDottedPath}.textStyle.textColor.default`,
        ) === undefined
      ) {
        // generated theme colors will be set to hex values, but stored values will contain theme tokens (i.e. colors.neutral700)
        const storedColor = get(
          additionalDataForPropFunc?.storedTypographies,
          `${currentVariant}.textColor.default`,
        );
        const generatedColor = get(
          themeTypographies,
          `${currentVariant}.textColor.default`,
        );

        if (isColorToken(storedColor)) {
          // set the value to the stored color
          customStylesUpdates.push({
            propertyPath: `${dynamicTextStyleParentDottedPath}.textStyle.textColor.default`,
            propertyValue: `{{ theme.${storedColor} }}`,
          });
        } else {
          customStylesUpdates.push({
            propertyPath: `${dynamicTextStyleParentDottedPath}.textStyle.textColor.default`,
            propertyValue: generatedColor,
          });
        }
      }

      allCustomStyleUpdates = allCustomStyleUpdates.concat(customStylesUpdates);
    }

    // if there is already an update hook, we need to run it
    // including on every update entry generated above
    let allUpdates: Array<{
      propertyPath: string;
      propertyValue: any;
    }> = [];

    allUpdates = allUpdates.concat(allCustomStyleUpdates);

    if (updateHook) {
      allUpdates = allUpdates.concat(
        updateHook({
          props,
          propertyPath,
          propertyValue,
          flags,
          additionalDataForPropFunc,
        }) || [],
      );

      allCustomStyleUpdates.forEach((update) => {
        allUpdates = allUpdates.concat(
          updateHook({
            props,
            propertyPath: update.propertyPath,
            propertyValue: update.propertyValue,
            flags,
            additionalDataForPropFunc,
          }) || [],
        );
      });
    }

    return allUpdates;
  };
}

export const textStyleProperty = <NestedPath extends string>({
  textStyleParentDottedPath,
  themeValue,
  defaultValueFn,
  label = "Text style",
  helpText = createThemeTooltip(),
  isBindProperty = false,
  isTriggerProperty = false,
  updateHook,
  isJSConvertible = false,
  customJSControl,
  additionalUserSelectableVariants = [],
  hidden,
  visibility,
  isRemovable,
  resetToThemeBtnText,
  optionsCustomizer,
  getDynamicTextStyleParentDottedPath,
}: {
  textStyleParentDottedPath: NestedPath;
  themeValue?: PropertyPaneControlConfig["themeValue"];
  defaultValueFn?: PropertyPaneControlConfig["defaultValueFn"];
  label?: PropertyPaneControlConfig["label"];
  helpText?: PropertyPaneControlConfig["helpText"];
  isBindProperty?: PropertyPaneControlConfig["isBindProperty"];
  isTriggerProperty?: PropertyPaneControlConfig["isTriggerProperty"];
  updateHook?: StylePropertyUpdateHook;
  isJSConvertible?: PropertyPaneControlConfig["isJSConvertible"];
  customJSControl?: PropertyPaneControlConfig["customJSControl"];
  hidden?: PropertyPaneControlConfig["hidden"];
  visibility?: PropertyPaneControlConfig["visibility"];
  isRemovable?: PropertyPaneControlConfig["isRemovable"];
  resetToThemeBtnText?: PropertyPaneControlConfig["resetToThemeBtnText"];
  optionsCustomizer?: OptionsCustomizerFn;
  // this function is needed to get the dynamic path from the property name passed into the function
  // for when we define the properties dynamically like in TableWidget columns
  getDynamicTextStyleParentDottedPath?: (path: string) => NestedPath;
  additionalUserSelectableVariants?: Array<NonCustomTypography>;
}) => {
  const textStyleUpdateVariantHook = createTypographyVariantUpdateHook({
    textStyleParentDottedPath,
    defaultValueFn,
    getDynamicTextStyleParentDottedPath,
    updateHook,
  });

  const config: PropertyPaneControlConfig = {
    propertyName: `${textStyleParentDottedPath}.textStyle.variant`,
    helpText,
    label,
    controlType: "DROP_DOWN",
    propertyCategory: PropsPanelCategory.Appearance,
    themeValue,
    defaultValueFn,
    customJSControl,
    isJSConvertible,
    visibility,
    isRemovable,
    resetToThemeBtnText,
    menuFooterOptions: [createThemeOption()],
    optionsSelector: (state, props, flags, propertyName) => {
      return selectTypographyVariantsAsDropDownControlOptions(
        state,
        props,
        propertyName,
        flags,
        additionalUserSelectableVariants,
        optionsCustomizer,
      );
    },
    isBindProperty,
    isTriggerProperty,
    hidden: (props, path, flags, additionalHiddenData, theme) => {
      if (hidden) {
        return hidden(props, path, flags, additionalHiddenData, theme) ?? false;
      }
      return false;
    },
    getAdditionalDataForPropFunc: {
      themeTypographies: selectGeneratedThemeTypographies,
      themeFontFamily: selectGeneratedThemeFontFamily,
      storedTypographies: selectStoredThemeTypographies,
    },
    updateHook: textStyleUpdateVariantHook,
  };

  return config;
};

type TextStylePropertyControlConfig<T> = Partial<{
  variant: T;
  ["textColor.default"]: T;
}>;

/**
 * This is a control for a textStyle, which combines the variant and textColor.default properties.
 * There are some limitations to this control:
 * - themeValues must be a function (not a string or other options)
 * - updateHook must be a function that returns an array instead of an object or promise
 *
 */
export const textStyleCombinedProperty = <NestedPath extends string>({
  textStyleParentDottedPath,
  themeValue,
  defaultValueFn,
  label = "Text style",
  helpText = createThemeTooltip(),
  isBindProperty = false,
  isTriggerProperty = false,
  updateHook,
  isJSConvertible = false,
  customJSControl,
  additionalUserSelectableVariants = [],
  hidden,
  visibility,
  isRemovable,
  resetToThemeBtnText,
  optionsCustomizer,
  getDynamicTextStyleParentDottedPath,
  defaultThemeVariant,
  enableTextColorConfig = true,
}: {
  textStyleParentDottedPath: NestedPath;
  themeValue?: TextStylePropertyControlConfig<ThemeValueFunction>; // Force themeValues to be functions (not strings or other options) for easier usage
  defaultValueFn?: TextStylePropertyControlConfig<
    PropertyPaneControlConfig["defaultValueFn"]
  >;
  label?: PropertyPaneControlConfig["label"];
  helpText?: PropertyPaneControlConfig["helpText"];
  isBindProperty?: PropertyPaneControlConfig["isBindProperty"];
  isTriggerProperty?: PropertyPaneControlConfig["isTriggerProperty"];
  updateHook?: TextStylePropertyControlConfig<
    (
      args: UpdateHookArgs,
    ) => Array<{ propertyPath: string; propertyValue: any }> | undefined
  >;
  isJSConvertible?: PropertyPaneControlConfig["isJSConvertible"];
  customJSControl?: PropertyPaneControlConfig["customJSControl"];
  hidden?: PropertyPaneControlConfig["hidden"];
  visibility?: PropertyPaneControlConfig["visibility"];
  isRemovable?: PropertyPaneControlConfig["isRemovable"];
  resetToThemeBtnText?: PropertyPaneControlConfig["resetToThemeBtnText"];
  optionsCustomizer?: OptionsCustomizerFn;
  // this function is needed to get the dynamic path from the property name passed into the function
  // for when we define the properties dynamically like in TableWidget columns
  getDynamicTextStyleParentDottedPath?: (path: string) => NestedPath;
  additionalUserSelectableVariants?: Array<NonCustomTypography>;
  defaultThemeVariant?: keyof Typographies;
  enableTextColorConfig?: boolean;
}) => {
  const textStyleUpdateVariantHook = createTypographyVariantUpdateHook({
    textStyleParentDottedPath: textStyleParentDottedPath,
    defaultValueFn: defaultValueFn?.variant,
    getDynamicTextStyleParentDottedPath,
    updateHook: updateHook?.variant,
  });

  const combinedDefaultValueFn: PropertyPaneControlConfig["defaultValueFn"] = (
    args,
  ) => {
    return {
      variant: defaultValueFn?.variant?.(args),
      "textColor.default": defaultValueFn?.["textColor.default"]?.(args),
    };
  };

  const textColorThemeValueFnToUse = getColorThemeValueFnToUse({
    themeValue: themeValue?.["textColor.default"],
    defaultThemeVariant,
    propertyNameHasNamespace: false,
  });

  const combinedThemeValue: ThemeValueFunction = (args) => {
    return {
      value: {
        variant: themeValue?.variant?.(args),
        "textColor.default":
          typeof textColorThemeValueFnToUse === "function"
            ? textColorThemeValueFnToUse(args)
            : textColorThemeValueFnToUse,
      },
      treatAsNull: false,
    };
  };

  const customStyles = customStylesProperties({
    textStyleParentDottedPath,
    forceFullPropertyNamePath: false, // the panel props popover already builds the full path for us
    isPopover: true,
  });

  const config: PropertyPaneControlConfig = {
    controlType: "TEXT_STYLE",
    propertyName: `${textStyleParentDottedPath}.textStyle`,
    themeValue: combinedThemeValue,
    defaultValueFn: combinedDefaultValueFn,
    helpText,
    label,
    propertyCategory: PropsPanelCategory.Appearance,
    customJSControl,
    isJSConvertible,
    visibility,
    isRemovable,
    resetToThemeBtnText,
    isBindProperty,
    isTriggerProperty,
    hidden: (props, path, flags, additionalHiddenData, theme) => {
      if (hidden) {
        return hidden(props, path, flags, additionalHiddenData, theme) ?? false;
      }
      return false;
    },
    getAdditionalDataForPropFunc: {
      themeTypographies: selectGeneratedThemeTypographies,
      themeFontFamily: selectGeneratedThemeFontFamily,
      storedTypographies: selectStoredThemeTypographies,
    },
    updateHook: textStyleUpdateVariantHook,
    optionsSelector: (state, props, flags, propertyName) => {
      return selectTypographyVariantsAsDropDownControlOptions(
        state,
        props,
        propertyName,
        flags,
        additionalUserSelectableVariants,
        optionsCustomizer,
      );
    },
    panelConfig: {
      title: "Edit custom typography",
      editableTitle: false,
      panelIdPropertyName: "textStyle",
      showEditIcon: false,
      children: [
        PanelCategory(PropsPanelCategory.Appearance, customStyles || [], {
          noHeader: true,
        }),
      ],
    },
    enableTextColorConfig,
  };

  return config;
};

// This function can be used both for single `textColor` property control,
// but also for `textStyle` properties (which combines textColor and variant)
// When used for `textStyle` the propertyName points to the combined attribute rather than the `textColor.default` attribute
const defaultColorThemeValueFn = ({
  theme,
  props,
  propertyName,
  defaultThemeVariant,
  propertyNameHasNamespace,
}: {
  theme: GeneratedTheme;
  props: any;
  propertyName: string;
  defaultThemeVariant: keyof Typographies;
  propertyNameHasNamespace: boolean;
}) => {
  // e.g: Received propertyName is either
  // - `labelProps.textStyle.textColor.default` (has namespace)
  // - or `labelProps.textStyle` (doesn't have namespace)
  // We want to get the base (`labelProps.textStyle`)
  const propertyNameBasePath = propertyNameHasNamespace
    ? removeLastPathSegments(propertyName, 2)
    : propertyName;

  const pathToTextStyleVariant = `${propertyNameBasePath}.variant`;

  let variantName = get(props, pathToTextStyleVariant);
  const variantFromTheme = get(theme.typographies, variantName);

  if (variantName === SB_CUSTOM_TEXT_STYLE || !variantFromTheme) {
    variantName = defaultThemeVariant;
  }

  return {
    value: `typographies.${variantName}.textColor.default`,
    treatAsNull: false,
  };
};

// This function can be used both for single `textColor` property control,
// but also for `textStyle` properties (which combines textColor and variant)
// When used for `textStyle` the propertyName points to the combined attribute rather than the `textColor.default` attribute
const getColorThemeValueFnToUse = ({
  themeValue,
  defaultThemeVariant,
  propertyNameHasNamespace,
}: {
  themeValue: PropertyPaneControlConfig["themeValue"];
  defaultThemeVariant?: keyof Typographies;
  propertyNameHasNamespace: boolean;
}): PropertyPaneControlConfig["themeValue"] => {
  if (themeValue) {
    return themeValue;
  }

  if (!defaultThemeVariant) {
    return undefined;
  }

  return ({
    theme,
    props,
    propertyName,
  }: {
    theme: GeneratedTheme;
    props: any;
    propertyName: string;
  }) => {
    return defaultColorThemeValueFn({
      theme,
      props,
      propertyName,
      propertyNameHasNamespace,
      defaultThemeVariant,
    });
  };
};

export const textColorProperty = ({
  textStyleParentDottedPath,
  themeValue,
  defaultValueFn,
  label = "Text color",
  hidden,
  isJSConvertible,
  customJSControl,
  isBindProperty = true,
  isTriggerProperty = false,
  defaultThemeVariant,
  updateHook,
}: {
  textStyleParentDottedPath: string;
  themeValue?: PropertyPaneControlConfig["themeValue"];
  defaultValueFn?: PropertyPaneControlConfig["defaultValueFn"];
  label?: string;
  hidden?: PropertyPaneControlConfig["hidden"];
  isJSConvertible?: PropertyPaneControlConfig["isJSConvertible"];
  customJSControl?: PropertyPaneControlConfig["customJSControl"];
  isBindProperty?: PropertyPaneControlConfig["isBindProperty"];
  isTriggerProperty?: PropertyPaneControlConfig["isTriggerProperty"];
  defaultThemeVariant?: keyof Typographies;
  updateHook?: StylePropertyUpdateHook;
}): PropertyPaneControlConfig => {
  let fullTextStylePath = "textStyle";
  if (textStyleParentDottedPath) {
    fullTextStylePath = `${textStyleParentDottedPath}.textStyle`;
  }
  const themeValueFnToUse = getColorThemeValueFnToUse({
    themeValue,
    defaultThemeVariant,
    propertyNameHasNamespace: true,
  });

  const config: PropertyPaneControlConfig = {
    propertyName: `${fullTextStylePath}.textColor.default`,
    helpText: "Sets the color of the text",
    label,
    controlType: "COLOR_PICKER",
    propertyCategory: PropsPanelCategory.Appearance,
    themeValue: themeValueFnToUse,
    isJSConvertible,
    customJSControl,
    defaultValueFn,
    isBindProperty,
    isTriggerProperty,
    updateHook,
    hidden: (props, path, flags, additionalHiddenData, theme) => {
      if (hidden) {
        return hidden(props, path, flags, additionalHiddenData, theme) ?? false;
      }
      return false;
    },
  };

  return config;
};

export const typographyProperties = (params: {
  propertyNameForHumans: string;
  textStyleParentDottedPath: string;
  defaultVariant: keyof Typographies;
  hiddenIfPropertyNameIsNullOrFalse?: string;
}): Array<PropertyPaneControlConfig> => {
  return [
    textStyleCombinedProperty({
      label:
        params.propertyNameForHumans === "Input"
          ? "Input text style"
          : `${params.propertyNameForHumans} style`,
      textStyleParentDottedPath: params.textStyleParentDottedPath,
      defaultValueFn: {
        variant: () => params.defaultVariant,
        "textColor.default": () => undefined,
      },
      defaultThemeVariant: params.defaultVariant,
      hidden: (props) => {
        return (
          !!params.hiddenIfPropertyNameIsNullOrFalse &&
          (get(props, params.hiddenIfPropertyNameIsNullOrFalse) == null ||
            get(props, params.hiddenIfPropertyNameIsNullOrFalse) === false)
        );
      },
    }),
  ];
};
