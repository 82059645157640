import { ApplicationScope } from "@superblocksteam/shared";
import React, { useCallback, useState } from "react";
import { CSSProperties, useMemo } from "react";
import DynamicSVG from "components/ui/DynamicSVG";
import { EventType } from "legacy/constants/ActionConstants";
import { RadioButtonControlIcons } from "legacy/icons/BooleanValueIcons";
import { RunWidgetEventHandlers } from "legacy/widgets/BaseWidget";
import { LinkComponent } from "legacy/widgets/LinkWidget/LinkComponent";
import { Button } from "legacy/widgets/Shared/Button";
import {
  BooleanStyleFalse,
  parseImageURLValue,
  ImageSize,
} from "legacy/widgets/Shared/ValueWithTypeUtils";
import PopoverVideo from "legacy/widgets/TableWidget/TableComponent/PopoverVideo";
import { TagsCell } from "legacy/widgets/TableWidget/TableComponent/TableUtilities";
import { LinkToType } from "legacy/widgets/navigationProperties";
import { createPerSideBorder } from "pages/Editors/AppBuilder/Sidebar/BorderEditor";
import { addNewPromise } from "store/utils/resolveIdSingleton";
import { styleAsClass } from "styles/styleAsClass";
import { isValidUrl } from "utils/url";
import { KeyValueProperty, PropertyType } from "../types";
import { getValueByType, getTagsColorAssignment } from "../utils";

const ValueWrapperClass = styleAsClass`
  display: flex;
`;

const ValueContentWrapperClass = styleAsClass`
  display: flex;
  gap: 5px;
`;

const ImageWrapperClass = styleAsClass`
  &[data-image-size="FIXED"] {
    width: 32px;
    height: 32px;
    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }

  &[data-image-size="FIT"] {
    height: 32px;
    width: 100%;
    overflow: hidden;
    img {
      max-width: 100%;
      max-height: 100%;
      height: auto;
    }
  }

  &[data-image-size="COVER"] {
    height: 32px;
    width: 100%;
    position: relative;
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &[data-image-size="GROW"] {
    width: 100%;
    img {
      width: 100%;
      height: auto;
    }
  }
`;

export const KeyValuePropertyValue = (props: {
  property: KeyValueProperty;
  data: Record<string, unknown>;
  runEventHandlers: (payload: RunWidgetEventHandlers) => void;
  style?: CSSProperties;
  className?: string;
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const { property, data, runEventHandlers, style, className } = props;

  const handleButtonClick = useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      e.stopPropagation();
      setIsLoading(true);

      if (property.typeSpecificProps?.onClick && !isLoading) {
        const callbackId = addNewPromise(() => setIsLoading(false));

        runEventHandlers({
          steps: property.typeSpecificProps.onClick,
          type: EventType.ON_CLICK,
          callbackId,
          currentScope: ApplicationScope.PAGE,
        });
      }
    },
    [property.typeSpecificProps?.onClick, isLoading, runEventHandlers],
  );

  const content = useMemo(() => {
    const icon = property.valueProps?.icon ? (
      <DynamicSVG iconName={property.valueProps.icon} />
    ) : null;
    const iconPosition = property.valueProps?.iconPosition ?? "LEFT";
    const value = property.isDerived
      ? property.computedValue
      : data[property.id];

    switch (property.type) {
      case PropertyType.BUTTON: {
        const borderColor = property.typeSpecificProps?.buttonBorderColor;
        const backgroundColor =
          property.typeSpecificProps?.buttonBackgroundColor;
        const buttonStyle = property.typeSpecificProps?.buttonStyle;
        const buttonLabel = property.typeSpecificProps?.buttonLabel;
        const buttonLabelColor = property.typeSpecificProps?.buttonLabelColor;
        const isDisabled = property.typeSpecificProps?.isDisabled;

        return (
          <Button
            version="v2"
            disabled={isDisabled}
            buttonStyle={buttonStyle ?? "PRIMARY_BUTTON"}
            backgroundColor={backgroundColor}
            border={
              borderColor
                ? createPerSideBorder({
                    color: borderColor ?? "#000000",
                    width: 1,
                  })
                : undefined
            }
            text={buttonLabel ?? "Click me"}
            icon={icon}
            iconPosition={iconPosition}
            textColor={buttonLabelColor}
            isLoading={isLoading}
            onClick={handleButtonClick}
            width="100%"
            height="100%"
          />
        );
      }
      case PropertyType.LINK:
        return (
          <LinkComponent
            url={property.typeSpecificProps?.linkUrl}
            linkTo={LinkToType.CustomUrl}
            text={property.typeSpecificProps?.linkLabel}
            isLoading={false}
            linkStyle="LINK"
            textProps={{
              textStyleVariant: "label",
            }}
            icon={icon}
            iconPosition={iconPosition}
            openInNewTab={property.typeSpecificProps?.openInNewTab ?? true}
          />
        );
      case PropertyType.BOOLEAN: {
        const ButtonIcon = getValueByType(property, data)
          ? RadioButtonControlIcons["CHECK"]
          : RadioButtonControlIcons[
              property.typeSpecificProps?.booleanStyleFalse ||
                BooleanStyleFalse.EMPTY
            ];
        return <ButtonIcon />;
      }
      case PropertyType.IMAGE: {
        const imageUrls = parseImageURLValue(value);
        if (!imageUrls || imageUrls.length === 0) {
          return <div>Invalid Image</div>;
        }

        return imageUrls.map((item: string, index: number) => {
          if (isValidUrl(item)) {
            const imageStyle = {
              borderRadius:
                typeof property.typeSpecificProps?.imageBorderRadius ===
                "string"
                  ? property.typeSpecificProps?.imageBorderRadius
                  : property.typeSpecificProps?.imageBorderRadius
                    ? `${property.typeSpecificProps.imageBorderRadius.value}${property.typeSpecificProps.imageBorderRadius.mode}`
                    : "50%",
            };
            if (
              property.typeSpecificProps?.openImageUrl ||
              property.typeSpecificProps?.openImageUrl === null // default behaviour needs to be true for backwards compatibility
            ) {
              return (
                <a
                  onClick={(e) => e.stopPropagation()}
                  target="_blank"
                  rel="noopener noreferrer"
                  href={item}
                  className={ImageWrapperClass}
                  key={index}
                  data-image-size={
                    property.typeSpecificProps?.imageSize ?? ImageSize.Fixed
                  }
                >
                  <img src={item} alt="" style={imageStyle} />
                </a>
              );
            }
            return (
              <div
                className={ImageWrapperClass}
                key={index}
                data-image-size={
                  property.typeSpecificProps?.imageSize ?? ImageSize.Fixed
                }
              >
                <img src={item} alt="" style={imageStyle} />
              </div>
            );
          } else {
            return (
              <div className={ValueContentWrapperClass} key={index}>
                Invalid Image
              </div>
            );
          }
        });
      }
      case PropertyType.VIDEO: {
        return <PopoverVideo url={value as string} />;
      }
      case PropertyType.TAGS: {
        let displayValue = value;
        if (displayValue && typeof displayValue === "string") {
          // handle case where displayedValue is a stringified array
          displayValue = displayValue.split(", ");
        } else if (
          typeof value === "string" ||
          typeof value === "number" ||
          typeof value === "boolean"
        ) {
          displayValue = [value];
        }

        if (!Array.isArray(displayValue)) {
          return null;
        }

        const tagsColorAssignment = getTagsColorAssignment(displayValue);

        return (
          <TagsCell
            cellProperties={{}}
            isHidden={false}
            tagValues={displayValue}
            tagsColorAssignment={tagsColorAssignment}
            tagsCustomColorAssignment={
              property.typeSpecificProps?.tagDisplayConfig
            }
            compactMode="DEFAULT"
          />
        );
      }
      case PropertyType.EMAIL: {
        const value = getValueByType(property, data);
        return (
          <a className={ValueContentWrapperClass} href={`mailto:${value}`}>
            {iconPosition === "LEFT" && icon}
            {value}
            {iconPosition === "RIGHT" && icon}
          </a>
        );
      }
      default:
        return (
          <div className={ValueContentWrapperClass}>
            {iconPosition === "LEFT" && icon}
            {getValueByType(property, data)}
            {iconPosition === "RIGHT" && icon}
          </div>
        );
    }
  }, [property, data, handleButtonClick, isLoading]);
  return (
    <div className={`${ValueWrapperClass} ${className}`} style={style}>
      {content}
    </div>
  );
};
