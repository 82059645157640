import { Dimension } from "@superblocksteam/shared";
import React, { memo, useCallback, useMemo } from "react";
import { WidgetTypes } from "legacy/constants/WidgetConstants";
import { APP_MODE } from "legacy/reducers/types";
import { getSingleWidgetProps } from "legacy/selectors/editorSelectors";
import { useAppSelector } from "store/helpers";
import { AppState } from "store/types";
import SkeletonWidget from "./SkeletonWidget";
import { isInvisibleButExpandedInStack } from "./StackLayout/utils";
import type { WidgetProps } from "./BaseWidget";
import type { WidgetLayoutProps } from "./shared";

export type CopyInfo = {
  isClone: boolean;
  originalId: string;
};

type OutputProps<Input> = WidgetProps & Input;

// The `withWidgetProps` HOC can be called multiple times on the same underlying component, e.g. when we (re)register custom components
// To avoid creating a new wrapped component every time, we cache the result of `withWidgetProps`
// This effectively turns `withWidgetProps` into a memoized function that behaves deterministically (= same input produces same output)
const WithWidgetPropsCache = new WeakMap<
  React.ComponentType<any>,
  React.ComponentType<any>
>();

const withWidgetProps = <I extends WidgetLayoutProps & Partial<CopyInfo>>(
  WrappedComponent: React.ComponentType<
    React.PropsWithChildren<OutputProps<I>>
  >,
) => {
  const cachedWithWidgetProps = WithWidgetPropsCache.get(WrappedComponent);
  if (cachedWithWidgetProps) {
    return cachedWithWidgetProps;
  }

  const WithWidgetProps = memo((layoutProps: I) => {
    const { widgetId, originalId } = layoutProps;

    const widgetProps = useAppSelector(
      useCallback(
        (state: AppState) =>
          getSingleWidgetProps(state, originalId || widgetId),
        [originalId, widgetId],
      ),
    );

    const parentWidgetProps = useAppSelector(
      useCallback(
        (state: AppState) => {
          if (!widgetProps.parentId) {
            // For example on the root canvas
            return;
          }
          return getSingleWidgetProps(state, widgetProps.parentId);
        },
        [widgetProps.parentId],
      ),
    );

    const props = useMemo(
      () => mergeProps(widgetProps, layoutProps),
      [layoutProps, widgetProps],
    );
    const isSectionWidget = props.type === WidgetTypes.SECTION_WIDGET;
    const isColumnWidget =
      parentWidgetProps?.type === WidgetTypes.SECTION_WIDGET;
    const isNotEditMode = props.appMode !== APP_MODE.EDIT;

    // we should not render in the following 2 situations:
    // 1. if the widget is not visible, and it's not in a vstack with collapse=false
    // 2. if the widget is detached and it's hidden
    if (
      isNotEditMode &&
      props.isVisible === false &&
      !isInvisibleButExpandedInStack(props) &&
      // If widget is NOT a column, just return null if it's not visible
      // If widget IS a column, only return null if we're also collapsing. If we're not collapsing we
      // need the column to maintain it's visual space in the section, otherwise
      // the sibling columns will visually shift
      (!isColumnWidget || props.collapseWhenHidden === true)
    ) {
      return null;
    }

    if (
      props.isVisible === false &&
      props.detachFromLayout &&
      !isSectionWidget && // TODO(Layouts): Sections should not really be detached
      !isColumnWidget
    ) {
      return null;
    }

    // As layout props are passed in, we need to check if the finalProps.type is a skeleton widget
    // as that is not known at the time of layout, but is known in redux
    if (props.type === WidgetTypes.SKELETON_WIDGET) {
      return <SkeletonWidget {...(props as any)} />;
    }

    return <WrappedComponent {...props} />;
  });

  WithWidgetPropsCache.set(WrappedComponent, WithWidgetProps);
  WithWidgetProps.displayName = `WithWidgetProps(${
    WrappedComponent.displayName || WrappedComponent.name
  })`;
  return WithWidgetProps;
};

// do in-place mutations, no key list for performance
const applyCachedDimensions = (props: WidgetProps & WidgetLayoutProps) => {
  if (props.width) {
    props.width = Dimension.build(props.width);
  }
  if (props.height) {
    props.height = Dimension.build(props.height);
  }
  if (props.minHeight) {
    props.minHeight = Dimension.build(props.minHeight);
  }
  if (props.maxHeight) {
    props.maxHeight = Dimension.build(props.maxHeight);
  }
  if (props.minWidth) {
    props.minWidth = Dimension.build(props.minWidth);
  }
  if (props.maxWidth) {
    props.maxWidth = Dimension.build(props.maxWidth);
  }
  if (props.left) {
    props.left = Dimension.build(props.left);
  }
  if (props.top) {
    props.top = Dimension.build(props.top);
  }
  return props;
};

const mergeProps = <EP extends WidgetProps, LP extends WidgetLayoutProps>(
  evaluatedProps: EP,
  layoutProps: LP,
): EP & LP => {
  const newProps = {
    ...evaluatedProps,
    ...layoutProps,
    key: evaluatedProps.widgetId,
    type: evaluatedProps.type,
    appMode: (layoutProps as any).appMode,
  };
  applyCachedDimensions(newProps);
  return newProps;
};

export default withWidgetProps;
