import React from "react";
import { colors } from "styles/colors";
import { styleAsClass } from "styles/styleAsClass";

const LabelCssString = `
  font-size: 12px;
  line-height: 16px;
  font-weight: 500;
  color: ${colors.GREY_700};
`;

const SubTextCssString = `
  color: ${colors.GREY_400};
  font-size: 11px;  
  line-height: 16px;
`;

export const Heading3 = styleAsClass`
  font-size: 13px;
  line-height: 16px;
  font-weight: 600;
  color: ${colors.GREY_900};
`;

// for help info
export const Dashed = styleAsClass`
  border-bottom: 1px dashed ${colors.GREY_200};
  cursor: pointer;
  width: fit-content;
`;

export const Label = styleAsClass`
  ${LabelCssString}
`;

export const SubText = styleAsClass`
 ${SubTextCssString}
 `;

const FormItemStyle = styleAsClass`
  &.form-item {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }
  .form-item-label {
    ${LabelCssString}
  }
 
  .form-item-subtext {
    ${SubTextCssString}
  }
 
  .form-item-error {
    color: ${colors.RED_500};
    font-size: 12px;
  }

  &.form-item-with-error .form-value-wrapper > span,
  &.form-item-with-error .form-value-wrapper > input {
    border-color: ${colors.RED_500} !important;
    box-shadow: none !important;
  }

  .ant-select {
    width: 100%;
  }
  input.ant-input {
    font-size: 12px;
    color: ${colors.GREY_700};
    border-color: ${colors.GREY_100};
    padding-left: 8px;
  }
  .ant-input-affix-wrapper {
    input.ant-input {
      padding-left: 0px;
    }
  }

  input.ant-input:focus {
    border-color: ${colors.ACCENT_BLUE_500};
  }
`;

export const FormWrapper = styleAsClass`
  display: flex;
  flex-direction: column;
  gap: 20px;

  .ant-select {
    width: 100%;
  }
    
  :not(.ant-input-affix-wrapper) > input.ant-input {
    font-size: 12px;
    height: 32px;
  }

  .ant-input-affix-wrapper {
    height: 32px;
    input.ant-input {
      font-size: 12px;
    }
  }

  .ant-input-affix-wrapper-disabled, .ant-input-affix-wrapper-disabled:hover,
  :not(.ant-input-affix-wrapper) > input:disabled.ant-input {
    color: ${colors.GREY_300};
    border-color: ${colors.GREY_50};
    background-color: ${colors.GREY_50};
    cursor: text;
  }
`;

export const DescriptionContainer = styleAsClass`
  font-weight: 400;
  color: ${colors.GREY_400};
  line-height: 16px;
  font-size: 11px;
  margin-top: 4px;
`;

export const FormItem = ({
  label,
  children,
  required,
  error,
  subText,
  hidden,
  style,
  dataTest,
}: {
  label?: string | React.ReactNode;
  children?: React.ReactNode;
  required?: boolean;
  error?: string | React.ReactNode;
  subText?: string | React.ReactNode;
  hidden?: boolean;
  style?: React.CSSProperties;
  dataTest?: string;
}) => {
  if (hidden) return null;
  return (
    <div
      style={style}
      className={`form-item ${
        error ? "form-item-with-error" : ""
      } ${FormItemStyle}`}
      data-test={dataTest}
    >
      {label && (
        <div className="form-item-label">
          {label} {required && <span style={{ color: colors.RED_500 }}>*</span>}
        </div>
      )}
      {children && <div className={`form-value-wrapper`}>{children}</div>}
      {subText && <div className="form-item-subtext">{subText}</div>}
      {error && <div className="form-item-error">{error}</div>}
    </div>
  );
};
