import { WidgetTypes } from "@superblocksteam/shared";

export const AI_EDITABLE_WIDGET_TYPES: Array<WidgetTypes> = [
  WidgetTypes.BUTTON_WIDGET,
  WidgetTypes.TEXT_WIDGET,
  WidgetTypes.IMAGE_WIDGET,
  WidgetTypes.INPUT_WIDGET,
  WidgetTypes.DATE_PICKER_WIDGET,
  WidgetTypes.TABLE_WIDGET,
  WidgetTypes.DROP_DOWN_WIDGET,
  WidgetTypes.CHECKBOX_WIDGET,
  WidgetTypes.RADIO_GROUP_WIDGET,
  WidgetTypes.RICH_TEXT_EDITOR_WIDGET,
  WidgetTypes.CHART_WIDGET,
  WidgetTypes.MAP_WIDGET,
  WidgetTypes.ICON_WIDGET,
  WidgetTypes.FILE_PICKER_WIDGET,
  WidgetTypes.VIDEO_WIDGET,
  WidgetTypes.CODE_WIDGET,
  WidgetTypes.PDF_VIEWER_WIDGET,
  WidgetTypes.IFRAME_WIDGET,
  WidgetTypes.DIFF_WIDGET,
  WidgetTypes.CHAT_WIDGET,
  WidgetTypes.CUSTOM_WIDGET,
  WidgetTypes.SWITCH_WIDGET,
  WidgetTypes.CALLOUT_WIDGET,
  WidgetTypes.LINK_WIDGET,
  WidgetTypes.CONTAINER_WIDGET,
  WidgetTypes.SECTION_WIDGET,
  WidgetTypes.CANVAS_WIDGET, // for columns, direct canvases aren't selectable anyway
  WidgetTypes.PAGE_WIDGET,
  WidgetTypes.FORM_WIDGET,
  WidgetTypes.FORM_BUTTON_WIDGET,
  WidgetTypes.SLIDEOUT_WIDGET,
  WidgetTypes.MODAL_WIDGET,
  WidgetTypes.MENU_WIDGET,
  WidgetTypes.GRID_WIDGET,
  WidgetTypes.TABS_WIDGET,
];
