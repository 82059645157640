import { Dimension } from "@superblocksteam/shared";
import React, { createRef } from "react";
import { FormInputWithErrorWrapperStyle } from "legacy/components/editorComponents/ErrorInlineMessage";
import WidgetErrorsWrapper from "legacy/components/editorComponents/WidgetErrorsWrapper";
import {
  ErrorMessagePlacement,
  GridDefaults,
} from "legacy/constants/WidgetConstants";
import { GeneratedTheme } from "legacy/themes";
import { CLASS_NAMES } from "legacy/themes/classnames";
import { styleAsClass } from "styles/styleAsClass";
import { CheckboxInput } from "../Shared/CheckboxInput";
import {
  type TypographyProps,
  useMinHeightFromTypography,
  useTypographyStyling,
} from "../typographyHooks";
import {
  createStyleClassName,
  getLineHeightInPxFromTextStyle,
} from "../typographyUtils";
import { DEFAULT_CHECKBOX_WIDGET_TEXT_STYLE_VARIANT } from "./constants";
import type {
  CheckboxComponentProps,
  CheckboxComponentWithLayoutManagedProps,
} from "./types";

const CheckboxContainerClass = styleAsClass`
    height: 100%;
    display: flex;
    justify-content: flex-start;
    gap: 8px;
    align-items: flex-start;
    width: fit-content;
`;

export const StaticTypographyCheckboxLabelProps: TypographyProps = {
  textStyleVariant: DEFAULT_CHECKBOX_WIDGET_TEXT_STYLE_VARIANT,
};

class CheckboxComponent extends React.PureComponent<CheckboxComponentProps> {
  containerRef = createRef<HTMLDivElement>();

  getLabelClass(textStyleVariant: string | undefined) {
    return createStyleClassName({
      textStyleVariant,
      isDisabled: this.props.isDisabled,
      isLoading: this.props.isLoading,
      type: "label",
    });
  }

  handleCheckChange = () => {
    if (this.props.isDisabled) return;
    this.props.onCheckChange(!this.props.isChecked);
  };

  render() {
    const labelClassName = this.getLabelClass(
      this.props.labelProps?.textStyleVariant,
    );

    const restOfLabel = this.props.label;
    const asterisk = "* ";
    const errorMessagePlacement =
      this.props.errorMessagePlacement ?? ErrorMessagePlacement.TOOLTIP;
    const cursorStyle = {
      cursor: this.props.isDisabled ? "not-allowed" : "pointer",
    };

    return (
      <WidgetErrorsWrapper
        widgetId={this.props.widgetId}
        showError={this.props.showError}
        messages={this.props.errorMessages}
        errorMessagePlacement={errorMessagePlacement}
        inlineErrorProps={{
          isFitContentHeight: this.props.isFitContentHeight,
        }}
        customTarget
      >
        {(inlineError, onTargetMouseOver, onTargetMouseLeave) => (
          <div ref={this.containerRef} style={FormInputWithErrorWrapperStyle}>
            <div
              className={`${CheckboxContainerClass} checkbox-container`}
              data-test={
                this.props.isChecked
                  ? "checkbox-checked"
                  : "checkbox-not-checked"
              }
              style={{
                overflowY: "hidden",
                minHeight: this.props.minHeight,
              }}
              onMouseOver={onTargetMouseOver}
              onMouseLeave={onTargetMouseLeave}
            >
              <CheckboxInput
                id={`${this.props.widgetId}-checkbox`}
                isChecked={this.props.isChecked}
                isDisabled={this.props.isDisabled === true}
                ariaLabel={restOfLabel}
                isValid={!this.props.showError}
                onClick={this.handleCheckChange}
              />
              <label
                data-test="checkbox-label"
                className={labelClassName}
                style={{
                  ...this.props.labelProps?.style,
                  ...cursorStyle,
                }}
                htmlFor={`${this.props.widgetId}-checkbox`}
              >
                {this.props.isRequired && (
                  <span className={CLASS_NAMES.ERROR_MODIFIER}>{asterisk}</span>
                )}
                {restOfLabel}
              </label>
            </div>
            {inlineError}
          </div>
        )}
      </WidgetErrorsWrapper>
    );
  }
}

export default CheckboxComponent;

export const CheckboxComponentWithLayoutManaged = (
  props: CheckboxComponentWithLayoutManagedProps,
) => {
  const labelProps = useTypographyStyling({
    textStyle: props.labelProps?.textStyle,
    defaultTextStyleVariant: DEFAULT_CHECKBOX_WIDGET_TEXT_STYLE_VARIANT,
  });

  const minHeight = useMinHeightFromTypography({
    isAutoHeight: props.height.mode === "fitContent",
    isAutoWidth: props.width.mode === "fitContent",
    textStyleVariant: labelProps.textStyleVariant as unknown as any,
    textStyle: props.labelProps?.textStyle,
    defaultTextStyleVariant: DEFAULT_CHECKBOX_WIDGET_TEXT_STYLE_VARIANT,
  });

  return (
    <CheckboxComponent
      labelProps={labelProps}
      isRequired={props.isRequired}
      showError={props.showError}
      isChecked={!!props.isChecked}
      label={props.label}
      widgetId={props.widgetId}
      key={props.widgetId}
      isDisabled={props.isDisabled}
      onCheckChange={props.onCheckChange}
      isLoading={props.isLoading}
      minHeight={minHeight}
      isFitContentWidth={props.width.mode === "fitContent"}
      errorMessagePlacement={props.errorMessagePlacement}
      errorMessages={props.errorMessages}
      isFitContentHeight={props.height?.mode === "fitContent"}
    />
  );
};

export const estimateInitialCheckboxWidgetHeightGU = (
  theme?: GeneratedTheme,
): number | undefined => {
  if (!theme?.typographies) {
    return undefined;
  }

  const textHeight = getLineHeightInPxFromTextStyle({
    textStyleVariant: DEFAULT_CHECKBOX_WIDGET_TEXT_STYLE_VARIANT,
    nestedProps: undefined,
    typographies: theme.typographies,
    defaultTextStyleVariant: DEFAULT_CHECKBOX_WIDGET_TEXT_STYLE_VARIANT,
  });

  return Dimension.toGridUnit(
    Dimension.px(textHeight),
    GridDefaults.DEFAULT_GRID_ROW_HEIGHT,
  ).roundUp().value;
};
