import { Dimension, Padding } from "@superblocksteam/shared";
import memoizeOne from "memoize-one";
import { INPUT_PADDING } from "legacy/themes/constants";
import { generatePaddingVariableAssignmentStyleObject } from "../base/generatePaddingStyle";
import {
  DEFAULT_ICON_SIZE,
  getIconPadding,
  getIconPositionStyle,
} from "../shared";
import { DEFAULT_DROPDOWN_BORDER } from "./constants";

export function getTagElementSpace(element: HTMLElement): number {
  // add a bit of margin to our width to account for gaps between tags
  return element.clientWidth + 4;
}

export const createCssVariablesForPadding = memoizeOne(
  (
    allowClearing: boolean,
    hasIcon: boolean,
    inputPadding?: Padding,
    iconSize?: number,
  ) => {
    const caretIconPadding = getIconPadding(
      inputPadding?.right,
      iconSize,
      true,
    );
    const clearIconPadding = Dimension.px(iconSize ?? DEFAULT_ICON_SIZE);
    const rightIconPadding = allowClearing
      ? Dimension.add(caretIconPadding, clearIconPadding).asFirst()
      : caretIconPadding;

    return generatePaddingVariableAssignmentStyleObject({
      ...inputPadding,
      left: hasIcon
        ? getIconPadding(inputPadding?.left, iconSize)
        : (inputPadding?.left ?? Dimension.px(0)),
      right: rightIconPadding,
    });
  },
);

export const getDropdownIconPositionStyle = memoizeOne(
  (inputPadding?: Padding) => {
    return getIconPositionStyle({
      padding: inputPadding ?? INPUT_PADDING,
      defaultBorderWidth: DEFAULT_DROPDOWN_BORDER,
      iconPosition: "LEFT",
    });
  },
);

// Returns the clearable icon distance to the right of the container
export const getClearableIconRight = memoizeOne(
  (allowClearing: boolean, inputPadding?: Padding, iconSize?: number) => {
    if (!allowClearing) {
      return 0;
    }
    const iconPadding = getIconPadding(
      inputPadding?.right,
      iconSize ?? DEFAULT_ICON_SIZE,
      false,
    );
    return iconPadding.value;
  },
);

/* Utilities for centering the icons inside the dropdowns */

export const getInputPaddingFactorForVerticalCenter = (
  inputPadding?: Padding,
) => {
  if (!inputPadding) {
    return 0;
  }

  const paddingTop = inputPadding.top?.value ?? 0;
  const paddingBottom = inputPadding.bottom?.value ?? 0;
  return paddingTop / 2 - paddingBottom / 2;
};

// Caret needs a 1px correction depending on this variables
export const getCaretCorrectionForVerticalCenter = (
  vertical?: boolean,
  inlineError?: boolean,
) => {
  if (vertical) {
    return inlineError ? -1 : -1;
  }
  return inlineError ? 1 : 0;
};

export const generateIconTop = ({
  vertical,
  iconSize = DEFAULT_ICON_SIZE,
  inputPadding,
}: {
  iconSize?: number;
  inputPadding?: Padding;
  vertical?: boolean;
}) => {
  const iconSizeTopValue = iconSize / 2;
  const inputPaddingTopValue =
    getInputPaddingFactorForVerticalCenter(inputPadding);
  // Dropdown border can't be customized
  const borderValue = vertical ? DEFAULT_DROPDOWN_BORDER : 0;

  return `calc(50% - ${iconSizeTopValue - inputPaddingTopValue - borderValue}px)`;
};

/****/
