import { ActionTypeEnum, ResourceTypeEnum } from "@superblocksteam/shared";
import { useSelector } from "react-redux";
import {
  getCurrentUser,
  getIsSuperUser,
  getUserPermissionsMap,
} from "legacy/selectors/usersSelectors";
import { Flag } from "store/slices/featureFlags";
import { useFeatureFlag } from "../useFeatureFlag";

export type PermissionToCheck = {
  resourceType: ResourceTypeEnum;
  action: ActionTypeEnum;
  preRbacValue?: boolean;
};

export const useAuthorizationCheck = (
  permissions: PermissionToCheck[],
): boolean[] => {
  const rbacV2Enabled = useFeatureFlag(Flag.ENABLE_RBAC_V2);
  const isSuperUser = useSelector(getIsSuperUser);

  const user = useSelector(getCurrentUser);
  const permissionsMap = useSelector(getUserPermissionsMap);
  if (!rbacV2Enabled) {
    return permissions.map((permission) => {
      if (permission.preRbacValue != null) return permission.preRbacValue;
      return Boolean(isSuperUser || user?.isAdmin);
    });
  }

  return permissions.map((permission) => {
    const { action, resourceType } = permission;
    return permissionsMap?.[resourceType]?.[action] ?? false;
  });
};
