export function markAsDeprecated(s: string): string {
  return `${s} (deprecated)`;
}

export function sortByDisplayName(items: { key: string; value: string; displayName: string }[]) {
  return items.sort((a, b) => {
    const isDeprecatedA = a.displayName.includes('(deprecated)');
    const isDeprecatedB = b.displayName.includes('(deprecated)');

    if (isDeprecatedA && !isDeprecatedB) return 1;
    if (!isDeprecatedA && isDeprecatedB) return -1;

    return a.displayName.localeCompare(b.displayName);
  });
}
