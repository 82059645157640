import { get } from "lodash";
import {
  DataTree,
  ScopedDataTreePath,
} from "legacy/entities/DataTree/dataTreeFactory";
import { getScopeAndEntityName } from "utils/dottedPaths";
import { makeShallowSetter } from "./shallowSetter";

const REFERENCE_METADATA_KEY = "__$sb_ref";
const REFERENCE_PLACEHOLDER = "__$sb_ref_placeholder";

// refPath is the relative path from the corresponding `this` context
// for a table widget's derived property, this would correspond to PAGE.<TableWidget>.<refPath>
export type SbReferenceConfig = {
  [REFERENCE_METADATA_KEY]: {
    refPath: string;
  };
};

export type ReferenceMetaPayload = {
  lazyReferencePaths: Record<ScopedDataTreePath, ScopedDataTreePath>;
};

export class ReferenceManager {
  static isReferenceConfig(value: any): value is SbReferenceConfig {
    return (
      typeof value === "object" &&
      value !== null &&
      REFERENCE_METADATA_KEY in value &&
      typeof value[REFERENCE_METADATA_KEY] === "object" &&
      value[REFERENCE_METADATA_KEY] !== null
    );
  }

  static referenceCreator(refPath: string, _value: any): SbReferenceConfig {
    return {
      [REFERENCE_METADATA_KEY]: {
        refPath,
      },
    };
  }

  private lazyReferencePaths: Record<ScopedDataTreePath, ScopedDataTreePath>;

  constructor() {
    this.lazyReferencePaths = {};
  }

  public getReferenceMeta(): ReferenceMetaPayload {
    return {
      lazyReferencePaths: this.lazyReferencePaths,
    };
  }

  public processSetValue(
    propertyPath: ScopedDataTreePath,
    value: any,
    dataTree: DataTree,
  ) {
    if (ReferenceManager.isReferenceConfig(value)) {
      const { refPath } = value[REFERENCE_METADATA_KEY];
      const { scope, entityName } = getScopeAndEntityName(propertyPath);
      const pathPrefix: ScopedDataTreePath = `${scope}.${entityName}`;
      const path = `${pathPrefix}.${refPath}` as ScopedDataTreePath;
      this.lazyReferencePaths[propertyPath] = path;
      const dereferencedValue = get(dataTree, path);
      return dereferencedValue;
    } else if (this.lazyReferencePaths[propertyPath]) {
      delete this.lazyReferencePaths[propertyPath];
    }
    return value;
  }

  public dehydrateReferences(dataTree: DataTree): DataTree {
    const pathKeys = Object.keys(this.lazyReferencePaths);
    if (pathKeys.length === 0) {
      return dataTree;
    }
    const shallowSetter = makeShallowSetter(dataTree);
    let result = dataTree;
    pathKeys.forEach((path) => {
      const { scope, entityName, rest } = getScopeAndEntityName(
        path as ScopedDataTreePath,
      );
      const entity = dataTree[scope]?.[entityName];
      if (entity && typeof entity === "object") {
        // this may be a partial update, so only set if needed
        result = shallowSetter(
          [scope, entityName, ...rest],
          REFERENCE_PLACEHOLDER,
        );
      }
    });
    return result;
  }

  public static rehydrateReferences(
    dataTree: DataTree,
    externalReferenceMeta: ReferenceMetaPayload,
  ): DataTree {
    const evalPathEntries = Object.entries(
      externalReferenceMeta.lazyReferencePaths,
    );
    if (evalPathEntries.length === 0) {
      return dataTree;
    }
    const shallowSetter = makeShallowSetter(dataTree);
    let result = dataTree;
    Object.entries(externalReferenceMeta.lazyReferencePaths).forEach(
      ([pathToTarget, pathToSource]) => {
        if (get(dataTree, pathToTarget) === REFERENCE_PLACEHOLDER) {
          const { scope, entityName, rest } = getScopeAndEntityName(
            pathToTarget as ScopedDataTreePath,
          );
          result = shallowSetter(
            [scope, entityName, ...rest],
            get(dataTree, pathToSource),
          );
        }
      },
    );
    return result;
  }
}
