import { Classes as BlueprintClasses } from "@blueprintjs/core";
import {
  Dimension,
  BorderStyleBlock,
  IconColorBlock,
  StyleBlock,
  StyleBlockWithSize,
  TextStyleBlock,
} from "@superblocksteam/shared";
import { css } from "styled-components";
import { generatePaddingCss } from "legacy/widgets/base/generatePaddingStyle";
import { CLASS_NAMES, MODIFIER_CLASSNAMES } from "../classnames";

export const generateBorderStyle = (block: BorderStyleBlock) => {
  return `${block.borderWidth.value ?? 0}${block.borderWidth.mode ?? "px"} ${
    block.borderStyle
  } ${block.borderColor.default}`;
};

export const styleFromDimension = (dimension: Dimension) => {
  return `${dimension.value ?? 0}${dimension.mode ?? "px"}`;
};

export const defaultStyles = (block: StyleBlock) =>
  css`
    background-color: ${block.backgroundColor.default};
    border-color: ${block.borderColor.default};
    box-shadow: ${block.boxShadow.default};
    color: ${block.textColor.default};
  `.join("");

export const disabledStyles = (block: StyleBlock) =>
  css`
    background-color: ${block.backgroundColor.disabled ??
    block.backgroundColor.default};
    border-color: ${block.borderColor.disabled ?? block.borderColor.default};
    box-shadow: ${block.boxShadow.disabled ?? block.boxShadow.default};
    color: ${block.textColor.disabled ?? block.textColor.default};
  `.join("");

export const activeStyles = (block: StyleBlock) =>
  css`
    background-color: ${block.backgroundColor.active ??
    block.backgroundColor.default};
    border-color: ${block.borderColor.active ?? block.borderColor.default};
    box-shadow: ${block.boxShadow.active ?? block.boxShadow.default};
    color: ${block.textColor.active ?? block.textColor.default};
  `.join("");

const hoverActiveStyles = (block: StyleBlock) =>
  css`
    background-color: ${block.backgroundColor.activeHover ??
    block.backgroundColor.default};
    border-color: ${block.borderColor.activeHover ?? block.borderColor.default};
    box-shadow: ${block.boxShadow.activeHover ?? block.boxShadow.default};
    color: ${block.textColor.activeHover ?? block.textColor.default};
  `.join("");

export const errorStyles = (block: StyleBlock) =>
  css`
    background-color: ${block.backgroundColor.error ??
    block.backgroundColor.default};
    border-color: ${block.borderColor.error ?? block.borderColor.default};
    box-shadow: ${block.boxShadow.error ?? block.boxShadow.default};
    color: ${block.textColor.error ?? block.textColor.default};
  `.join("");

export const hoverStyles = (block: StyleBlock) => `
  background-color: ${
    block.backgroundColor.hover ?? block.backgroundColor.default
  };
  border-color: ${block.borderColor.hover ?? block.borderColor.default};
  box-shadow: ${block.boxShadow.hover ?? block.boxShadow.default};
  color: ${block.textColor.hover ?? block.textColor.default};
`;

const checkMarkIconWithFill = (fill: string) => {
  return `"data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='${encodeURIComponent(
    fill,
  )}'/%3e%3c/svg%3e"`;
};

export const styleFromTextStyleBlock = (
  block: TextStyleBlock,
  fallbackFont?: string,
) => {
  return css`
    color: ${block.textColor.default};
    font-family: ${block.fontFamily && block.fontFamily !== "inherit"
      ? `${block.fontFamily}, ${fallbackFont ?? "sans-serif"}`
      : block.fontFamily};
    font-size: ${block.fontSize};
    font-weight: ${block.fontWeight};
    line-height: ${block.lineHeight};
    text-decoration: ${block.textDecoration ?? "none"};
    font-style: ${block.fontStyle ?? "normal"};
    letter-spacing: ${block.letterSpacing ?? "normal"};
    text-transform: ${block.textTransform ?? "none"};
  `.join("");
};

export const styleFromTextBlockWithColors = (
  block: TextStyleBlock,
  fallbackFont?: string,
) => {
  return css`
    ${styleFromTextStyleBlock(block, fallbackFont)};
    &.${MODIFIER_CLASSNAMES.DISABLED_MODIFIER}, &:disabled {
      color: ${block.textColor.disabled ?? block.textColor.default};
      &:hover {
        color: ${block.textColor.disabled ?? block.textColor.default};
      }
    }
    &.${MODIFIER_CLASSNAMES.ERROR_MODIFIER} {
      color: ${block.textColor.error ?? block.textColor.default};
      &:hover {
        color: ${block.textColor.error ?? block.textColor.default};
      }
    }
    &:hover:not(.${MODIFIER_CLASSNAMES.DISABLED_MODIFIER}):not(
        .${MODIFIER_CLASSNAMES.ERROR_MODIFIER}
      ):not(.${MODIFIER_CLASSNAMES.ACTIVE_MODIFIER}) {
      color: ${block.textColor.hover ?? block.textColor.default};
    }
    &.${MODIFIER_CLASSNAMES.ACTIVE_MODIFIER} {
      color: ${block.textColor.active ?? block.textColor.default};
      &:hover {
        color: ${block.textColor.activeHover ??
        block.textColor.active ??
        block.textColor.default};
      }
    }
  `.join("");
};

export const styleFromStyleBlock = (block: StyleBlock) => {
  return css`
    ${styleFromTextStyleBlock(block)}
    outline: none;
    border: ${generateBorderStyle(block)};
    border-radius: ${styleFromDimension(block.borderRadius)};
    ${generatePaddingCss(block.padding)}
    ${defaultStyles(block)}
    &:hover {
      ${hoverStyles(block)}
    }
    &:active,
    &:focus,
    ${CLASS_NAMES.ACTIVE_MODIFIER} {
      ${activeStyles(block)}
    }
    &:disabled,
    &.${MODIFIER_CLASSNAMES.DISABLED_MODIFIER} {
      ${disabledStyles(block)}
      &:hover {
        ${disabledStyles(block)}
      }
    }
    &.${MODIFIER_CLASSNAMES.ERROR_MODIFIER} {
      ${errorStyles(block)}
    }
  `.join("");
};

export const staticStyleFromStyleBlock = (block: StyleBlock) => {
  return css`
    ${styleFromTextStyleBlock(block)}
    outline: none;
    border: ${generateBorderStyle(block)};
    border-radius: ${styleFromDimension(block.borderRadius)};
    ${generatePaddingCss(block.padding)}
    ${defaultStyles(block)}
    &.${CLASS_NAMES.ACTIVE_MODIFIER} {
      ${activeStyles(block)}
    }
  `.join("");
};

export const borderlessStyleFromStyleBlock = (block: StyleBlock) => {
  return css`
    ${styleFromTextStyleBlock(block)}
    outline: none;
    border-radius: ${styleFromDimension(block.borderRadius)};
    ${generatePaddingCss(block.padding)}
    ${defaultStyles(block)}
    &.${CLASS_NAMES.ACTIVE_MODIFIER} {
      ${activeStyles(block)}
    }
  `.join("");
};

export const noBackgroundOrBorderStyleFromStyleBlock = (block: StyleBlock) => {
  return css`
    ${styleFromTextStyleBlock(block)}
    outline: none;
    ${generatePaddingCss(block.padding)}
    color: ${block.textColor.default};
    box-shadow: none;
  `.join("");
};

export const containerBorderOutline = (block: StyleBlock) => {
  return css`
    outline: ${generateBorderStyle(block)} !important;
  `.join("");
};

export const inputStyleFromStyleBlock = (block: StyleBlock) => {
  return css`
    ${styleFromTextStyleBlock(block)}
    ${generatePaddingCss(block.padding)}
    outline: none;
    border: ${generateBorderStyle(block)};
    border-radius: ${styleFromDimension(block.borderRadius)};
    ${defaultStyles(block)}

    &:active,
    &:focus {
      border-radius: ${styleFromDimension(block.borderRadius)};
      ${activeStyles(block)}
    }
    &:hover {
      ${hoverStyles(block)}
    }
    &.${CLASS_NAMES.ERROR_MODIFIER} {
      ${errorStyles(block)}
    }
    &:disabled,
    &.${MODIFIER_CLASSNAMES.DISABLED_MODIFIER} {
      ${disabledStyles(block)}
    }
  `.join("");
};

export const checkboxStyleFromStyleBlock = (block: StyleBlockWithSize) => {
  return css`
    display: block;
    position: relative;
    height: ${styleFromDimension(block.height)};
    width: ${styleFromDimension(block.width)};
    flex-shrink: 0;
    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;
    }

    span {
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      border: ${generateBorderStyle(block)};
      border-radius: min(4px, ${styleFromDimension(block.borderRadius)});
      ${defaultStyles(block)}
      &:hover {
        ${hoverStyles(block)}
      }
    }
    input:checked:not(:disabled) + span {
      ${activeStyles(block)}
      &:hover {
        ${hoverActiveStyles(block)}
      }
    }
    input:indeterminate:not(:disabled) + span {
      border-color: ${block.borderColor.active ??
      block.borderColor.default} !important;
      &:hover {
        border-color: ${block.borderColor.activeHover ??
        block.borderColor.active ??
        block.borderColor.default};
      }
    }

    input:disabled + span {
      ${disabledStyles(block)}
      cursor: not-allowed;
    }

    span:after {
      content: "";
      position: absolute;
      display: none;
      height: 100%;
      width: 100%;
    }

    input:checked + span:after {
      display: block;
      height: 100%;
      width: 100%;
      background-image: url(${checkMarkIconWithFill(block.textColor.default)});
    }

    input:indeterminate + span:after {
      display: block;
      top: 2px;
      left: 2px;
      width: calc(${styleFromDimension(block.width)} - 6px);
      height: calc(${styleFromDimension(block.height)} - 6px);
      background-color: ${block.backgroundColor.active};
      border-radius: calc(
        min(4px, ${styleFromDimension(block.borderRadius)}) - 2px
      );
    }
    input:indeterminate + span:hover:after {
      background-color: ${block.backgroundColor.activeHover ??
      block.backgroundColor.active};
    }

    input:disabled + span:after {
      background-image: url(${checkMarkIconWithFill(
        block.textColor.disabled ?? block.textColor.default,
      )});
    }

    &.${CLASS_NAMES.ERROR_MODIFIER} {
      span {
        ${errorStyles(block)}
      }

      input:checked + span {
        overflow: auto;
        border-color: ${block.borderColor.error};

        &:hover {
          border-color: ${block.borderColor.error};
        }

        &::after {
          ${errorStyles(block)}
          background-image: url(${checkMarkIconWithFill(
            block.textColor.error ?? block.textColor.default,
          )});
        }
      }
    }
  `.join("");
};

export const switchStyleFromStyleBlock = (block: StyleBlockWithSize) => {
  return css`
    height: ${styleFromDimension(block.height)};
    width: ${styleFromDimension(block.width)};
    position: relative;
    display: inline-block;
    flex-shrink: 0;
    &.${CLASS_NAMES.DISABLED_MODIFIER} > span {
      cursor: not-allowed;
    }

    input {
      opacity: 0;
      width: 0;
      height: 0;
    }

    span {
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      transition: transform 0.2s;
      border: ${generateBorderStyle(block)};
      border-radius: ${styleFromDimension(block.borderRadius)};
      ${defaultStyles(block)}
      &::before {
        position: absolute;
        content: "";
        height: calc(${styleFromDimension(block.height)} - 4px);
        width: calc(
          ${styleFromDimension(block.height)} - 4px
        ); // using height value to make a circular slider
        left: 1px;
        bottom: 1px;
        background-color: ${block.textColor.default};
        transition: transform 0.2s;
        border-radius: 50%;
      }
    }
    input:checked + span {
      &::before {
        transform: translateX(
          calc(
            ${styleFromDimension(block.width)} -
              ${styleFromDimension(block.height)}
          )
        );
      }
    }
    input:checked:not(:disabled) + span {
      ${activeStyles(block)}
      &:hover {
        ${hoverActiveStyles(block)}
      }
      &::before {
        background-color: ${block.textColor.active ?? block.textColor.default};
      }
    }

    input:disabled + span {
      ${disabledStyles(block)}
      &::before {
        background-color: ${block.textColor.disabled ??
        block.textColor.default};
      }
    }

    &.${CLASS_NAMES.ERROR_MODIFIER} span {
      ${errorStyles(block)}
      &::before {
        background-color: ${block.textColor.error ?? block.textColor.default};
      }
    }

    &.${CLASS_NAMES.ERROR_MODIFIER} input:checked:not(:disabled) + span {
      ${errorStyles(block)}
      background-color: ${block.borderColor.error ?? block.borderColor.default};
    }
  `.join("");
};

export const radioStyleFromStyleBlock = (block: StyleBlockWithSize) => {
  return css`
    display: flex;
    margin: 0px;
    padding: 4px 0px;
    position: relative;
    cursor: pointer;
    flex-shrink: 0;
    padding-left: calc(${styleFromDimension(block.width)} + 8px);
    &.${CLASS_NAMES.DISABLED_MODIFIER} {
      cursor: not-allowed;
    }

    input {
      position: absolute;
      opacity: 0;
      height: 0;
      width: 0;
    }

    span {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 0;
      height: ${styleFromDimension(block.height)};
      width: ${styleFromDimension(block.width)};
      border-radius: 50%;
      border: ${generateBorderStyle(block)};
      ${defaultStyles(block)}
      &:hover {
        ${hoverStyles(block)}
      }
    }

    input:checked:not(:disabled) + span {
      ${activeStyles(block)}

      &.${CLASS_NAMES.ERROR_MODIFIER}, &.${CLASS_NAMES.ERROR_MODIFIER}:hover {
        border: 1px solid ${block.textColor.error};

        &::after {
          background-color: ${block.textColor.error};
        }
      }

      &:hover {
        ${hoverActiveStyles(block)}
      }
    }

    input:disabled + span {
      ${disabledStyles(block)}
    }

    span {
      &:after {
        content: "";
        position: absolute;
        display: none;
        top: 2px;
        left: 2px;
        height: calc(${styleFromDimension(block.height)} - 6px);
        width: calc(${styleFromDimension(block.width)} - 6px);
        border-radius: 50%;
        background-color: ${block.textColor.active ?? block.textColor.default};
      }

      &:hover:after {
        background-color: ${block.textColor.activeHover ??
        block.textColor.default};
      }
    }

    input:checked + span:after {
      display: block;
    }

    input:disabled + span:after {
      background-color: ${block.textColor.disabled ?? block.textColor.default};
      &:hover {
        background-color: ${block.textColor.disabled ??
        block.textColor.default};
      }
    }
  `.join("");
};

export const selectStyleFromStyleBlock = (
  block: StyleBlock,
  caratIcon: IconColorBlock,
) => {
  return css`
    select {
      appearance: none;
      outline: none;
      ${generatePaddingCss(block.padding)}
      border: ${generateBorderStyle(block)};
      ${styleFromTextStyleBlock(block)}
      ${defaultStyles(block)}
            &:hover:not(:disabled) {
        ${hoverStyles(block)}
      }
      &:active,
      &:focus {
        ${activeStyles(block)}
      }
      &:disabled {
        ${disabledStyles(block)}
      }
      background-image: url("data:image/svg+xml,%3Csvg width='10' height='6' viewBox='0 0 10 6' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M0.558058 0.558058C0.802136 0.313981 1.19786 0.313981 1.44194 0.558058L5 4.11612L8.55806 0.558058C8.80214 0.313981 9.19786 0.313981 9.44194 0.558058C9.68602 0.802136 9.68602 1.19786 9.44194 1.44194L5.44194 5.44194C5.19786 5.68602 4.80214 5.68602 4.55806 5.44194L0.558058 1.44194C0.313981 1.19786 0.313981 0.802136 0.558058 0.558058Z' fill='${encodeURIComponent(
        caratIcon.iconColor.default,
      )}'/%3E%3C/svg%3E");
      background-repeat: no-repeat;
      background-position-x: right;
      background-position-y: center;
      background-origin: content-box;
    }
    .${BlueprintClasses.ICON} {
      display: none;
    }
  `.join("");
};

export const iconStyleFromStyleBlock = (block: IconColorBlock) => {
  return css`
    fill: ${block.iconColor.default};
    color: ${block.iconColor.default};
    &:hover {
      fill: ${block.iconColor.hover ?? block.iconColor.default};
      color: ${block.iconColor.hover ?? block.iconColor.default};
    }
    &.${MODIFIER_CLASSNAMES.DISABLED_MODIFIER} {
      fill: ${block.iconColor.disabled ?? block.iconColor.default};
      color: ${block.iconColor.disabled ?? block.iconColor.default};
    }
    &.${MODIFIER_CLASSNAMES.ERROR_MODIFIER} {
      fill: ${block.iconColor.error ?? block.iconColor.default};
      color: ${block.iconColor.error ?? block.iconColor.default};
    }
    &.${MODIFIER_CLASSNAMES.ACTIVE_MODIFIER} {
      fill: ${block.iconColor.active ?? block.iconColor.default};
      color: ${block.iconColor.active ?? block.iconColor.default};
    }
  `.join("");
};

export const menuItemStyleFromBlock = (block: StyleBlock) => {
  return css`
    margin: 0px;
    ${generatePaddingCss(block.padding)}
    border-radius: ${styleFromDimension(block.borderRadius)};
    border: ${generateBorderStyle(block)};
    ${styleFromTextStyleBlock(block)}
    ${defaultStyles(block)}
      &:hover {
      ${hoverStyles(block)}
    }
    &.${BlueprintClasses.ACTIVE}, &.selected {
      ${activeStyles(block)}
    }

    &.ant-select-item-option-active:not(.ant-select-item-option-disabled) {
      ${hoverStyles(block)}
      font-weight: ${block.fontWeight};
    }

    &.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
      ${activeStyles(block)}
      font-weight: ${block.fontWeight};
    }
    &.${BlueprintClasses.DISABLED} {
      ${disabledStyles(block)}
    }
  `.join("");
};
