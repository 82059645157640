import { noop } from "lodash";
import React from "react";
import { CLASS_NAMES } from "legacy/themes/classnames";
import { DEFAULT_RADIO_GROUP_WIDGET_OPTION_TEXT_STYLE_VARIANT } from "./constants";

export const RadioInput = ({
  isDisabled,
  option,
  isSelected,
  onClick,
  radioGroupName,
  textClassName,
  textStyle,
  hasError,
}: {
  isSelected: boolean;
  option: { label: string; value: string };
  isDisabled: boolean;
  onClick: (value: string) => void;
  radioGroupName?: string;
  textClassName?: string;
  textStyle?: React.CSSProperties;
  hasError: boolean;
}) => {
  let className = DEFAULT_RADIO_GROUP_WIDGET_OPTION_TEXT_STYLE_VARIANT;
  if (textClassName) {
    className = textClassName;
  }

  return (
    <label
      key={option.value}
      className={`${CLASS_NAMES.RADIO} ${
        isDisabled ? CLASS_NAMES.DISABLED_MODIFIER : ""
      }`}
      data-test={isSelected ? "radio-selected" : "radio-not-selected"}
      onClick={() => !isDisabled && onClick(option.value)}
    >
      <input
        type="radio"
        checked={isSelected}
        disabled={isDisabled}
        name={radioGroupName}
        onChange={noop} // handled by parent
      />
      {/* this span is the radio checkmark */}
      <span className={hasError ? CLASS_NAMES.ERROR_MODIFIER : ""}></span>
      <div className={className} style={textStyle}>
        {option.label}
      </div>
    </label>
  );
};
