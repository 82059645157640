import { Spinner, SpinnerSize } from "@blueprintjs/core";
import { Spin } from "antd";
import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { ReactComponent as LoadingSuperblocks } from "assets/images/superblocks-loading.svg";
import { SHOW_LOADING_STATE_PARAM } from "constants/pageSearchParams";
import { getCurrentApplicationId } from "legacy/selectors/editorSelectors";
import { selectOverrideThemeMode } from "legacy/selectors/themeSelectors";
import { DARK_APP_BG, LIGHT_APP_BG } from "legacy/themes/constants";
import { isOnEmbedRoute, isIFrameInEmbedRoute } from "utils/embed/messages";

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
`;

const SpinIcon = styled(LoadingSuperblocks)`
  width: 128px;
  height: 128px;
`;

const shouldShowSpinner = () => {
  // If the iframe is in an embed route, we should not show the spinner
  if (isIFrameInEmbedRoute()) return false;

  // If the SHOW_LOADING_STATE_PARAM is set to false, we should not show the spinner
  const searchParams = new URLSearchParams(window.location.search);
  const show = searchParams.get(SHOW_LOADING_STATE_PARAM);
  if (!show) return true;
  return show !== "false";
};

export const FullPageSpinner = ({
  useNonBrandedSpinner,
}: {
  useNonBrandedSpinner?: boolean;
}) => {
  const [showSmallSpinner, setShowSmallSpinner] = React.useState(false);
  React.useEffect(() => {
    setTimeout(() => {
      setShowSmallSpinner(true);
    }, 200);
  }, []);
  let bgColor;
  const overrideThemeMode = useSelector(selectOverrideThemeMode);
  const currentAppId = useSelector(getCurrentApplicationId);
  // if there is no app loaded but an override theme is set, set the background color to the override theme
  if (!currentAppId && overrideThemeMode) {
    bgColor = overrideThemeMode === "DARK" ? DARK_APP_BG : LIGHT_APP_BG;
  }
  if (!shouldShowSpinner()) return null;
  if (
    isOnEmbedRoute() ||
    useNonBrandedSpinner ||
    window.location.pathname.startsWith("/applications/iframe")
  ) {
    const shouldShow = showSmallSpinner || isOnEmbedRoute();
    return (
      <Wrapper style={{ background: bgColor }}>
        {shouldShow ? <Spinner size={SpinnerSize.STANDARD} /> : null}
      </Wrapper>
    );
  }
  return (
    <Wrapper data-test="full-page-spinner">
      <Spin indicator={<SpinIcon />} />
    </Wrapper>
  );
};
