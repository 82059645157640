import React from "react";

import { CSSProperties } from "react";
import DynamicSVG from "components/ui/DynamicSVG";
import { colors } from "styles/colors";
import { styleAsClass } from "styles/styleAsClass";
import { KeyValueProperty } from "../types";

const PropertyLabelWrapperClass = styleAsClass`
  display: flex;
  gap: 5px;
  color: ${colors.GREY_500};
`;

export const KeyValuePropertyKey = ({
  property,
  style,
  className,
}: {
  property: KeyValueProperty;
  style?: CSSProperties;
  className?: string;
}) => {
  const icon = property.keyProps?.icon ? (
    <DynamicSVG iconName={property.keyProps.icon} />
  ) : null;
  const iconPosition = property.keyProps?.iconPosition ?? "LEFT";

  return (
    <div className={`${PropertyLabelWrapperClass} ${className}`} style={style}>
      {iconPosition === "LEFT" && icon}
      {property.label ?? property.key}
      {iconPosition === "RIGHT" && icon}
    </div>
  );
};
