import { BillingPlan } from "@superblocksteam/shared";
import {
  Layout,
  Menu,
  Divider as AntDivider,
  Typography,
  Dropdown,
  Button,
  Tooltip,
} from "antd";
import { isEmpty, noop } from "lodash";
import React, { useCallback, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router";
import { Link } from "react-router-dom";
import styled, { useTheme } from "styled-components";
import { ReactComponent as ArrowLeft } from "assets/icons/common/arrow-left-long.svg";
import { ReactComponent as ChevronDown } from "assets/icons/common/chevron-down-dropdown.svg";
import { ReactComponent as KeyIcon } from "assets/icons/common/key.svg";
import { ReactComponent as UserGearIcon } from "assets/icons/common/user-gear.svg";
import { ReactComponent as GroupIcon } from "assets/icons/common/user-three.svg";
import { ReactComponent as UserIcon } from "assets/icons/common/user.svg";
import { ReactComponent as AccessTokenIcon } from "assets/icons/home/access-token.svg";
import { ReactComponent as AgentsIcon } from "assets/icons/home/agents.svg";
import { ReactComponent as DocsIcon } from "assets/icons/home/docs.svg";
import { ReactComponent as HomeIcon } from "assets/icons/home/home.svg";
import { ReactComponent as IntegrationsIcon } from "assets/icons/home/integrations.svg";
import { ReactComponent as LinkIcon } from "assets/icons/home/link.svg";
import { ReactComponent as LockIcon } from "assets/icons/home/lock.svg";
import { ReactComponent as LogsIcon } from "assets/icons/home/logs.svg";
import { ReactComponent as ObservabilityIcon } from "assets/icons/home/observability.svg";
import { ReactComponent as ProfilesIcon } from "assets/icons/home/profiles.svg";
import { ReactComponent as RepositoriesIcon } from "assets/icons/home/repositories.svg";
import { ReactComponent as RoleIcon } from "assets/icons/home/role.svg";
import { ReactComponent as SecretsManagementIcon } from "assets/icons/home/secrets-management.svg";
import { ReactComponent as GearIcon } from "assets/icons/sidebar/settings-icon.svg";
import { ReactComponent as VideoIcon } from "assets/icons/widgets/video.svg";
import {
  MANAGE_ORGANIZATION,
  MANAGE_USERS,
  READ_LOGS,
  READ_ORGANIZATION,
  VIEW_ACCESS_TOKENS,
  VIEW_AGENTS,
  VIEW_GROUPS,
  VIEW_OBSERVABILITY,
  VIEW_PROFILES,
  VIEW_REPOSITORIES,
  VIEW_ROLES,
  VIEW_SECRET_STORES,
  VIEW_USERS,
} from "constants/rbac";
import { useAuthorizationCheck } from "hooks/ui/rbac/useAuthorizationCheck";
import { useOrganizationSettingsLink } from "hooks/ui/rbac/useOrganizationSettingsLink";
import {
  AGENTS_BASE_URL,
  AUDIT_LOGS_BASE_URL,
  DOCS_URL,
  HOME_URL,
  INTEGRATIONS_URL,
  LOGOUT_URL,
  PROFILES_URL,
  VIDEO_LIST_URL,
  REPOSITORIES_URL,
  ACCESS_TOKENS_URL,
  USERS_PAGE_URL,
  GROUPS_PAGE_URL,
  GENERAL_PAGE_URL,
  // PERMISSIONS_PAGE_URL,
} from "legacy/constants/routes";
import {
  getCurrentUser,
  getIsSuperUser,
} from "legacy/selectors/usersSelectors";
import PlanAndUsageModal from "pages/Home/PlanAndUsageModal";
import { RoleAndPermissionsPages } from "pages/Permissions/constants";
import {
  PERMISSION_PAGE_URL,
  OBSERVABILITY_PAGE_URL,
  PERSONAL_SETTINGS_URL,
  SECRETS_MANAGEMENT_URL,
  ROLES_AND_PERMISSIONS_URL,
} from "pages/routes";
import { Flag } from "store/slices/featureFlags";
import { selectOnlyOrganization } from "store/slices/organizations";
import { Heading2 } from "styles/Typography";
import { colors } from "styles/colors";
import { styleAsClass } from "styles/styleAsClass";
import { SUPERBLOCKS_UI_ENABLE_OBSERVABILITY } from "../../env";
import { useFeatureFlag } from "../../hooks/ui/useFeatureFlag";
import ChecklistStatus from "./Checklist/ChecklistStatus";
import ColoredAvatar from "./ColoredAvatar";
import {
  AGENTS_TITLE,
  AUDIT_LOGS_TITLE,
  DOCUMENTATION_TITLE,
  HOME_TITLE,
  INTEGRATIONS_TITLE,
  INVITE_MEMBERS_TITLE,
  OBSERVABILITY_TITLE,
  PERMISSIONS_TITLE,
  PROFILES_TITLE,
  REPOSITORIES_TITLE,
  ROLES_AND_PERMISSIONS_TITLE,
  SECRETS_MANAGEMENT_TITLE,
  VIDEO_TITLE,
} from "./PageWrapper";
import InviteOrgUserModal from "./invitation/InviteOrgUserModal";

enum NavPanelKeys {
  MAIN = "main",
  ORG_SETTINGS = "org_settings",
  PERSONAL_SETTINGS = "personal_settings",
}

export const StyledSider = styled(Layout.Sider)`
  border-right: 1px solid ${(props) => props.theme.colors.GREY_100};
  background: ${({ theme }) => theme.colors.WHITE};
  overflow-y: auto;

  .ant-layout-sider-children {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .ant-divider-horizontal {
    margin: 10px 0;
  }
`;

const SiderItemWrapper = styled.div`
  display: flex;
  padding: 8px;
  & > div {
    width: 100%;
  }
`;

const TopItemWrapper = styled(SiderItemWrapper)`
  padding-bottom: 4px;
`;

const UserOrgItemWrapper = styled(SiderItemWrapper)`
  padding: 4px 8px;
`;

export const StyledMenu = styled(Menu)`
  border-right: 0;
  display: flex;
  height: 100%;
  margin-bottom: 48px;
  flex-direction: column;
  padding: 8px;

  li.ant-menu-item {
    height: 38px;
    padding: 18px !important;
    border-radius: 2px;

    svg.ant-menu-item-icon path {
      transition: fill 0.2s ease-in-out;
      fill: ${(props) => props.theme.colors.GREY_500};
    }

    /* some of the icons have the outline path as stroke instead of fill, so we cannot use fill to the path as it will fill the interior of the icon with the same color of outline.
    other icon use seperate path for outline and interier, and they use fill in the ourterier path. */
    svg.ant-menu-item-icon.stroke-outline path {
      transition: fill 0.2s ease-in-out;
      stroke: ${(props) => props.theme.colors.GREY_500};
      fill: ${(props) =>
        `${props.theme.colors.GREY_500}1F`}; /* 1F is 12% opacity */
    }

    &:hover svg.ant-menu-item-icon:not(.stroke-outline) path {
      fill: ${(props) => props.theme.colors.ACCENT_BLUE_500};
    }
    &:hover svg.stroke-outline path,
    &:hover svg.stroke-outline circle {
      stroke: ${(props) => props.theme.colors.ACCENT_BLUE_500};
      fill: ${(props) => `${props.theme.colors.ACCENT_BLUE_500}1F`};
    }
    .ant-menu-title-content {
      font-size: 12px;
      font-weight: 400;
      color: ${({ theme }) => theme.colors.GREY_700};
    }

    .ant-menu-item-selected > .ant-menu-title-content > .ant-typography {
      color: #27bbff; !important;
    }

    .ant-typography {
      transition: color 0.2s;
    }

    &:hover .ant-typography,  &:hover .ant-menu-title-content {
      color: ${({ theme }) => theme.colors.ACCENT_BLUE_500};
    }

    &::after {
      top: 7px;
      bottom: 7px;
      left: 0;
      right: unset;
      border-right-width: 2px;
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
    }
  }

  li.ant-menu-item-selected {
    background: ${(props) => props.theme.colors.SUBTLE_BLUE};

    svg.ant-menu-item-icon:not(.stroke-outline) path {
      fill: ${(props) => props.theme.colors.ACCENT_BLUE_500};
    }

    svg.ant-menu-item-icon.stroke-outline path,
    svg.ant-menu-item-icon.stroke-outline circle {
      stroke: ${(props) => props.theme.colors.ACCENT_BLUE_500};
      fill: ${(props) => `${props.theme.colors.ACCENT_BLUE_500}1F`};
    }

    .ant-menu-title-content .ant-typography, .ant-menu-title-content{
      color: ${({ theme }) => theme.colors.ACCENT_BLUE_500};
    }
  }

  li.ant-menu-item:not(:last-child) {
    margin-bottom: 0px;
    margin-top: 2px;
  }
`;

export const StyledMenuItem = styled(Menu.Item)`
  svg {
    width: 16px;
    height: 16px;
  }

  .suffix {
    position: absolute;
    right: 12px;
  }

  padding: 8px 16px;
`;

const DividerMenuItem = styled.div.withConfig({
  shouldForwardProp: (prop, defaultValidatorFn) =>
    !["eventKey"].includes(prop) && defaultValidatorFn(prop),
})`
  pointer-events: none;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
`;

const SubheaderMenuItem = styled(Typography.Text).withConfig({
  shouldForwardProp: (prop, defaultValidatorFn) =>
    !["eventKey"].includes(prop) && defaultValidatorFn(prop),
})`
  pointer-events: none;
  margin-top: 8px;
  padding: 6px 16px;
  font-size: 12px;
  line-height: 16px;
  display: flex;
  align-items: center;
  font-variant: small-caps;
  color: #a4aab7;
`;

type NavLink = {
  displayName: string;
  icon?: JSX.Element;
  url?: string;
  onClick?: () => void;
  openInNewWindow?: boolean;
  type: "Link";
  hidden?: boolean;
};

type Header = {
  displayName: string;
  type: "Header";
  hidden?: boolean;
};

type Divider = {
  type: "Divider";
  hidden?: boolean;
};

type NavItem = NavLink | Header | Divider;

const OrgUserDropdownWrapper = styled.div<{ $isOpen: boolean }>`
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  background: ${(props) =>
    props.$isOpen ? props.theme.colors.GREY_50 : "white"};
  :hover {
    background: ${(props) => props.theme.colors.GREY_50};
  }
  padding: 8px 4px 8px 12px;
  border-radius: 4px;
`;

const ColoredAvatarWrapper = styled.div`
  display: flex;
  align-items: center;
  padding-right: 12px;
  .ant-avatar {
    width: 26px;
    height: 26px;
    line-height: 26px;
  }
`;

const OrgUserNameWrapper = styled.div`
  display: flex;
  flex-direction: column;
  & > div {
    max-width: 145px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const OrgNameText = styled.div`
  font-size: 14px;
  line-height: 15px;
  padding-bottom: 8px;
  font-weight: 500;
  color: ${(props) => props.theme.colors.GREY_700};
`;

const UserNameText = styled.div`
  font-size: 14px;
  line-height: 15px;
  font-weight: 400;
  color: ${(props) => props.theme.colors.GREY_500}!important;
`;

const DropdownButtonWrapper = styled.div`
  display: flex;
  margin-left: auto;
  align-items: center;
`;

const BackButtonWrapper = styled.div`
  padding: 12px;
  padding-bottom: 9px; // to align with same height as checklist box
  button {
    display: flex;
    align-items: center;
    gap: 6px;
    padding-left: 10px;
    padding-right: 12px;
  }

  .ant-btn:hover,
  .ant-btn:focus {
    color: ${(props) => props.theme.colors.GREY_900};
    border-color: ${(props) => props.theme.colors.GREY_100};
    background: ${(props) => props.theme.colors.GREY_25};
  }
  .ant-btn:active {
    color: ${(props) => props.theme.colors.GREY_900};
    border-color: ${(props) => props.theme.colors.GREY_100};
    background: ${(props) => props.theme.colors.GREY_50};
  }
`;

const NavTitleWrapper = styleAsClass`
  display: flex;
  padding: 6px 12px;
  margin-bottom: 14px;
`;

const StyledDropdownMenuWrapper = styled(Menu)`
  width: ${(props) => props.theme.legacy.profilesSidebarWidth};
  background: white;
  padding: 0 6px;
  padding-bottom: 4px;
  color: ${(props) => props.theme.colors.GREY_700};
  cursor: default;
  .ant-divider-horizontal {
    margin: 0;
  }
  .ant-menu-item-divider {
    margin-top: 4px;
  }
  .ant-menu-item {
    padding: 0 10px;
    border-radius: 4px;
    cursor: pointer;
    height: 32px;
    line-height: 32px;
    font-size: 14px;
  }
  li.ant-menu-item:first-child {
    margin-top: 4px;
  }
  .ant-menu-vertical .ant-menu-item:not(:last-child) {
    margin-top: 4px;
    margin-bottom: 0px;
  }
  li.ant-menu-item:last-child {
    margin-bottom: 4px;
  }
  .ant-menu-item:hover,
  .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected:hover {
    background: rgba(39, 187, 255, 0.12);
  }

  .ant-menu-vertical {
    border: 0px;
  }
  .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: transparent; // no need to highlight the selected item
  }
  .ant-menu-item,
  .ant-menu-item:hover {
    color: ${(props) => props.theme.colors.GREY_700};
  }
`;

const UserInfoWrapper = styled.div`
  display: flex;
  padding: 14px 10px;
  flex-direction: column;
  & > :first-child {
    font-weight: 500;
  }

  & > :nth-child(2) {
    color: ${(props) => props.theme.colors.GREY_500};
  }

  & > div {
    max-width: 188px;
    text-overflow: ellipsis;
    overflow: hidden;
  }
`;

// check if a nav item matches the current path
const navItemMatchPath = (
  item: NavItem,
  basePath: string,
  hash: string | undefined,
) => {
  if (item.type !== "Link" || !item.url || !item.url.startsWith("/"))
    return false;

  if (hash !== undefined) {
    hash = hash.replace("#", "");
  }
  const [itemPath, itemHash] = item.url.split("#");
  return (
    basePath.includes(itemPath.split("/")[1]) &&
    (itemHash === hash || (isEmpty(itemHash) && isEmpty(hash)))
  );
};

// A react component for a dropdown that shows user and org information, as well as options for accessing organization settings pages.
const OrgUserDropdown = ({
  selectNavPanel,
  showOrganizationSettings,
}: {
  selectNavPanel: (panel: NavPanelKeys) => void;
  showOrganizationSettings: boolean;
}) => {
  const navigate = useNavigate();
  const currentUser = useSelector(getCurrentUser);
  const isSuperUser = useSelector(getIsSuperUser);
  const currentOrg = useSelector(selectOnlyOrganization);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownParentRef = useRef<HTMLDivElement>(null);
  const [currentPlanModalVisible, setCurrentPlanModalVisible] = useState(false);
  const handleLogout = useCallback(
    () => navigate({ pathname: LOGOUT_URL }),
    [navigate],
  );
  const [canManageBilling] = useAuthorizationCheck([
    {
      ...MANAGE_ORGANIZATION,
      preRbacValue: currentUser?.isAdmin || isSuperUser,
    },
  ]);
  const showPlanAndUsage = useMemo(() => {
    return canManageBilling && currentOrg?.billing?.plan !== BillingPlan.TRIAL;
  }, [currentOrg?.billing?.plan, canManageBilling]);

  const dropdownMenu = useMemo(
    () => (
      <StyledDropdownMenuWrapper onClick={() => setIsDropdownOpen(false)}>
        <UserInfoWrapper
          onClick={(e: any) => {
            e.stopPropagation();
          }}
        >
          <Tooltip
            title={
              currentUser?.email && currentUser?.email.length > 24
                ? currentUser?.email
                : ""
            }
          >
            <div>{currentUser?.email}</div>
          </Tooltip>
          <Tooltip title={currentOrg.name.length > 24 ? currentOrg.name : ""}>
            <div>{currentOrg.name}</div>
          </Tooltip>
        </UserInfoWrapper>
        <Menu.Divider />
        {showPlanAndUsage && (
          <Menu.Item
            key="plan-and-usage"
            onClick={() => {
              setCurrentPlanModalVisible(true);
            }}
            data-test="plan-and-usage"
          >
            Plan and usage
          </Menu.Item>
        )}
        {showOrganizationSettings && (
          <Menu.Item
            key={NavPanelKeys.ORG_SETTINGS}
            onClick={() => {
              selectNavPanel(NavPanelKeys.ORG_SETTINGS);
            }}
            data-test="organization-settings"
          >
            Organization settings
          </Menu.Item>
        )}
        <Menu.Item
          key={NavPanelKeys.PERSONAL_SETTINGS}
          onClick={() => {
            selectNavPanel(NavPanelKeys.PERSONAL_SETTINGS);
          }}
          data-test="personal-settings"
        >
          Personal settings
        </Menu.Item>
        <Menu.Divider />
        <Menu.Item key="logout" onClick={handleLogout}>
          Logout
        </Menu.Item>
        <PlanAndUsageModal
          isVisible={currentPlanModalVisible}
          setVisible={setCurrentPlanModalVisible}
        />
      </StyledDropdownMenuWrapper>
    ),
    [
      currentUser?.email,
      currentOrg.name,
      showPlanAndUsage,
      handleLogout,
      currentPlanModalVisible,
      selectNavPanel,
      showOrganizationSettings,
    ],
  );

  return (
    <Dropdown
      overlay={dropdownMenu}
      getPopupContainer={() => dropdownParentRef.current || document.body}
      trigger={["click"]}
      onOpenChange={(visible) => setIsDropdownOpen(visible)}
    >
      <OrgUserDropdownWrapper
        data-test="org-user-dropdown"
        $isOpen={isDropdownOpen}
        ref={dropdownParentRef}
      >
        <ColoredAvatarWrapper>
          <ColoredAvatar name={currentOrg.name}>
            {currentOrg.name.toUpperCase().charAt(0)}
          </ColoredAvatar>
        </ColoredAvatarWrapper>
        <OrgUserNameWrapper>
          <Tooltip title={currentOrg.name.length > 18 ? currentOrg.name : ""}>
            <OrgNameText>{currentOrg.name}</OrgNameText>
          </Tooltip>
          <Tooltip
            title={
              currentUser?.name && currentUser?.name.length > 18
                ? currentUser?.name
                : ""
            }
          >
            <UserNameText>{currentUser?.name}</UserNameText>
          </Tooltip>
        </OrgUserNameWrapper>

        <DropdownButtonWrapper>
          <ChevronDown
            width={16}
            height={16}
            style={{
              color: colors.GREY_300,
              transform: `rotate(${isDropdownOpen ? 0 : -90}deg)`,
              transition: "transform 0.2s ease-out",
            }}
          />
        </DropdownButtonWrapper>
      </OrgUserDropdownWrapper>
    </Dropdown>
  );
};

export const PageNav = React.memo(() => {
  const navigate = useNavigate();
  const enableProfiles = useFeatureFlag(Flag.ENABLE_PROFILES);
  const enableRepositoriesPage = useFeatureFlag(Flag.ENABLE_REPOSITORIES_PAGE);
  const enableSecretsManagement = useFeatureFlag(
    Flag.ENABLE_SECRETS_MANAGEMENT,
  );
  const enableAccessTokensPage = useFeatureFlag(Flag.ENABLE_ACCESS_TOKENS_PAGE);
  const [
    canViewUsers,
    canViewGroups,
    canViewRoles,
    canViewSecrets,
    canViewAgents,
    canViewProfiles,
    canViewAccessTokens,
    canViewRepositories,
    canViewObservability,
    canViewAuditLogs,
    canInviteUsers,
    canViewOrg,
  ] = useAuthorizationCheck([
    VIEW_USERS,
    VIEW_GROUPS,
    VIEW_ROLES,
    VIEW_SECRET_STORES,
    VIEW_AGENTS,
    VIEW_PROFILES,
    VIEW_ACCESS_TOKENS,
    VIEW_REPOSITORIES,
    VIEW_OBSERVABILITY,
    READ_LOGS,
    MANAGE_USERS,
    READ_ORGANIZATION,
  ]);
  const showSecurityHeader = canViewAgents || canViewAccessTokens;
  const showBuildAndDeployHeader =
    canViewProfiles ||
    canViewRepositories ||
    canViewObservability ||
    canViewSecrets;

  const rbacV2Enabled = useFeatureFlag(Flag.ENABLE_RBAC_V2);

  const [isInviteModalOpen, setIsInviteModalOpen] = useState(false);
  const openInviteUserModal = useCallback(() => {
    setIsInviteModalOpen(true);
  }, []);

  const closeInviteUserModal = useCallback(() => {
    setIsInviteModalOpen(false);
  }, []);

  const NAV_LINKS: NavItem[] = useMemo(
    () => [
      {
        type: "Link",
        url: HOME_URL,
        displayName: HOME_TITLE,
        icon: <HomeIcon />,
      },
      {
        type: "Link",
        url: INTEGRATIONS_URL,
        displayName: INTEGRATIONS_TITLE,
        icon: <IntegrationsIcon />,
      },
      {
        type: "Header",
        displayName: "MONITOR",
        hidden: !canViewAuditLogs,
      },
      {
        type: "Link",
        url: AUDIT_LOGS_BASE_URL,
        displayName: AUDIT_LOGS_TITLE,
        icon: <LogsIcon />,
        hidden: !canViewAuditLogs,
      },
      {
        type: "Header",
        displayName: "LEARN",
      },
      {
        type: "Link",
        url: DOCS_URL,
        displayName: DOCUMENTATION_TITLE,
        icon: <DocsIcon />,
        openInNewWindow: true,
        onClick: noop, // do not make the nav item into selected state, as it opens page in new tab and current page is not changed.
      },
      {
        type: "Link",
        url: VIDEO_LIST_URL,
        displayName: VIDEO_TITLE,
        icon: <VideoIcon />,
        openInNewWindow: true,
        onClick: noop,
      },
      {
        type: "Divider",
        hidden: !canInviteUsers,
      },
      {
        type: "Link",
        displayName: INVITE_MEMBERS_TITLE,
        icon: <LinkIcon />,
        onClick: openInviteUserModal,
        hidden: !canInviteUsers,
      },
    ],
    [canViewAuditLogs, canInviteUsers, openInviteUserModal],
  );

  const NAV_LINKS_ORGANIZATION_SETTINGS: NavItem[] = useMemo(
    () => [
      {
        type: "Link",
        url: GENERAL_PAGE_URL,
        icon: <GearIcon className="stroke-outline" />,
        displayName: "General",
        hidden: !canViewOrg,
      },
      {
        type: "Header",
        displayName: "ACCESS",
      },
      {
        type: "Link",
        url: USERS_PAGE_URL,
        displayName: "Users",
        icon: <UserGearIcon className="stroke-outline" />,
        hidden: !canViewUsers,
      },
      {
        type: "Link",
        url: GROUPS_PAGE_URL,
        displayName: "Groups",
        icon: <GroupIcon className="stroke-outline" />,
        hidden: !canViewGroups,
      },
      {
        type: "Link",
        url: ROLES_AND_PERMISSIONS_URL(
          RoleAndPermissionsPages.ORGANIZATION_ROLES,
        ),
        displayName: ROLES_AND_PERMISSIONS_TITLE,
        icon: <RoleIcon />,
        hidden: !rbacV2Enabled || !canViewRoles,
      },
      {
        type: "Link",
        url: PERMISSION_PAGE_URL("entities"),
        displayName: PERMISSIONS_TITLE,
        icon: <LockIcon />,
        hidden: rbacV2Enabled,
      },
      {
        type: "Header",
        displayName: "BUILD AND DEPLOY",
        hidden: !showBuildAndDeployHeader,
      },
      {
        type: "Link",
        url: PROFILES_URL,
        displayName: PROFILES_TITLE,
        icon: <ProfilesIcon className="stroke-outline" />,
        hidden: !enableProfiles || !canViewProfiles,
      },
      {
        type: "Link",
        url: REPOSITORIES_URL,
        displayName: REPOSITORIES_TITLE,
        icon: <RepositoriesIcon className="stroke-outline" />,
        hidden: !canViewRepositories || !enableRepositoriesPage,
      },

      {
        type: "Link",
        url: OBSERVABILITY_PAGE_URL(),
        displayName: OBSERVABILITY_TITLE,
        icon: <ObservabilityIcon />,
        hidden: !canViewObservability || !SUPERBLOCKS_UI_ENABLE_OBSERVABILITY,
      },
      {
        type: "Link",
        url: SECRETS_MANAGEMENT_URL,
        displayName: SECRETS_MANAGEMENT_TITLE,
        icon: <SecretsManagementIcon className="stroke-outline" />,
        hidden: !enableSecretsManagement || !canViewSecrets,
      },
      {
        type: "Header",
        displayName: "SECURITY",
        hidden: !showSecurityHeader,
      },
      {
        type: "Link",
        url: AGENTS_BASE_URL,
        displayName: AGENTS_TITLE,
        icon: <AgentsIcon />,
        hidden: !canViewAgents,
      },
      {
        type: "Link",
        url: ACCESS_TOKENS_URL,
        displayName: "Access Tokens",
        icon: <AccessTokenIcon className="stroke-outline" />,
        hidden: !canViewAccessTokens || !enableAccessTokensPage,
      },
    ],
    [
      canViewOrg,
      canViewUsers,
      canViewGroups,
      rbacV2Enabled,
      canViewRoles,
      showBuildAndDeployHeader,
      enableProfiles,
      canViewProfiles,
      canViewRepositories,
      enableRepositoriesPage,
      canViewObservability,
      enableSecretsManagement,
      canViewSecrets,
      showSecurityHeader,
      canViewAgents,
      canViewAccessTokens,
      enableAccessTokensPage,
    ],
  );

  const NAV_LINKS_PERSONAL_SETTINGS: NavItem[] = useMemo(
    () => [
      {
        type: "Link",
        url: PERSONAL_SETTINGS_URL("profile"),
        displayName: "Profile",
        icon: <UserIcon className="stroke-outline" />,
      },
      {
        type: "Link",
        url: PERSONAL_SETTINGS_URL("apiKey"),
        displayName: "API Key",
        icon: <KeyIcon className="stroke-outline" />,
      },
    ],
    [],
  );

  let currentNavPanel = NavPanelKeys.MAIN;
  const location = useLocation();
  const basePath = location.pathname.split("/")[1];
  const hash = location.hash;
  if (
    NAV_LINKS_ORGANIZATION_SETTINGS.find((item) =>
      navItemMatchPath(item, basePath, hash),
    )
  ) {
    currentNavPanel = NavPanelKeys.ORG_SETTINGS;
  } else if (
    NAV_LINKS_PERSONAL_SETTINGS.find((item) =>
      navItemMatchPath(item, basePath, hash),
    )
  ) {
    currentNavPanel = NavPanelKeys.PERSONAL_SETTINGS;
  }

  const ALL_NAV_LINKS = useMemo(
    () => [
      ...NAV_LINKS,
      ...NAV_LINKS_ORGANIZATION_SETTINGS,
      ...NAV_LINKS_PERSONAL_SETTINGS,
    ],
    [NAV_LINKS, NAV_LINKS_ORGANIZATION_SETTINGS, NAV_LINKS_PERSONAL_SETTINGS],
  );

  const selectedKeys = useMemo(() => {
    const selectedItem = ALL_NAV_LINKS.find((item) =>
      navItemMatchPath(item, basePath, hash),
    ) as NavLink;
    return selectedItem && selectedItem?.displayName
      ? [selectedItem.displayName]
      : [];
  }, [ALL_NAV_LINKS, basePath, hash]);

  const theme = useTheme();
  const { canViewOrgSettings, pathname: orgSettingsPathname } =
    useOrganizationSettingsLink();

  const selectNavPanel = useCallback(
    (key: NavPanelKeys) => {
      if (key === currentNavPanel) return;
      if (key === NavPanelKeys.MAIN) {
        navigate({
          pathname: HOME_URL,
          search: location.search,
        });
      } else if (key === NavPanelKeys.ORG_SETTINGS) {
        navigate({
          pathname: orgSettingsPathname,
          search: location.search,
        });
      } else if (key === NavPanelKeys.PERSONAL_SETTINGS) {
        const [url, hash] = PERSONAL_SETTINGS_URL("profile").split("#");
        navigate({
          pathname: url,
          hash,
          search: location.search,
        });
      }
    },
    [currentNavPanel, navigate, location.search, orgSettingsPathname],
  );

  const renderItemWithIndex = useCallback((navItem: any, index: any) => {
    if (navItem.hidden) return null;
    switch (navItem.type) {
      case "Link": {
        const { displayName, url, icon, openInNewWindow, onClick } = navItem;
        return (
          <StyledMenuItem key={displayName} icon={icon} onClick={onClick}>
            {url ? (
              <Link
                to={url}
                target={openInNewWindow ? "_blank" : "_self"}
                data-test={`nav-${displayName}`}
                rel="noreferrer"
              >
                {displayName}
              </Link>
            ) : (
              <Typography.Text>{displayName}</Typography.Text>
            )}
          </StyledMenuItem>
        );
      }
      case "Header":
        return (
          <SubheaderMenuItem key={navItem.displayName}>
            {navItem.displayName}
          </SubheaderMenuItem>
        );
      case "Divider":
      default:
        return (
          <DividerMenuItem key={index}>
            <div style={{ flexGrow: 1 }}></div>
            <AntDivider />
          </DividerMenuItem>
        );
    }
  }, []);

  return (
    <StyledSider width={theme.legacy.pageNavWidth}>
      <TopItemWrapper>
        {currentNavPanel === NavPanelKeys.MAIN && (
          <ChecklistStatus type="card" />
        )}
        {currentNavPanel !== NavPanelKeys.MAIN && (
          <BackButtonWrapper>
            <Button
              icon={<ArrowLeft />}
              onClick={() => selectNavPanel(NavPanelKeys.MAIN)}
              data-test="nav-back-button"
            >
              Home
            </Button>
          </BackButtonWrapper>
        )}
      </TopItemWrapper>
      <UserOrgItemWrapper>
        <OrgUserDropdown
          selectNavPanel={selectNavPanel}
          showOrganizationSettings={canViewOrgSettings}
        />
      </UserOrgItemWrapper>
      <AntDivider />
      {currentNavPanel === NavPanelKeys.MAIN && (
        <StyledMenu mode="inline" selectedKeys={selectedKeys}>
          {NAV_LINKS.map(renderItemWithIndex)}
        </StyledMenu>
      )}
      {currentNavPanel === NavPanelKeys.ORG_SETTINGS && (
        <StyledMenu mode="inline" selectedKeys={selectedKeys}>
          <div className={`${NavTitleWrapper} ${Heading2}`}>
            Organization Settings
          </div>
          {NAV_LINKS_ORGANIZATION_SETTINGS.map(renderItemWithIndex)}
        </StyledMenu>
      )}
      {currentNavPanel === NavPanelKeys.PERSONAL_SETTINGS && (
        <StyledMenu mode="inline" selectedKeys={selectedKeys}>
          <div className={`${NavTitleWrapper} ${Heading2}`}>
            Personal settings
          </div>
          {NAV_LINKS_PERSONAL_SETTINGS.map(renderItemWithIndex)}
        </StyledMenu>
      )}
      <InviteOrgUserModal
        isVisible={isInviteModalOpen}
        onClose={closeInviteUserModal}
      />
    </StyledSider>
  );
});

PageNav.displayName = "PageNav";
