import { Checkbox, Input, Select } from "antd";
import React, { useCallback, useContext } from "react";
import styled from "styled-components";
import { colors } from "styles/colors";
import { styleAsClass } from "styles/styleAsClass";
import { GitOnboardingContext } from "./GitOnboardingContext";
import { Repository } from "./utils";

interface BranchAndDirectoryWrapperProps {
  repo: Repository | null;
  showAlert: boolean;
  foundConfig: boolean;
  setFoundConfig: (value: boolean) => void;
  directory: string;
  selectedBranch: string;
  setDirectory: (value: any) => void;
}
const { Option } = Select;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const StyledSelect = styled(Select)`
  width: 100%;
`;

const StyledInput = styled(Input)`
  width: 100%;
  font-size: ${(props) => props.theme.text.sizes.small};
`;

const InputLabel = styled.div`
  color: ${(props) => props.theme.colors.GREY_700};
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
`;

const SubText = styled.div`
  font-size: 11px;
  font-weight: 400;
  line-height: 16px;
  color: ${(props) => props.theme.colors.GREY_400};
`;

const MainText = styleAsClass`
  font-size: 12px;
  color: ${colors.GREY_700};
`;

const BranchAndDirectoryWrapper = ({
  repo,
  showAlert,
  directory,
  setDirectory,
  selectedBranch,
}: BranchAndDirectoryWrapperProps) => {
  const handleDirectoryChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newDirectory = e.target.value;
    setDirectory(newDirectory);
  };

  const cleanDirectory = useCallback(() => {
    if (directory.endsWith("/")) {
      setDirectory(directory.replace(/\/+$/, ""));
    }
  }, [directory, setDirectory]);

  const { protectDefaultBranch, setProtectDefaultBranch } =
    useContext(GitOnboardingContext);

  const onClickProtectedCheckBoxText = useCallback(() => {
    setProtectDefaultBranch?.(!protectDefaultBranch);
  }, [protectDefaultBranch, setProtectDefaultBranch]);

  return (
    <div>
      <Container>
        <InputLabel>Branch</InputLabel>
        <StyledSelect
          key={selectedBranch}
          defaultValue={selectedBranch}
          disabled
        >
          <Option value={selectedBranch}>{selectedBranch}</Option>
        </StyledSelect>
        {showAlert && (
          <SubText>
            Default branch in Superblocks. Only commits on the{" "}
            <b>{repo?.defaultBranch}</b> branch can be deployed.
          </SubText>
        )}
      </Container>

      <Container
        style={{
          marginTop: 12,
          flexDirection: "row",
          alignItems: "center",
          cursor: "pointer",
        }}
        onClick={onClickProtectedCheckBoxText}
      >
        <Checkbox checked={protectDefaultBranch} disabled={!showAlert} />
        <div className={MainText}>
          {" "}
          Prevent editing on the <strong>main</strong> branch
        </div>
      </Container>

      <Container style={{ marginTop: "20px" }}>
        <InputLabel>Directory</InputLabel>
        <StyledInput
          value={directory}
          onChange={handleDirectoryChange}
          // If showAlert is false, then that means we're in the last step of the
          // onboarding flow, and the user has already selected a directory.
          disabled={!showAlert}
          onBlur={cleanDirectory}
        />
        {showAlert && (
          <SubText>
            {`Top level directory containing Superblocks files and sub-directories.`}
          </SubText>
        )}
      </Container>
    </div>
  );
};

export default BranchAndDirectoryWrapper;
