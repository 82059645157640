import { Spinner } from "@blueprintjs/core";
import {
  AgentType,
  ExtendedIntegrationPluginId,
  getBasePluginId,
  IntegrationKind,
  Plugin,
  RbacRole,
  SupersetIntegrationDto,
} from "@superblocksteam/shared";
import { Tooltip } from "antd";
import Fuse from "fuse.js";
import { isNil, partition } from "lodash";
import React, {
  ChangeEvent,
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useSelector } from "react-redux";
import { Layout, MainWrapper } from "components/app";
import AddButton from "components/ui/AddButton";
import { PrimaryButton } from "components/ui/Button";
import { MultiFilter } from "components/ui/Filter";
import { HeaderWrapper } from "components/ui/Page";
import { DropdownOption } from "components/ui/RecommendedMultiDropdown";
import { SearchContainer, SearchInput } from "components/ui/SearchSection";
import { CREATE_INTEGRATION } from "constants/rbac";
import { useSaga } from "hooks/store";
import { useAuthorizationCheck } from "hooks/ui/rbac/useAuthorizationCheck";
import { getCurrentUser } from "legacy/selectors/usersSelectors";
import Header from "pages/components/Header";
import { PageNav } from "pages/components/PageNav";
import { INTEGRATIONS_TITLE, PageWrapper } from "pages/components/PageWrapper";
import { selectPluginDatasourcesWithAnyPermission } from "store/slices/datasources";
import { getSupersetDatasourcesSaga } from "store/slices/datasources/sagas/getSupersetDatasources";
import { selectOnlyOrganization } from "store/slices/organizations";
import { selectRecommendedDatasources } from "store/slices/user";
import { colors } from "styles/colors";
import { styleAsClass } from "styles/styleAsClass";
import { PLUGIN_INTEGRATIONS_LIST, getPluginById } from "utils/integrations";
import logger, { stringifyError } from "utils/logger";
import IntegrationTable from "./IntegrationTable";
import IntegrationsEmptyState from "./IntegrationsEmptyState";
import NewIntegrationModal from "./NewIntegrationModal";
import { PluginCard } from "./PluginCard";
import { AvailablePlugin, ConnectedIntegration } from "./utils";

const PluginCardGrid = styleAsClass`
    display: grid;
    grid-template-columns: repeat(2, 234px);
    grid-column-gap: 16px;
    grid-row-gap: 12px;
`;

const QuickAddWrapper = styleAsClass`
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-grow: 1;
    gap: 24px;
    justify-content: center; /* Horizontally center content if needed */
    padding-bottom: 250px; // make it slightly higher than center
    max-width: 1500px;
    .quick-add-header-section {
      display: flex;
      flex-direction: column;
      gap: 4px;
    }
    .quick-add-header {
      font-size: 15px;
      color: ${colors.GREY_700};
      font-weight: 500;
      line-height: 20px;
      text-align: center;
    }
    .quick-add-subheader {
      font-size: 13px;
      color: ${colors.GREY_500};
      font-weight: 400;
      line-height: 20px;
      text-align: center;
      max-width: 332px;
    }
`;

const MostUsedPlugins = [
  "postgres",
  "mongodb",
  ExtendedIntegrationPluginId.SALESFORCE,
  ExtendedIntegrationPluginId.SLACK,
  ExtendedIntegrationPluginId.REST_API,
  ExtendedIntegrationPluginId.GRAPHQL,
];

export default function Integrations(): ReactElement {
  const [available, setAvailable] = useState<AvailablePlugin[]>([]);
  const [connected, setConnected] = useState<ConnectedIntegration[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [canCreate] = useAuthorizationCheck([CREATE_INTEGRATION]);

  const organization = useSelector(selectOnlyOrganization);

  const [getDatasources] = useSaga(getSupersetDatasourcesSaga);

  const user = useSelector(getCurrentUser);
  const integrationsWithAnyPermission = useSelector(
    selectPluginDatasourcesWithAnyPermission,
  );

  const [connectedLoading, setConnectedLoading] = useState(true);

  useEffect(() => {
    (async () => {
      if (user?.currentOrganizationId) {
        // There will be a flicker if we load 1 view of the integrations before
        // the other. Synchronize them with the loading state var.
        setConnectedLoading(true);
        try {
          await getDatasources({
            organizationId: user.currentOrganizationId,
            kind: IntegrationKind.PLUGIN,
          });
        } finally {
          setConnectedLoading(false);
        }
      }
    })();
  }, [user, getDatasources]);

  useEffect(() => {
    try {
      const connectedIntegrations = Object.values(integrationsWithAnyPermission)
        .filter(
          (datasource: SupersetIntegrationDto) => datasource?.isUserConfigured,
        )
        .map((datasource: SupersetIntegrationDto): ConnectedIntegration => {
          const plugin = getPluginById(datasource.pluginId);
          // TODO(rbac) remove legacy role field once rbac V2 is GA
          let role = RbacRole.NONE;
          if (!datasource.editable) {
            role = RbacRole.BUILDER;
          } else {
            role = RbacRole.CONFIGURATOR;
          }

          const isSupported =
            isNil(organization.pluginExecutionVersions) ||
            organization.agentType !== AgentType.ONPREMISE
              ? true
              : getBasePluginId(plugin?.id) in
                organization.pluginExecutionVersions;

          return {
            datasourceName: datasource.name,
            datasourceId: datasource.id,
            iconLocation: plugin?.iconLocation,
            pluginId: plugin?.id,
            pluginName: plugin?.name,
            pluginType: plugin?.type,
            role: role,
            permissions: datasource.permissions,
            isSupported,
            configurations: datasource.configurations,
            creator: datasource.creator,
            lastUpdated: datasource.updated,
          };
        });

      const availIntegrations = PLUGIN_INTEGRATIONS_LIST.filter(
        (plugin: Plugin) =>
          plugin.datasourceTemplate &&
          plugin.datasourceTemplate.sections.length > 0,
      ).map((plugin: Plugin): AvailablePlugin => {
        const isSupported =
          isNil(organization.pluginExecutionVersions) ||
          organization.agentType !== AgentType.ONPREMISE
            ? true
            : getBasePluginId(plugin?.id) in
              organization.pluginExecutionVersions;
        return {
          iconLocation: plugin?.iconLocation,
          pluginId: plugin?.id,
          pluginName: plugin?.name,
          pluginType: plugin?.type,
          isSupported,
        };
      });

      setAvailable(availIntegrations);

      if (!connectedLoading) {
        setConnected(connectedIntegrations);
      }
    } catch (error) {
      logger.error(
        `Failed to build integration objects for org ${
          user?.currentOrganizationId
        }, error:
${stringifyError(error)}`,
      );
    }
  }, [
    user,
    integrationsWithAnyPermission,
    connectedLoading,
    organization.pluginExecutionVersions,
    organization.agentType,
  ]);

  const surveyDatasources = useSelector(selectRecommendedDatasources);
  const [availableSorted, supportedSorted] = useMemo(() => {
    const topIntegrations = [...surveyDatasources, ...MostUsedPlugins];
    const orderMap = new Map(
      topIntegrations.map((nameOrId, index) => [nameOrId, index]),
    );
    const [topUsed, others] = partition(
      available,
      (integration) =>
        orderMap.has(integration.pluginName) ||
        orderMap.has(integration.pluginId),
    );

    // Sort topUsed based on the position in orderMap
    const sortedTopUsed = topUsed.sort((a, b) => {
      const aIndex = orderMap.get(a.pluginName) ?? orderMap.get(a.pluginId);
      const bIndex = orderMap.get(b.pluginName) ?? orderMap.get(b.pluginId);
      return (aIndex ?? Infinity) - (bIndex ?? Infinity);
    });

    const sorted = [...sortedTopUsed, ...others];
    const supported = sorted.filter((integration) => integration.isSupported);
    return [sorted, supported];
  }, [available, surveyDatasources]);

  // filter connected integrations
  const [selectedPlugins, setSelectedPlugins] = useState<DropdownOption[]>([]);
  const selectedPluginIds = selectedPlugins.map((plugin) => plugin.value);
  const allPluginOptions = useMemo(() => {
    const pluginOptionsById = connected.reduce(
      (acc: Record<string, DropdownOption>, plugin) => {
        if (plugin.pluginId) {
          acc[plugin.pluginId] = {
            displayName: plugin.pluginName,
            key: plugin.pluginId,
            value: plugin.pluginId,
            icon: (
              <img
                width={20}
                height={20}
                src={plugin.iconLocation ?? ""}
                alt=""
              />
            ),
          };
        }
        return acc;
      },
      {},
    );

    const allOptions = Object.values(pluginOptionsById).sort((a, b) =>
      (a.displayName as string)?.localeCompare(b.displayName as string),
    );

    // avoid resetting the existing type filter when connected is updated e.g. when an integration is deleted
    setSelectedPlugins((selectedPlugins) => {
      if (selectedPlugins.length === 0) {
        return allOptions;
      }
      return selectedPlugins;
    });

    return allOptions;
  }, [connected]);

  const [selectedCreators, setSelectedCreators] = useState<DropdownOption[]>(
    [],
  );
  const selectedCreatorEmails = selectedCreators.map(
    (creator) => creator.value,
  );
  const allCreatorOptions = useMemo(() => {
    const creatorOptionsByEmail = connected.reduce(
      (acc: Record<string, DropdownOption>, user) => {
        if (user.creator) {
          acc[user.creator.email] = {
            displayName: user.creator.name,
            key: user.creator.email,
            value: user.creator.email,
          };
        }
        return acc;
      },
      {},
    );
    const allOptions = Object.values(creatorOptionsByEmail).sort((a, b) =>
      (a.displayName as string)?.localeCompare(b.displayName as string),
    );
    setSelectedCreators((selectedCreators) => {
      // avoid resetting the existing creator filter when connected is updated e.g. when an integration is deleted
      if (selectedCreators.length === 0) {
        return allOptions;
      }
      return selectedCreators;
    });
    return allOptions;
  }, [connected]);

  const onSearchConnected = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
  }, []);

  const fuse = useMemo(
    () =>
      new Fuse(connected, {
        shouldSort: true,
        threshold: 0.1,
        ignoreLocation: true,
        minMatchCharLength: 1,
        findAllMatches: true,
        keys: ["datasourceName", "pluginName"],
      }),
    [connected],
  );

  const searchConnected = useMemo(() => {
    let results = connected;
    if (searchTerm) {
      results = fuse.search(searchTerm).map((result) => result.item);
    }
    const filterIntegration = (integration: ConnectedIntegration) => {
      const isPluginSelected =
        integration.pluginId &&
        selectedPluginIds.includes(integration.pluginId);
      const isCreatorSelected =
        integration.creator?.email &&
        selectedCreatorEmails.includes(integration.creator?.email);

      const hasNoCreator = !integration.creator?.email;
      const noOrAllCreatorSelected =
        selectedCreatorEmails.length === allCreatorOptions.length ||
        selectedCreatorEmails.length === 0;

      // if plugin has no creator, we should show it if no creator is selected or all creators are selected
      return (
        isPluginSelected &&
        (isCreatorSelected || (hasNoCreator && noOrAllCreatorSelected))
      );
    };

    const filtered = results.filter(filterIntegration);
    return filtered;
  }, [
    connected,
    searchTerm,
    fuse,
    selectedPluginIds,
    selectedCreatorEmails,
    allCreatorOptions.length,
  ]);

  const [addModalOpen, setAddModalOpen] = useState(false);

  const hasConnected = connected.length > 0;

  return (
    <PageWrapper pageName={INTEGRATIONS_TITLE}>
      <Layout Header={<Header />} Sider={<PageNav />}>
        <MainWrapper
          data-test="integration-home"
          style={{ display: "flex", flexDirection: "column" }}
        >
          <div className={HeaderWrapper}>
            <div className="page-header-title"> {INTEGRATIONS_TITLE} </div>
          </div>
          {!hasConnected && !canCreate && <IntegrationsEmptyState />}
          {hasConnected && (
            <div className={SearchContainer}>
              <SearchInput
                data-test="search-integrations"
                onChange={onSearchConnected}
                allowClear
                placeholder="Search integrations..."
              />
              <MultiFilter
                selectedItems={selectedPlugins}
                options={allPluginOptions}
                onChange={setSelectedPlugins}
                label="Integration"
                placeholder="Filter by integration"
                enableSelectAll={true}
                defaultSelectAll={true}
                showSearchInPopover={true}
                width={162}
                popoverWidth={266}
                useDynamicWidth={true}
              />
              <MultiFilter
                selectedItems={selectedCreators}
                options={allCreatorOptions}
                onChange={setSelectedCreators}
                label="Created by"
                placeholder="Filter by creator"
                enableSelectAll={true}
                defaultSelectAll={true}
                showSearchInPopover={true}
                width={162}
                popoverWidth={266}
                useDynamicWidth={true}
              />
              <AddButton
                text="Add integration"
                dataTest="add-integration-button"
                onAdd={() => setAddModalOpen(true)}
                disabled={!canCreate}
                disabledText="You do not have permission to add integrations"
              />
            </div>
          )}
          {connectedLoading ? (
            <div style={{ padding: 20 }}>
              <Spinner size={42} />
            </div>
          ) : (
            hasConnected && (
              <>
                <IntegrationTable
                  integrations={searchConnected}
                  setIntegrations={setConnected}
                  loading={connectedLoading}
                  totalCount={connected.length}
                />
              </>
            )
          )}
          {!hasConnected &&
            !connectedLoading &&
            supportedSorted &&
            supportedSorted.length > 0 &&
            canCreate && (
              <div className={QuickAddWrapper}>
                <div className="quick-add-header-section">
                  <div className="quick-add-header">
                    Connect to Integrations
                  </div>
                  <div className="quick-add-subheader">
                    Connect your databases, APIs, and data warehouses to get
                    started building with Superblocks
                  </div>
                </div>
                <div className={PluginCardGrid}>
                  {supportedSorted.slice(0, 6).map((plugin) => (
                    <PluginCard
                      key={plugin.pluginId}
                      pluginInfo={plugin}
                      buttonStyle="PRIMARY"
                      isDisabled={!canCreate}
                    />
                  ))}
                </div>
                {supportedSorted.length > 6 && (
                  <div>
                    <Tooltip
                      title={
                        canCreate
                          ? undefined
                          : "You do not have permission to add integrations"
                      }
                    >
                      <PrimaryButton
                        onClick={() => setAddModalOpen(true)}
                        disabled={!canCreate}
                      >
                        {`+${supportedSorted.length - 6} more integrations`}
                      </PrimaryButton>
                    </Tooltip>
                  </div>
                )}
              </div>
            )}
          <NewIntegrationModal
            available={availableSorted}
            isModalOpen={addModalOpen}
            setIsModalOpen={setAddModalOpen}
          />
        </MainWrapper>
      </Layout>
    </PageWrapper>
  );
}
