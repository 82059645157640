import { useCallback } from "react";
import { useStore } from "react-redux";
import { PAGE_WIDGET_ID } from "legacy/constants/WidgetConstants";
import { getWidgets } from "legacy/selectors/sagaSelectors";
import { AppState } from "store/types";
import { getPositionInIframe } from "utils/iframe";
import { getPasteAtCursorInsertionIndexes } from "./usePasteWidget";

const useInsertionIndexes = ({
  isInsideIframe,
}: {
  isInsideIframe?: boolean;
} = {}) => {
  const store = useStore<AppState>();

  const getCurrentInsertionIndexes = useCallback(
    (widgetId: string, clientX: number, clientY: number) => {
      const state = store.getState();
      const focusedWidget = state.legacy.ui.widgetDragResize.focusedWidgetId;
      const selectedWidgetIds =
        state.legacy.ui.widgetDragResize.selectedWidgets;
      const pasteTargetIdDefaulted =
        widgetId ?? selectedWidgetIds[0] ?? PAGE_WIDGET_ID;
      const widgets = getWidgets(state);

      const { x, y } = isInsideIframe
        ? { x: clientX, y: clientY }
        : getPositionInIframe(clientX, clientY);

      return getPasteAtCursorInsertionIndexes({
        focusedWidget,
        pasteTargetId: pasteTargetIdDefaulted,
        mousePosition: {
          x,
          y,
        },
        widgets,
      });
    },
    [store, isInsideIframe],
  );

  return getCurrentInsertionIndexes;
};

export default useInsertionIndexes;
