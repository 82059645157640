import { Icon } from "@iconify/react";
import { WidgetTypes } from "@superblocksteam/shared";
import Tooltip from "antd/lib/tooltip";
import React, { useState, useCallback, useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import tinycolor from "tinycolor2";
import { AI_EDITABLE_WIDGET_TYPES } from "ai/AiComponentEditor/constants";
import { ReactComponent as AiStarsIcon } from "assets/icons/common/ai-stars.svg";
import { ReactComponent as ChevronRightIcon } from "assets/icons/common/chevron-right.svg";
import { ReactComponent as EllipsisIcon } from "assets/icons/common/ellipsis.svg";
import { ReactComponent as HideIcon } from "assets/icons/common/hide.svg";
import Popper from "components/ui/Popper";
import { useFeatureFlag } from "hooks/ui";
import { Widget, useNavigateTo, NestedItem } from "hooks/ui/useNavigateTo";
import { focusWidget } from "legacy/actions/widgetActions";
import { Layers } from "legacy/constants/Layers";
import { DisplayableParent } from "legacy/selectors/propertyPaneSelectors";
import { getInitialAiModalPosition } from "legacy/utils/ai";
import { clearAiChanges, openAiModal } from "store/slices/ai/slice";
import { Flag } from "store/slices/featureFlags";
import { colors } from "styles/colors";
import { isUserOnMac } from "utils/navigator";
import eventBus from "./eventBus";

export type WidgetNamePosition = "top" | "bottom" | "inset";

export enum Activities {
  HOVERING,
  SELECTED,
  ACTIVE, // This state appears unused
  NONE,
}

const WRAPPER_X_PADDING = 6;

const VERTICAL_WIDTH_TRHESHOLD_PX = 200;

const CRUMBS_TO_SHOW_IF_HORIZONAL = 2;

const AiButtonPositioner = styled.div`
  position: absolute;
  right: 100%;
  top: 0px;
  height: 100%;
  padding-right: 2px;
  // make the hover interaction fill the gap between the widget and the ai button
  &[data-is-selected="false"]::after {
    content: "";
    position: absolute;
    bottom: -4px;
    left: 0;
    width: 100%;
    height: 4px;
    background-color: transparent;
  }
  &[data-is-selected="false"]&[data-position="bottom"]::after {
    top: -4px;
  }
`;

const AiButton = styled.div`
  width: 20px;
  height: 100%;
  background: ${({ theme }) => theme.colors.ACCENT_BLUE_NEW_DARKER};
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.colors.WHITE};
  cursor: pointer;
`;

const WidgetNameWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-self: flex-end;
  height: 100%;
  padding: 0px ${WRAPPER_X_PADDING}px;
  justify-content: space-between;
  align-items: center;
  color: ${({ theme }) => theme.colors.WHITE};
  border-radius: 3px;
  position: relative;

  &[data-round-only-top="true"] {
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
  }

  box-shadow: 0px 4px 16px 0px rgba(34, 39, 47, 0.08);
  box-shadow: 0px 1px 3px 0px rgba(34, 39, 47, 0.06);

  pointer-events: none;
  &[data-has-pointer-events="true"] {
    pointer-events: auto;
  }

  cursor: pointer;
  font-family: var(--font-family);

  &[data-activity="${Activities.ACTIVE}"],
  &[data-activity="${Activities.SELECTED}"],
  &[data-activity="${Activities.HOVERING}"] {
    background: ${({ theme }) =>
      tinycolor(theme.colors.ACCENT_BLUE_NEW_DARKER)
        .setAlpha(0.9)
        .toHexString()};
  }

  &[data-activity="${Activities.NONE}"] {
    background: ${({ theme }) => theme.colors.ACCENT_BLUE_NEW_DARKER};
  }

  &[data-has-invalid-props="true"] {
    background: ${({ theme }) => theme.legacy.colors.error};
  }

  & {
    pre {
      margin: 0 5px 0 0;
      font-size: ${(props) => props.theme.legacy.fontSizes[3]}px;
      height: ${(props) => props.theme.legacy.fontSizes[3]}px;
      line-height: ${(props) => props.theme.legacy.fontSizes[3] - 1}px;
    }
  }

  &[data-show-breadcrumbs="true"]&[data-has-more-breadcrumbs="true"]&[data-vertical-breadcrumbs="false"] {
    padding-left: 0px; // the more button handles this with its padding to give it a bigger click target
  }

  &[data-show-breadcrumbs="false"] .t--widget-name span {
    opacity: 1;
  }

  &[data-show-breadcrumbs="false"]&[data-is-selected="true"]&[data-should-expand-hint="true"]
    [data-expand-hint],
  &[data-show-breadcrumbs="true"][data-vertical-breadcrumbs="true"]
    [data-expand-hint] {
    display: block;
  }

  &[data-is-selected="false"]::after {
    content: "";
    position: absolute;
    bottom: -4px;
    left: 0;
    width: 100%;
    height: 4px;
    background-color: transparent;
  }
  &[data-is-selected="false"]&[data-position="bottom"]::after {
    top: -4px;
  }
`;

const IconWrapper = styled.div`
  padding: 0;
  margin-right: 4px;
  display: flex;
  g {
    color: ${({ theme }) => theme.colors.WHITE};
  }
  svg {
    height: 10px;
    width: auto;
    position: relative;
  }
`;

const NameItem = styled.div`
  font-size: 13px;
  font-weight: 500;
  line-height: 16px;
  color: ${({ theme }) => theme.colors.WHITE};
  cursor: pointer;
  padding: 2px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1px;
  pointer-events: auto;

  span {
    opacity: 0.56;
  }

  &:hover span {
    opacity: 1;
  }
`;

const HorzBreadCrumbItem = styled(NameItem)`
  span {
    max-width: 80px;
    text-overflow: ellipsis;
    overflow: hidden;
    text-wrap: nowrap;
  }
`;

const ExtendedBreadCrumbItem = styled(NameItem)`
  background: ${({ theme }) => theme.colors.ACCENT_BLUE_NEW_DARKER};
  padding: 1px 4px;
  border-radius: 3px;
  pointer-events: auto;
`;

const SlashDivider = styled.div`
  color: ${({ theme }) => theme.colors.WHITE};
  font-size: 13px;
  padding: 0 4px;
  opacity: 0.64;

  ::before {
    content: "/";
  }
`;

const ExpandHintIcon = styled(ChevronRightIcon)`
  padding: 0;
  margin-right: 4px;
  transform: rotate(180deg);
  display: none;

  path {
    stroke: ${({ theme }) => theme.colors.WHITE};
    opacity: 0.84;
  }
`;

const VerticalExpandHintIcon = styled(ExpandHintIcon)`
  transform: rotate(90deg);
`;

const MoreBtn = styled.div`
  color: ${({ theme }) => theme.colors.WHITE};
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 ${WRAPPER_X_PADDING}px;
  margin-right: -4px;
  align-self: stretch;
  opacity: 0.56;
  position: relative;

  &:hover {
    opacity: 1;
  }

  svg {
    width: 14px;
  }
`;

const EXTENDED_BREADCRUMB_GAP = 3;

const RemainingCrumbsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: flex-start;
  gap: ${EXTENDED_BREADCRUMB_GAP}px;
`;

type WidgetNameProps = {
  widgetId: string;
  widgetWidthPx: number;
  onSelect: (e: React.MouseEvent) => void;
  activity: Activities;
  name: string;
  hasInvalidProps: boolean;
  errorMessage?: string;
  widgetIsHidden: boolean;
  hasPointerEvents?: boolean;
  roundOnlyTop?: boolean;
  style?: React.CSSProperties;
  initialShownBreadcrumbs?: number;
  displayableParents: DisplayableParent[];
  isSelected: boolean;
  disableHoverInteraction?: boolean;
  position: WidgetNamePosition;
  widgetType: WidgetTypes;
  aiModalIsOpen: boolean;
  aiSelectedWidgetId?: string;
};

const NAVIGATION_OPTIONS = {
  dontOpenModals: true,
};

const useBreadCrumbEvents = (
  item: BreadcrumbAncestryItem,
  baseWidgetId: string,
) => {
  const { navigateToItem } = useNavigateTo(item, NAVIGATION_OPTIONS);

  const handleClick = useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation();
      navigateToItem(e);
    },
    [navigateToItem],
  );
  const dispatch = useDispatch();
  const handleMouseOver = useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation();
      dispatch(focusWidget(item.widgetId));
    },
    [item.widgetId, dispatch],
  );

  const handleMouseOut = useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation();
      dispatch(focusWidget(baseWidgetId));
    },
    [baseWidgetId, dispatch],
  );

  return {
    handleClick,
    handleMouseOver,
    handleMouseOut,
  };
};

const BreadCrumbItem: React.FC<{
  item: BreadcrumbAncestryItem;
  baseWidgetId: string;
  disableHoverInteraction?: boolean;
}> = ({ item, baseWidgetId, disableHoverInteraction }) => {
  const { handleClick, handleMouseOver, handleMouseOut } = useBreadCrumbEvents(
    item,
    baseWidgetId,
  );

  return (
    <>
      <HorzBreadCrumbItem
        onClick={handleClick}
        onMouseOver={disableHoverInteraction ? undefined : handleMouseOver}
        onMouseOut={disableHoverInteraction ? undefined : handleMouseOut}
      >
        <span>{item.label}</span>
        <SlashDivider />
      </HorzBreadCrumbItem>
    </>
  );
};

const ExtendedBreadCrumbItemComponent: React.FC<{
  item: BreadcrumbAncestryItem;
  baseWidgetId: string;
  disableHoverInteraction?: boolean;
}> = ({ item, baseWidgetId, disableHoverInteraction }) => {
  const { handleClick, handleMouseOver, handleMouseOut } = useBreadCrumbEvents(
    item,
    baseWidgetId,
  );

  return (
    <ExtendedBreadCrumbItem
      onClick={handleClick}
      onMouseOver={disableHoverInteraction ? undefined : handleMouseOver}
      onMouseOut={disableHoverInteraction ? undefined : handleMouseOut}
    >
      <span>{item.label}</span>
    </ExtendedBreadCrumbItem>
  );
};

type BreadcrumbAncestryItem = (Widget | NestedItem) & { label: string };

const BREADCRUMBS_EXPANDED_EVENT = "widgetBreadcrumbsExpanded";
type EventDetail = {
  widgetId: string;
};

const WidgetName: React.FC<WidgetNameProps> = ({
  onSelect,
  isSelected,
  widgetId,
  widgetWidthPx,
  style,
  hasInvalidProps,
  roundOnlyTop,
  name,
  activity,
  hasPointerEvents,
  widgetIsHidden,
  initialShownBreadcrumbs = 0,
  displayableParents,
  disableHoverInteraction,
  position,
  widgetType,
  aiModalIsOpen,
  aiSelectedWidgetId,
}) => {
  const wrapperRef = React.useRef<HTMLDivElement>(null);
  const popperModifiers = [
    {
      name: "offset",
      options: {
        offset: [0, 5],
      },
    },
  ];

  const [showBreadcrumbs, setShowBreadcrumbs] = useState(false);
  const [showExtendedBreadcrumbs, setShowExtendedBreadcrumbs] = useState(false);

  const handleEvent = useCallback(
    (e: Event) => {
      const customEvent = e as CustomEvent<EventDetail>;
      if (customEvent.detail.widgetId !== widgetId) {
        setShowBreadcrumbs(false);
        setShowExtendedBreadcrumbs(false);
      }
    },
    [widgetId],
  );

  useEffect(() => {
    eventBus.addEventListener(BREADCRUMBS_EXPANDED_EVENT, handleEvent);

    return () => {
      eventBus.removeEventListener(BREADCRUMBS_EXPANDED_EVENT, handleEvent);
    };
  }, [handleEvent]);

  const showCrumbsVertical = widgetWidthPx < VERTICAL_WIDTH_TRHESHOLD_PX;

  const handleClick = useCallback(
    (e: React.MouseEvent) => {
      onSelect(e);

      if (!isSelected) {
        return;
      }

      if (!showBreadcrumbs) {
        setShowBreadcrumbs(true);

        if (showCrumbsVertical) {
          setShowExtendedBreadcrumbs(true);
        }

        eventBus.dispatchEvent(
          new CustomEvent<EventDetail>(BREADCRUMBS_EXPANDED_EVENT, {
            detail: { widgetId },
          }),
        );
      }

      if (showBreadcrumbs) {
        setShowBreadcrumbs(false);
        setShowExtendedBreadcrumbs(false);
      }
    },
    [
      onSelect,
      isSelected,
      showBreadcrumbs,
      setShowExtendedBreadcrumbs,
      showCrumbsVertical,
      widgetId,
    ],
  );

  const clickExpandExtendedBreadcrumbs = useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation(); // prevent the click from hiding the breadcrumbs
      setShowExtendedBreadcrumbs(!showExtendedBreadcrumbs);
    },
    [showExtendedBreadcrumbs, setShowExtendedBreadcrumbs],
  );

  const isAiEditingEnabled = useFeatureFlag(Flag.ENABLE_AI_COMPONENT_EDITING);

  const dispatch = useDispatch();
  const onAiButtonClick = useCallback(
    async (e: React.MouseEvent) => {
      e.stopPropagation();

      if (aiModalIsOpen && aiSelectedWidgetId === widgetId) {
        dispatch(clearAiChanges({ shouldClose: true }));
        return;
      }

      const namePosition = wrapperRef.current?.getBoundingClientRect();

      const position = await getInitialAiModalPosition({
        widgetId,
        widgetType,
        insertionPoint: namePosition,
      });
      dispatch(
        openAiModal({
          widgetId,
          position,
        }),
      );
    },
    [dispatch, widgetId, widgetType, aiModalIsOpen, aiSelectedWidgetId],
  );

  const showAiButton = useMemo(() => {
    return isAiEditingEnabled && AI_EDITABLE_WIDGET_TYPES.includes(widgetType);
  }, [isAiEditingEnabled, widgetType]);

  const handleMouseOverAiButton = useCallback(
    (e: React.MouseEvent) => {
      if (disableHoverInteraction) return;
      e.stopPropagation();
      dispatch(focusWidget(widgetId));
    },
    [dispatch, widgetId, disableHoverInteraction],
  );

  const {
    initialShownCrumbParents,
    initialExpandedCrumbParents,
    remainingCrumbParents,
  } = useMemo(() => {
    let initialShownCrumbParents: BreadcrumbAncestryItem[] = [];
    let initialExpandedCrumbParents: BreadcrumbAncestryItem[] = [];
    let remainingCrumbParents: BreadcrumbAncestryItem[] = [];

    if (initialShownBreadcrumbs !== undefined && initialShownBreadcrumbs > 0) {
      initialShownCrumbParents = [
        displayableParents[displayableParents.length - initialShownBreadcrumbs],
      ];
    }

    if (showCrumbsVertical) {
      // all go into vertical stack
      // except for any already shown horizontally
      // so slice to remove the length equal to initialShownCrumbParents
      remainingCrumbParents = displayableParents.slice(
        0,
        displayableParents.length - initialShownCrumbParents.length,
      );
    } else {
      // We're doing horizontal

      // get the initial expanded parents
      // which is CRUMBS_TO_SHOW_IF_HORIZONAL - initially shown parent length
      initialExpandedCrumbParents = displayableParents.slice(
        displayableParents.length - CRUMBS_TO_SHOW_IF_HORIZONAL,
        displayableParents.length - initialShownCrumbParents.length,
      );

      // set the remaining parents to all parents
      // sliced minus initialExpandedCrumbParents
      remainingCrumbParents = displayableParents.slice(
        0,
        displayableParents.length - CRUMBS_TO_SHOW_IF_HORIZONAL,
      );
    }

    return {
      initialShownCrumbParents,
      initialExpandedCrumbParents,
      remainingCrumbParents: remainingCrumbParents.reverse(),
    };
  }, [displayableParents, initialShownBreadcrumbs, showCrumbsVertical]);

  const [shouldExpandHint, setShouldExpandHint] = useState(false);

  const handleMouseOverWidgetNameWrapper = useCallback(
    (e: React.MouseEvent) => {
      if (disableHoverInteraction) return;
      e.stopPropagation();

      // Don't trigger hover effects if hovering over AI button
      if (
        e.target instanceof Element &&
        e.target.closest(".widget-name-ai-button")
      ) {
        return;
      }
      setShouldExpandHint(true);
    },
    [disableHoverInteraction],
  );

  const handleMouseLeaveWidgetNameWrapper = useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation();
      setShouldExpandHint(false);
    },
    [],
  );

  return (
    <>
      <WidgetNameWrapper
        className="t--widget-propertypane-toggle"
        onMouseOver={handleMouseOverWidgetNameWrapper}
        onMouseLeave={handleMouseLeaveWidgetNameWrapper}
        data-should-expand-hint={shouldExpandHint}
        onClick={handleClick}
        style={style}
        data-has-invalid-props={hasInvalidProps}
        data-round-only-top={roundOnlyTop}
        data-activity={activity}
        data-test={`widget-name-handle-${name}${
          hasInvalidProps ? "-error-state" : "-valid-state"
        }`}
        data-has-pointer-events={hasPointerEvents}
        data-show-breadcrumbs={showBreadcrumbs}
        data-is-selected={isSelected}
        data-has-more-breadcrumbs={remainingCrumbParents.length > 0}
        data-vertical-breadcrumbs={showCrumbsVertical}
        data-position={position}
        ref={wrapperRef}
      >
        {showAiButton && (
          <AiButtonPositioner
            className="widget-name-ai-button"
            onMouseOver={handleMouseOverAiButton}
            data-test={`ask-ai-btn-${name}`}
            data-maintain-widget-focus="true"
            data-position={position}
            data-is-selected={isSelected}
          >
            <Tooltip
              title={
                <div>
                  Ask AI{" "}
                  <span style={{ opacity: 0.75 }}>
                    ({isUserOnMac() ? "⌘" : "Ctrl"}I)
                  </span>
                </div>
              }
            >
              <AiButton onClick={onAiButtonClick}>
                <AiStarsIcon color={colors.WHITE} />
              </AiButton>
            </Tooltip>
          </AiButtonPositioner>
        )}
        {!showCrumbsVertical &&
          showBreadcrumbs &&
          remainingCrumbParents.length > 0 && (
            <>
              <MoreBtn onClick={clickExpandExtendedBreadcrumbs}>
                <EllipsisIcon />
              </MoreBtn>
              <SlashDivider />
            </>
          )}
        {!showCrumbsVertical &&
          showBreadcrumbs &&
          initialExpandedCrumbParents.map((widget, index) => (
            <BreadCrumbItem
              item={widget}
              key={`${widget.label}_${index}`}
              baseWidgetId={widgetId}
              disableHoverInteraction={disableHoverInteraction}
            />
          ))}
        {!showCrumbsVertical && <ExpandHintIcon data-expand-hint />}
        {showCrumbsVertical && <VerticalExpandHintIcon data-expand-hint />}
        {initialShownCrumbParents.map((widget, index) => (
          <BreadCrumbItem
            item={widget}
            key={`${widget.label}_${index}`}
            baseWidgetId={widgetId}
            disableHoverInteraction={disableHoverInteraction}
          />
        ))}
        {hasInvalidProps && <Icon icon="clarity:exclamation-circle-solid" />}
        {widgetIsHidden && (
          <IconWrapper data-test={`widget-name-${name}-hidden`}>
            <HideIcon />
          </IconWrapper>
        )}
        <NameItem className="t--widget-name">
          <span>{name}</span>
        </NameItem>
      </WidgetNameWrapper>
      {showExtendedBreadcrumbs && (
        <Popper
          targetNode={wrapperRef.current || undefined}
          isOpen={showExtendedBreadcrumbs}
          zIndex={Layers.canvasBreadCrumbPopover}
          placement="bottom-start"
          modifiers={popperModifiers}
        >
          <RemainingCrumbsContainer>
            {remainingCrumbParents.map((widget, index) => (
              <ExtendedBreadCrumbItemComponent
                item={widget}
                key={`${widget.label}_${index}`}
                baseWidgetId={widgetId}
                disableHoverInteraction={disableHoverInteraction}
              />
            ))}
          </RemainingCrumbsContainer>
        </Popper>
      )}
    </>
  );
};

export default WidgetName;
