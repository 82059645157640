import {
  AppStateVar6,
  AppStateVarPersistance6,
  ApplicationScope,
} from "@superblocksteam/shared";

export { AppStateVarPersistance6 as AppStateVarPersistance };

export const getAppLocalStorageKey = (
  appId: string,
  stateVarId: AppStateVar6["id"],
): string => {
  return `app/${appId}:var/${stateVarId}:}`;
};

export const getPageLocalStorageKey = (
  appId: string,
  pageId: string,
  stateVarId: AppStateVar6["id"],
): string => {
  return `app/${appId}/page/${pageId}/:var/${stateVarId}:}`;
};

export const extractScopeAndVariableNameFromKey = (
  key: string,
  appId: string,
  pageId: string,
): null | {
  scope: ApplicationScope;
  stateVarId: string;
} => {
  const regexPageVar = new RegExp(`^app/${appId}/page/${pageId}/:var/.*:}$`);
  const regexAppVar = new RegExp(`^app/${appId}:var/.*:}$`);
  if (regexPageVar.test(key)) {
    return {
      scope: ApplicationScope.PAGE,
      stateVarId: key.split("/").pop()!.slice(0, -2), // remove the :} from the end
    };
  } else if (regexAppVar.test(key)) {
    return {
      scope: ApplicationScope.APP,
      stateVarId: key.split("/").pop()!.slice(0, -2), // remove the :} from the end
    };
  }
  return null;
};

export const DEFAULT_STATE_VAR_META: AppStateVarMetaType = {
  value: null,
};

export { type AppStateVar6 as AppStateVar };

export type AppStateVarScoped = {
  scope: ApplicationScope;
} & AppStateVar6;

export type AppStateVarsAndMetaMap = Record<
  AppStateVar6["id"],
  { stateVar: AppStateVarScoped; stateVarMeta: AppStateVarMetaType | undefined }
>;

// TODO(pbardea): If you add properties, take a look at how they're handled in
// the evaluator (they're parsed as JSON for e.g.)
export type AppStateVarMetaType = {
  value: any;
};

export type AppStateVarWithMetaType = AppStateVarScoped & AppStateVarMetaType;
