import { WidgetTypes } from "@superblocksteam/shared";

export const TABLE_KEYS_TO_TRIM = [
  "tableData",
  "allEdits",
  "filteredTableData",
  "tableDataWithInserts",
  "selectedRow",
  "selectedRows",
  "editedRows",
];

export const TABLE_KEYS_TO_REMOVE = ["primaryColumns"];

export const OMITTED_PROPS = [
  "widgetLastChange",
  "updateWidgetMetaProperty",
  "invalidProps",
  "widgetId",
  "validationMessages",
  "isLoading",
  "internalMaxWidth",
  "dynamicBindingPathList",
  "dynamicTriggerPathList",
  "dragDisabled",
  "dropDisabled",
  "bindingPaths",
  "disablePropertyPane",
  "evaluatedValues",
  "resizeDisabled",
  "isDeletable",
  "isDefaultClickDisabled",
  "docsUrl",
  "appMode",
  "parentId",
  // layouts
  "minWidth",
  "minHeight",
  "maxWidth",
  "maxHeight",
  "height",
  "width",
  "gridColumns",
  "gridRows",
  "left",
  "top",
  // table widget
  "derivedColumns",
  "cachedColumnSettings",
  // page/modal/slideout widget
  "routes",
  "sections",
  // section widget
  "columns",
  "stackColumnsAt",
];

export const DIMENSIONLESS_PROPS = [
  "padding",
  "margin",
  "borderRadius",

  "border.bottom.width",
  "border.left.width",
  "border.right.width",
  "border.top.width",

  // search props for table
  "searchProps.border.bottom.width",
  "searchProps.border.left.width",
  "searchProps.border.right.width",
  "searchProps.border.top.width",
  "searchProps.borderRadius",
] as const;

export const IMMUTABLE_STATE_PROPERTIES_BY_WIDGET_TYPE: Partial<
  Record<WidgetTypes, string[]>
> = {
  [WidgetTypes.BUTTON_WIDGET]: ["isLoading"],
  [WidgetTypes.INPUT_WIDGET]: [
    "isValid",
    "value",
    "isoCurrencyCode",
    "isTouched",
  ],
  [WidgetTypes.TABLE_WIDGET]: [
    "tableDataWithInserts",
    "filteredTableData",
    "editedRows",
    "allEdits",
    "selectedRow",
    "selectedRows",
  ],
  [WidgetTypes.DATE_PICKER_WIDGET]: [
    "value",
    "isValid",
    "outputDateLocal",
    "outputDateUtc",
  ],
  [WidgetTypes.DROP_DOWN_WIDGET]: [
    "isValid",
    "selectedOption",
    "selectedOptionArr",
    "value",
    "selectedIndex",
    "selectedIndexArr",
    "searchText",
  ],
  [WidgetTypes.GRID_WIDGET]: [
    "selectedCell",
    "selectedCellIndex",
    "searchText",
    "cells",
  ],
  [WidgetTypes.CHECKBOX_WIDGET]: ["value", "isValid"],
  [WidgetTypes.SWITCH_WIDGET]: ["value", "isValid"],
  [WidgetTypes.RADIO_GROUP_WIDGET]: ["value", "isValid", "selectedOption"],
  [WidgetTypes.FILE_PICKER_WIDGET]: ["isValid", "files"],
  [WidgetTypes.MAP_WIDGET]: [],
  [WidgetTypes.FORM_WIDGET]: ["isValid", "data"],
  [WidgetTypes.TABS_WIDGET]: ["selectedTab"],
  [WidgetTypes.MODAL_WIDGET]: ["isOpen", "isVisible"],
  [WidgetTypes.SLIDEOUT_WIDGET]: ["isOpen", "isVisible"],
  [WidgetTypes.CHAT_WIDGET]: [
    "lastMessage",
    "userMessageText",
    "userMessageRichText",
    "userMessageImages",
  ],
  [WidgetTypes.RICH_TEXT_EDITOR_WIDGET]: ["isValid"],
  [WidgetTypes.CODE_WIDGET]: ["isValid", "parsedValue"],
  [WidgetTypes.VIDEO_WIDGET]: ["playState"],
  [WidgetTypes.CHART_WIDGET]: ["selectedData"],
};
// Add other widget types as needed
