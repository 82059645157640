import { Tooltip } from "antd";
import React, { useRef } from "react";
import { useMemo } from "react";
import { APP_MODE } from "legacy/reducers/types";
import { getAppMode } from "legacy/selectors/applicationSelectors";
import { getSelectedWidget } from "legacy/selectors/sagaSelectors";
import { selectGeneratedTheme } from "legacy/selectors/themeSelectors";
import { useAppSelector } from "store/helpers";
import { colors } from "styles/colors";
import { styleAsClass } from "styles/styleAsClass";
import { useErrorMessages } from "./ErrorUtils";

export const INLINE_ERROR_MESSAGE_HEIGHT = 18; // px
export const GAP_BETWEEN_INPUT_AND_INLINE_ERROR_MESSAGE = 2; // px

export interface Props {
  widgetId: string;
  isInvalid?: boolean;
  errorMessages?: string | string[];
  isFocused?: boolean;
  isTouched?: boolean;
  isFitContentWidth?: boolean;
  isFitContentHeight?: boolean;
  style?: React.CSSProperties;
  showError?: boolean;
}

const ErrorWrapper = styleAsClass`
  font-size: 12px;
  display: flex;
  align-items: center;
  min-height: ${String(INLINE_ERROR_MESSAGE_HEIGHT)}px;
  overflow: hidden;
  > span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    max-width: 100%;
    min-width: 0;
  }

  .inline-error-error-message {
    color: var(--error-color);
  }

  .inline-error-placeholder-message {
    color: ${colors.GREY_200};
    opacity: 1;
  }

  &.fit-content-width {
    position: relative;
    .inline-error-error-message, .inline-error-placeholder-message {
      position: absolute;
      left: 0;
      right: 0;
    }
  }

  &.hidden {
    display: none;
  }

  &[data-theme-mode="DARK"] {
    .inline-error-placeholder-message {
      color: ${colors.GREY_500};
    }
  }
`;

export const ErrorInlineMessage = (props: Props) => {
  const theme = useAppSelector(selectGeneratedTheme);
  const appMode = useAppSelector(getAppMode);
  const selectedWidget: { widgetId: string } | undefined =
    useAppSelector(getSelectedWidget);
  const errorColor = theme.colors.danger;
  const errorElement = useRef<HTMLSpanElement>(null);

  const errorMessages = useErrorMessages(props.errorMessages);

  const styleForVariables = useMemo(() => {
    return {
      "--error-color": errorColor,
      ...props.style,
    } as Record<string, string>;
  }, [errorColor, props.style]);

  const errorMessage = Array.isArray(errorMessages)
    ? errorMessages.join(", ")
    : errorMessages;

  const isWidgetSelected =
    appMode === APP_MODE.EDIT && selectedWidget?.widgetId === props.widgetId;

  const showError =
    errorMessages &&
    errorMessages.length > 0 &&
    (props.showError ||
      (props.isInvalid && props.isTouched && !props.isFocused));

  const showPlaceholderError =
    appMode === APP_MODE.EDIT &&
    isWidgetSelected &&
    !(errorMessages && props.isInvalid);

  const isMessageCropped =
    errorElement.current &&
    errorElement.current.scrollWidth > errorElement.current.offsetWidth;

  const element = (
    <div
      className={`
        ${ErrorWrapper} 
        ${props.isFitContentWidth ? "fit-content-width" : ""} 
        ${props.isFitContentHeight && !props.showError ? "hidden" : ""}
      `}
      data-theme-mode={theme.mode}
      style={styleForVariables}
    >
      {showError && (
        <span ref={errorElement} className="inline-error-error-message">
          {errorMessage}
        </span>
      )}
      {!showError && showPlaceholderError && (
        <span className="inline-error-placeholder-message">
          Error message will be displayed here
        </span>
      )}
      {!showError && !showPlaceholderError && <span />}
    </div>
  );

  return isMessageCropped ? (
    <Tooltip placement="right" title={errorMessage}>
      {element}
    </Tooltip>
  ) : (
    element
  );
};

/**
 * How to use:
 * Wrap the input (Input, Dropdown, etc.) with InlineErrorMessage.
 * Apply this style to the combined wrapper.
 * Style vs Class: style is necessary when the component uses ControlGroup to override Blueprint's default styling.
 */
export const FormInputWithErrorWrapperStyle: React.CSSProperties = {
  display: "flex",
  flexFlow: "column",
  gap: `${GAP_BETWEEN_INPUT_AND_INLINE_ERROR_MESSAGE}px`,
  flex: 1,
  minWidth: 0,
  justifyContent: "space-between",
};

/**
 * Use when the input grows in height to fill the parent.
 *
 * How to use:
 * Wrap the input (Input, Dropdown, etc).
 * Apply this style to the input wrapper.
 *
 * You might need to set the input to `height: 100%;`
 */
export const FormInputWithHeightGrowStyle: React.CSSProperties = {
  minWidth: 0,
  flexGrow: 1,
  height: "100%",
  display: "flex",
};
