import equal from "@superblocksteam/fast-deep-equal";
import { get } from "lodash";
import {
  PropsPanelCategory,
  ThemeValueFunction,
  type PropertyPaneCategoryConfig,
} from "legacy/constants/PropertyControlConstants";
import { VerticalAlign } from "legacy/constants/WidgetConstants";
import { VALIDATION_TYPES } from "legacy/constants/WidgetValidation";
import { PanelCategory } from "legacy/pages/Editor/PropertyPane/propertyPaneCategoryUtils";
import { EMPTY_RADIUS } from "legacy/themes/constants";
import { createPerSideBorder } from "pages/Editors/AppBuilder/Sidebar/BorderEditor";
import { createPerCornerBorderRadius } from "pages/Editors/AppBuilder/Sidebar/BorderRadiusEditor";
import { ANIMATE_LOADING_PROPERTY_CONTROL_HELP_TEXT } from "pages/Editors/AppBuilder/Sidebar/PropertyControlCommons";
import { sizeProperties, visibleProperties } from "../basePropertySections";
import { getPopoverConfig } from "../eventHandlerPanel";
import {
  backgroundColorProperty,
  borderProperty,
  borderRadiusProperty,
  textStyleCombinedProperty,
  typographyProperties,
} from "../styleProperties";
import {
  CalloutType,
  CTA_THEME_COLOR_NAMES,
  DEFAULT_CALLOUT_WIDGET_CTA_TEXT_STYLE_VARIANT,
  DEFAULT_CALLOUT_WIDGET_DESC_TEXT_STYLE_VARIANT,
  DEFAULT_CALLOUT_WIDGET_TITLE_TEXT_STYLE_VARIANT,
} from "./Constants";
import { CalloutWidgetProps } from "./types";
import { getThemeColorByCalloutType } from "./utils";

/***
 * - Users can override the default color for CTA
 * - if override, that color will be used for every callout type
 * - if it's not override, the color will change according to the callout type
 */
const getCtaThemeValue: ThemeValueFunction<CalloutWidgetProps> = ({
  props,
  propertyName: dottedPathToTextStyle,
}) => {
  const colorNameFromTheme = CTA_THEME_COLOR_NAMES[props.calloutType];

  const fullPathToPropertyName = `${dottedPathToTextStyle}.textColor.default`;
  const color = get(props, fullPathToPropertyName, colorNameFromTheme);

  return {
    value: color,
    treatAsNull: false,
  };
};

const config: PropertyPaneCategoryConfig<CalloutWidgetProps>[] = [
  PanelCategory(PropsPanelCategory.Content, [
    {
      propertyName: "title",
      label: "Title",
      helpText: "The title of the callout",
      placeholderText: "Enter title",
      controlType: "INPUT_TEXT",
      isBindProperty: true,
      isTriggerProperty: false,
      visibility: "SHOW_NAME",
      isRemovable: true,
      defaultValue: "Important content",
      propertyCategory: PropsPanelCategory.Content,
    },
    {
      propertyName: "description",
      label: "Description",
      helpText: "The description of the callout",
      placeholderText: "Enter description",
      controlType: "INPUT_TEXT",
      isBindProperty: true,
      isTriggerProperty: false,
      visibility: "SHOW_NAME",
      isRemovable: true,
      defaultValue:
        "Here are some details about the important content, including some <b>bold</b> and <i>italicized</i> content",
      propertyCategory: PropsPanelCategory.Content,
    },
    {
      propertyName: "ctaText",
      label: "Call to action text",
      helpText:
        "The call to action text (CTA) displayed on the callout. When clicked, fires the onCtaClick event",
      placeholderText: "Enter CTA text",
      controlType: "INPUT_TEXT",
      isBindProperty: true,
      isTriggerProperty: false,
      visibility: "SHOW_NAME",
      isRemovable: true,
      defaultValue: "Click here",
      propertyCategory: PropsPanelCategory.Content,
    },
  ]),
  PanelCategory(PropsPanelCategory.Appearance, [
    {
      helpText: "The type of callout",
      propertyName: "calloutType",
      label: "Callout type",
      controlType: "DROP_DOWN",
      defaultValue: CalloutType.INFO,
      options: [
        { label: "Info", value: CalloutType.INFO },
        { label: "Success", value: CalloutType.SUCCESS },
        { label: "Warning", value: CalloutType.WARNING },
        { label: "Danger", value: CalloutType.DANGER },
      ],
      isBindProperty: true,
      isTriggerProperty: false,
      isJSConvertible: true,
      canExpandEditor: true,
      customJSControl: "INPUT_JS_EXPR",
      propertyCategory: PropsPanelCategory.Appearance,
    },
    ...typographyProperties({
      propertyNameForHumans: "Title",
      textStyleParentDottedPath: "titleProps",
      defaultVariant: DEFAULT_CALLOUT_WIDGET_TITLE_TEXT_STYLE_VARIANT,
      // We don't hide this prop control even if there's no title - as it can be used to change the icon's size
      // The color aspect of it is ignored.
    }),
    ...typographyProperties({
      propertyNameForHumans: "Description",
      hiddenIfPropertyNameIsNullOrFalse: "description",
      textStyleParentDottedPath: "descriptionProps",
      defaultVariant: DEFAULT_CALLOUT_WIDGET_DESC_TEXT_STYLE_VARIANT,
    }),
    textStyleCombinedProperty({
      label: "Call to action style",
      textStyleParentDottedPath: "ctaProps",
      defaultValueFn: {
        variant: () => DEFAULT_CALLOUT_WIDGET_CTA_TEXT_STYLE_VARIANT,
      },
      themeValue: {
        "textColor.default": getCtaThemeValue,
      },
      additionalUserSelectableVariants: ["buttonLabel"],
      hidden: (props: CalloutWidgetProps) => !props.ctaText,
      defaultThemeVariant: DEFAULT_CALLOUT_WIDGET_CTA_TEXT_STYLE_VARIANT,
    }),

    {
      // Vertical alignment other than center doesn't look good at all.
      // We are keeping it for legacy reasons, only shown on those callouts that are not vertically centered.
      propertyName: "verticalAlign",
      helpText: "Sets the vertical alignment relative to the container",
      label: "Vertical align",
      controlType: "RADIO_BUTTON_GROUP",
      defaultValue: VerticalAlign.CENTER,
      options: [
        {
          icon: "VERTICAL_TOP",
          value: VerticalAlign.TOP,
        },
        {
          icon: "VERTICAL_CENTER",
          value: VerticalAlign.CENTER,
        },
        {
          icon: "VERTICAL_BOTTOM",
          value: VerticalAlign.BOTTOM,
        },
      ],
      isJSConvertible: true,
      isBindProperty: true,
      isTriggerProperty: false,
      canExpandEditor: true,
      customJSControl: "INPUT_JS_EXPR",
      hidden: (props) => props.verticalAlign === VerticalAlign.CENTER,
      propertyCategory: PropsPanelCategory.Appearance,
    },

    {
      propertyName: "animateLoading",
      label: "Loading animation",
      helpText: ANIMATE_LOADING_PROPERTY_CONTROL_HELP_TEXT,
      controlType: "SWITCH",
      defaultValue: true,
      isJSConvertible: true,
      isBindProperty: true,
      isTriggerProperty: false,
      canExpandEditor: true,
      customJSControl: "INPUT_JS_EXPR",
      validation: VALIDATION_TYPES.BOOLEAN,
      propertyCategory: PropsPanelCategory.Appearance,
    },

    backgroundColorProperty({
      themeValue: ({ props }) => {
        const color = getThemeColorByCalloutType(props.calloutType);

        return {
          value: color,
          treatAsNull: false,
        };
      },
    }),

    borderProperty({
      defaultValue: createPerSideBorder({
        color: "{{ theme.colors.neutral100 }}",
        width: 1,
      }),
    }),
    borderRadiusProperty({
      defaultValue: EMPTY_RADIUS,
      themeValue: ({ theme }) => {
        const themeDefault = createPerCornerBorderRadius(theme.borderRadius);

        return {
          value: themeDefault,
          treatAsNull:
            themeDefault == null || equal(themeDefault, EMPTY_RADIUS),
        };
      },
    }),
  ]),
  PanelCategory(PropsPanelCategory.Layout, [
    ...sizeProperties({ heightSupportsFitContent: true }),
    ...visibleProperties({ useJsExpr: true }),
  ]),
  PanelCategory(PropsPanelCategory.Interaction, [
    {
      propertyName: "isDismissible",
      label: "Dismissible",
      helpText:
        "Controls whether the callout can be dismissed. When enabled, clicking the X on the callout will toggle its visibility to false",
      controlType: "SWITCH",
      defaultValue: false,
      isJSConvertible: true,
      isBindProperty: true,
      isTriggerProperty: false,
      canExpandEditor: true,
      customJSControl: "INPUT_JS_EXPR",
      validation: VALIDATION_TYPES.BOOLEAN,
    },
  ]),
  PanelCategory(PropsPanelCategory.EventHandlers, [
    getPopoverConfig<CalloutWidgetProps>(
      "onCtaClick",
      "If the callout includes a call to action (CTA), this event fires when it is clicked",
    ),
    getPopoverConfig<CalloutWidgetProps>(
      "onDismiss",
      "If the callout is dismissible, this event fires when the callout is dismissed",
    ),
  ]),
];
export default config;
