import { VirtualElement } from "@popperjs/core";
import { Tooltip } from "antd";
import React, { useCallback, useEffect, useMemo } from "react";
import { useRef, useState } from "react";
import { DragPreviewImage } from "react-dnd";
import { useHotkeys } from "react-hotkeys-hook";
import styled from "styled-components";
import { ReactComponent as PlusIcon } from "assets/icons/common/plus-circle-shaded.svg";
import { ReactComponent as UIBlockIcon } from "assets/icons/common/ui-block.svg";
import { ReactComponent as SettingsIcon } from "assets/icons/sidebar/settings-icon.svg";
import Popper, { PopperProps } from "components/ui/Popper";
import {
  SearchableListWithSections,
  SearchListItem,
  CustomRenderProps,
  ItemsType,
  SectionProps,
  SectionTitle,
} from "components/ui/SearchableListWithSections";
import { TemporaryCheckmark } from "components/ui/TemporaryCheckmark";
import { useFeatureFlag } from "hooks/ui/useFeatureFlag";
import { useInsertWidgetByType } from "hooks/ui/useInsertWidgetByType";
import { PasteInsertionIndexes } from "hooks/ui/usePasteWidget";
import { usePointerDownOutside } from "hooks/ui/usePointerDownOutside";
import { updateApplicationSidebarKey } from "legacy/actions/editorPreferencesActions";
import blankImage from "legacy/assets/images/blank.png";
import { ReduxActionTypes } from "legacy/constants/ReduxActionConstants";
import {
  getEditorReadOnly,
  getIsLeftPanePinned,
} from "legacy/selectors/editorSelectors";
import { getSelectedWidgets } from "legacy/selectors/sagaSelectors";
import { useAppDispatch, useAppSelector } from "store/helpers";
import { getAvailableWidgets } from "store/slices/availableWidgets/selectors";
import { Flag } from "store/slices/featureFlags/models/Flags";
import {
  setInsertionContext,
  showUiBlocksModal,
} from "store/slices/uiBlocks/slice";
import { colors } from "styles/colors";
import { styleAsClass } from "styles/styleAsClass";
import logger from "utils/logger";
import { getInsertionIndex } from "utils/paste";
import { SideBarKeys } from "../../constants";
import { PlusButton } from "../Entity/HeaderButtons";
import { getWidgetIcon } from "../ExplorerIcons";
import { useDragWidgetIntoCanvas } from "./useDragWidgetIntoCanvas";
import type {
  WidgetCardProps,
  WidgetTypes,
} from "legacy/constants/WidgetConstants";

const DraggableSearchListItem = styled(SearchListItem)`
  cursor: grab;
  user-select: none;

  &[data-focused="true"] {
    box-shadow:
      0px 0px 1px 0px #22272f52,
      0px 12px 32px -8px #22272f29,
      0px 1px 3px 0px #22272f1f;
    background: ${colors.WHITE};
  }
`;

const LoadingPositioner = styled.div`
  position: absolute;
  right: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
`;

// to add some feedback that the item was clicked/actioned
const SearchListItemSuccess = (props: {
  value: string;
  lastCreatedItem?: [string | undefined, number];
}) => {
  const [createdValue, createdTime = null] = props.lastCreatedItem ?? [];
  const isActive = createdValue === props.value;
  return (
    <LoadingPositioner>
      <TemporaryCheckmark isActive={isActive} activeTimestamp={createdTime} />
    </LoadingPositioner>
  );
};

// A custom implementation just to use enable Dragging a widget into the canvas
const CustomRenderedItemWithDrag = (
  props: CustomRenderProps & {
    cardProps: WidgetCardProps;
    onDragStart?: () => void;
  },
) => {
  const { drag, preview } = useDragWidgetIntoCanvas(props.cardProps, {
    onDragStart: props.onDragStart,
  });

  return (
    <>
      <DragPreviewImage connect={preview} src={blankImage} />
      <DraggableSearchListItem
        ref={drag}
        data-focused={props.focusedIndex === props.index}
        className={props.className}
        onMouseEnter={props.onMouseEnter}
        onClick={props.onClick}
        data-test={props.dataTest}
        keyboardFocusActive={props.keyboardFocusActive}
      >
        {getWidgetIcon(props.cardProps.type)}
        {props.cardProps.widgetCardName}
        <SearchListItemSuccess
          value={props.cardProps.type}
          lastCreatedItem={props.lastCreatedItem}
        />
      </DraggableSearchListItem>
    </>
  );
};

const CustomRenderedItemWithoutDrag = (
  props: CustomRenderProps & { cardProps: WidgetCardProps },
) => {
  return (
    <SearchListItem
      data-focused={props.focusedIndex === props.index}
      className={props.className}
      onMouseEnter={props.onMouseEnter}
      onClick={props.onClick}
      data-test={props.dataTest}
      keyboardFocusActive={props.keyboardFocusActive}
    >
      {getWidgetIcon(props.cardProps.type)}
      {props.cardProps.widgetCardName}
      <SearchListItemSuccess
        value={props.cardProps.type}
        lastCreatedItem={props.lastCreatedItem}
      />
    </SearchListItem>
  );
};

const RenderBelowListClassName = styleAsClass`
  list-style: none;
  margin: 4px 0;
`;

const CCHeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 10px;
  margin: 0 4px;

  svg {
    cursor: pointer;
  }
`;

const CCFooterWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 8px 10px;
  margin: 4px;
  color: ${colors.ACCENT_BLUE_500};
  cursor: pointer;
  gap: 6px;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  border-radius: 4px;

  &:hover {
    background-color: ${colors.ACCENT_BLUE_500_50};
  }
`;

export const AddWidgetList = ({
  allowDrag,
  mousePosition,
  insertionIndexes,
  onDragStart,
  onDragEnd,
  onOpenUIBlocksModal,
  onInsertWidget,
  onOpenCustomComponents,
  hideUIBlocks,
  originatingWidgetId,
}: {
  allowDrag?: boolean;
  mousePosition?: { x: number; y: number } | null;
  onDragStart?: () => void;
  onDragEnd?: () => void;
  onOpenUIBlocksModal?: () => void;
  onOpenCustomComponents?: () => void;
  hideUIBlocks?: boolean;
  originatingWidgetId?: string;
  onInsertWidget?: (widgetType: WidgetTypes) => void;
  insertionIndexes?: PasteInsertionIndexes;
}) => {
  const widgets = useAppSelector(getAvailableWidgets);
  const isUIBlocksEnabled = useFeatureFlag(Flag.UI_BLOCKS_FOR_USERS);
  const isDraggingNewWidget = useAppSelector(
    (state) => state.legacy.ui.widgetDragResize.isDraggingNewWidget,
  );
  const [isWaitingForDragEnd, setIsWaitingForDragEnd] = useState(false);
  const dispatch = useAppDispatch();

  const internalDragStart = useCallback(() => {
    onDragStart?.();
    setIsWaitingForDragEnd(true);
  }, [onDragStart]);

  const sections = useMemo(() => {
    const regularWidgets: ItemsType[] = [];
    const customComponents: ItemsType[] = [];

    widgets.forEach((widgetGroup) => {
      widgetGroup.widgetCards.forEach((widgetCardProps) => {
        const itemRender = allowDrag
          ? (props: CustomRenderProps) => (
              <CustomRenderedItemWithDrag
                {...props}
                cardProps={widgetCardProps}
                onDragStart={internalDragStart}
              />
            )
          : (props: CustomRenderProps) => (
              <CustomRenderedItemWithoutDrag
                {...props}
                cardProps={widgetCardProps}
              />
            );
        (widgetGroup.widgetCategory === "CUSTOM"
          ? customComponents
          : regularWidgets
        ).push({
          customRender: itemRender,
          value: widgetCardProps.type,
          label: widgetCardProps.widgetCardName,
        });
      });
    });

    const renderCustomComponentsHeader = () => (
      <CCHeaderWrapper>
        <SectionTitle>Custom components</SectionTitle>
        <SettingsIcon
          onClick={() => {
            dispatch(
              updateApplicationSidebarKey({
                selectedKey: SideBarKeys.CUSTOM_COMPONENTS,
              }),
            );
            onOpenCustomComponents?.();
          }}
          width={16}
          height={16}
        />
      </CCHeaderWrapper>
    );

    const renderCustomComponentsFooter = () => (
      <CCFooterWrapper
        onClick={() => {
          dispatch({
            type: ReduxActionTypes.SET_SHOW_CUSTOM_COMPONENTS_MODAL,
            payload: true,
          });
        }}
      >
        <PlusIcon />
        Add custom component
      </CCFooterWrapper>
    );

    return [
      {
        name: "Components",
        items: regularWidgets,
      } satisfies SectionProps,
      {
        name: "Custom components",
        items: customComponents,
        header: {
          render: renderCustomComponentsHeader,
        },
        footer: {
          render: renderCustomComponentsFooter,
          height: 32,
        },
      } satisfies SectionProps,
    ];
  }, [widgets, internalDragStart, allowDrag, dispatch, onOpenCustomComponents]);

  const selectedWidgets = useAppSelector(getSelectedWidgets);
  const firstSelectedWidget = selectedWidgets?.[0];

  useEffect(() => {
    if (!isDraggingNewWidget && isWaitingForDragEnd) {
      onDragEnd?.();
      setIsWaitingForDragEnd(false);
    }
  }, [isDraggingNewWidget, isWaitingForDragEnd, onDragEnd]);

  const insertWidgetByType = useInsertWidgetByType();
  const handleInsert = useCallback(
    (item: string) => {
      insertWidgetByType({
        targetWidgetId: firstSelectedWidget?.widgetId,
        widgetType: item as WidgetTypes,
        mousePosition: mousePosition ?? undefined,
        insertionIndex: getInsertionIndex(
          item as WidgetTypes,
          insertionIndexes,
        ),
      });
      onInsertWidget?.(item as WidgetTypes);
    },
    [
      insertWidgetByType,
      firstSelectedWidget,
      mousePosition,
      onInsertWidget,
      insertionIndexes,
    ],
  );

  const handleOpenUiBlocksModal = useCallback(() => {
    onOpenUIBlocksModal?.();
    if (firstSelectedWidget) {
      dispatch(
        setInsertionContext({
          mousePosition: mousePosition ?? undefined,
          insertionTargetId:
            originatingWidgetId ?? firstSelectedWidget?.widgetId,
        }),
      );
    }

    dispatch(showUiBlocksModal());
    logger.info("UI Blocks: Opened modal from component menu");
  }, [
    onOpenUIBlocksModal,
    dispatch,
    firstSelectedWidget,
    originatingWidgetId,
    mousePosition,
  ]);

  return (
    <SearchableListWithSections
      sections={sections}
      onItemSelect={handleInsert}
      id="add-widget-menu"
      noResultsMessage="No matching components"
      refocusOnEnter={true}
      renderBelowList={
        !hideUIBlocks && isUIBlocksEnabled ? (
          <div className={RenderBelowListClassName}>
            <SearchListItem
              data-intent="primary"
              onClick={handleOpenUiBlocksModal}
            >
              <UIBlockIcon width={16} height={16} />
              Browse UI Templates
            </SearchListItem>
          </div>
        ) : undefined
      }
    />
  );
};

// exported separately to allow for customizing the open/close behavior of this popup
export const AddWidgetPopup = (props: {
  isOpen: boolean;
  targetNode: Element | VirtualElement | null;
  onClose: () => void;
  onDragStart?: () => void;
  onDragEnd?: () => void;
  onInsertWidget?: (widgetType: WidgetTypes) => void;
  onOpenUIBlocksModal?: () => void;
  onOpenCustomComponents?: () => void;
  popperProps?: Partial<PopperProps>;
  originatingWidgetId?: string; // if this is initiated from a specific widget
  allowDrag?: boolean;
  hideUIBlocks?: boolean;
  mousePosition?: { x: number; y: number } | null;
  insertionIndexes?: PasteInsertionIndexes;
  clickOutsideWrapperSelectors?: string[];
}) => {
  const {
    isOpen,
    onClose,
    onDragStart,
    popperProps,
    targetNode,
    onDragEnd,
    onInsertWidget,
    originatingWidgetId,
    allowDrag,
    hideUIBlocks,
    mousePosition,
    insertionIndexes,
    clickOutsideWrapperSelectors,
  } = props;

  const handleClose = useCallback(() => {
    // focus the iframe after closing the menu, so that the user can interact with the canvas immediately with their
    // keyboard, if they want
    if (isOpen && !document.activeElement?.matches('[data-test="sb-iframe"]')) {
      document.querySelector<HTMLElement>('[data-test="sb-iframe"]')?.focus();
    }
    onClose();
  }, [onClose, isOpen]);

  usePointerDownOutside({
    onClickOutside: handleClose,
    wrapperIds: ["add-widget-menu", "add-widget-button"],
    wrapperSelectors: clickOutsideWrapperSelectors || [],
  });

  useHotkeys(
    "Esc",
    () => {
      handleClose();
    },
    { enabled: isOpen, enableOnTags: ["INPUT"] },
    [isOpen],
  );

  return (
    <>
      {isOpen && targetNode && (
        <Popper
          targetNode={targetNode}
          zIndex={100}
          isOpen={true}
          placement={popperProps?.placement ?? "top-end"}
          {...(popperProps ?? {})}
        >
          <AddWidgetList
            allowDrag={allowDrag}
            onDragStart={onDragStart}
            onDragEnd={onDragEnd}
            onOpenUIBlocksModal={onClose}
            onInsertWidget={onInsertWidget}
            hideUIBlocks={hideUIBlocks}
            originatingWidgetId={originatingWidgetId}
            mousePosition={mousePosition}
            insertionIndexes={insertionIndexes}
            onOpenCustomComponents={onClose}
          />
        </Popper>
      )}
    </>
  );
};

const AddWidgetPopupWithButton = () => {
  const dispatch = useAppDispatch();
  const readOnly = useAppSelector(getEditorReadOnly);
  const isLeftPanePinned = useAppSelector(getIsLeftPanePinned);
  const [isListOpen, setIsListOpen] = useState(false);

  const buttonRef = useRef<HTMLButtonElement>(null);

  const handleOpenList = useCallback(() => {
    setIsListOpen(true);
  }, [setIsListOpen]);

  const handleCloseList = useCallback(() => {
    setIsListOpen(false);
  }, [setIsListOpen]);

  const handleDragStart = useCallback(() => {
    setIsListOpen(false);
    if (!isLeftPanePinned) {
      dispatch(updateApplicationSidebarKey({ selectedKey: undefined }));
    }
  }, [setIsListOpen, dispatch, isLeftPanePinned]);

  if (readOnly) {
    return null;
  }

  return (
    <div>
      <Tooltip
        title="Add a new component"
        key="add-component-button"
        mouseEnterDelay={0.5}
        mouseLeaveDelay={0}
      >
        <PlusButton ref={buttonRef} onClick={handleOpenList} />
      </Tooltip>
      <AddWidgetPopup
        isOpen={isListOpen}
        targetNode={buttonRef.current}
        onClose={handleCloseList}
        onDragStart={handleDragStart}
        allowDrag={true}
      />
    </div>
  );
};

export default AddWidgetPopupWithButton;
