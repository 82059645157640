import { Padding } from "@superblocksteam/shared";
import { CSSProperties } from "react";
import { css } from "styled-components";
import { getVariableName } from "./StylesUtils";

export const generatePaddingCss = (padding?: Padding) => css`
  padding-left: ${padding?.left?.value ?? 0}${padding?.left?.mode ?? "px"};
  padding-right: ${padding?.right?.value ?? 0}${padding?.right?.mode ?? "px"};
  padding-top: ${padding?.top?.value ?? 0}${padding?.top?.mode ?? "px"};
  padding-bottom: ${padding?.bottom?.value ?? 0}${padding?.bottom?.mode ?? "px"};
`;

export const generatePaddingStyleObject = (
  padding?: Padding,
): CSSProperties => ({
  paddingLeft: `${padding?.left?.value ?? 0}${padding?.left?.mode ?? "px"}`,
  paddingRight: `${padding?.right?.value ?? 0}${padding?.right?.mode ?? "px"}`,
  paddingTop: `${padding?.top?.value ?? 0}${padding?.top?.mode ?? "px"}`,
  paddingBottom: `${padding?.bottom?.value ?? 0}${
    padding?.bottom?.mode ?? "px"
  }`,
});

const INPUT_PADDING_TOP = "padding-top";
const INPUT_PADDING_RIGHT = "padding-right";
const INPUT_PADDING_BOTTOM = "padding-bottom";
const INPUT_PADDING_LEFT = "padding-left";

export const generatePaddingVariableDeclarationCss = (prefix?: string) => `
  padding-left: var(${getVariableName(INPUT_PADDING_LEFT, prefix)});
  padding-right: var(${getVariableName(INPUT_PADDING_RIGHT, prefix)});
  padding-top: var(${getVariableName(INPUT_PADDING_TOP, prefix)});
  padding-bottom: var(${getVariableName(INPUT_PADDING_BOTTOM, prefix)});
`;

export const generatePaddingVariableAssignmentStyleObject = (
  padding?: Padding,
  prefix?: string,
): { [key: `--${string}`]: string } => ({
  [getVariableName(INPUT_PADDING_TOP, prefix)]: `${padding?.top?.value ?? 0}${
    padding?.top?.mode ?? "px"
  }`,
  [getVariableName(INPUT_PADDING_RIGHT, prefix)]:
    `${padding?.right?.value ?? 0}${padding?.right?.mode ?? "px"}`,
  [getVariableName(INPUT_PADDING_BOTTOM, prefix)]:
    `${padding?.bottom?.value ?? 0}${padding?.bottom?.mode ?? "px"}`,
  [getVariableName(INPUT_PADDING_LEFT, prefix)]: `${padding?.left?.value ?? 0}${
    padding?.left?.mode ?? "px"
  }`,
});
