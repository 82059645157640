import { ApplicationScope, WidgetTypes } from "@superblocksteam/shared";
import React from "react";
import {
  getDerivedPropertiesWithErrors,
  getErrorMessagesList,
} from "legacy/components/editorComponents/ErrorUtils";
import { EventType } from "legacy/constants/ActionConstants";
import { type PropertyPaneConfig } from "legacy/constants/PropertyControlConstants";
import { WidgetType } from "legacy/constants/WidgetConstants";
import { VALIDATION_TYPES } from "legacy/constants/WidgetValidation";
import {
  WidgetPropertyValidationType,
  BASE_WIDGET_VALIDATION,
} from "legacy/constants/WidgetValidation";
import { createRunEventHandlersPayloadOptional } from "legacy/utils/actions";
import BaseWidget, { WidgetState } from "../BaseWidget";
import withMeta from "../withMeta";
import { RadioGroupComponentWithLayoutManaged } from "./RadioGroupComponent";
import RadioGroupPropertyCategories from "./RadioGroupPropertyCategories";
import { RadioGroupWidgetProps } from "./types";

class RadioGroupWidget extends BaseWidget<RadioGroupWidgetProps, WidgetState> {
  static getNewPropertyPaneConfig():
    | PropertyPaneConfig<RadioGroupWidgetProps>[]
    | undefined {
    return RadioGroupPropertyCategories;
  }

  static getPropertyPaneConfig(): PropertyPaneConfig<RadioGroupWidgetProps>[] {
    throw new Error("Deprecated config should not be called");
  }

  static getPropertyValidationMap(): WidgetPropertyValidationType {
    return {
      ...BASE_WIDGET_VALIDATION,
      label: VALIDATION_TYPES.TEXT,
      options: VALIDATION_TYPES.OPTIONS_DATA,
      selectedOptionValue: VALIDATION_TYPES.TEXT,
      defaultOptionValue: VALIDATION_TYPES.TEXT_EMPTY_NULL,
      isRequired: VALIDATION_TYPES.BOOLEAN,
      isDisabled: VALIDATION_TYPES.BOOLEAN,
      customValidationRule: VALIDATION_TYPES.TEXT,
      customErrorMessage: VALIDATION_TYPES.TEXT,
    };
  }
  static getDerivedPropertiesMap() {
    return getDerivedPropertiesWithErrors({
      validationErrors: /*javascript*/ `{{
        (() => {
          let errors = {};
          if (this.isRequired && !this.value) {
            errors.isRequired = "Required";
          }
          
          if (
            this.customValidationRule &&
            this.customValidationRule.length &&
            this.customValidationRule === "false"
          ) {
            errors.customError = this.customErrorMessage || "Invalid";
          }

          return errors;
        })()
      }}`,
      selectedOption:
        "{{_.find(this.options, { value: this.selectedOptionValue })}}",
      value: `{{this.selectedOptionValue}}`,
    });
  }
  static getDefaultPropertiesMap(): Record<string, string> {
    return {
      selectedOptionValue: "defaultOptionValue",
    };
  }

  static getMetaPropertiesMap(): Record<string, any> {
    return {
      selectedOptionValue: undefined,
      isTouched: false,
    };
  }
  getPageView() {
    return (
      <RadioGroupComponentWithLayoutManaged
        {...this.props}
        onRadioSelectionChange={this.onRadioSelectionChange}
        errorMessages={getErrorMessagesList(
          this.props.validationErrors,
          this.props.showError,
        )}
      />
    );
  }

  onRadioSelectionChange = (updatedValue: string) => {
    if (!this.props.isTouched) {
      this.props.updateWidgetMetaProperty("isTouched", true);
    }

    this.props.updateWidgetMetaProperty(
      "selectedOptionValue",
      updatedValue,
      createRunEventHandlersPayloadOptional({
        steps: this.props.onSelectionChange,
        currentScope: ApplicationScope.PAGE,
        type: EventType.ON_OPTION_CHANGE,
        entityName: this.props.widgetName,
      }),
    );
  };

  getWidgetType(): WidgetType {
    return WidgetTypes.RADIO_GROUP_WIDGET;
  }
}

export default RadioGroupWidget;
export const ConnectedRadioGroupWidget = withMeta(RadioGroupWidget);
