import { get } from "lodash";
import {
  PropertyPaneCategoryConfig,
  PropsPanelCategory,
} from "legacy/constants/PropertyControlConstants";
import { WidgetLabelPosition } from "legacy/constants/WidgetConstants";
import { PanelCategory } from "legacy/pages/Editor/PropertyPane/propertyPaneCategoryUtils";
import {
  DEFAULT_CONTAINER_BORDER_OBJECT,
  DEFAULT_SYSTEM_RADIUS,
} from "legacy/themes/constants";
import {
  DATE_OUTPUT_FORMATS,
  TIMEZONE_OPTIONS,
} from "legacy/utils/FormatUtils";
import { createPerCornerBorderRadius } from "pages/Editors/AppBuilder/Sidebar/BorderRadiusEditor";
import { ANIMATE_LOADING_PROPERTY_CONTROL_HELP_TEXT } from "pages/Editors/AppBuilder/Sidebar/PropertyControlCommons";
import { getParentPath } from "utils/dottedPaths";
import { DropdownWidgetProps } from "../DropdownWidget/DropdownWidget";
import {
  customValidationProperties,
  errorMessagePlacementProperty,
  labelWidthProperty,
} from "../FormInputs/formInputsProperties";
import {
  sizeSection,
  visibleProperties,
  isDisabledProperty,
  labelPositionProperty,
} from "../basePropertySections";
import { getPopoverConfig } from "../eventHandlerPanel";
import { updateHeightOnAddOrRemove } from "../propsPanelUtils";
import {
  typographyProperties,
  borderProperty,
  borderRadiusProperty,
} from "../styleProperties";
import {
  DEFAULT_DATEPICKER_LABEL_STYLE_VARIANT,
  DEFAULT_DATEPICKER_INPUT_STYLE_VARIANT,
  DEFAULT_DATE_FORMAT,
} from "./Constants";
import { DatePickerWidgetProps } from "./types";
import { formatIncludesTime } from "./utils";

const config: PropertyPaneCategoryConfig<DatePickerWidgetProps>[] = [
  PanelCategory(PropsPanelCategory.Content, [
    {
      propertyName: "label",
      label: "Label",
      helpText: "Sets a label text for the Datepicker",
      controlType: "INPUT_TEXT",
      placeholderText: "Enter label text",
      isBindProperty: true,
      isTriggerProperty: false,
      visibility: "SHOW_NAME",
      isRemovable: true,
      defaultValue: "Label",
      updateHook: updateHeightOnAddOrRemove,
    },
    {
      propertyName: "defaultDate",
      label: "Default date",
      helpText:
        "Sets the default date of the component. The date is updated if the default date changes",
      controlType: "DATE_PICKER",
      placeholderText: "Enter default date",
      isJSConvertible: true,
      isBindProperty: true,
      isTriggerProperty: false,
      visibility: "SHOW_NAME",
      isRemovable: true,
      defaultValue: "...", // this gets overriden in applyActionHook
    },
    {
      helpText: "Sets the format of the selected date",
      propertyName: "dateFormat",
      label: "Value format",
      controlType: "DROP_DOWN",
      isJSConvertible: true,
      options: DATE_OUTPUT_FORMATS,
      isBindProperty: true,
      isTriggerProperty: false,
    },
    {
      helpText: "Sets the format of the date displayed in the UI",
      propertyName: "displayDateFormat",
      label: "Display format",
      controlType: "DROP_DOWN",
      isJSConvertible: true,
      options: DATE_OUTPUT_FORMATS,
      isBindProperty: true,
      isTriggerProperty: false,
      visibility: "SHOW_NAME",
      isRemovable: true,
      defaultValueFn: ({ props }: { props: DatePickerWidgetProps }) => {
        if (props.dateFormat) {
          return props.dateFormat;
        }
        return DEFAULT_DATE_FORMAT;
      },
    },
    {
      helpText:
        "Allows you to control the timezone of the Datepicker's output value and display",
      propertyName: "manageTimezone",
      label: "Manage timezone",
      controlType: "SWITCH",
      isJSConvertible: false,
      isBindProperty: true,
      isTriggerProperty: false,
    },
    {
      helpText: "Timezone of the Datepicker's output value",
      propertyName: "timezone",
      label: "Value timezone",
      controlType: "DROP_DOWN",
      isJSConvertible: false,
      options: TIMEZONE_OPTIONS,
      isBindProperty: true,
      isTriggerProperty: false,
      hidden: (props: DropdownWidgetProps, propertyPath: string) => {
        const parentPath = getParentPath(propertyPath);
        const manageTimezone = get(
          props,
          `${parentPath ? `${parentPath}.manageTimezone` : "manageTimezone"}`,
          "",
        );
        return !manageTimezone;
      },
    },
    {
      helpText: "Timezone of the date displayed in the UI",
      propertyName: "displayTimezone",
      label: "Display timezone",
      controlType: "DROP_DOWN",
      isJSConvertible: false,
      options: TIMEZONE_OPTIONS,
      isBindProperty: true,
      isTriggerProperty: false,
      hidden: (props: DropdownWidgetProps, propertyPath: string) => {
        const parentPath = getParentPath(propertyPath);
        const manageTimezone = get(
          props,
          `${parentPath ? `${parentPath}.manageTimezone` : "manageTimezone"}`,
          "",
        );
        return !manageTimezone;
      },
    },
    {
      propertyName: "twentyFourHourTime",
      label: "24 hour time",
      controlType: "SWITCH",
      defaultValue: true,
      isBindProperty: true,
      isTriggerProperty: false,
      hidden: (props: DropdownWidgetProps, propertyPath: string) => {
        const parentPath = getParentPath(propertyPath);

        const dateFormat = get(
          props,
          `${parentPath ? `${parentPath}.dateFormat` : "dateFormat"}`,
          DEFAULT_DATE_FORMAT,
        );
        return !formatIncludesTime(dateFormat);
      },
    },
  ]),
  PanelCategory(PropsPanelCategory.Appearance, [
    labelPositionProperty({ parentDottedPath: "labelProps" }),
    ...typographyProperties({
      textStyleParentDottedPath: "labelProps",
      propertyNameForHumans: "Label",
      defaultVariant: DEFAULT_DATEPICKER_LABEL_STYLE_VARIANT,
      hiddenIfPropertyNameIsNullOrFalse: "label",
    }),
    ...typographyProperties({
      textStyleParentDottedPath: "inputProps",
      propertyNameForHumans: "Input",
      defaultVariant: DEFAULT_DATEPICKER_INPUT_STYLE_VARIANT,
    }),
    {
      propertyName: "inputProps.backgroundColor",
      label: "Input background",
      helpText: "Changes the color of the input background",
      controlType: "COLOR_PICKER",
      themeValue: "{{ theme.colors.neutral }}",
      isJSConvertible: true,
      isBindProperty: true,
      isTriggerProperty: false,
    },
    borderProperty({
      label: "Input border",
      helpText: "Changes the color of the input border",
      propertyNamespaceDottedPath: "inputProps",
      propertyName: "border",
      themeValue: () => ({
        value: DEFAULT_CONTAINER_BORDER_OBJECT,
        treatAsNull: false,
      }),
    }),
    borderRadiusProperty({
      label: "Input border radius",
      helpText: "Changes the input border radius",
      propertyNamespaceDottedPath: "inputProps",
      propertyName: "borderRadius",
      defaultValue: DEFAULT_SYSTEM_RADIUS,
      themeValue: ({ theme }) => {
        return {
          treatAsNull: false,
          value: createPerCornerBorderRadius(theme.borderRadius),
        };
      },
    }),
    {
      propertyName: "showIcon",
      label: "Icon",
      controlType: "SWITCH",
      isBindProperty: true,
      isTriggerProperty: false,
    },
    {
      helpText: ANIMATE_LOADING_PROPERTY_CONTROL_HELP_TEXT,
      propertyName: "animateLoading",
      label: "Loading animation",
      controlType: "SWITCH",
      isJSConvertible: true,
      isBindProperty: true,
      isTriggerProperty: false,
    },
  ]),
  PanelCategory(PropsPanelCategory.Layout, [
    sizeSection({ heightSupportsFitContent: true }),
    labelWidthProperty({
      hidden: (props: DatePickerWidgetProps) =>
        !props.labelProps?.position ||
        !props.label ||
        props.labelProps?.position !== WidgetLabelPosition.LEFT,
    }),
    ...visibleProperties({ useJsExpr: false }),
  ]),
  PanelCategory(PropsPanelCategory.Interaction, [
    {
      propertyName: "isRequired",
      label: "Required",
      helpText: "Makes date picker mandatory",
      controlType: "SWITCH",
      isJSConvertible: true,
      isBindProperty: true,
      isTriggerProperty: false,
    },
    {
      propertyName: "minDate",
      label: "Min date",
      controlType: "DATE_PICKER",
      placeholderText: "Minimum Date",
      isJSConvertible: true,
      isBindProperty: true,
      isTriggerProperty: false,
      visibility: "SHOW_NAME",
      isRemovable: true,
      defaultValue: "",
    },
    {
      propertyName: "maxDate",
      label: "Max date",
      controlType: "DATE_PICKER",
      placeholderText: "Maximum Date",
      isJSConvertible: true,
      isBindProperty: true,
      isTriggerProperty: false,
      visibility: "SHOW_NAME",
      isRemovable: true,
      defaultValue: "",
    },
    isDisabledProperty({ useJsExpr: false }),
    errorMessagePlacementProperty(),
    ...customValidationProperties(),
  ]),
  PanelCategory(PropsPanelCategory.EventHandlers, [
    getPopoverConfig("onDateSelected", ""),
  ]),
];

export default config;
