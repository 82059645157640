import { useEffect, useState } from "react";
import { useDebounce } from "./useDebounce";

const DEFAULT_DEBOUNCE_DELAY = 100;

export type Parser<T> = {
  load: (value: string) => T;
  save: (value: T) => string;
};

const JSON_PARSER: Parser<any> = {
  load: JSON.parse,
  save: JSON.stringify,
};

const STRING_PARSER: Parser<string> = {
  load: (value: string) => value,
  save: (value: string) => value,
};

export const Parsers = {
  JSON: JSON_PARSER,
  STRING: STRING_PARSER,
};

// Custom hook to manage state synchronized with localStorage
const useLocalStorageState = <T>({
  key,
  initialValue,
  debounceDelay = DEFAULT_DEBOUNCE_DELAY,
  parser = Parsers.JSON,
}: {
  key: string;
  initialValue: T;
  debounceDelay?: number;
  parser: Parser<T>;
}): [T, React.Dispatch<React.SetStateAction<T>>] => {
  // Initialize state with value from localStorage or the initial value
  const [state, setState] = useState<T>(() => {
    const storedValue = localStorage.getItem(key);
    if (storedValue !== null) {
      try {
        return parser.load(storedValue);
      } catch (error) {
        console.warn(`Error parsing localStorage key "${key}":`, error);
      }
    }
    return initialValue;
  });

  const debouncedSet = useDebounce((newState: T) => {
    try {
      // Only update localStorage if the new state is different from the initial value
      if (newState !== initialValue) {
        localStorage.setItem(key, parser.save(newState));
      }
    } catch (error) {
      console.warn(`Error setting localStorage key "${key}":`, error);
    }
  }, debounceDelay);

  useEffect(() => {
    debouncedSet?.(state);
  }, [state, debouncedSet]);

  return [state, setState];
};

export default useLocalStorageState;
