export function createLock() {
  let resolve: (() => void) | undefined;
  let prom: Promise<void> | undefined;
  let queue: Array<Promise<() => void>> = [];

  async function obtainLock(id?: string): Promise<() => void> {
    if (resolve) {
      await prom;
    }

    let r: () => void;
    prom = new Promise((res) => {
      resolve = res;
      r = res;
    });

    return () => {
      r();
    };
  }

  async function lock(id?: string): Promise<() => void> {
    const called = new Set();
    for (const caller of queue) {
      await caller;
      called.add(caller);
    }

    const unlocker = obtainLock(id);
    queue.push(unlocker);
    queue = queue.filter((caller) => !called.has(caller));

    return unlocker;
  }

  return lock;
}
