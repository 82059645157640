import {
  ActionTypeEnum,
  Agent,
  AgentDiagnosticMetadata,
  AgentStatus,
  AgentType,
  DiagnosticType,
  ENVIRONMENT_PRODUCTION,
  ExtendedIntegrationPluginMap,
  jsonPrettyPrint,
  RbacRole,
  ResourceTypeEnum,
  RoleTypeEnum,
} from "@superblocksteam/shared";
import { Input, Tooltip } from "antd";
import { isEmpty } from "lodash";
import moment from "moment";
import React, { useCallback, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import Link, { PlainLink, UnderlinedLinkClass } from "components/ui/Link";
import { DeleteModal } from "components/ui/Modal";
import RecommendedTable, {
  DEFAULT_TABLE_PAGE_SIZE,
  RecColumn,
} from "components/ui/RecommendedTable";
import { SUPERBLOCKS_UI_AGENT_BASE_URL } from "env";
import { useIsControlFlowEnabled } from "hooks/api/useIsControlFlowEnabled";
import { useFeatureFlag } from "hooks/ui";
import { getCurrentOrgId } from "legacy/selectors/organizationSelectors";
import ShareModalV2 from "pages/components/ShareModalV2";
import { chooseAgents, selectAllAgents } from "store/slices/agents";
import {
  deleteDatasource,
  deleteDatasourceOnAgent,
  deleteDatasourceOnOrchestrator,
} from "store/slices/datasources/client";
import { Flag } from "store/slices/featureFlags";
import { selectOnlyOrganization } from "store/slices/organizations";
import { orgIsOnPremise } from "store/slices/organizations/utils";
import { colors } from "styles/colors";
import { styleAsClass } from "styles/styleAsClass";
import { UIEvent } from "utils/event";
import { getPluginById } from "utils/integrations";
import logger from "utils/logger";
import {
  sendErrorUINotification,
  sendSuccessUINotification,
} from "utils/notification";
import { ConnectedIntegration } from "./utils";

type ColType = RecColumn<ConnectedIntegration>;

const renderTime = ({ value }: { value?: Date }) =>
  value ? (
    <div>{moment(value).format("MMMM D, YYYY")}</div>
  ) : (
    <div style={{ color: colors.GREY_200 }}>–</div>
  );

const NameCellWrapper = styleAsClass`
    display: flex;
    align-items: center;
    gap: 16px;
    img.plugin-img {
      height: 20px;
      width: 20px;
    }
    color: ${colors.GREY_700};
    div.plugin-name {
      font-weight: 500;
      font-size: 12px;
    }
`;

const BuildOnlyTag = styleAsClass`
  color: ${colors.GREY_700};
  background-color: ${colors.GREY_25};
  font-size: 12px;
  border: 1px solid ${colors.GREY_100};
  border-radius: 3px;
  padding: 4px 6px;
`;

const NeedUpgradeTag = styleAsClass`
  color: ${colors.ORANGE_600};
  background-color: ${colors.ORANGE_600}14;
  font-size: 12px;
  border: 1px solid ${colors.ORANGE_25};
  border-radius: 3px;
  padding: 4px 6px;
`;

const IntegrationTable = ({
  integrations,
  setIntegrations,
  loading,
  totalCount,
}: {
  integrations: ConnectedIntegration[];
  setIntegrations: React.Dispatch<React.SetStateAction<ConnectedIntegration[]>>;
  loading: boolean;
  totalCount: number;
}) => {
  const navigate = useNavigate();
  const rbacV2Enabled = useFeatureFlag(Flag.ENABLE_RBAC_V2);

  const organization = useSelector(selectOnlyOrganization);

  const [integrationToModify, setIntegrationToModify] = useState<
    ConnectedIntegration | undefined
  >();
  const stopPropagation = useCallback((e: React.MouseEvent) => {
    e.stopPropagation();
  }, []);

  const columns: ColType[] = useMemo(
    () => [
      {
        Header: "Id",
        accessor: "datasourceId",
        hidden: true,
      },
      {
        Header: "Name",
        accessor: "datasourceName",
        Cell: ({ row }) => {
          const integration = row.original;
          const isBuildOnly = rbacV2Enabled
            ? integration.permissions &&
              integration.permissions.includes(ActionTypeEnum.BUILD) &&
              !integration.permissions.includes(ActionTypeEnum.UPDATE)
            : integration.role === RbacRole.BUILDER;

          // slightly different from the opposite of isBuildOnly, e.g. if user has only share permission, isBuildOnly is false and nameClickable is false
          const nameClickable =
            (rbacV2Enabled
              ? integration.permissions?.includes(ActionTypeEnum.UPDATE)
              : integration.role === RbacRole.CONFIGURATOR) &&
            integration.isSupported;

          const link = `/integrations/${integration.pluginId}/${integration.datasourceId}`;

          return (
            <div
              style={{ display: "flex" }}
              className={NameCellWrapper}
              data-test={`integration-connected-${integration.pluginId}`}
            >
              <img
                className="plugin-img"
                src={integration.iconLocation ?? ""}
                alt=""
              />
              {nameClickable ? (
                <Link
                  to={link}
                  className={`plugin-name ${UnderlinedLinkClass}`}
                >
                  {integration.datasourceName}
                </Link>
              ) : (
                <span className="plugin-name">
                  {integration.datasourceName}
                </span>
              )}
              {isBuildOnly && <div className={BuildOnlyTag}> Build only </div>}
              {!integration.isSupported && (
                <div>
                  {" "}
                  <Tooltip
                    title={
                      <span>
                        Upgrade your agent to access this Integration{" "}
                        <PlainLink
                          href={
                            "https://docs.superblocks.com/on-premise-agent/deployment"
                          }
                          target="_blank"
                          rel="noreferrer"
                          onClick={stopPropagation}
                        >
                          learn how
                        </PlainLink>
                      </span>
                    }
                  >
                    <div className={NeedUpgradeTag}> Upgrade required </div>
                  </Tooltip>
                </div>
              )}
            </div>
          );
        },
      },
      {
        Header: "Created by",
        accessor: "creator",
        Cell: ({ row }) => {
          return (
            <div>
              <Tooltip title={row.original.creator?.email}>
                <div style={{ width: "fit-content" }}>
                  {row.original.creator?.name}
                </div>
              </Tooltip>
            </div>
          );
        },
      },
      {
        Header: "Last updated",
        accessor: "lastUpdated",
        Cell: renderTime,
      },
    ],
    [rbacV2Enabled, stopPropagation],
  );

  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  const menuItems = useCallback(
    (integration: ConnectedIntegration) => {
      const canEditIntegration = rbacV2Enabled
        ? (integration.permissions ?? []).includes(ActionTypeEnum.UPDATE)
        : integration.role === RbacRole.CONFIGURATOR;

      const canShareIntegration = rbacV2Enabled
        ? (integration.permissions ?? []).includes(ActionTypeEnum.SHARE)
        : integration.role === RbacRole.CONFIGURATOR;

      const canDeleteIntegration = rbacV2Enabled
        ? (integration.permissions ?? []).includes(ActionTypeEnum.DELETE)
        : integration.role === RbacRole.CONFIGURATOR;

      // do now allow edit if integration is not supported
      if (!integration.isSupported) return [];

      return [
        ...(canEditIntegration
          ? [
              {
                key: "manage-integration",
                label: "View details",
                onClick: () => {
                  navigate(
                    `/integrations/${integration.pluginId}/${integration.datasourceId}`,
                  );
                },
                disabled: Boolean(!canEditIntegration),
              },
            ]
          : []),
        ...(canShareIntegration
          ? [
              {
                key: "share-integration",
                label: "Share",
                disabled: Boolean(!canShareIntegration),
                onClick: () => {
                  setIntegrationToModify(integration);
                  setShareModalVisible(true);
                },
              },
            ]
          : []),
        ...(canDeleteIntegration
          ? [
              {
                key: "remove-integration",
                label: (
                  <Tooltip
                    title={
                      canEditIntegration
                        ? ""
                        : "You do not have permission to delete the integration"
                    }
                    placement="left"
                  >
                    <div style={{ color: colors.DANGER }}>Delete</div>
                  </Tooltip>
                ),
                onClick: () => {
                  setIntegrationToModify(integration);
                  setDeleteModalOpen(true);
                },
                "data-test": "delete-integration-button",
                disabled: Boolean(!canEditIntegration),
              },
            ]
          : []),
      ];
    },
    [rbacV2Enabled, navigate],
  );

  const orgId = useSelector(getCurrentOrgId);

  const agents = useSelector(selectAllAgents);
  const profiles = useMemo(() => {
    if (isEmpty(organization.profiles)) {
      logger.event(UIEvent.LIST_PROFILES_IS_EMPTY);
      logger.debug("organization " + jsonPrettyPrint(organization));
    }

    return (organization.profiles ?? [])
      .slice()
      .sort((a, b) => a.key.localeCompare(b.key));
  }, [organization]);

  const productionProfile = useMemo(
    () => profiles.find((p) => p.key === ENVIRONMENT_PRODUCTION),
    [profiles],
  );
  const productionProfileId = productionProfile?.id ?? "";

  const profileIdToKey = useCallback(
    (profileId: string | undefined) => {
      return profiles.find((p) => {
        return p.id === profileId;
      })?.key;
    },
    [profiles],
  );

  const isControlFlowEnabled = useIsControlFlowEnabled();
  const controlFlowOverrideEnabled = useFeatureFlag(
    Flag.OVERRIDE_CONTROL_FLOW_ON,
  );
  const isOnPremise = orgIsOnPremise(organization);

  const getValidAgentsForProfiles = useCallback(
    (profileIds: string[]) => {
      if (isControlFlowEnabled && !isOnPremise) {
        // return a fake Agent - not ideal, but refactoring all the code to gracefully handle 0 agents is not worth the work since
        // this code will be much simpler when controller is completely removed
        return {
          agents: [
            {
              id: "fake-agent-id",
              key: "fake-agent-key",
              environment: ENVIRONMENT_PRODUCTION,
              status: AgentStatus.ACTIVE,
              version: "1.0.0",
              versionExternal: "1.0.0",
              supportedPluginVersions: {},
              url: SUPERBLOCKS_UI_AGENT_BASE_URL,
              type: AgentType.MULTITENANT,
              updated: new Date(),
              created: new Date(),
              tags: {},
            } as Agent,
          ],
          profileId: profileIds[0],
        };
      }

      for (const profileId of profileIds) {
        const profileKey = profileIdToKey(profileId);
        if (!profileKey) continue;
        const availableAgents = chooseAgents(
          agents,
          organization.agentType,
          profileKey,
          isControlFlowEnabled,
          controlFlowOverrideEnabled,
        );
        if (availableAgents.length > 0) {
          return {
            agents: availableAgents,
            profileId: profileId,
          };
        }
      }
      return {
        agents: [],
        profileId: undefined,
      };
    },
    [
      isControlFlowEnabled,
      isOnPremise,
      profileIdToKey,
      agents,
      organization.agentType,
      controlFlowOverrideEnabled,
    ],
  );

  const [deleteError, setDeleteError] = useState<string | undefined>("");

  const [isDeleting, setIsDeleting] = useState(false);

  const onDelete = useCallback(async () => {
    const integration = integrationToModify;
    if (!integration) {
      return false;
    }
    setDeleteError(undefined);
    setIsDeleting(true);
    try {
      const { agents } = getValidAgentsForProfiles([productionProfileId]);
      const plugin = getPluginById(integration?.pluginId);
      if (!plugin) {
        setDeleteError("Cannot find plugins.");
        return;
      }

      let currentResult;
      if (isControlFlowEnabled && agents?.length) {
        for (const config of Object.values(integration.configurations ?? [])) {
          currentResult = await deleteDatasourceOnOrchestrator({
            datasourceId: integration.datasourceId,
            organization,
            pluginName: ExtendedIntegrationPluginMap[plugin?.id],
            agents,
            configurationId: config.id,
          });
          if (!currentResult.success) {
            const message = currentResult.message ?? currentResult.systemError;
            setDeleteError(
              `Failed to delete a datasource on worker: ${message}`,
            );
            return;
          }
        }
      } else if (agents?.length) {
        const tags: AgentDiagnosticMetadata = {
          superblocks_agent_id: agents[0].id,
          superblocks_org_id: organization.id,
          superblocks_org_name: organization.name,
          pluginId: plugin?.id,
          datasourceId: integration.datasourceId,
          profileId: productionProfileId,
          type: DiagnosticType.AGENT,
        };

        currentResult = await deleteDatasourceOnAgent(
          agents,
          organization,
          ENVIRONMENT_PRODUCTION,
          integration.datasourceId,
          tags,
        );

        const deleteDatasourceOnAgentResult = currentResult;
        if (!deleteDatasourceOnAgentResult?.success) {
          const message =
            deleteDatasourceOnAgentResult?.message ??
            deleteDatasourceOnAgentResult?.systemError;

          setDeleteError(`Failed to delete a datasource on agent: ${message}`);
          return;
        }
      }
      // TODO(rbac): is this still ture if we return early above?
      // even if the delete on agent fails, we still want to delete the datasource on the server
      // delete datasource on server
      await deleteDatasource(integrationToModify?.datasourceId);
      sendSuccessUINotification({
        message: "Integration deleted",
        duration: 5,
      });
      setDeleteModalOpen(false);
      setIntegrations((integrations) =>
        integrations.filter((i) => i.datasourceId !== integration.datasourceId),
      );
      setConfirmInput("");
    } catch (e: any) {
      sendErrorUINotification({
        message: e.message,
        duration: 5,
      });
    } finally {
      setIsDeleting(false);
    }
  }, [
    integrationToModify,
    getValidAgentsForProfiles,
    productionProfileId,
    isControlFlowEnabled,
    setIntegrations,
    organization,
  ]);

  const [shareModalVisible, setShareModalVisible] = useState(false);

  const onCancelDelete = useCallback(() => {
    setDeleteModalOpen(false);
    setIntegrationToModify(undefined);
    setDeleteError(undefined);
    setConfirmInput("");
  }, []);

  const [confirmInput, setConfirmInput] = useState("");

  return (
    <>
      <RecommendedTable<ConnectedIntegration>
        data={integrations}
        dataTest="connected-integrations"
        dataLabel="integrations"
        uniqueKey="datasourceId"
        columns={columns}
        actionMenuItems={menuItems}
        // using totalCount to avoid frequent update when search which could cause crash
        paginationOptions={
          totalCount > DEFAULT_TABLE_PAGE_SIZE
            ? { pageSize: DEFAULT_TABLE_PAGE_SIZE }
            : undefined
        }
        loading={loading}
      />
      <DeleteModal
        open={deleteModalOpen}
        onCancel={onCancelDelete}
        title={`Delete ${integrationToModify?.pluginName} ${integrationToModify?.datasourceName}?`}
        dataTestName="integration"
        confirmText={"Delete"}
        disableDelete={confirmInput !== integrationToModify?.datasourceName}
        onDelete={onDelete}
        isDeleting={isDeleting}
        width={384}
      >
        <p>
          You are attempting to delete the{" "}
          <b style={{ color: colors.GREY_800 }}>
            {" "}
            {integrationToModify?.datasourceName}
          </b>{" "}
          integration. This action cannot be undone.
        </p>

        <p>
          Deleting an integration used in backend APIs will cause those APIs to
          fail immediately. This could impact end-users of deployed apps and
          services calling workflows.
        </p>
        <p style={{ marginBottom: 4 }}>
          <b
            style={{ color: colors.GREY_700 }}
          >{`To confirm, type "${integrationToModify?.datasourceName}" in the box below`}</b>{" "}
          <span style={{ color: colors.RED_500 }}>*</span>
        </p>
        <Input
          data-test="confirm-delete-integration-input"
          placeholder={`${integrationToModify?.datasourceName}`}
          value={confirmInput}
          onChange={(e) => setConfirmInput(e.target.value)}
        />
        {deleteError && (
          <div style={{ color: colors.RED_500, fontSize: 12, marginTop: 4 }}>
            {deleteError}
          </div>
        )}
      </DeleteModal>
      {integrationToModify?.datasourceId && (
        <ShareModalV2
          isVisible={shareModalVisible}
          resourceId={integrationToModify?.datasourceId ?? ""}
          resourceDisplayName={
            integrationToModify?.datasourceName || "Integration"
          }
          organizationId={orgId}
          setShareModalVisible={setShareModalVisible}
          resourceType={ResourceTypeEnum.INTEGRATIONS}
          roleType={RoleTypeEnum.INTEGRATIONS}
          inEditMode={true}
        />
      )}
    </>
  );
};

export default IntegrationTable;
