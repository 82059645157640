import {
  Agent,
  ApplicationSettings,
  PutApplicationSettingsUpdateResponseBody,
  UIErrorType,
  ViewMode,
  PageDSL8,
  sha256Object,
  getDefaultPageLayout,
  SuperblocksError,
  PostApplicationCreateRequestBody,
  verifyApplicationHashTree,
  IApiV3Dto,
  upgradeApplicationSignatureTree,
  ApplicationSignatureTree,
  AccessMode,
} from "@superblocksteam/shared";
import { get, isEmpty } from "lodash";
import {
  all,
  call,
  put,
  select,
  takeEvery,
  takeLatest,
  getContext,
} from "redux-saga/effects";
import { getEditorBasePath } from "hooks/store/useGetEditorPath";
import { updateCurrentRoute } from "legacy/actions/pageActions";
import { ApiResponse } from "legacy/api/ApiResponses";
import ApplicationApi, {
  UpdateApplicationMetadataRequest,
  UpdateNonVersionedApplicationSettingsRequest,
} from "legacy/api/ApplicationApi";
import {
  StdISocketRPCClient,
  connectToISocketRPCServer,
} from "legacy/api/ISocketRPC";
import { Toaster } from "legacy/components/ads/Toast";
import { Variant } from "legacy/components/ads/common";
import { ERROR_CODES } from "legacy/constants/ApiConstants";
import {
  ReduxAction,
  ReduxActionErrorTypes,
  ReduxActionTypes,
} from "legacy/constants/ReduxActionConstants";
import {
  DELETING_APPLICATION,
  DUPLICATING_APPLICATION,
} from "legacy/constants/messages";
import { EditorRoute } from "legacy/constants/routes";
import { Profiles } from "legacy/reducers/entityReducers/appReducer";
import { SafeCrashReason } from "legacy/reducers/uiReducers/errorReducer";
import { getAppProfilesInCurrentMode } from "legacy/selectors/applicationSelectors";
import {
  getCurrentApplicationId,
  getCurrentPageId,
} from "legacy/selectors/editorSelectors";
import { getRoutesList } from "legacy/selectors/routeSelectors";
import selectLastSuccessfulWrite from "legacy/selectors/successfulWriteSelector";
import {
  getAccessMode,
  getIsOrgSwitched,
} from "legacy/selectors/usersSelectors";
import { BASE_THEME_NEW_APPS } from "legacy/themes/constants";
import { getDefaultTypographies } from "legacy/themes/utils";
import AnalyticsUtil from "legacy/utils/AnalyticsUtil";
import { getOpaAgents } from "legacy/utils/getOpaAgents";
import { resetCachedCurrentBranch } from "pages/Repositories/utils";
import { getAllApisSaga } from "store/slices/apis";
import { selectControlFlowEnabledDynamic } from "store/slices/apisShared/selectors";
import { lock as lockApis } from "store/slices/apisShared/sharedPersistApiLock";
import { getAllV2ApisSaga } from "store/slices/apisV2";
import { Api } from "store/slices/apisV2/backend-types";
import {
  fetchApplication,
  fetchApplicationPermissions,
  fetchApplicationPermissionsError,
  fetchApplicationPermissionsSuccess,
  fetchApplicationSuccess,
  updateApplicationMetadata,
  updateApplicationSettings,
  updateNonVersionedApplicationSettings,
} from "store/slices/application/applicationActions";
import { getApplicationPermissions } from "store/slices/application/client";
import { getEnvironment } from "store/slices/application/selectors";
import { getSupersetDatasourcesSaga } from "store/slices/datasources/sagas/getSupersetDatasources";
import { Flag, selectFlagById, selectFlags } from "store/slices/featureFlags";
import {
  createApplicationError,
  createApplicationInit,
  createApplicationSuccess,
  deleteApplicationError,
  deleteApplicationInit,
  deleteApplicationSuccess,
  duplicateApplicationError,
  duplicateApplicationInit,
  duplicateApplicationSuccess,
  fetchAllApplicationsError,
  getAllApplicationsInit,
  getAllApplicationsSuccess,
} from "store/slices/homepage/slice";
import { selectOnlyOrganization } from "store/slices/organizations";
import { orgIsOnPremise } from "store/slices/organizations/utils";
import { callSagas } from "store/utils/saga";
import { OutgoingMessage, sendEventToEmbedder } from "utils/embed/messages";
import { NOOP } from "utils/function";
import logger, { stringifyError } from "utils/logger";
import { sendErrorUINotification } from "utils/notification";
import {
  AnySignedResource,
  GenericResource,
  getShouldSignAndVerify,
  verifyResources,
} from "utils/resource-signing";
import { getMatchingRoute } from "utils/routing";
import { findAndUnMarshalProtoBlocks } from "../../utils/marshalProto";
import { APP_MODE } from "../reducers/types";
import { updateProfiles } from "./ApplicationSagaHelpers";
import { validateResponse } from "./ErrorSagas";
import { putFetchPageSagaResult } from "./PageSagas";

function* getAllApplicationSaga(): Generator<any, any, any> {
  try {
    const response: Awaited<
      ReturnType<typeof ApplicationApi.getAllApplication>
    > = yield call(ApplicationApi.getAllApplication);
    const isValidResponse = yield validateResponse(response);
    if (isValidResponse) {
      yield put(getAllApplicationsSuccess(response.data.applications));
    }
  } catch (error: any) {
    yield put(fetchAllApplicationsError(error.message as string));
  }
}

export function* fetchApplicationSaga(
  action: ReturnType<typeof fetchApplication>,
): Generator<any, any, any> {
  const { mode, applicationId, commitId, branch } = action.payload;

  const organization: ReturnType<typeof selectOnlyOrganization> = yield select(
    selectOnlyOrganization,
  );
  let shouldVerifySignature = false;
  let agents: Agent[] = [];
  if (orgIsOnPremise(organization)) {
    agents = yield call(getOpaAgents);
    const isSigningEnabled: boolean = yield call(
      getShouldSignAndVerify,
      agents,
    );
    const isOrgSwitched: boolean = yield select(getIsOrgSwitched);
    if (isSigningEnabled && !isOrgSwitched) {
      if (agents.length === 0) {
        yield put({
          type: ReduxActionErrorTypes.FETCH_APPLICATION_ERROR,
          payload: {
            error: new Error("No OPA agents found"),
          },
        });
        yield put({
          type: ReduxActionTypes.SAFE_CRASH_SUPERBLOCKS_REQUEST,
          payload: {
            code: ERROR_CODES.NO_AGENTS,
          },
        });
        return;
      }
      shouldVerifySignature = true;
    }
  }
  yield put(fetchApplicationPermissions(applicationId));

  let rpcClient: undefined | StdISocketRPCClient;
  try {
    if (shouldVerifySignature && mode === APP_MODE.EDIT) {
      rpcClient = yield call(connectToISocketRPCServer, agents, organization);
    }
    let response: Awaited<
      ReturnType<typeof ApplicationApi.fetchSingleApplication>
    >;
    if (mode === APP_MODE.PUBLISHED) {
      response = yield call(
        ApplicationApi.fetchDeployedApplicationForViewMode,
        applicationId,
      );
    } else if (rpcClient) {
      response = yield call(rpcClient.call.v3.application.get, {
        applicationId,
        branchName: branch,
      });
    } else {
      response = yield call(ApplicationApi.fetchSingleApplication, {
        applicationId,
        viewMode: mode === APP_MODE.EDIT ? ViewMode.EDITOR : ViewMode.PREVIEW,
        branch,
        commitId,
      });
    }
    if (!response.responseMeta) {
      // Handles auth0 failure
      throw response;
    } else if (response.responseMeta.error) {
      throw response.responseMeta.error;
    }

    const application = {
      ...response.data.application,
      global: response.data.global,
    };

    // Page will be undefined if the application is not published and the user is trying to view it in deployed moe
    // in this case, another splash screen will be used to take the user to EDIT mode and then the page will be
    // fetched (and verified if signing is enabled).
    // Page will also be undefined if the v3 endpoint is used to fetch the application.
    const page = response.data.page;
    const integrations = response.data.integrations ?? [];
    const apiResponse = response.data.apis;

    const v2Apis: IApiV3Dto[] = [];
    const v1Apis: IApiV3Dto[] = [];
    if (apiResponse && apiResponse.length > 0) {
      const controlFlowEnabled: boolean = yield select(
        selectControlFlowEnabledDynamic,
      );

      const apis = apiResponse;
      apis.forEach((api) => {
        findAndUnMarshalProtoBlocks(api.apiPb);
        if (!controlFlowEnabled) v1Apis.push(api);
        if (controlFlowEnabled && api.apiPb) v2Apis.push(api);
      });
    }

    const pages = application.pageSummaryList?.map((page: any) => ({
      pageId: page.id,
      pageName: page.name,
    }));

    if (
      shouldVerifySignature &&
      mode === APP_MODE.PUBLISHED &&
      !page &&
      !application.signature
    ) {
      // If the application is fetched in published mode but has not been published, we will not
      // get a signature and a page and there is nothing to verify.
      shouldVerifySignature = false;
    }
    // If using OPA, verify all of the application resources
    // The page must be verified BEFORE any mutations are made to it
    if (shouldVerifySignature) {
      try {
        // We need to verify the application signature, so we treat it as a resource whose signature the agent needs to verify:
        let appResourceToVerify: GenericResource;
        let hashTree: ApplicationSignatureTree | undefined;
        if (!application.signature) {
          // There is no signature to verify, so send an empty signature to the agent and let the agent decide if that's acceptable
          // based on whether signature verification is enabled or not.
          appResourceToVerify = {
            literal: {
              data: "",
            },
          };
        } else {
          const pageDsls = !page
            ? // if no page is included during app fetch, then we don't need to verify any page,
              // instead it will be verified when it is loaded later, PageSagas
              undefined
            : { [page.id]: page.layouts[0].dsl };
          const hashVerificationResult: Awaited<
            ReturnType<typeof verifyApplicationHashTree>
          > = yield call(verifyApplicationHashTree, {
            hashTree: application.signature.root,
            pages: pageDsls,
            configuration: application.configuration,
            appSettings: application.settings as unknown as ApplicationSettings,
          });
          if (hashVerificationResult) {
            const hashedTree: string = yield call(
              sha256Object,
              application.signature.root,
            );
            appResourceToVerify = {
              literal: {
                data: hashedTree,
                signature: {
                  keyId: application.signature.signature.keyId,
                  data: application.signature.signature.data,
                  publicKey: application.signature.signature.publicKey,
                  algorithm: application.signature.signature.algorithm,
                },
              },
            };
            const upgradedHashTree: Awaited<
              ReturnType<typeof upgradeApplicationSignatureTree>
            > = yield call(upgradeApplicationSignatureTree, {
              currentHashTree: application.signature.root,
              pageIds: pages?.map((page) => page.pageId) ?? [],
              configuration: application.configuration!,
            });
            hashTree = upgradedHashTree;
          } else {
            // If the hash verification fails, we cannot trust the app signature. Nevertheless, we still don't want to show the verification error
            // page to the user yet, because the agent might have verification disabled. So we will send to the agent's verify endpoint an
            // invalid signature and if the agent accepts that, this means that verification is disabled.
            appResourceToVerify = {
              literal: {
                data: "",
                signature: {
                  // This is an invalid signature, it will only be accepted by the agent if signature verification is disabled in the agent.
                  // We could have also left the signature undefined, but it might be useful for debugging to use this invalid signature here.
                  algorithm: "ALGORITHM_UNSPECIFIED",
                  data: "hash-verification-failed",
                  publicKey: "",
                  keyId: "",
                },
              },
            };
          }
        }
        const resourcesToVerify: AnySignedResource[] = [
          appResourceToVerify,
          // We need to verify all APIs
          ...v2Apis.map((api) => ({
            api: api.apiPb as Api,
          })),
          // We also need to add to this list the application itself, as one of the resources to be verified.
          // We do that below.
        ];
        yield call(verifyResources, {
          resources: resourcesToVerify,
          agents,
          organization,
          branchName: branch,
        });
        yield put({
          type: ReduxActionTypes.UPDATE_APPLICATION_HASH_TREE,
          payload: { tree: hashTree },
        });
      } catch (e: any) {
        yield put({
          type: ReduxActionErrorTypes.FETCH_APPLICATION_ERROR,
          payload: {
            error: e,
          },
        });
        yield put({
          type: ReduxActionTypes.SAFE_CRASH_SUPERBLOCKS_REQUEST,
          payload: {
            code: ERROR_CODES.VERIFY_RESOURCES_FAILED,
          },
        });
        return;
      }
    }

    if (!isEmpty(page)) {
      if (mode === APP_MODE.EDIT || mode === APP_MODE.PREVIEW) {
        yield call(putFetchPageSagaResult, page, false);
      } else {
        yield call(putFetchPageSagaResult, page, true);
      }
    }

    yield put({
      type: ReduxActionTypes.FETCH_PAGE_LIST_SUCCESS,
      payload: { pages, applicationId: application.id },
    });

    yield all([
      call(getAllApisSaga.setStore, v1Apis),
      call(getAllV2ApisSaga.setStore, v2Apis),
      integrations.length > 0 &&
        call(getSupersetDatasourcesSaga.setStore, integrations),
    ]);

    if (application.settings?.theme?.typographies) {
      application.settings.theme.typographies = {
        ...getDefaultTypographies(application.settings?.theme?.version), // ensure that all typographies are present
        ...application.settings?.theme?.typographies,
      };
    }

    yield put(fetchApplicationSuccess(application));

    // The reducer has a fallback in case the application was created before multi-page
    const allRoutes: ReturnType<typeof getRoutesList> =
      yield select(getRoutesList);
    const { routeDefinition, params } = getMatchingRoute(
      window.location.href,
      allRoutes,
    );

    if (routeDefinition) {
      yield put(
        updateCurrentRoute({
          routeDef: routeDefinition,
          params: params ?? {},
          isNewPage: true,
        }),
      );
    }

    // start updating the profiles that used to generate global profiles obj
    yield updateProfiles();

    yield put({
      type: ReduxActionTypes.UPDATE_LAST_SUCCESSFUL_WRITE,
      payload: application.updated,
    });
    sendEventToEmbedder({
      type: OutgoingMessage.APP_LOADED,
      data: {
        appId: application.id,
        appName: application.name,
      },
    });
  } catch (error: any) {
    const errorType = error?.errorType;
    sendEventToEmbedder({
      type: OutgoingMessage.AUTH_ERROR,
      data: {
        error:
          errorType === SuperblocksError.JWTExpired
            ? "token_expired"
            : errorType === SuperblocksError.APIKeyInvalid
              ? "unauthorized"
              : "access_denied",
        message:
          "Failed to fetch application. Either this application does not exist or you do not have access to it.",
      },
    });
    resetCachedCurrentBranch(applicationId);
    console.log("err", error);
    yield put({
      type: ReduxActionErrorTypes.FETCH_APPLICATION_ERROR,
      payload: {
        error,
      },
    });
    const code = get(error, "code", ERROR_CODES.SERVER_ERROR);
    logger.error(
      `Fetch Application failed:
code: ${code}, error:
${stringifyError(error)}
`,

      {
        superblocks_ui_error_type:
          code !== 404
            ? UIErrorType.CRASH_APP_ERROR
            : UIErrorType.SERVER_ERROR_4XX,
        superblocks_ui_error_code: code,
      },
    );
    // crash application with error page if can't fetch application
    yield put({
      type: ReduxActionTypes.SAFE_CRASH_SUPERBLOCKS_REQUEST,
      payload: {
        code,
        reason: branch ? SafeCrashReason.BRANCH_NOT_FOUND : undefined,
      },
    });
  } finally {
    rpcClient?.close();
  }
}

function* updateNonVersionedApplicationSettingsSaga(
  action: ReturnType<typeof updateNonVersionedApplicationSettings>,
): Generator<any, any, any> {
  let unlock = NOOP;
  const currentApplicationId: string = yield select(getCurrentApplicationId);
  const currentPageId: string = yield select(getCurrentPageId);
  let shouldRefetchApis = false;
  try {
    // TODO: this lock is not always useful since the saga is put in takeLatest, it will be cancelled if new task comes in and lock will be released while the response it not received.
    unlock = yield call(lockApis, action.payload.id);
    const lastSuccessfulWrite: number = yield select(selectLastSuccessfulWrite);

    const request: UpdateNonVersionedApplicationSettingsRequest = {
      ...action.payload,
      lastSuccessfulWrite,
    };
    // update settings redux state optimistically

    // this block will only run in editor mode
    yield put(updateApplicationSettings(request.settings));
    const appProfiles: Profiles = yield select(getAppProfilesInCurrentMode);
    // this could be undefined if old default profile is deleted
    const oldDefaultId = appProfiles.default?.id;
    // start updating the profiles that used to generate global profiles obj
    yield call(updateProfiles);

    shouldRefetchApis =
      oldDefaultId !== request.settings?.profiles?.editor.defaultProfileId;

    const superblocksSupportUpdateEnabled: boolean = yield select(
      selectFlagById,
      Flag.ENABLE_SUPERBLOCKS_SUPPORT_UPDATES,
    );
    const response: ApiResponse<PutApplicationSettingsUpdateResponseBody> =
      yield call(
        ApplicationApi.updateNonVersionedApplicationSettings,
        request,
        superblocksSupportUpdateEnabled,
      );
    const isValidResponse = yield validateResponse(response);
    if (isValidResponse && request) {
      yield put({
        type: ReduxActionTypes.UPDATE_APPLICATION_SUCCESS,
      });
    }

    yield put({
      type: ReduxActionTypes.UPDATE_LAST_SUCCESSFUL_WRITE,
      payload: response.data.updated,
    });
    if (shouldRefetchApis) {
      //refetch apis after default profile changes
      const environment: string = yield select(getEnvironment);
      const controlFlowEnabled: boolean = yield select(
        selectControlFlowEnabledDynamic,
      );
      yield callSagas(
        [
          controlFlowEnabled
            ? getAllV2ApisSaga.apply({
                applicationId: currentApplicationId,
                pageId: currentPageId,
              })
            : getAllApisSaga.apply({
                applicationId: currentApplicationId,
                environment,
                isPublished: false,
              }),
        ],
        { throwOnFailure: true },
      );
    }
  } catch (error) {
    yield put({
      type: ReduxActionErrorTypes.UPDATE_APPLICATION_ERROR,
      payload: {
        error,
      },
    });
  } finally {
    unlock();
  }
}

function* updateApplicationMetadataSaga(
  action: ReturnType<typeof updateApplicationMetadata>,
): Generator<any, any, any> {
  const currentApplicationId: string = yield select(getCurrentApplicationId);
  try {
    const request: UpdateApplicationMetadataRequest = action.payload;
    const superblocksSupportUpdateEnabled: boolean = yield select(
      selectFlagById,
      Flag.ENABLE_SUPERBLOCKS_SUPPORT_UPDATES,
    );
    const response: Awaited<
      ReturnType<typeof ApplicationApi.updateApplicationMetadata>
    > = yield call(
      ApplicationApi.updateApplicationMetadata,
      request,
      superblocksSupportUpdateEnabled,
    );
    const isValidResponse = yield validateResponse(response);
    if (isValidResponse && request) {
      if (request.name) {
        Toaster.show({
          text: "Application name updated",
          variant: Variant.success,
        });
      }
      yield put({
        type: ReduxActionTypes.UPDATE_APPLICATION_SUCCESS,
      });
    }
    if (isValidResponse && request.id === currentApplicationId) {
      if (request.name) {
        yield put({
          type: ReduxActionTypes.CURRENT_APPLICATION_NAME_UPDATE,
          payload: request.name,
        });
      }
      if (
        request.isPublic != null &&
        request.isPublic === response.data?.isPublic
      ) {
        yield put({
          type: ReduxActionTypes.CURRENT_APPLICATION_IS_PUBLIC_UPDATE,
          payload: response.data.isPublic,
        });
      }
    }
    yield put({
      type: ReduxActionTypes.UPDATE_LAST_SUCCESSFUL_WRITE,
      payload: response.data.updated,
    });
  } catch (error) {
    yield put({
      type: ReduxActionErrorTypes.UPDATE_APPLICATION_ERROR,
      payload: {
        error,
      },
    });
  }
}

function* deleteApplicationSaga(
  action: ReturnType<typeof deleteApplicationInit>,
): Generator<any, any, any> {
  try {
    Toaster.show({
      text: DELETING_APPLICATION,
    });
    const request = action.payload;
    const response: Awaited<
      ReturnType<typeof ApplicationApi.deleteApplication>
    > = yield call(ApplicationApi.deleteApplication, request);
    const isValidResponse = yield validateResponse(response);
    if (isValidResponse) {
      yield put(deleteApplicationSuccess({ id: (response.data as any).id }));
    }
  } catch (error) {
    yield put(deleteApplicationError());
    const message = `Could not delete application: ${
      "message" in (error as any) ? (error as any).message : error
    }`;
    sendErrorUINotification({ message });
  }
}

// export to make it testable
export function* duplicateApplicationSaga(
  action: ReturnType<typeof duplicateApplicationInit>,
): Generator<any, any, any> {
  try {
    Toaster.show({
      text: DUPLICATING_APPLICATION,
    });
    const request = action.payload;
    // If using OPA, use the RPC code path
    let rpcClient: undefined | StdISocketRPCClient;
    const organization: ReturnType<typeof selectOnlyOrganization> =
      yield select(selectOnlyOrganization);
    if (orgIsOnPremise(organization)) {
      const agents: Agent[] = yield call(getOpaAgents);
      const shouldUseWs: boolean = yield call(getShouldSignAndVerify, agents);
      if (shouldUseWs) {
        if (agents.length === 0) {
          yield put(duplicateApplicationError());
          yield put({
            type: ReduxActionTypes.SAFE_CRASH_SUPERBLOCKS_REQUEST,
            payload: {
              code: ERROR_CODES.NO_AGENTS,
            },
          });
          return;
        }
        rpcClient = yield call(connectToISocketRPCServer, agents, organization);
      }
    }
    let response: Awaited<ReturnType<typeof ApplicationApi.cloneApplication>>;
    try {
      response = rpcClient
        ? yield call(rpcClient.call.v2.application.clone, request)
        : yield call(ApplicationApi.cloneApplication, request);
    } finally {
      rpcClient?.close();
    }
    const isValidResponse = yield validateResponse(response);
    if (isValidResponse) {
      const application = response.data;
      yield put(duplicateApplicationSuccess());

      const isCodeModeEnabled: boolean = yield select(
        selectFlagById,
        Flag.ENABLE_CODE_MODE,
      );
      const pageURL = getEditorBasePath(EditorRoute.EditApplication, {
        applicationId: application.id,
      });
      if (isCodeModeEnabled) {
        window.location.href = `/code-mode${pageURL}`;
      } else {
        const navigate = yield getContext("navigate");
        navigate(pageURL);
      }
    }
  } catch (error) {
    yield put(duplicateApplicationError());
    const message = `Could not clone application: ${
      "message" in (error as any) ? (error as any).message : error
    }`;
    sendErrorUINotification({ message });
  }
}

export function* createApplicationSaga(
  action: ReturnType<typeof createApplicationInit>,
): Generator<any, any, any> {
  const { applicationName, folderId, orgId } = action.payload;
  const featureFlags: ReturnType<typeof selectFlags> =
    yield select(selectFlags);

  const initialPageDSL: PageDSL8 = yield call(
    getDefaultPageLayout,
    8,
    featureFlags,
  );
  try {
    const request: PostApplicationCreateRequestBody = {
      name: applicationName,
      folderId,
      organizationId: orgId,
      initialPageDSL,
      settings: {
        theme: BASE_THEME_NEW_APPS,
      },
    };
    // If signing is required, use the RPC code path
    let rpcClient: undefined | StdISocketRPCClient;
    const organization: ReturnType<typeof selectOnlyOrganization> =
      yield select(selectOnlyOrganization);
    if (orgIsOnPremise(organization)) {
      const agents: Agent[] = yield call(getOpaAgents);
      const shouldSign: boolean = yield call(getShouldSignAndVerify, agents);
      if (shouldSign) {
        if (agents.length === 0) {
          throw new Error("Could not sign application, no OPA agents found");
        }
        rpcClient = yield call(connectToISocketRPCServer, agents, organization);
      }
    }
    let response: Awaited<ReturnType<typeof ApplicationApi.createApplication>>;
    try {
      response = rpcClient
        ? yield call(rpcClient.call.v2.application.create, request)
        : yield call(ApplicationApi.createApplication, request);
    } finally {
      rpcClient?.close();
    }
    const isValidResponse = yield validateResponse(response);
    if (isValidResponse) {
      const application = response.data;
      AnalyticsUtil.logEvent("CREATE_APP", {
        appName: application.name,
      });
      yield put(createApplicationSuccess(application));
      yield put({
        type: ReduxActionTypes.UPDATE_APPLICATION_HASH_TREE,
        payload: { tree: application.signature?.root },
      });

      const isCodeModeEnabled = response.data.devEnvEnabled ?? false;
      const pageURL = getEditorBasePath(EditorRoute.EditApplication, {
        applicationId: application.id,
      });
      if (isCodeModeEnabled) {
        window.location.href = `/code-mode${pageURL}`;
      } else {
        const navigate = yield getContext("navigate");
        navigate(pageURL);
      }
    }
  } catch (error: any) {
    sendErrorUINotification({
      message: "Could not create application",
      description: error.message,
    });
    yield put(createApplicationError());
  }
}

function* setProfileSaga(
  action: ReduxAction<{
    profileId: string;
  }>,
): Generator<any, any, any> {
  yield updateProfiles(action.payload.profileId);
}

function* fetchApplicationPermissionsSaga(
  action: ReturnType<typeof fetchApplicationPermissions>,
): Generator<any, any, any> {
  const accessMode: ReturnType<typeof getAccessMode> =
    yield select(getAccessMode);
  if (
    accessMode === AccessMode.EXTERNAL_USER ||
    accessMode === AccessMode.VISITOR
  ) {
    return;
  }

  const applicationId = action.payload.applicationId;
  try {
    const response = yield call(getApplicationPermissions, { applicationId });
    if (response) {
      yield put(fetchApplicationPermissionsSuccess(response.permissions));
    } else {
      yield put(
        fetchApplicationPermissionsError(
          "Failed to fetch application permissions",
        ),
      );
    }
  } catch (e: any) {
    yield put(fetchApplicationPermissionsError(e?.message ?? ""));
  }
}

export default function* applicationSagas() {
  yield all([
    takeLatest(
      updateNonVersionedApplicationSettings.type,
      updateNonVersionedApplicationSettingsSaga,
    ),
    takeEvery(updateApplicationMetadata.type, updateApplicationMetadataSaga),
    takeLatest(getAllApplicationsInit.type, getAllApplicationSaga),
    takeLatest(fetchApplication.type, fetchApplicationSaga),
    takeLatest(createApplicationInit.type, createApplicationSaga),
    takeLatest(deleteApplicationInit.type, deleteApplicationSaga),
    takeLatest(duplicateApplicationInit.type, duplicateApplicationSaga),
    takeLatest(ReduxActionTypes.SET_PROFILE, setProfileSaga),
    takeLatest(
      fetchApplicationPermissions.type,
      fetchApplicationPermissionsSaga,
    ),
  ]);
}
