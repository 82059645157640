import { Base64 } from 'js-base64';

// This module is used by the FE and the BE, so it must be compatible with both.
// For both sides, we are using the Web Crypto API. This API is supported by all modern browsers, and
// newer versions of Node.js.

const isNodeJS = typeof globalThis.process?.versions?.node !== 'undefined';

// The Web Crypto API entry point
let crypto = globalThis.crypto;

// TODO(george): remove this function once we do not need support for Node.js 15-19
async function ensureWebCryptoApiIsLoaded() {
  if (isNodeJS) {
    const nodeJsCrypto = await import(/* webpackIgnore: true */ 'crypto');
    crypto = nodeJsCrypto.webcrypto as unknown as Crypto;
  }
  if (!crypto) {
    throw new Error('Web Crypto API is not available');
  }
}

/**
 * Returns the SHA-256 hash of the given message.
 * @param message The message to hash, that could be a string or a binary. If a string is given, it will be encoded as UTF-8.
 * @returns The raw SHA-256 hash of the given message in binary form.
 */
export async function sha256(message: string | BufferSource): Promise<ArrayBuffer> {
  await ensureWebCryptoApiIsLoaded();
  // If the message is a string, we need convert it to a Uint8Array because the Web Crypto API does not support strings.
  // Otherwise, we can pass the message directly to the Web Crypto API.
  if (typeof message === 'string') {
    // Note: `TextEncoder` always encodes as UTF-8.
    message = new TextEncoder().encode(message);
  }
  return await crypto.subtle.digest('SHA-256', message);
}

/**
 * Returns the SHA-256 hash of the given message, encoded as a base64 string.
 * @param message The message to hash, that could be a string or a binary. If a string is given, it will be encoded as UTF-8.
 * @returns The SHA-256 hash of the given message, encoded as a base64 string.
 */
export async function sha256Base64(message: string | BufferSource): Promise<string> {
  const rawHash = await sha256(message);
  return Base64.fromUint8Array(new Uint8Array(rawHash));
}
