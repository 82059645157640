import { WidgetTypes } from "@superblocksteam/shared";
import {
  AI_COMPONENT_EDITOR_DEFAULT_HEIGHT,
  AI_COMPONENT_EDITOR_DEFAULT_WIDTH,
  AI_COMPONENT_EDITOR_GAP,
} from "constants/ai";
import { generateSectionComponentId } from "./generators";
const WIDGET_NAME_HEIGHT = 21;
const THRESHOLD = 50;

export const isSubProperty = (propertyName: string, key: string) => {
  const propertyNamePieces: string[] = propertyName.split(".");
  const keyPieces: string[] = key.split(".");
  return (
    keyPieces.length > propertyNamePieces.length &&
    keyPieces.slice(0, propertyNamePieces.length).join(".") === propertyName
  );
};

export const getSimplifiedTypeFromWidgetType = (widgetType: string) => {
  if (widgetType === WidgetTypes.CANVAS_WIDGET) {
    return "section_column";
  }
  return widgetType.toLowerCase().replace("_widget", "");
};

export const getInitialAiModalPosition = ({
  widgetId,
  widgetType,
  insertionPoint,
}: {
  widgetId: string;
  widgetType: keyof typeof WidgetTypes;
  insertionPoint?: {
    x: number;
    y: number;
  };
}): Promise<{ x: number; y: number }> | { x: number; y: number } => {
  const isInIframe = window !== window.parent;

  if (!isInIframe) {
    return new Promise((resolve) => {
      const handler = (e: MessageEvent) => {
        if (e.data.type === "get-ai-modal-position-result") {
          window.removeEventListener("message", handler);
          resolve(e.data.payload.position);
        }
      };

      window.addEventListener("message", handler);
      setTimeout(() => {
        window.removeEventListener("message", handler);
        resolve({ x: 0, y: 0 });
      }, 1000);

      const iframe = document.querySelector<HTMLIFrameElement>(
        '[data-test="sb-iframe"]',
      );
      if (!iframe?.contentWindow) {
        window.removeEventListener("message", handler);
        resolve({ x: 0, y: 0 });
        return;
      }

      iframe.contentWindow.postMessage(
        {
          type: "get-ai-modal-position",
          payload: { widgetId, widgetType },
        },
        "*",
      );
    });
  }

  const canvasRect =
    document.getElementById("root")?.getBoundingClientRect() ??
    document.querySelector("[data-test='sb-iframe']")?.getBoundingClientRect();

  if (!canvasRect) {
    return { x: 0, y: 0 };
  }

  if (widgetType === WidgetTypes.PAGE_WIDGET) {
    return {
      x: canvasRect.right - AI_COMPONENT_EDITOR_DEFAULT_WIDTH,
      y: canvasRect.top,
    };
  }

  if (insertionPoint) {
    let y = insertionPoint.y + AI_COMPONENT_EDITOR_GAP + WIDGET_NAME_HEIGHT;
    let x = insertionPoint.x;
    const distanceToRight = canvasRect.right - insertionPoint.x;
    if (
      distanceToRight &&
      distanceToRight < AI_COMPONENT_EDITOR_DEFAULT_WIDTH
    ) {
      const adjustment = AI_COMPONENT_EDITOR_DEFAULT_WIDTH - distanceToRight;
      x = insertionPoint.x - adjustment;
    }
    // move above the name pill if it's going to be cut off
    if (y + AI_COMPONENT_EDITOR_DEFAULT_HEIGHT > canvasRect.bottom) {
      y =
        insertionPoint.y -
        AI_COMPONENT_EDITOR_DEFAULT_HEIGHT -
        AI_COMPONENT_EDITOR_GAP;
    }
    return { x, y };
  }

  // no insertion point (i.e. just cmd+i with a component selected)
  let element: HTMLElement | null = null;
  if (widgetType === WidgetTypes.SECTION_WIDGET) {
    element = document.getElementById(generateSectionComponentId(widgetId));
  } else {
    element = document.getElementById(`widget-${widgetId}`);
  }

  const rect = element?.getBoundingClientRect();
  if (!rect) {
    return { x: 0, y: 0 };
  }

  // For these widgets, we want to spawn the AI editor at the top right of the widget
  // right by the name pill
  if (
    [WidgetTypes.SECTION_WIDGET, WidgetTypes.CANVAS_WIDGET].includes(
      widgetType as WidgetTypes,
    )
  ) {
    return {
      x: rect.right - AI_COMPONENT_EDITOR_DEFAULT_WIDTH,
      y: rect.top,
    };
  }

  let distanceToBottom = canvasRect.bottom - rect.bottom;
  const distanceToTop = rect.top - canvasRect.top;
  const distanceToLeft = rect.left - canvasRect.left;
  let distanceToRight = canvasRect.right - rect.right;
  // prefered order is: bottom, right, left, top
  const position = {
    x: rect.left,
    y: rect.bottom + AI_COMPONENT_EDITOR_GAP,
  };
  // if this is too low on the page, move it somewhere else
  if (
    distanceToBottom < AI_COMPONENT_EDITOR_DEFAULT_HEIGHT ||
    distanceToBottom < distanceToTop - THRESHOLD
  ) {
    // try to move to the right
    if (distanceToRight >= AI_COMPONENT_EDITOR_DEFAULT_WIDTH) {
      position.x = rect.right + AI_COMPONENT_EDITOR_GAP;
      position.y = rect.top;
    }
    // try to move to the left
    else if (distanceToLeft >= AI_COMPONENT_EDITOR_DEFAULT_WIDTH) {
      position.x =
        position.x -
        AI_COMPONENT_EDITOR_DEFAULT_WIDTH -
        AI_COMPONENT_EDITOR_GAP;
      position.y = rect.top;
    } else {
      // try to move above the widget
      if (distanceToTop > AI_COMPONENT_EDITOR_DEFAULT_HEIGHT) {
        position.y =
          rect.top -
          AI_COMPONENT_EDITOR_DEFAULT_HEIGHT -
          AI_COMPONENT_EDITOR_GAP;
      } else {
        // otherwise, just move it to the top of the widget (overlapping it)
        position.y = rect.top;
      }
    }
  }

  // final adjustments to make sure the AI editor is fully visible
  distanceToRight = canvasRect.right - position.x;
  if (distanceToRight < AI_COMPONENT_EDITOR_DEFAULT_WIDTH) {
    const adjustment = AI_COMPONENT_EDITOR_DEFAULT_WIDTH - distanceToRight;
    position.x = position.x - adjustment;
  }

  distanceToBottom = canvasRect.bottom - position.y;
  if (distanceToBottom < AI_COMPONENT_EDITOR_DEFAULT_HEIGHT) {
    const adjustment = AI_COMPONENT_EDITOR_DEFAULT_HEIGHT - distanceToBottom;
    position.y = position.y - adjustment;
  }
  return position;
};
